import { makeStyles, Theme, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import EconomicQualitativeFactorAdjustment from '../../../../containers/economicqualitativefactoradjustment/EconomicQualitativeFactorAdjustment';
import ModelAssumptionsTable from '../../../../containers/tables/ModelAssumptionsTable';
import { colors } from '../../../../theme';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { CeclApiState } from '../../../../store/ceclapi/types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grid: {
            marginTop: theme.spacing(10),
            position: 'relative',
        },
        paddingLess: {
            padding: 0,
        },
        title: {
            width: '100%',
            textAlign: 'left',
            fontSize: 35,
            paddingLeft: theme.spacing(2),
        },
        item: {
            position: 'relative',
        },
        bottom: {
            position: 'absolute',
            bottom: 0,
            backgroundColor: colors.fadedBlue,
        },
        dialogDivider: {
            color: colors.black,
        },
        description: {
            marginTop: theme.spacing(4),
            fontStyle: 'italic',
        },
    })
);

interface ModalAssumptionsProps {
    noScroll?: boolean;
}

const ModelAssumptions: React.FC<ModalAssumptionsProps> = (props) => {
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const classes = useStyles();
    const economicQualitativeAdjustmentFactor = cecl.calculatedLoss?.economicQualitativeAdjustmentFactor ? cecl.calculatedLoss?.economicQualitativeAdjustmentFactor * 100 : 0;

    React.useEffect(() => {}, []);

    return (
        <div id="modelAssumptions">
            <Typography className={classes.title}>Model Assumptions</Typography>
            <ModelAssumptionsTable noScroll={props.noScroll} />
            <EconomicQualitativeFactorAdjustment value={economicQualitativeAdjustmentFactor} />
            <div className={classes.description}>
                * Loan Quality Qualitative Factor Adjustments reflect the amount of change in expected losses due to
                improvement or deterioration in loan quality.
                <br />
                ** Economic Qualitative Factor Adjustments reflect the amount of change in expected losses due to
                improvement or deterioration in the economy (national or local).
            </div>
        </div>
    );
};

export default ModelAssumptions;
