export interface UserInfo extends ApiResponse {
  objectId: string;
  state: number;
  city: string;
  displayName: string;
  givenName: string;
  emailAddress: string;
  jobTitle: string;
  postalCode: string;
  stateProvince: string;
  surname: string;
  userIsNew: boolean;
  rtNumber: string;
  creditUnionName: string;
  accountEnabled: boolean;
  disableReports: boolean;
  disableUpload: boolean;
  streetAddress: string;
  accountNumbers: AccountNumber[];
  creditUnionAccountId: string;
}

export interface AccountNumber extends ApiResponse {
  name: string;
  RoutingNumber: string;
  account_Name__c: string;
  account: Account;
  account_No_Type__c: string;
  acct_No_Status__c: string;
  attributes: Attributes;
  CCUN_CU_Name__c?: any;
  Charter_No__c?: any;
  Account_Name__r: Account;
  contactName: string;
  email: string;
  selected: boolean;
}

export interface Contact extends ApiResponse {
  accountId: string;
  lastName: string;
  firstName: string;
  email: string;
  Is_Active__c: boolean;
  attributes: Attributes;
  id: string;
  Is_CEO_CFO__c: boolean;
  Functional_Title__c: string;
}

export interface Attributes {
  type: string;
  url: string;
}

export interface Account {
  name: string;
  attributes: Attributes;
}

export interface Account {
  attributes: Attributes;
  Name: string;
}

export interface AcceptUser {
  userId: string;
  rtNumber: string;
  salesforceAccountId: string;
  envelopeId: string;
  creditUnionName: string;
}

export type ApiResponse = Record<string, any>;

export enum UserManagementActionTypes {
  UPDATE_USER_REQUEST = '@@userManagement/UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS = '@@userManagement/UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR = '@@userManagement/UPDATE_USER_ERROR',
  ACCEPT_USER_REQUEST = '@@userManagement/ACCEPT_USER_REQUEST',
  ACCEPT_USER_LOADING = '@@userManagement/ACCEPT_USER_LOADING',
  ACCEPT_USER_SUCCESS = '@@userManagement/ACCEPT_USER_SUCCESS',
  ACCEPT_USER_ERROR = '@@userManagement/ACCEPT_USER_ERROR',
}

export interface UserManagementState {
  readonly loading: boolean;
  readonly sfLoading: boolean;
  readonly sfErrors?: boolean;
  readonly data?: UserInfo[];
  readonly selected?: UserInfo;
  readonly errors?: string;
  readonly accepted?: any[];
  readonly acceptLoading?: boolean;
  readonly updated?: boolean;
}

export interface VerifyUserSubmission {
  userId: string;
  rtNumber: string;
  salesforceAccountId: string;
  creditUnionName: string;
  envelopeId: string;
}
