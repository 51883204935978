import { Reducer } from 'redux';
import { UserManagementState, UserManagementActionTypes } from './types';

export const initialState: UserManagementState = {
  data: undefined,
  errors: undefined,
  sfErrors: undefined,
  selected: undefined,
  loading: false,
  sfLoading: false,
  acceptLoading: false,
  updated: undefined,
  accepted: [],
};

export const UserManagementReducer: Reducer<UserManagementState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UserManagementActionTypes.UPDATE_USER_REQUEST:
      return { ...state, loading: true, updated: false };
    case UserManagementActionTypes.UPDATE_USER_SUCCESS:
      return { ...state, loading: false, updated: true };
    case UserManagementActionTypes.UPDATE_USER_ERROR:
      return { ...state, loading: false, errors: action.payload };
    case UserManagementActionTypes.ACCEPT_USER_REQUEST:
      return { ...state, acceptLoading: true };
    case UserManagementActionTypes.ACCEPT_USER_SUCCESS:
      return { ...state, acceptLoading: false, accepted: [action.payload] };
    case UserManagementActionTypes.ACCEPT_USER_ERROR:
      return { ...state, acceptLoading: false, errors: action.payload };
    default:
      return state;
  }
};
