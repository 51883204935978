import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

export const colors = {
  tableHoverbg: 'rgb(244, 248, 252)',
  sunflowerYellow: '#fadc00',
  orangeyYellow: '#fcb714',
  pinkishRed: '#ed2c37',
  lipstick: '#ce202f',
  lipstickShadow: 'rgba(111, 11, 19, 0.62)',
  sectionTitle: 'rgba(196, 204, 216, 1.00)',
  lightSteelBlue: 'rgba(169, 193, 224, 1.00)',
  peacockBlue: '#004fa5',
  white: '#fff',
  white10: 'rgba(255, 255, 255, 0.1)',
  white05: 'rgba(255, 255, 255, 0.05)',
  paleGrey: '#f0f5fa',
  nardoGrey: `#a0a5ad`,
  darkGrey: '#626262',
  veryLightBlue: '#dde9ff',
  uglyBlue: '#326195',
  windowsBlue: '#397cc6',
  blackTwo: '#1f1f1f',
  skyBlue: '#6cb2ff',
  iceBlue: '#eaf1f8',
  navyBlue: '#1b3d6d',
  darkSkyBlue: '#3c92f0',
  signupBlue: '#0773e8',
  loadMoreBlue: '#478cc6',
  black: '#000',
  fadedBlack: 'rgba(0, 0, 0, 0.8)',
  success: '#4BB543',
};

export const theme = {
  breakpoints: [32, 48, 64],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [12, 14, 16, 20, 24, 36, 48, 80, 96],
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em',
  },
  fonts: {
    serif: 'athelas, georgia, times, serif',
    sansSerif:
      '-apple-system, "Montserrat", "Noto Sans", BlinkMacSystemFont, "avenir next", avenir, "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial, sans-serif',
  },
  borders: [0, '1px solid', '2px solid', '4px solid', '8px solid', '16px solid', '32px solid'],
  radii: [0, 2, 4, 16, 9999, '100%'],
  width: [16, 32, 64, 128, 256],
  heights: [16, 32, 64, 128, 256],
  maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
  modes: {
    light: {
      name: 'light',
      text: '#131217',
      bg: '#fff',
      secondary: '#47444e',
    },
    dark: {
      name: 'dark',
      text: '#dcdcdc',
      bg: '#131217',
      secondary: '#47444e',
    },
  },
};
/**
 * Colors
 */
export let muiTheme = createMuiTheme({
  palette: {
    background: {
      default: colors.white,
    },
    primary: {
      main: colors.lipstick,
    },
    secondary: {
      main: colors.signupBlue,
    },
    // success: green,
  },
  typography: {
    fontFamily: theme.fonts.sansSerif,
    h1: {
      fontSize: theme.fontSizes[6],
      fontWeight: 900,
      color: colors.sectionTitle,
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 16,
      },
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          fontFamily: theme.fonts.sansSerif,
        },
        '.MuiTooltip-tooltip': {
          fontSize: 16,
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
});

muiTheme = responsiveFontSizes(muiTheme);

export type Colors = typeof colors;
export type Theme = typeof muiTheme & Colors;
