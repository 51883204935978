import { action } from 'typesafe-actions';
import {
  UserManagementActionTypes,
  UserInfo,
  AcceptUser,
  VerifyUserSubmission,
} from './types';

export const updateUserRequest = (
  id: string,
  patch: any,
  verifyUserSubmission?: VerifyUserSubmission
) =>
  action(UserManagementActionTypes.UPDATE_USER_REQUEST, {
    id: id,
    data: patch,
    verifyUserSubmission: verifyUserSubmission,
  });
export const updateUserSuccess = (data: UserInfo) =>
  action(UserManagementActionTypes.UPDATE_USER_SUCCESS, data);
export const updateUserError = (message: string) =>
  action(UserManagementActionTypes.UPDATE_USER_ERROR, message);

export const acceptUserRequest = (request: AcceptUser) =>
  action(UserManagementActionTypes.ACCEPT_USER_REQUEST, request);
export const acceptUserSuccess = (id: string) =>
  action(UserManagementActionTypes.ACCEPT_USER_SUCCESS, id);
export const acceptUserError = (message: string) =>
  action(UserManagementActionTypes.ACCEPT_USER_ERROR, message);