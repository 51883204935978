import React from 'react';
import { AppBarProps, Toolbar, IconButton, makeStyles, Theme, createStyles, Link } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { colors } from '../../theme';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bar: {
            backgroundColor: colors.gray,
            position: 'relative',
            paddingLeft: 240,
            height: 70,
        },
        rightJustified:{
            color: colors.darkBlue,
            position: 'absolute',
            bottom: theme.spacing(1.5),
            right: theme.spacing(1),
        },
        topButton: {
            color: colors.darkBlue,
            position: 'absolute',
            bottom: theme.spacing(1),
            right: theme.spacing(1),
        },
        link: {
            color: colors.darkBlue,
            paddingRight: theme.spacing(5),
            fontSize:10
        },
        links: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            paddingLeft: theme.spacing(5),
        },
        arrow: {
            color: colors.darkBlue,
            paddingRight: theme.spacing(5),
        }
    })
);

const AppFooter: React.FC<AppBarProps> = (props) => {
    const classes = useStyles();

    const scrollToTop = (e: any) => {
        e.stopPropagation();

        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <footer className={classes.bar}>
            <div className={classes.links}>
                <NavLink
                    className={classes.link}                                      
                    to="/home"
                >
                    HOME
                </NavLink>
                <NavLink
                    className={classes.link}                                  
                    to="/home/contact-us"
                >
                    CONTACT
                </NavLink>
                <NavLink
                    className={classes.link}                  
                    to="/home/model-overview-and-regulations"                    
                >
                    MODEL OVERVIEW AND REGULATIONS
                </NavLink>

                <div className={classes.rightJustified}>
                    <NavLink
                        className={classes.link} 
                        to='home/privacy-policy'                                                            >
                        PRIVACY POLICY
                    </NavLink>
                    <NavLink
                        className={classes.link}
                        to='home/termsofuse'                                        
                    >
                        TERMS OF SERVICE
                    </NavLink>
                    <IconButton
                        aria-label="scroll back to top"
                        className={classes.arrow}
                        onClick={(e) => scrollToTop(e)}
                    >
                        <ArrowUpwardIcon fontSize="inherit" />
                    </IconButton>
                </div>
            </div>
        </footer>
    );
};

export default AppFooter;
