import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import Table, { Column, TableProps } from '../../components/table/Table';
import { ApplicationState } from '../../store';
import { AmortizationSchedule, CeclApiState, LossesDataPoint, LossForecastResult } from '../../store/ceclapi/types';
import { colors } from '../../theme';
import { formatDate, formatNumber, getPreviousBusinessDate, convertToPercentage} from '../../utils/helpers';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            maxWidth: '80vh',
            overflowY: 'auto',
            overflowX: 'auto',
        },
        noScroll: {
            '& > *': {
                '& > *': {
                    overflowX: 'unset',
                },
            },
        },
        gap: {
            backgroundColor: colors.white,
        },
        paddingLess: {
            padding: 0,
        },
        header: {
            width: '100%',
            textAlign: 'center',
            fontSize: 16,
            color: colors.white,
            paddingLeft: theme.spacing(2),
            fontWeight: 'bold',
        },
        headerItem: {
            backgroundColor: colors.fadedBlue,
            paddingBottom: theme.spacing(1),
            paddingTop: theme.spacing(1),
        },
        title: {
            backgroundColor: colors.blue,
            width: '100%',
            textAlign: 'left',
            fontSize: 16,
            color: colors.white,
            paddingLeft: theme.spacing(2),
            paddingBottom: theme.spacing(1),
            paddingTop: theme.spacing(1),
            fontWeight: 'bold',
        },
        value: {
            backgroundColor: colors.blue,
            width: '100%',
            textAlign: 'center',
            fontSize: 16,
            color: colors.white,
            paddingLeft: theme.spacing(2),
            paddingBottom: theme.spacing(1),
            paddingTop: theme.spacing(1),
            fontWeight: 'bold',
        },
        dialogDivider: {
            color: colors.black,
        },
        grid: {
            backgroundColor: colors.blue,
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '100%',
        },
    })
);

const bodyFontSize = "0.9000rem";
const headerFontSize = 16;

const defaultProjectedMonthlyLossesColumns: Column[] = [
    {
        name: 'Date',
        label: 'Date',
        numeric: false,
        headerColor: colors.lightGray3,            
        centerHeader: true,
        bodyFontSize: bodyFontSize,
        headerFontSize: headerFontSize,
        alignRight: true,
    },
    {
        name: 'Economic Index',
        label: '',
        numeric: false,
        headerColor: colors.lightGray3,            
        centerHeader: true,
        bodyFontSize: bodyFontSize,
        headerFontSize: headerFontSize,
        alignRight: true,
    },
    {
        name: 'Forecasted National - All Cus',
        label: 'Forecasted National - All CUs',
        numeric: false,
        headerColor: colors.lightGray3,
        bodyColor: colors.lightBlue,            
        centerHeader: true,
        bodyFontSize: bodyFontSize,
        headerFontSize: headerFontSize,
        alignRight: true,
    },
    {
        name: 'Forecasted CU',
        label: 'Forecasted CU',
        numeric: false,
        headerColor: colors.lightGray3,
        bodyColor: colors.lightBlue,            
        centerHeader: true,
        bodyFontSize: bodyFontSize,
        headerFontSize: headerFontSize,
        alignRight: true,
    },
    {
        name: 'Actual National - All Cus',
        label: 'Actual National - All CUs',
        numeric: false,
        headerColor: colors.lightGray3,
        bodyColor: colors.lightBlue,            
        centerHeader: true,
        bodyFontSize: bodyFontSize,
        headerFontSize: headerFontSize,
        alignRight: true,
    },
    {
        name: 'Actual CU',
        label: 'Actual CU',
        numeric: false,
        headerColor: colors.lightGray3,
        bodyColor: colors.lightBlue,            
        centerHeader: true,
        bodyFontSize: bodyFontSize,
        headerFontSize: headerFontSize,
        alignRight: true,
    },
];

interface ProjectedMonthlyLossesTableProps {
    result?: {
        backcast?: LossesDataPoint[],
        economicIndexHistory?: LossesDataPoint[],
        forecast?: LossForecastResult,
        historyCutoffDate?: Date,
    };
    title?: string;
}

const ProjectedMonthlyLossesTable: React.FC<ProjectedMonthlyLossesTableProps> = (props) => {
    const classes = useStyles();
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const [Headers, setHeaders] = React.useState({
        lookbackPeriod: 0,
        qFactor: 0.0,
    });
    const [TableProps, setTableProps] = React.useState({
        loading: true,
        dense: true,
        keyColumn: 'Date',
        rows: [],
        columns: [],
        pageSize: 15,
        selectable: false,
        hasBorder: true,
    } as TableProps);

    React.useEffect(() => {
        GetProjectedMonthlyLossesTable()
    }, [props.result]);

    function GetEconomicIndexValue(date: Date, economicIndexDictionary: Map<string, number>) {
        let economicIndex = 0;
        let economicIndexDate = new Date(date);

        while (economicIndex == 0){
            economicIndex = economicIndexDictionary.get(formatDate(economicIndexDate)) ?? 0;
            economicIndexDate = economicIndex != 0 ? economicIndexDate : getPreviousBusinessDate(economicIndexDate);
        }

        return economicIndex;
    }

    function GetProjectedMonthlyLossesTable() {
        if (!props.result.forecast?.pandILoanAmortizationSchedule?.length) {
            setTableProps({...TableProps, loading: true});
            return;
        }

        const economicIndexDictionary = new Map<string, number>();
        const backcastingPredictionsDictionary = new Map<string, AmortizationSchedule>();
        var fredIndex = cecl.forecastAssumptions?.indexes?.indexList.find(index => index.fredSeriesId === cecl.calculatedLoss.economicIndex);
        defaultProjectedMonthlyLossesColumns[1].label = fredIndex.fredLabel;

        props.result.economicIndexHistory.forEach((history) => {
            economicIndexDictionary.set(formatDate(history.item1), history.item2);
        });
        
        props.result.forecast.backcastingPandILoanAmortizationSchedule.forEach((prediction) => {
            backcastingPredictionsDictionary.set(formatDate(prediction.paymentDate), prediction);
        });

        var newRows = props.result && props.result.backcast
        .filter((history) => new Date(history.item1).getTime() >= props.result.historyCutoffDate.getTime())
        .map((history) => {
            const originalDate = new Date(history.item1);
            const modifiedDate = new Date(originalDate);
            modifiedDate.setDate(originalDate.getDate() + 1);

            var obj: any = {};
            obj['Date'] = formatDate(modifiedDate);
            obj['Economic Index'] = GetEconomicIndexValue(originalDate, economicIndexDictionary);
            obj['Forecasted National - All Cus'] = convertToPercentage(backcastingPredictionsDictionary.get(formatDate(originalDate))?.forecastedAllCUsValueAtEconomicIndicator) ?? '';
            obj['Forecasted CU'] = convertToPercentage(backcastingPredictionsDictionary.get(formatDate(originalDate))?.forecastedSpecificCULossAtAllCUsValue) ?? '';
            obj['Actual National - All Cus'] = convertToPercentage(history.item3);
            obj['Actual CU'] = convertToPercentage(history.item4);

            return obj;
        })
        .concat(props.result && props.result.forecast.pandILoanAmortizationSchedule
            .map((schedule) => {
                var obj: any = {};
                obj['Date'] = formatDate(schedule.paymentDate);
                obj['Economic Index'] = schedule.qFactorAdjustedForecastedEconomicIndexAtPaymentDate.toFixed(2);
                obj['Forecasted National - All Cus'] = convertToPercentage(schedule.forecastedAllCUsValueAtEconomicIndicator);
                obj['Forecasted CU'] = convertToPercentage(schedule.forecastedSpecificCULossAtAllCUsValue);
                obj['Actual National - All Cus'] = '';
                obj['Actual CU'] = '';

                return obj;
            })
        );

        setHeaders({
            ...Headers,
            lookbackPeriod: props.result.forecast.loanTypeLossAnalysisRequest.historicalLookbackPeriodYear,
            qFactor: props.result.forecast.pandILoanAmortizationSchedule[0].qFactorAdjustedForecastedSpecificCULossAtAllCUsValue
        })
        setTableProps((prevState) => ({
            ...prevState,
            columns: defaultProjectedMonthlyLossesColumns,
            rows: newRows,
            loading: false
        }));
    }

    return(
        <div className={classes.noScroll}>
            <Grid container direction="row" className={classes.grid} id="historical-levels-header">
                <Grid item xs={5} className={classes.gap}>
                    &nbsp;
                </Grid>
                <Grid item xs={4} className={classes.headerItem}>
                    <Typography className={classes.header}>Lookback Period (Years):</Typography>
                </Grid>
                <Grid item xs={3} className={classes.headerItem}>
                    <Typography className={classes.header}>Q Factor:</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography className={classes.title}>{props.title}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography className={classes.value}>
                        {Headers.lookbackPeriod}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.value}>
                        {formatNumber(Headers.qFactor)} %
                    </Typography>
                </Grid>
            </Grid>
            <Table
            loading = {TableProps.loading}
            columns={TableProps.columns}
            dense={TableProps.dense}
            rows={TableProps.rows}
            keyColumn={TableProps.keyColumn}
            selectable={TableProps.selectable}
            hasBorder={TableProps.hasBorder}
            pageSize={TableProps.pageSize}
            />
        </div>
    );
}

export default ProjectedMonthlyLossesTable;