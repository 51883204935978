import * as React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import GetTheme from './theme';
import { ApplicationState } from './store';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import { Provider, useSelector } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { Store } from 'redux';
import { ThemeProvider } from '@material-ui/core/styles';
import Pages from './pages';
import SessionTimeout from './components/session-tracker/session-tracker';
import Messages from './Messages'
interface MainProps {
    store: Store<ApplicationState>;
    history: History;
}

const Main: React.FC<MainProps> = ({ store, history }) => {
    const theme = GetTheme();
    
    return (        
            <Provider store={store}>
                <SessionTimeout />
                <ConnectedRouter history={history}>
                    <SnackbarProvider maxSnack={4} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <Pages />
                        </ThemeProvider>
                        <Messages/>
                    </SnackbarProvider>
                </ConnectedRouter>
            </Provider>
    );
};

export default Main;
