import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { ConfigState } from '../../store/config/types';
import Button from '../../components/button/Button';
import { toggleSideMenu } from '../../store/config/actions';
import { colors } from '../../theme';
import Table, { Column } from '../../components/table/Table';
import { useLocation } from 'react-router-dom';

interface TableProps {
    columns: Column[];
    dense: boolean;
    rows: any;
    keyColumn: string;
    loading: boolean;
    availableTables: string[];
}

const TestPage: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation<any>();
    const config = useSelector<ApplicationState, ConfigState>((state) => state.config);
    const [loading, setLoading] = React.useState(false);

    const [tableProps, setTableProps] = React.useState({
        loading: false,
        dense: true,
        keyColumn: 'id',
        availableTables: [],
        rows: [],
        columns: [],
    } as TableProps);

    React.useEffect(() => {
        console.log(location);
        const createData = (
            id: number,
            dessert: string,
            calories: number,
            fat: number,
            carbs: number,
            protein: number
        ) => {
            return { id, dessert, calories, fat, carbs, protein };
        };

        type Sample = [string, number, number, number, number];

        const sample: Sample[] = [
            ['Frozen yogurt', 159, 6.0, 24, 4.0],
            ['Ice cream sandwich', 237, 9.0, 37, 4.3],
            ['Eclair', 262, 16.0, 24, 6.0],
            ['Cupcake', 305, 3.7, 67, 4.3],
            ['Gingerbread', 356, 16.0, 49, 3.9],
            ['Gingerbread', 159, 6.0, 24, 4.0],
            ['Frozen yogurt', 237, 9.0, 37, 4.3],
            ['Ice cream sandwich', 262, 16.0, 24, 6.0],
            ['Eclair', 305, 3.7, 67, 4.3],
            ['Cupcake', 356, 16.0, 49, 3.9],
        ];

        const rows: any[] = [];

        for (let i = 0; i < 20; i += 1) {
            const randomSelection = sample[Math.floor(Math.random() * sample.length)];
            rows.push(createData(i, ...randomSelection));
        }

        const columns: Column[] = [
            //{ name: 'id', label: 'ID', numeric: true },
            { name: 'dessert', label: 'Dessert', numeric: false, sortable: true, filterable: true },
            { name: 'calories', label: 'Calories', numeric: true, sortable: true, filterable: true },
            { name: 'fat', label: 'Fat', numeric: true, sortable: true },
            { name: 'carbs', label: 'Carbs', numeric: true, sortable: true },
            { name: 'protein', label: 'Protein', numeric: true, sortable: true },
        ];

        setTableProps((prev) => ({
            ...prev,
            columns,
            rows,
            loading: false,
        }));
    }, []);

    const toggleLoading = () => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 3000);
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <div>Side Menu: {config.sideMenu ? 'Ya' : 'Nop'}</div>
            <div>Loading (Secondary Button): {loading ? 'Ya' : 'Nop'}</div>

            <div style={{ padding: 5 }}>
                <Button onClick={() => dispatch(toggleSideMenu())}>Toggle SideMenu</Button>
            </div>
            <div style={{ padding: 5 }}>
                <Button variety="secondary" loading={loading} onClick={toggleLoading}>
                    Secondary
                </Button>
            </div>
            <div style={{ padding: 5 }}>
                <Button variety="small">Small</Button>
            </div>
            <div
                style={{
                    backgroundColor: colors.blue,
                    width: 300,
                    height: 75,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 5,
                }}
            >
                <Button variety="light">Light</Button>
            </div>
            <div>
                <Table
                    loading={tableProps.loading}
                    columns={tableProps.columns}
                    dense={tableProps.dense}
                    rows={tableProps.rows}
                    keyColumn={tableProps.keyColumn}
                />
            </div>
        </div>
    );
};

export default TestPage;
