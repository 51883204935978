import React from 'react';
import {
    AppBar,
    AppBarProps,
    Typography,
    Toolbar,
    IconButton,
    makeStyles,
    Theme,
    createStyles,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

interface NavBarProps extends AppBarProps {
    children?: React.ReactNode;
    menuClicked?: (event?: any) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 2,
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
            paddingLeft: 240,
        },
        topRight: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            marginLeft: 0,
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 2,
            justifyContent: 'flex-end',
            alignItems: 'center',
            height: 64,
        },
    })
);

const NavBar: React.FC<NavBarProps> = (props) => {
    const { menuClicked, children, ...rest } = props;
    const classes = useStyles();

    const click = (e: any) => {
        if (menuClicked) {
            menuClicked(e);

            e.stopPropagation();
        }
    };

    return (
        <AppBar position="static" {...rest} elevation={0}>
            <Toolbar>
                {/* <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="open menu"
                    onClick={(e) => click(e)}
                >
                    <MenuIcon />
                </IconButton> */}
                <Typography className={classes.title} variant="h6" noWrap>
                    {props.title}
                </Typography>
                {children && <div className={classes.topRight}>{children}</div>}
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
