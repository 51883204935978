//@ts-ignore
import CanvasJSReact from '../../assets/js/canvasjs.react';
import { Checkbox, createStyles, Grid, lighten, makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { ChartData, ChartOptions } from '../../store/ceclapi/types';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import { colors } from '../../theme';

export interface ChartProps {
    options?: ChartOptions;
    data?: ChartData[];
    loading?: boolean;
    id?: string;
    hideInvisibleOnLegend?: boolean;
}

export function filterOutNulls(data) {
    // check the datapoint for both series. If one or both have a null then remove the data point from both series
    for (var y = 0; y < data[0].dataPoints.length; y++) {
        var hasNull = false;
        for (var z = 0; z < data.length; z++) {
            if (!data[z].dataPoints[y].y) {
                hasNull = true;
                break;
            }
        }

        if (hasNull) {
            for (var z = 0; z < data.length; z++) {
                data[z].dataPoints.splice(y, 1);
            }
            y--;
        }
    }

    return data;
}

export const loanCompositionColorMap = {
    'Credit Card Loans': {
        color: '#FFDD00',
    },
    'Short Term Small Amount': {
        color: '#DEEBF7',
    },
    'Auto Loans': {
        color: '#FFB900',
    },
    'All Other Loans': {
        color: '#003A70',
    },
    'All Other Unsec Loans/LOCs': {
        color: '#CE0E2D',
    },
    'Student Loans': {
        color: '#005800',
    },

    'Real Estate Loans': {
        color: '#98989A',
    },
};

export const depositCompositionColorMap = {
    'Share Drafts': {
        color: '#005800',
    },
    'Share Certificates': {
        color: '#DEEBF7',
    },
    'Nonmemeber Deposits': {
        color: '#FFDD00',
    },
    'Regular Shares': {
        color: '#98989A',
    },
    'IRA/KEOUGH Accounts': {
        color: '#CE0E2D',
    },
    'Money Market Shares': {
        color: '#FFB900',
    },

    'All Other Shares': {
        color: '#003A70',
    },
};

export const historyGraphColorMap = {
    'All Other RE Loans/LOC': {
        color: '#C5E0B4',
    },
    'All Other Secure Non-RE Loans/LOC': {
        color: '#BBBBBB',
    },
    'All Other Unsecured Loans/LOC': {
        color: '#F62735',
    },
    'Commercial Loans/LOC Not RE Secured': {
        color: '#00B050',
    },
    'Commercial Loans/LOC RE Secured': {
        color: '#005800',
    },
    'Leases Receivable': {
        color: '#98989A',
    },
    'New Vehicle Loans': {
        color: '#FFB900',
    },
    'Non-Federally Guaranteed Student Loans': {
        color: '#CE0E2D',
    },
    'Payday Alternative Loans': {
        color: '#316094',
    },
    'Total 1st Lien RE Loans/LOC': {
        color: '#DEEBF7',
    },
    'Total Junior Lien RE Loans/LOC': {
        color: '#3E2FD1',
    },
    'Unsecured Credit Card Loans': {
        color: '#003A70',
    },
    'Used Vehicle Loans': {
        color: '#FFDD00',
    },
};

export function filterOutStackedBarGraph(data) {
    for (var z = 0; z < data.length; z++) {
        var hasData = false;
        for (var y = 0; y < data[z].dataPoints.length; y++) {
            if (data[z].dataPoints[y].y) {
                hasData = true;
                break;
            }
        }

        if (!hasData) {
            data.splice(z, 1);
            z--;
        }
    }

    return data;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        legend: {
            padding: theme.spacing(5),
        },
        legendItem: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        legendCheckbox: {
            flexBasis: 50,
        },
        legendIndicator: {
            flexBasis: 20,
            position: 'relative',
            width: 20,
            height: 20,
            marginRight: theme.spacing(1),
        },
        legendIndicatorLine: {
            flexBasis: 20,
            position: 'relative',
            width: 20,
            height: 5,
            marginRight: theme.spacing(1),
        },
        legendIndicatorLineDash: {
            flexBasis: 20,
            position: 'relative',
            width: 4,
            height: 10,
            backgroundColor: colors.white,
            margin: '0 auto',
        },
        legendText: {
            fontSize: 20,
            flex: 3,
        },
        legendTitle: {
            fontWeight: 'bold',
            textAlign: 'end',
            whiteSpace: 'nowrap',
        },
    })
);

export interface ChartOptionsWithData extends ChartOptions {
    data: ChartData[];
}

const initialOptions: ChartOptions = {
    animationEnabled: true,
    backgroundColor: 'transparent',
    dataPointMaxWidth: 75,
    title: {
        text: '',
        fontSize: 20,
        fontFamily: 'Montserrat',
        fontWeight: 'bolder',
        margin: 50,
    },
    colorSet: 'ceclColors',
    legend: {
        labelFontFamily: 'Montserrat',
    },
    legendDisplay: 'bottom',
    axisX: {
        labelFontFamily: 'Montserrat',
        lineColor: colors.gray,
    },
    axisY: {
        labelFontFamily: 'Montserrat',
        lineColor: colors.white,
        tickLength: 0,
        gridColor: colors.gray,
    },
};

const Chart: React.FC<ChartProps> = (props) => {
    const [options, setOptions] = React.useState<ChartOptionsWithData>({
        ...{ ...initialOptions, ...props.options },
        data: [],
    });

    const classes = useStyles();
    const CanvasJS = CanvasJSReact.CanvasJS;
    const CanvasJSChart = CanvasJSReact.CanvasJSChart;
    const ceclColors = [
        '#D51616',
        '#2A5CC1',
        '#1B3D6D',
        '#585757',
        '#FDB714',
        '#000',
        lighten('#D51616', 0.5),
        lighten('#2A5CC1', 0.5),
        lighten('#585757', 0.5),
        lighten('#FDB714', 0.5),
    ];

    CanvasJS.addColorSet('ceclColors', ceclColors);

    const toggleKey = (e: any, key: number) => {
        if (options.data[key]) {
            options.data[key].visible = !options.data[key].visible;

            setOptions((prevState) => ({
                ...prevState,
                data: options.data,
            }));
        }
    };

    React.useEffect(() => {
        const data = props.data?.map((d) => {
            if (d.visible === undefined) {
                d.visible = true;
            }

            return d;
        });

        setOptions((prevState) => ({
            ...prevState,
            ...{ ...props.options, data: data ? data : [] },
        }));
    }, [props]);

    const ChartLegend = ({ sideStack = false, title = '' }) => {
        const smallCols = sideStack ? 12 : 6;
        const mediumCols = sideStack ? 12 : 4;

        const LegendIndicator = ({ item, index }) => (
            <>
                {item.type === 'line' && (
                    <div
                        className={classes.legendIndicatorLine}
                        style={{ backgroundColor: item.color || ceclColors[index % ceclColors.length] }}
                    >
                        {item.lineDashType === 'dash' && <div className={classes.legendIndicatorLineDash} />}
                    </div>
                )}
                {item.type !== 'line' && (
                    <div
                        className={classes.legendIndicator}
                        style={{ backgroundColor: item.color || ceclColors[index % ceclColors.length] }}
                    />
                )}
            </>
        );

        return (
            <Grid container className={classes.legend}>
                {title && (
                    <Grid item xs={12} sm={smallCols} md={mediumCols}>
                        <Typography className={classes.legendTitle}>{title}</Typography>
                    </Grid>
                )}
                {props.data &&
                    props.data
                        .filter((l) => !props.hideInvisibleOnLegend || l.visible)
                        .map((item, i) => (
                            <Grid key={i} item xs={12} sm={smallCols} md={mediumCols}>
                                <div className={classes.legendItem}>
                                    <div className={classes.legendCheckbox}>
                                        <Checkbox
                                            checked={!!item.visible}
                                            onChange={(e) => toggleKey(e, i)}
                                            name={item.name}
                                            color="secondary"
                                        />
                                    </div>
                                    {!sideStack && <LegendIndicator item={item} index={i} />}
                                    <div className={classes.legendText}>{item.name}</div>
                                    {sideStack && <LegendIndicator item={item} index={i} />}
                                </div>
                            </Grid>
                        ))}
            </Grid>
        );
    };

    return (
        <div id={props.id}>
            <LoadingSpinner loading={props.loading} />
            {options.legendDisplay === 'left' && (
                <>
                    <Grid container>
                        <Grid item xs={3}>
                            <ChartLegend sideStack title={options.legendTitle} />
                        </Grid>
                        <Grid item xs={9}>
                            <CanvasJSChart options={options} />
                        </Grid>
                    </Grid>
                </>
            )}
            {options.legendDisplay === 'bottom' && (
                <>
                    <CanvasJSChart options={options} />
                    <ChartLegend />
                </>
            )}
        </div>
    );
};

export default Chart;
