import { Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Panel from '../../../../components/panel/Panel';
import ForecastMonthlyInputsTable from '../../../../containers/tables/ForecastMonthlyInputsTable';
import { ApplicationState } from '../../../../store';
import { ceclstateForecastSingleMonthlyInputUpdate } from '../../../../store/ceclapi/actions';
import { CeclApiState, ForecastInput, ValidationError } from '../../../../store/ceclapi/types';
import { colors } from '../../../../theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paddingLess: {
            padding: 0,
        },
        title: {
            width: '100%',
            textAlign: 'left',
            fontSize: 35,
            paddingLeft: theme.spacing(2),
        },
        dialogDivider: {
            color: colors.black,
        }
    })
);

export interface ForecastMonthlyInputsProps{
    inputsWithErrors?: ValidationError[];
    inputsFromCsv?: ForecastInput[];
}

const ForecastMonthlyInputs: React.FC<ForecastMonthlyInputsProps> = (props) => {
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const inputsFromCsv = props.inputsFromCsv;
    const classes = useStyles();
    const [monthlyInputs, setMonthlyInputs] = React.useState([] as ForecastInput[]);
    const dispatch = useDispatch();

    React.useEffect(()=> {
        if(inputsFromCsv){
            setMonthlyInputs(inputsFromCsv);
        }
    }, [inputsFromCsv])
    React.useEffect(() => {
        if (cecl.forecastAssumptions?.configuration?.config?.inputs) {
            setMonthlyInputs([...cecl.forecastAssumptions!.configuration!.config!.inputs!]);
        }
    }, []);

    React.useEffect(() => {
        if (cecl.forecastAssumptions?.configuration?.config?.inputs) {
            setMonthlyInputs(JSON.parse(JSON.stringify(cecl.forecastAssumptions!.configuration!.config!.inputs!)));
        }
    }, [cecl.forecastAssumptions?.configuration]);

    React.useEffect(() => {
        if (!monthlyInputs.length || !cecl.forecastAssumptions?.detail?.data) {
            return;
        }
      
        var currentInputs = JSON.parse(JSON.stringify(cecl.forecastAssumptions!.configuration.config.inputs));
        var newInputs = [...cecl.forecastAssumptions!.detail!.data.inputs!];

        for (var i = 0; i < newInputs!.length; i++) {
            for (var j = 0; j < currentInputs!.length; j++) {
                if (newInputs![i].name == currentInputs![j].name) {
                    currentInputs![j] = newInputs![i];
                }
            }
        }

        setMonthlyInputs(currentInputs);
    }, [cecl.forecastAssumptions?.detail]);

    React.useEffect(() => {
        var myInputs = [...monthlyInputs];

        if(myInputs.length == 0){
            return;
        }
     
        myInputs.forEach(input => {
            input.error = false
        });

        props.inputsWithErrors?.map(inputError => inputError.fieldName).forEach(inputName => {
            var inputIndex = myInputs.findIndex(input => input.name == inputName);
            if(inputIndex >= 0){
                myInputs[inputIndex].error = true;
            } 
        });

        setMonthlyInputs(myInputs);
    }, [props.inputsWithErrors])



    function handleTableEdit(event:any, rowIndex: number, colName: string) {
        var inputIndex = monthlyInputs.findIndex((input) => input.order == rowIndex + 1);
        if (inputIndex < 0) {
            return;
        }
        if (event === null || event === ''){
            event = '0';
        }

        var testString = event.replace(/,/g, "");
        var value = parseFloat((testString as unknown) as string);
        var newInputs = [...monthlyInputs];
        switch (colName) {
            case 'Ending Balance':
                newInputs[inputIndex].endingBalance = value;
                break;
            case 'Number Of Loans':
                newInputs[inputIndex].numberOfLoans = value;
                break;
            case 'Average Loan Rate (%)':
                newInputs[inputIndex].averageLoanRate = value;
                break;
            case 'Weighted Average Remaining Maturity (Months)':
                newInputs[inputIndex].weightedAverageRemainingMaturity = value;
                break;
            case 'Dollar Amount Of Interest Only Loans':
                newInputs[inputIndex].dollarAmountOfInterestOnlyLoans = value;
                break;
            default:
                break;
        }
        var input = newInputs[inputIndex];
        dispatch(ceclstateForecastSingleMonthlyInputUpdate(input));
        setMonthlyInputs(newInputs);
    }

    return (
        <div>
            <Panel className={classes.paddingLess}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography className={classes.title}>Monthly Inputs</Typography>
                        <Divider variant="middle" className={classes.dialogDivider} />
                    </Grid>
                    <Grid item>
                        <ForecastMonthlyInputsTable inputs={monthlyInputs} handleTableEdit={handleTableEdit} />
                    </Grid>
                </Grid>
            </Panel>
        </div>
    );
};

export default ForecastMonthlyInputs;
