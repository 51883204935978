import { Button, ButtonProps, CircularProgress, makeStyles, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import { AxiosError, AxiosResponse } from 'axios';
import React, { FC, useState } from 'react';
import { AxiosInstance } from '../../utils/callApi';

interface FileUploadButtonProps extends ButtonProps {
  url: string;
  label?: string;
  uploadingLabel?: string;
  fileName?: string;
  handleResponse:  (response: AxiosResponse) => void;
  handleError?: (err: any) => void;
  handleOnClick?: (evt: React.MouseEvent<HTMLInputElement, MouseEvent>) => void
  acceptFileType?: string;
  buttonClass?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        input: {
            display: 'none',
        },
        button:{}
    })
);

const FileUploadButton: FC<FileUploadButtonProps> = ({
  url,
  label,
  uploadingLabel,
  startIcon,
  handleResponse,
  handleError,
  handleOnClick,
  acceptFileType,
  buttonClass
  ...props
}) => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<AxiosError<{ message: string }> & any>();
  const classes = useStyles();
  const handleUpload = e => {
    const run = async () => {
      setUploading(true);

      let formData = new FormData();
      formData.append("multipart/form-data", e.target.files[0])
      
      await AxiosInstance({
        method: 'POST',
        url,
        data: formData,
        headers: {'content-disposition': `attachment; filename=${e.target.files[0].name}`}
      })
        .then(response => {
          handleResponse(response);
        })
        .catch(err => {
          handleError && handleError(err.response.data)})
        .finally(() => {
          setUploading(false)
        });
    };

    run();
  };

  const onInputClick = ( event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement
    element.value = ''
    handleOnClick && handleOnClick(event);
  }
  
  return (
    <>
      <input
        accept={acceptFileType}
        className={classes.input}
        id="buttonFileUpload"
        type="file"
        onChange={handleUpload}
        onClick={onInputClick}
      />
      <label htmlFor="buttonFileUpload">
        <Button className={buttonClass ? buttonClass : classes.button} component="span">
            {label}
        </Button>
      </label>
    </>
  );
};

FileUploadButton.defaultProps = {
  startIcon: <CircularProgress size={10} color="inherit" />,
  label: 'Upload File',
  uploadingLabel: 'uploading',
};

export default FileUploadButton;
