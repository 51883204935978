import { createStyles, Drawer, DrawerProps, List, ListItem, ListItemText, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import { routes } from '../../Routes';
import { ApplicationState } from '../../store';
import { CeclApiState, UserTypes } from '../../store/ceclapi/types';
import { colors } from '../../theme';

interface CECLMenuProps extends DrawerProps {
    open?: boolean;
    loggedIn?: boolean;
    showLoggedIn?: boolean;
    onClose?: (event: any) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toggle: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            marginLeft: 0,
        },
        drawer: {
            flexShrink: 0,
            width: 240,
        },
        list: {
            paddingTop: 0,
        },
        link: {
            width: '100%',
        },
        drawerPaper: {
            width: 240,
            padding: 0,
            margin: 0,
            border: 0,
        },
        titleBar: {
            height: theme.spacing(8),
            display: 'flex',
            alignItems: 'center',
            paddingLeft: theme.spacing(2),
        },
        titleIcon: {
            display: 'inline-flex',
            marginRight: theme.spacing(4),
        },
        title: {
            fontSize: '1.25rem',
            display: 'inline-flex',
        },
        menu: {
            paddingTop: 0,
            height: '100%',
        },
        active: {
            color: colors.red,
            textTransform: 'uppercase',
            fontWeight: 'bold',
        },
        login: {
            position: 'absolute',
            bottom: 0,
        },
    })
);

const initialProps: CECLMenuProps = {
    open: true,
    loggedIn: false,
    showLoggedIn: true,
};

const SideMenu: React.FC<CECLMenuProps> = (props) => {
    const [state, setState] = React.useState<CECLMenuProps>({ ...initialProps, ...props });
    const classes = useStyles();
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const userType: UserTypes = cecl.user?.userType || UserTypes.Authenticated;

    React.useEffect(() => {
        setState((prevState) => ({ ...prevState, ...props }));
    }, [props]);

    const close = (e: any) => {
        if (state.onClose) {
            state.onClose(e);

            e.stopPropagation();
        }
    };

    return (
        <Drawer
            className={classes.drawer}
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="left"
            open={state.open}
            onClose={(e) => close(e)}
            PaperProps={{ elevation: 0 }}
            variant="persistent"
        >
            <div className={classes.titleBar}>
                <Link to="/" className={classes.link}>
                    <img src={require('../../assets/images/cecl_logo.svg')}></img>
                </Link>
            </div>
            <div id="side-bar-list">
                <List style={{ paddingTop: 0, height: '100%' }}>
                    {routes &&
                        routes
                            .filter((r) => r.auth && !!(r.auth & userType))
                            .filter((r) => r.order !== -1)
                            .sort((r1, r2) => r1.order - r2.order)
                            .map((route, i) => {
                                return (
                                    <ListItem
                                        key={i}
                                        button
                                        component={NavLink}
                                        to={route.path}
                                        activeClassName="sidemenu-active"
                                        exact={route.order === 0}
                                    >
                                        <ListItemText id={`list-label-${route.id}`} primary={route.name} />
                                    </ListItem>
                                );
                            })}
                </List>
            </div>
        </Drawer>
    );
};

export default SideMenu;
