import * as React from 'react';
import { useLocation } from "react-router-dom"
import { matchPath } from "react-router";
import { useSnackbar } from 'notistack';
import {
    createStyles,
    makeStyles,
    Theme,
} from '@material-ui/core';
import { colors } from './theme';
import { displayToast } from './store/config/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from './store';
import { ConfigState } from './store/config/types';
import { CeclApiState, MessageAction } from './store/ceclapi/types'
import Button from './components/button/Button';
import {
    ceclApiMessagesConfigurationRequest
} from './store/ceclapi/actions';


function hashCode(s) {
    let h=0;
    for(let i = 0; i < s.length; i++) 
          h = Math.imul(31, h) + s.charCodeAt(i) | 0;

    return h;
}

const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            msgButton: {
                backgroundColor: 'transparent',
                border: 'none',
                color: colors.white,
                textDecoration: 'underline',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            }
        })
    );

const Messages = () => {

    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const config = useSelector<ApplicationState, ConfigState>((state) => state.config);

    const location = useLocation();
    const dispatch = useDispatch();
    const { closeSnackbar } = useSnackbar();

    const classes = useStyles();
    
    const [keys, setKeys] = React.useState({});

    const changeAction = message => (
        <Button className={classes.msgButton} onClick={() => 
            { 
                let hash = hashCode(message.messageText);
                localStorage.setItem(hash.toString(), 'dismissed')
                closeSnackbar(keys[hash]); 
            }}>
            Dismiss
        </Button>
    );

    if(!cecl?.messageConfig?.messages && !cecl.messageConfig?.loading)            {
        dispatch(ceclApiMessagesConfigurationRequest())
    }

    React.useEffect(() => {
        cecl.messageConfig?.messages?.forEach(message => {
            
            let match = matchPath(location.pathname, {path: message.route, exact: true})

            if(match){
                if(message.action === MessageAction.PermantelyDismissable){
                    let hash = hashCode(message.messageText);
                    let dismissed = localStorage.getItem(hash.toString());

                    if(!dismissed || dismissed != 'dismissed')
                    {
                        dispatch(displayToast({message: message.messageText, variant: 'info', anchorOrigin: {vertical: 'top', horizontal: 'center'}, action: changeAction(message)}));
                        setKeys({...keys, hash: config.snackbarKey});
                    }
                }
                else{
                    dispatch(displayToast({message: message.messageText, variant: 'info', anchorOrigin: {vertical: 'top', horizontal: 'center'}}));
                }
            }
        });

    },[location, cecl.messageConfig])

    return (<></>);

};

export default Messages;
