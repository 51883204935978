import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import Table, { Column, TableProps } from '../../components/table/Table';
import { ApplicationState } from '../../store';
import { CeclApiState } from '../../store/ceclapi/types';
import { colors } from '../../theme';
import { formatCurrencyNumber } from '../../utils/helpers';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        blueBar: {
            fontWeight: 'bold',
            color: colors.white,
            backgroundColor: colors.blue,
            textAlign: 'start',
        },
        inputBar: {
            width: '100%',
        },
        inputName: {
            fontWeight: 'bold',
            textAlign: 'start',
            color: colors.white,
        },
        blueBackground: {
            backgroundColor: colors.blue,
        },
        blueBorder: {
            borderWidth: 1,
            borderColor: colors.blue,
            borderStyle: 'solid',
        },
        paddingLess: {
            padding: 0,
        },
        lightBlueBackground: {
            backgroundColor: colors.lightBlue,
        },
        width: {
            width: '60%',
        },
    })
);

const ClassificationLoansTable: React.FC = () => {
    const classes = useStyles();
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const [monthlyInputsTableProps, setMonthlyInputsTableProps] = React.useState({
        loading: true,
        dense: true,
        keyColumn: 'name',
        rows: [],
        columns: [],
        selectable: false,
        hasBorder: true,
    } as TableProps);
    const [totalBalance, setTotalBalance] = React.useState({} as number);

    const bodyFontSize = "1.3000rem";
    const headerFontSize = 22;
    const secondHeaderFontSize = 22;

    const defaultMonthlyInputsColumns: Column[] = [
        {
            name: 'name',
            label: '',
            numeric: false,
            bodyColor: colors.lightGray3,
            bodyFontSize: bodyFontSize,
            bodyFontWeight: 'bold',
        },
        {
            name: 'Ending Balances',
            label: 'Ending Balances',
            numeric: false,
            headerColor: colors.lightGray3,
            centerHeader: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
            secondHeaderFontSize: secondHeaderFontSize,
            bodyFontWeight: 'bold',
            alignRight: true,
        },
        {
            name: 'Estimated Losses',
            label: 'Estimated Losses',
            numeric: false,
            numericPrecision: 0,
            headerColor: colors.lightGray3,
            centerHeader: true,
            //centerBody: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
            secondHeaderFontSize: secondHeaderFontSize,
            bodyFontWeight: 'bold',
            alignRight: true,
        },
    ];

    React.useEffect(() => {
        if (cecl!.calculatedLoss?.loanTypeLossForecastResults && cecl.forecastAssumptions?.detail?.data) {
            mapMonthlyInputsTableDataFromProps();
        }
    }, [cecl.forecastAssumptions?.detail?.data, cecl.calculatedLoss?.loanTypeLossForecastResults]);

    React.useEffect(() => {
        var newBalance: number = 0;

        monthlyInputsTableProps.rows.forEach((row) => {
            newBalance += row['Period Ending Balance'];
        });

        setTotalBalance(newBalance);
    }, [monthlyInputsTableProps.rows]);

    function mapMonthlyInputsTableDataFromProps() {
        var newRows = cecl.forecastAssumptions.detail.data.inputs
            .sort((a, b) => {
                return a.order! - b.order!;
            })
            .map((input, i) => {
                const result = cecl.calculatedLoss.loanTypeLossForecastResults.find(
                    (l) => l.analysisRequestTaskNumber === i + 1
                );

                var obj: any = {};
                obj['name'] = input.name;
                obj['Ending Balances'] = formatCurrencyNumber(result.loanTypeLossAnalysisRequest.specificLoanLossEndingBalance);
                obj['Estimated Losses'] = formatCurrencyNumber(result.loanTypeLossAnalysisRequest.allocation);

                return obj;
            });

        setMonthlyInputsTableProps((prevState) => ({
            ...prevState,
            columns: defaultMonthlyInputsColumns,
            rows: newRows,
            loading: false,
        }));
    }

    return (
        <div className={classes.width}>
            <Table
                loading={monthlyInputsTableProps.loading}
                columns={monthlyInputsTableProps.columns}
                dense={monthlyInputsTableProps.dense}
                rows={monthlyInputsTableProps.rows}
                keyColumn={monthlyInputsTableProps.keyColumn}
                selectable={monthlyInputsTableProps.selectable}
                hasBorder={monthlyInputsTableProps.hasBorder}
            />
        </div>
    );
};

export default ClassificationLoansTable;
