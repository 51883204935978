import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Panel from '../../../components/panel/Panel';
import { ApplicationState, LastAction } from '../../../store';
import {
    ceclapiCalculateLossRequest,
    ceclapiCreatePdfClear,
    ceclapiCreatePdfRequest,
    ceclapiForecastAssumptionsDetailRequest,
    ceclapiForecastAssumptionsSaveRequest,
} from '../../../store/ceclapi/actions';
import { CeclApiState, ForecastAssumptionsSaveRequest, PdfRequest } from '../../../store/ceclapi/types';
import { displayToast } from '../../../store/config/actions';
import LoadingModal from './sections/LoadingModal';
import ExpectedCreditLosses from './sections/ExpectedCreditLosses';
import LossParameters from './sections/LossParameters';
import ModelAssumptions from './sections/ModelAssumptions';
import ClassificationLoans from './sections/ClassificationLoans';
import ChargeOffsChart from './sections/ChargeOffsChart';
import Allowances from './sections/Allowances';
import TopPanel from './sections/TopPanel';
import PdfTopPanel from './sections/PdfTopPanel';
import _ from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grid: {
            padding: '0px !important',
        },
        buttons: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        title: {
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
        },
    })
);

export interface ViewForecastRouteParams {
    routingNumber?: string;
    id?: string;
    pdf?: any;
}

export interface ViewForecastUserParams {
    dataLag?: number;
    economicIndex?: string;
    forecastTimeHorizon?: number;
    historicalTimeHorizon?: number;
}

const ViewForecastPage: React.FC = () => {
    const routeParams = useParams<ViewForecastRouteParams>();
    const history = useHistory();
    const lastAction = useSelector<ApplicationState, LastAction>((state) => state.lastAction);
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [userParams, setUserParams] = React.useState<ViewForecastUserParams>();
    const [pdfMode, setPdfMode] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (routeParams.pdf) {
            setPdfMode(true);
            dispatch(ceclapiCreatePdfClear()); 
        }
        if (routeParams.id) {
            if (!cecl.forecastAssumptions?.detail?.data) {
                dispatch(
                    ceclapiForecastAssumptionsDetailRequest({
                        cuid: routeParams.routingNumber,
                        assumptionId: routeParams.id,
                    })
                );
            }
        } else {
            //error - redirect?
        }
    }, []);

    React.useEffect(() => {
        setUserParams({
            dataLag: cecl?.calculatedLoss?.dataLagMonths,
            economicIndex: cecl?.calculatedLoss?.economicIndex,
            forecastTimeHorizon: cecl?.myAssumptionSet?.forecastTimeHorizon ?? 5,
            historicalTimeHorizon: cecl?.myAssumptionSet?.historicalTimeHorizon ?? 25,
        });

        setLoading(
            cecl.forecastAssumptions?.indexes?.loading ||
                cecl.forecastAssumptions?.detail?.loading ||
                cecl.calculatedLoss?.loading
        );

        if (
            pdfMode &&
            cecl.forecastAssumptions?.indexes &&
            cecl.forecastAssumptions?.detail?.data &&
            cecl.calculatedLoss?.loanTypeLossForecastResults?.length
        ) {
            const loadingInterval = setInterval(() => {
                const element = document.getElementById('pdfTopPanel');

                if (element && loadingInterval) {
                    clearInterval(loadingInterval);
                    createPdf();
                }
            }, 100);
        }
    }, [
        cecl.forecastAssumptions?.indexes?.loading,
        cecl.forecastAssumptions?.detail?.loading,
        cecl.calculatedLoss?.loading,
        pdfMode
    ]);

    // This functions as a redux action watcher.
    React.useEffect(() => {
        // Handle calculations load error
        if (lastAction.type === '@@ceclapi/CECLAPI_CALCULATELOSS_ERROR') {
            history.push(`/forecast/assumptions/${routeParams.routingNumber}/${cecl.forecastAssumptions?.detail?.data?.id}`);
            dispatch(
                displayToast({
                    message: 'An unexpected error occurred. Please verify your forecast data and try again.',
                    variant: 'error',
                })
            );
        }

        // After forecast save, redirect to the forecast history page
        if (lastAction.type === '@@ceclapi/CECL_FORECASTASSUMPTIONSSAVE_SUCCESS') {
            history.push('/forecasthistory');
        }

        // After PDF is created, save the forecast with a status of 'Archived'
        if (lastAction.type === '@@ceclapi/CECLAPI_CREATEPDF_SUCCESS') {
            saveToHistory();
        }

        if(lastAction.type === '@@ceclapi/CECLAPI_CREATEPDF_ERROR') {
            dispatch(
                displayToast({
                    message: 'An unexpected error occurred while saving the pdf. Please try again.',
                    variant: 'error',
                })
            );
            history.goBack();            
        }

        // Handle forecast load error
        if (lastAction.type === '@@ceclapi/CECL_FORECASTASSUMPTIONSSAVE_ERROR') {
            dispatch(
                displayToast({
                    message: 'An unexpected error occurred. Please try your request again',
                    variant: 'error',
                })
            );
        }
    }, [lastAction]);

    // Wait for forecast to load before attempting to run calculations
    React.useEffect(() => {
        if (routeParams.id 
                && cecl.forecastAssumptions?.detail?.data 
                && !cecl.calculatedLoss?.loading
                && !cecl.calculatedLoss?.cuId) {
                    const data = { ...cecl.forecastAssumptions.detail.data };
                    if (data.economicQualitativeFactorAdjustmentPercentage) {
                        data.economicQualitativeFactorAdjustmentPercentage /= 100;
                    }
                    
                    data.parameters = data.parameters.map((p) => {
                        p.loanQualityFactorAdjustment /= 100;
                        return p;
                    });

                    dispatch(ceclapiCalculateLossRequest(data));
        }
    }, [cecl.forecastAssumptions?.detail?.data]);

    const createPdf = (base64Response: boolean = true) => {
        const request: PdfRequest = {
            fileId: cecl.forecastAssumptions?.detail?.data?.id,
            pages: [
                { id: 'pdfTopPanel', ordinal: 0, stretch: false, width: '2000px' },
                { id: 'allowances', ordinal: 1, stretch: true },
                { id: 'expectedCreditLosses', ordinal: 2, stretch: true },
                { id: 'modelAssumptions', ordinal: 3, stretch: true },
                { id: 'classificationLoans', ordinal: 4, stretch: true },
                { id: 'chargeOffsChart', ordinal: 5, stretch: true },
            ],
            fileName: `forecast_${new Date().toISOString()}.pdf`,
            base64Response,
        };

        dispatch(ceclapiCreatePdfRequest(request));
    };

    const handleParameterChange = ({ dataLag, economicIndex }) => {
        const request = {
            ...cecl.forecastAssumptions.detail.data,
            dataLag,
            economicIndex,
        };

        setUserParams((prev) => ({ ...prev, dataLag, economicIndex }));

        dispatch(ceclapiCalculateLossRequest(request));
    };

    const handleChartParameterChange = ({ forecastTimeHorizon, historicalTimeHorizon }) => {
        setUserParams((prev) => ({ ...prev, forecastTimeHorizon, historicalTimeHorizon }));
    };

    const saveToHistory = () => {
        const request: ForecastAssumptionsSaveRequest = {
            assumptionsData: {
                ...cecl.forecastAssumptions.detail.data,
                dataLag: userParams.dataLag,
                economicIndex: userParams.economicIndex,
                forecastTimeHorizon: userParams.forecastTimeHorizon,
                historicalTimeHorizon: userParams.historicalTimeHorizon,
                status: 'Archived',
            },
        };

        dispatch(
            displayToast({
                message: 'Saving forecast...',
                variant: 'info',
            })
        );

        dispatch(ceclapiForecastAssumptionsSaveRequest(request));
    };

    const onSave = () => {
        history.push(`/forecast/view/${routeParams.routingNumber}/${routeParams.id}/createpdf`);
    }

    return (
        <div style={{ filter: pdfMode ? 'opacity(0)' : 'none' }}>
            {!loading && (
                <>
                    {!pdfMode && <TopPanel onSave={onSave} routingNumber={routeParams.routingNumber} />}
                    {pdfMode && <PdfTopPanel />}
                    <Panel>
                        <LossParameters
                            dataLag={cecl?.calculatedLoss?.dataLagMonths}
                            economicIndex={cecl?.calculatedLoss?.economicIndex}
                            onChange={handleParameterChange}
                            pdfMode={pdfMode}
                        />
                        <Allowances />
                        <ExpectedCreditLosses noScroll={pdfMode} />
                        <ModelAssumptions noScroll={pdfMode} />
                        <ClassificationLoans />
                    </Panel>
                    <ChargeOffsChart
                        forecastTimeHorizon={userParams?.forecastTimeHorizon}
                        historicalTimeHorizon={userParams?.historicalTimeHorizon}
                        onChange={handleChartParameterChange}
                        pdfMode={pdfMode}
                    />
                </>
            )}
            <LoadingModal pdfMode={pdfMode} />
        </div>
    );
};

export default ViewForecastPage;
