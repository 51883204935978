import React from 'react';
import { useSelector } from 'react-redux';
import Chart, { ChartProps, depositCompositionColorMap, filterOutNulls, filterOutStackedBarGraph } from '../../../components/chart/Chart';
import { ApplicationState } from '../../../store';
import { CeclApiState, ChartData, Chart as ChartType } from '../../../store/ceclapi/types';

const initialChartProps: ChartProps = {
    options: {
        title: {
            text: 'Deposit Composition - CU',
            fontSize: 20,
            fontFamily: 'Montserrat',
            fontWeight: 'bolder',
            margin: 50,
        },
        dataSetNamesForYAxis1: ['CU CU']
    },
    data: [],
    id: 'cudepositcomposition',
};

const accounts: string[] = 
                        [
                            'Share Drafts', 
                            'Share Certificates', 
                            'Nonmemeber Deposits', 
                            'Regular Shares', 
                            'IRA/KEOUGH Accounts', 
                            'Money Market Shares',
                            'All Other Shares'
                        ];

const CuDepositComposition: React.FC<ChartProps> = (props) => {
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const [chartProps, setChartProps] = React.useState<ChartProps>({ ...initialChartProps, ...props });

    const prepChartData = (chart: ChartType): ChartData[] => {

        var myChartData: ChartData[] = JSON.parse(JSON.stringify(chart.data));

        if (myChartData) {
            const mappedData = myChartData.filter((d) => accounts.includes(d.name)).map((d) => {
                d.type = 'stackedColumn100';
                d.color = depositCompositionColorMap[d.name].color;
                d.dataPoints = d.dataPoints?.sort((a, b) => {
                    var dateA: Date = new Date(a.label);
                    var dateB: Date = new Date(b.label);

                    return dateA.getTime() - dateB.getTime();
                }).map((dp) => {
                    dp.label = dp.label;
                    dp.x = undefined;
                    dp.y = +dp.y;

                    return dp;
                });

                return d;
            });

            return mappedData;
        }

        return [];
    };

    React.useEffect(() => {
        const charts = cecl.dashboardData?.charts?.filter(
            (d) => chartProps.options.dataSetNamesForYAxis1.includes(d.graphName)
        );

        var shouldSetToInitial: boolean = true;
        var jointData: ChartData[] = [];

        if (charts && charts.length > 0) {

            shouldSetToInitial = false;

            for(var i = 0; i < charts.length; i++){
                jointData = [...jointData, ...prepChartData(charts[i])];
            }

            jointData = filterOutStackedBarGraph(jointData); 
            jointData = filterOutNulls(jointData);
        }

        if (shouldSetToInitial) {
            setChartProps(initialChartProps);
        } else {
            setChartProps((prevState) => ({
                ...prevState,
                data: jointData,
            }));
        }
    }, [cecl.dashboardData]);

    return (
        <>
            <Chart loading={cecl.dashboardData?.loading} {...chartProps} />
        </>
    );
};

export default CuDepositComposition;
