import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { Collapse as MuiCollapse, Box, CollapseProps, BoxProps } from '@material-ui/core';

let Collapse: FC<CollapseProps & BoxProps> = MuiCollapse as any;
Collapse.defaultProps = {
  component: Box,
  position: 'sticky',
  bottom: '0',
  bgcolor: 'transparent',
  right: 0,
};

type ActionBarProps = {
  show?: boolean;
};

const ActionBar: FC<ActionBarProps> = ({ children, show, ...props }) => {
  const classes = useStyles(props);

  return (
    <Collapse in={show} component={Box} position="sticky">
      <div className={classes.root}>{children}</div>
    </Collapse>
  );
};

type ActionBarStyleProps = Omit<ActionBarProps, 'show'>;
const useStyles = makeStyles<Theme, ActionBarStyleProps>(theme =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(0, 1),
      backdropFilter: 'blur(5px)',
      zIndex: 1000,
      ...theme.mixins.toolbar,
    },
  })
);

ActionBar.defaultProps = { show: true };

export default ActionBar;
