import { CircularProgress, createStyles, Fade, makeStyles, Paper, PaperProps, Theme } from '@material-ui/core';
import * as React from 'react';

export interface LoadingSpinnerProps {
    loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loading: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            margin: '-40px 0 0 -40px',
            zIndex: 999999
        },
    })
);

const initialProps: LoadingSpinnerProps = {
    loading: false,
};

const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props) => {
    const [state, setState] = React.useState<LoadingSpinnerProps>({ ...initialProps, ...props });
    const classes = useStyles();

    React.useEffect(() => {
        setState((prevState) => ({ ...prevState, ...props }));
    }, [props]);

    return (
        <Fade
            in={state.loading}
            style={{
                transitionDelay: state.loading ? '800ms' : '0ms',
            }}
            unmountOnExit
        >
            <CircularProgress color="secondary" className={classes.loading} />
        </Fade>
    );
};

export default LoadingSpinner;
