import React from 'react';
import { Button as ReactButton, lighten } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../theme';
import clsx from 'clsx';

interface CECLButtonProps extends ButtonProps {
    loading?: boolean;
    loadingSize?: number;
    onDoneLoading?: (event?: any) => void;
    variety?: 'primary' | 'secondary' | 'small' | 'light' | 'outlined' | 'text';
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        spinner: {
            color: theme.palette.primary.contrastText,
        },
        padding: {
            width: 10,
        },
        button: {
            textTransform: 'none',
            width: 250,
            backgroundColor: colors.blue,
            fontSize: 18,
            color: colors.white,
            borderRadius: theme.spacing(1),
            borderColor: colors.white,
            border: theme.spacing(0.25),
            borderStyle: 'solid',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: colors.blue,
            },
            '&:disabled': {
                border: theme.spacing(0.25),
                borderStyle: 'solid',
            },
        },
        secondary: {
            backgroundColor: colors.white,
            color: colors.blue,
            '&:hover': {
                opacity: 0.8,
                backgroundColor: colors.white,
            },
        },
        small: {
            width: 160,
            padding: 0,
        },
        light: {
            borderColor: colors.white,
        },
        outlined: {
            color: colors.blue,
            backgroundColor: colors.white,
            borderColor: colors.blue,
            '&:hover': {
                backgroundColor: lighten(colors.blue, 0.9),
            },
        },
        text: {
            color: '#0000ee',//#561aa9
            fontSize: 16,
            fontWeight: 'bold',
            minWidth: '0px',
            paddingLeft: '0px',
            textAlign: 'left',
            textDecoration: 'underline',
            textTransform: 'none',
            '&:hover': {
                backgroundColor: 'transparent',
                color: '#561aa9',
                textDecoration: 'underline',
            },
        },
    })
);

const initialProps: CECLButtonProps = {
    loading: false,
    loadingSize: 14,
    variety: 'primary',
    variant: 'outlined',
};

const Button: React.FC<CECLButtonProps> = (props) => {
    const [state, setState] = React.useState<CECLButtonProps>({ ...initialProps, ...props });
    const { onDoneLoading, loading, loadingSize, ...buttonProps } = props;
    const [loadingStart, setLoadingStart] = React.useState(false);
    const classes = useStyles();

    React.useEffect(() => {
        setState((prevState) => ({ ...prevState, ...props }));

        if (state.loading) {
            setLoadingStart(true);
        }

        if (!state.loading && loadingStart && state.onDoneLoading) {
            state.onDoneLoading();
            setLoadingStart(false);
        }
    }, [props]);

    let variety = null;

    switch (state.variety) {
        case 'secondary':
            variety = classes.secondary;
            break;
        case 'small':
            variety = classes.small;
            break;
        case 'light':
            variety = classes.light;
            break;
        case 'outlined':
            variety = classes.outlined;
            break;
        case 'text':
            variety = classes.text;
            break;
    }

    function ConfigureButton() {
        if (state.variety == 'text') {
            return (
                <ReactButton
                    {...buttonProps}
                    disabled={state.loading || state.disabled}
                    className={clsx(variety)}
                    variant="text"
                >
                </ReactButton>
            );
        }
        return (
            <ReactButton
                {...buttonProps}
                disabled={state.loading || state.disabled}
                className={clsx(classes.button, variety, buttonProps.className)}
                variant={state.variant}
            >
                {state.children}
                {state.loading && <div className={classes.padding} />}
                {state.loading && <CircularProgress size={state.loadingSize} className={classes.spinner} />}
            </ReactButton>
        );
    }

    return (
        <div>
            {ConfigureButton()}
        </div>
    );
};

export default Button;
