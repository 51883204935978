import { FormControl, IconButton, MenuItem, Select } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/button/Button';
import Modal from '../../components/modal/Modal';
import Panel from '../../components/panel/Panel';
import AllocationOfAnnualNetChargeOffs from '../../containers/charts/historicaldata/AllocationOfAnnualNetChargeOffs';
import AnnualizedNetChargeOffs from '../../containers/charts/historicaldata/AnnualizedNetChargeOffs';
import AnnualNetChargeOffs from '../../containers/charts/historicaldata/AnnualNetChargeOffs';
import EndingBalances from '../../containers/charts/historicaldata/EndingBalances';
import HistoricalDataTable from '../../containers/tables/HistoricalData';
import { ApplicationState, LastAction } from '../../store';
import { ceclapiCreatePdfRequest, ceclapiNcuaHistoricalDataRequest } from '../../store/ceclapi/actions';
import { CeclApiState, PdfRequest } from '../../store/ceclapi/types';
import { colors } from '../../theme';
import SaveIcon from '@material-ui/icons/Save';
import Pdf from '../../containers/pdf/Pdf';
import { displayToast } from '../../store/config/actions';
import PdfHeaderPanel from './sections/PdfHeaderPanel';
import PdfFooterDiv from './sections/PdfFooterDiv';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        panels: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flex: 1,
            margin: -theme.spacing(1),
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 100,
        },
        title: {
            fontSize: 20,
            fontFamily: 'Montserrat',
            fontWeight: 'bolder',
            margin: 10,
            textAlign: 'center',
        },
        controlPanel: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        controlPanelTitle: {
            alignSelf: 'center',
        },
        controlPanelForm: {
            flex: 1,
        },
        description: {
            backgroundColor: colors.lightGray,
        },
        panel: {
            margin: theme.spacing(2),
        },
    })
);

const HistoricalDataPage: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const lastAction = useSelector<ApplicationState, LastAction>((state) => state.lastAction);

    const [timeHorizon, setTimeHorizon] = React.useState('10');
    const [selectedTable, setSelectedTable] = React.useState('');
    const [pdfMode, setPdfMode] = React.useState(false);
    const [pdfModal, setPdfModal] = React.useState(false);

    const handleTimeHorizonChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const val = event.target.value as string;
        setTimeHorizon(val);
        dispatch(ceclapiNcuaHistoricalDataRequest({ cuid: cecl.user!.routingNumber, lookback: val }));
    };

    const handleTableChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedTable(event.target.value as string);
    };

    // This functions as a redux action watcher.
    React.useEffect(() => {
        if (lastAction.type === '@@ceclapi/CECLAPI_CREATEPDF_SUCCESS') {
            const fixWidth = (id: string) => {
                const element = document.getElementById(id);
                element.style['width'] = null;
            };
            fixWidth('endingBalances');
            fixWidth('annualChargeOffs');
            fixWidth('allocation');
            fixWidth('annualizedChargeOffs');
            fixWidth('pdfHeaderPanel');
            fixWidth('pdfFooterDiv');

            setPdfMode(false);
        }

        if (lastAction.type === '@@ceclapi/CECLAPI_CREATEPDF_ERROR') {
            dispatch(
                displayToast({
                    message: 'An unexpected error occurred. Please try your request again.',
                    variant: 'error',
                })
            );
        }
    }, [lastAction]);

    React.useEffect(() => {
        if (!cecl.historicalData?.charts || !cecl.historicalData?.charts.length) {
            dispatch(ceclapiNcuaHistoricalDataRequest({ cuid: cecl.user!.routingNumber, lookback: timeHorizon }));
        }
    }, []);

    React.useEffect(() => {
        if (!selectedTable && cecl.historicalData?.charts?.length) {
            setSelectedTable('CU Net Charge Offs');
        }
    }, [cecl?.historicalData?.charts]);

    const createPdf = (base64Response: boolean = true) => {
        const creditUnionName = cecl.user?.creditUnionName ? cecl.user?.creditUnionName + '_' : '';
        const request: PdfRequest = {
            pages: [
                { id: 'endingBalances', ordinal: 0, width: '1750px' },
                { id: 'annualChargeOffs', ordinal: 1, width: '1750px' },
                { id: 'allocation', ordinal: 2, width: '1750px' },
                { id: 'annualizedChargeOffs', ordinal: 3, width: '1750px' },
            ],
            pagesHeaderFooter: [
                { id: 'pdfHeaderPanel', isHeader: true, stretch: true },
                { id: 'pdfFooterDiv', isHeader: false, stretch: true },
            ],
            fileName: `${creditUnionName}Historical Data_${new Date().toISOString()}.pdf`,
            base64Response,
        };

        // Prepare request with all tables
        cecl?.historicalData?.charts
            ?.filter((chart) => !chart.graphName.includes('FRED') && !chart.graphName.includes('Weighted'))
            .map((chart) => {
                const name = chart.graphName.toLowerCase().replace(/ /g, '-');
                const ordinal = request.pages.reduce((a, b) => (a.ordinal > b.ordinal ? a : b)).ordinal;
                
                request.pages.push({ id: name, ordinal: ordinal + 1, width: '2600px' });
            });

        setPdfMode(true);
        setPdfModal(true);

        // Wait for all tables to display before requesting PDF
        const loadingInterval = setInterval(() => {
            const element = document.getElementById('pdfMode');

            if (element && loadingInterval) {
                clearInterval(loadingInterval);
                dispatch(ceclapiCreatePdfRequest(request));
            }
        }, 500);
    };

    return (
        <div id="panels" className={classes.panels} style={{ filter: pdfMode ? 'opacity(0)' : 'none' }}>
            <Panel>
                <div className={classes.controlPanel}>
                    <div className={classes.controlPanelTitle}>Historical Time Horizon (Years): </div>
                    <div className={classes.controlPanelForm}>
                        <FormControl className={classes.formControl}>
                            <Select
                                labelId="time-horizon-select-label"
                                id="time-horizon-select"
                                value={timeHorizon}
                                displayEmpty
                                onChange={handleTimeHorizonChange}
                                disabled={cecl.historicalData?.loading}
                                inputProps={{ 'aria-label': 'Select an Option' }}
                            >
                                <MenuItem value="" disabled>
                                    Select an Option
                                </MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={15}>15</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <Button
                        onClick={() => {
                            createPdf();
                        }}
                    >
                        View as Pdf
                    </Button>
                </div>
                <EndingBalances hideInvisibleOnLegend={pdfMode} />
            </Panel>
            <Panel className={classes.panel}>
                <AnnualNetChargeOffs hideInvisibleOnLegend={pdfMode} />
            </Panel>
            <Panel className={classes.panel}>
                <AllocationOfAnnualNetChargeOffs hideInvisibleOnLegend={pdfMode} />
            </Panel>
            <Panel className={classes.panel}>
                <AnnualizedNetChargeOffs hideInvisibleOnLegend={pdfMode} />
            </Panel>            
            <Panel className={classes.panel}>
                <div className={classes.controlPanelTitle}>Select Table Data Set To View: </div>
                <div className={classes.controlPanelForm}>
                    <FormControl className={classes.formControl}>
                        <Select
                            labelId="table--select-label"
                            id="table-select"
                            value={selectedTable}
                            onChange={handleTableChange}
                        >
                            {cecl?.historicalData?.charts
                                ?.filter(
                                    (chart) =>
                                        !chart.graphName.includes('FRED') && !chart.graphName.includes('Weighted')
                                )
                                .map((chart, i) => {
                                    return (
                                        <MenuItem key={i} value={chart.graphName}>
                                            {chart.graphName}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </div>
                {pdfModal && <div id="pdfMode"></div>}
                {pdfModal &&
                    cecl?.historicalData?.charts
                        ?.filter((chart) => !chart.graphName.includes('FRED') && !chart.graphName.includes('Weighted'))
                        .map((chart, i) => (
                            <HistoricalDataTable key={i} selectedTable={chart.graphName} noScroll={true} />
                        ))
                        
                        }
                {!pdfModal && <HistoricalDataTable selectedTable={selectedTable} />}
            </Panel>
            { pdfMode && 
                <div>
                    <Panel>
                        <PdfHeaderPanel />
                    </Panel>
                    <Panel>
                        <PdfFooterDiv />
                    </Panel>
                </div>
            } 
            <Modal
                open={pdfModal}
                onClose={() => {
                    setPdfModal(false);
                }}
                contentbackgroundcolor={colors.lightGray}
                title="Historical Data PDF"
                titleactions={
                    <IconButton
                        onClick={() => {
                            createPdf(false);
                        }}
                        disabled={pdfMode}
                    >
                        <SaveIcon />
                    </IconButton>
                }
            >
                <Pdf data={cecl?.pdf?.data} loading={pdfMode} />
            </Modal>
        </div>
    );
};

export default HistoricalDataPage;
