import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import { AxiosError } from 'axios';
import React, { FC, useState } from 'react';
import { AxiosInstance } from '../../utils/callApi';

interface FileViewButtonProps extends ButtonProps {
  url: string;
  label?: string;
  modalTitle?: string;
  pdfId?: string;
  clickHandler?:(summary: {pdfData: string, pdfTitle: string, uniqueId: string, loading: boolean}) => void;
  modalHandler: () => void;
}

const FileViewButton: FC<FileViewButtonProps> = ({
  url,
  label,
  modalTitle,
  pdfId,
  clickHandler,
  modalHandler,
  startIcon,
  ...props
}) => {
  const [error, setError] = useState<AxiosError<{ message: string }> & any>();
  const handleViewPdf = e => {
    modalHandler();
    const run = async () => {
        await AxiosInstance({
            method: 'GET',
            url,
            responseType: 'text',
            timeout: null,
        })
        .then(response => {
            clickHandler({ pdfData: response.data, pdfTitle: modalTitle, uniqueId: pdfId, loading: false });
        })
        .catch(err => setError(err))
    };

    run();
  };

  if (error) return <div>{error?.message}</div>;

  return (
    <Button
      onClick={handleViewPdf}
      {...props}
    >
      View
    </Button>
  );
};

FileViewButton.defaultProps = {
  label: 'view',
};

export default FileViewButton;
