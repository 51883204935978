import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { ConfigState } from '../../store/config/types';
import { useEffect, useRef } from 'react';
import { createStyles, makeStyles, Tabs, Theme } from '@material-ui/core';
import { Tab } from '@material-ui/core';
import { colors } from '../../theme';
import TabPanel from '../../components/tabpanel/TabPanel';
import Button from '../../components/button/Button';
import LandingHomePage from './Home';
import LandingModelOverviewPage from './ModelOverview';
import LandingRequestDemoPage from './RequestDemo';
import LandingContactUsPage from './ContactUs';
import MarketingFooter from '../../containers/footer/MarketingFooter';
import { Link, NavLink, useParams } from 'react-router-dom';
import { CeclApiState } from '../../store/ceclapi/types';
import { useHistory } from 'react-router-dom';
import { logUserIn } from '../../utils/helpers';
import UserInfoDropdown from '../../containers/userinfodropdown/UserInfoDropdown';
import { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { TermsOfUsePage } from '../terms-of-use';
import { Privacy } from '../privacy';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: '100%',
            flexDirection: 'column',
            display: 'flex',
            margin: 0,
            height: '100%',
            position: 'relative',
        },
        headerArea: {
            position: 'sticky',
            top: 0,
            width: '100%',
            zIndex: 10000,
        },

        menuBar: {
            padding: theme.spacing(0.5),
            backgroundColor: colors.white,
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
        },
        loginButton: {
            right: theme.spacing(1),
            flexBasis: 200,
            minWidth: 200,
            height: theme.spacing(5),
            margin: 'auto',
        },
        logo: {
            margin: 10,
            cursor: 'pointer',
            alignContent: 'left',
            flex: '0 1 20%',
        },
        tabs: {
            flex: '1 1 auto',
            flexWrap: 'wrap',
            width: '50%',
        },
        right: {
            float: 'right',
            paddingRight: theme.spacing(2),
        },
        initialImage: {
            height: 75,
            width: '90%',
        },
        tab: {
            fontWeight: 'bold',
            opacity: 1,
            flex: '0 1 auto',
            minWidth: 'unset',
            maxWidth: 'unset',
            padding: 0,
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },

        indicator: {
            backgroundColor: 'gray',
        },

        paper: {
            margin: theme.spacing(2),
            marginTop: 0,
            padding: theme.spacing(1),
            height: '100%',
            flexGrow: 1,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
        },
        middle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        },
        colorBar: {
            width: '100%',
            display: 'grid',
            gridTemplateColumns: '41% 20% 14% 25%',
            gridTemplateRows: '0.75em',
        },
        blueBar: {
            backgroundImage: 'linear-gradient(92deg, #1b3d6d, #114fa7);',
        },
        grayBar: {
            backgroundImage: 'linear-gradient(94deg, #d0d0d0, #686868);',
        },
        redBar: {
            backgroundImage: 'linear-gradient(267deg, #ed2c37, #ce202f);',
        },
        yellowBar: {
            backgroundImage: 'linear-gradient(261deg, #fadc00, #fcb714);',
        },
    })
);

const LandingPage: React.FC = () => {
    const [shrink, setShrink] = useState(false);
    const dispatch = useDispatch();
    const config = useSelector<ApplicationState, ConfigState>((state) => state.config);
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const [tabIndex, setTabIndex] = React.useState<boolean | number>(false);
    const classes = useStyles();
    const { tab } = useParams<{ tab: string }>();
    type Callback = () => any;
    const history = useHistory();
    const animation = useSpring({ height: shrink ? `35px` : '100px' });
    const header = useRef(null);
    const headerAnimation = () => {
        setShrink(header.current && window.pageYOffset > header.current.offsetHeight);
    };

    useEffect(() => {
        window.addEventListener('scroll', headerAnimation);
        return () => {
            window.removeEventListener('scroll', headerAnimation);
        };
    }, []);

    useEffect(() => {
        switch (tab) {
            case 'model-overview-and-regulations':
                setTabIndex(0);
                break;
            case 'request-demo':
                setTabIndex(1);
                break;
            case 'contact-us':
                setTabIndex(2);
                break;
            case 'privacy-policy':
                setTabIndex(3);
                break;
            case 'termsofuse':
                setTabIndex(4);
                break;

            default:
                setTabIndex(false);
        }
    });    

    const ColorBar = () => {
        return (
            <div className={classes.colorBar}>
                <div className={classes.blueBar} />
                <div className={classes.grayBar} />
                <div className={classes.yellowBar} />
                <div className={classes.redBar} />
            </div>
        );
    };

    return (
        <div className={classes.root}>
            <header ref={header} className={classes.headerArea}>
                <ColorBar />
                <div className={classes.menuBar}>
                    <NavLink to="/">
                        <div
                            className={classes.logo}
                        >
                            <animated.img
                                className={classes.initialImage}
                                src={require('../../assets/images/cecl_logo.svg')}
                                alt="logo"
                                style={animation}
                            />
                        </div>
                    </NavLink>

                    <div className={classes.tabs}>
                        <Tabs
                            className={classes.right}
                            value={tabIndex}
                            indicatorColor="primary"
                            onChange={(_, val) => {
                                setTabIndex(val);
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                            aria-label="tabs"
                        >
                            <Tab
                                className={classes.tab}
                                component={Link}
                                to="/home/model-overview-and-regulations"
                                label="Model Overview and Regulations"
                            />
                            <Tab
                                className={classes.tab}
                                component={Link}
                                to="/home/request-demo"
                                label="Request Demo"
                            />
                            <Tab className={classes.tab} component={Link} to="/home/contact-us" label="Contact Us" />

                            <Tab
                                className={classes.tab}
                                component={Link}
                                style={{ display: 'none' }}
                                to="/home/privacy-policy"
                            />
                            <Tab
                                className={classes.tab}
                                component={Link}
                                style={{ display: 'none' }}
                                to="/home/termsofservice"
                            />
                        </Tabs>
                    </div>
                    {cecl?.user?.isAuthenticated ? (
                        <UserInfoDropdown />
                    ) : (
                        <Button
                            className={classes.loginButton}
                            onClick={() => {
                                logUserIn();
                            }}
                        >
                            Login
                        </Button>
                    )}
                </div>
            </header>
            <div>
                <TabPanel value={tabIndex} index={false}>
                    <LandingHomePage />
                </TabPanel>
                <TabPanel value={tabIndex} index={0}>
                    <LandingModelOverviewPage />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <LandingRequestDemoPage />
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                    <LandingContactUsPage />
                </TabPanel>
                <TabPanel value={tabIndex} index={3}>
                    <Privacy />
                </TabPanel>
                <TabPanel value={tabIndex} index={4}>
                    <TermsOfUsePage />
                </TabPanel>
            </div>
            <MarketingFooter />
        </div>
    );
};

export default LandingPage;
