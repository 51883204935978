import { CircularProgress, Grid, makeStyles, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { CeclApiState } from '../../../../store/ceclapi/types';
import { colors } from '../../../../theme';
import Modal from '../../../../components/modal/Modal';
import CheckIcon from '@material-ui/icons/Check';

export interface LoadingModalProps {
    pdfMode?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paddingLess: {
            padding: 0,
        },
        title: {
            width: '100%',
            textAlign: 'left',
            fontSize: 35,
            paddingLeft: theme.spacing(2),
        },
        dialogDivider: {
            color: colors.black,
        },
        spinner: {
            color: theme.palette.primary.contrastText,
        },
        loadingItem: {
            width: 250,
            height: 75,
        },
    })
);

const LoadingModal: React.FC<LoadingModalProps> = (props) => {
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const classes = useStyles();

    React.useEffect(() => {}, []);

    return (
        <Modal
            open={
                cecl.forecastAssumptions?.indexes?.loading ||
                cecl.forecastAssumptions?.detail?.loading ||
                cecl.calculatedLoss?.loading ||
                (props.pdfMode && !cecl.pdf?.data)
            }
            title="Loading Results..."
            maxWidth="xs"
            style={{ zIndex: 50000 }}
        >
            <div style={{ padding: 20 }}>
                <Grid container justify="center" spacing={3}>
                    <Grid item xs={6}>
                        Updating FRED Indexes...
                    </Grid>
                    <Grid item xs={6} className={classes.loadingItem}>
                        {cecl.forecastAssumptions?.indexes?.loading && (
                            <CircularProgress size={12} className={classes.spinner} />
                        )}
                        {cecl.forecastAssumptions?.indexes?.indexList?.length && <CheckIcon />}
                    </Grid>
                    <Grid item xs={6}>
                        Loading Forecast...
                    </Grid>
                    <Grid item xs={6} className={classes.loadingItem}>
                        {cecl.forecastAssumptions?.detail?.loading && (
                            <CircularProgress size={12} className={classes.spinner} />
                        )}
                        {cecl.forecastAssumptions?.detail?.data?.id && <CheckIcon />}
                    </Grid>
                    <Grid item xs={6}>
                        Calculating Data...
                    </Grid>
                    <Grid item xs={6} className={classes.loadingItem}>
                        {cecl.calculatedLoss?.loading && <CircularProgress size={12} className={classes.spinner} />}
                        {cecl.calculatedLoss?.cuId && <CheckIcon />}
                    </Grid>
                    {props.pdfMode && (
                        <>
                            <Grid item xs={6}>
                                Building PDF...
                            </Grid>
                            <Grid item xs={6} className={classes.loadingItem}>
                                {cecl.pdf?.loading && <CircularProgress size={12} className={classes.spinner} />}
                                {cecl.pdf?.data && <CheckIcon />}
                            </Grid>
                        </>
                    )}
                </Grid>
            </div>
        </Modal>
    );
};

export default LoadingModal;
