import { createGlobalStyle, css } from 'styled-components';
/* eslint-disable import/prefer-default-export */
import { generateMedia } from 'styled-media-query';
import { muiTheme } from './theme';

export const zIndex = {
  session: 5000,
};

export const containerWidths = {
  xs: '0px',
  sm: `375px`,
  md: `720px`,
  lg: `960px`,
  xl: `1200px`,
};

export const media = generateMedia({
  xs: containerWidths.xs,
  sm: containerWidths.sm,
  md: containerWidths.md,
  lg: containerWidths.lg,
  xl: containerWidths.xl,
});

export const fluidPadding = css`
  padding: 0 16px;
  margin: 0;

  ${media.greaterThan('sm')`
    padding-left: 24px;
    padding-right: 24px;
  `};

  ${media.greaterThan('md')`
  padding: 0 32px;
  `};
`;

export const fluidMargin = css`
  margin: 0 16px;
  padding: 0;

  ${media.greaterThan('sm')`
    margin: 0 24px;
  `};

  ${media.greaterThan('md')`
    margin: 0 32px;
  `};
`;

export const GlobalStyle = createGlobalStyle`
  td,
  th {
    :first-child {
      padding-left: 1em;
      }
    :last-child {
      padding-right: 1em;
      }
  }

  .ie-error {
    display: none !important;
    * {margin: 0;
    opacity: 0;}
  }

  .LP-withAITracking {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
`;

export default muiTheme;
