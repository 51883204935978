import React from 'react';
import { useSelector } from 'react-redux';
import Chart, { ChartProps } from '../../../components/chart/Chart';
import { ApplicationState } from '../../../store';
import { CeclApiState, ChartData, DataPoint, LossesDataPoint } from '../../../store/ceclapi/types';

export interface LossesChartProps extends ChartProps {
    forecastTimeHorizon: number;
    historicalTimeHorizon: number;
}

const initialChartProps: LossesChartProps = {
    options: {
        title: {
            text: 'Historical & Forecasted Losses',
            fontSize: 20,
            fontFamily: 'Montserrat',
            fontWeight: 'bolder',
            margin: 50,
        },
        legendDisplay: 'left',
        legendTitle: 'Select Data Sets To Show',
        axisY: {
            valueFormatString: "0.00'%'",
        },
        axisY2: {
            valueFormatString: '0.000',
        },
    },
    data: [],
    id: 'historicalForecastedLosses',
    forecastTimeHorizon: 5,
    historicalTimeHorizon: 25,
};

const DateToMMYY = (dateString: any) => {
    const date = new Date(dateString);

    let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let monthIndex = date.getMonth();
    let monthName = monthNames[monthIndex];
    let year = date.getFullYear().toString().substr(-2);

    return `${monthName}-${year}`;
};

const HistoricalForecastedLosses: React.FC<LossesChartProps> = (props) => {
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const [chartProps, setChartProps] = React.useState<LossesChartProps>({ ...initialChartProps, ...props });
    const [forecasts, setForecasts] = React.useState<LossesDataPoint[]>([]);
    const [economicIndexHistoricals, setEconomicIndexHistoricals] = React.useState<LossesDataPoint[]>([]);

    let economicIndex = cecl.calculatedLoss?.economicIndex;

    if (cecl.forecastAssumptions?.indexes?.indexList) {
        economicIndex = cecl.forecastAssumptions?.indexes?.indexList.find((i) => i.fredSeriesId === economicIndex)
            ?.fredLabel;
    }

    const prepChartData = (): ChartData[] => {
        const chartData: ChartData[] = [];

        const buildChart = (
            data: LossesDataPoint[],
            name: string,
            type: string,
            field: string,
            lineDashType: string = 'solid',
            yIndex: number = 0
        ) => {
            const dataPoints: DataPoint[] = data.map((d) => ({
                label: DateToMMYY(d.item1),
                markerType: 'none',
                x: new Date(d.item1),
                y: d[field],
            }));

            chartData.push({
                name: name,
                type: type,
                lineDashType: lineDashType,
                dataPoints: dataPoints,
                axisYType: yIndex === 1 ? 'secondary' : 'primary',
            });
        };

        if (forecasts) {
            buildChart(forecasts, cecl.user?.creditUnionName, 'line', 'item3');
            buildChart(forecasts, 'National Loss % - All CU', 'line', 'item2');
            buildChart(forecasts, 'National Loss % by Asset Size', 'line', 'item4');
            buildChart(forecasts, 'State Loss % by Asset Size', 'line', 'item5');
        }

        if (economicIndexHistoricals) {
            buildChart(economicIndexHistoricals, economicIndex, 'line', 'item2', 'solid', 1);
        }

        return chartData;
    };

    React.useEffect(() => {
        const historicalDate = new Date();
        const forecastDate = new Date();
        historicalDate.setFullYear(new Date().getFullYear() - props.historicalTimeHorizon);
        forecastDate.setFullYear(new Date().getFullYear() + props.forecastTimeHorizon);

        if (cecl.calculatedLoss?.viewForecastChartingData?.historicalPlusForecast) {
            setForecasts(
                cecl.calculatedLoss?.viewForecastChartingData?.historicalPlusForecast.filter(
                    (c) =>
                        new Date(c.item1).getTime() >= historicalDate.getTime() &&
                        new Date(c.item1).getTime() <= forecastDate.getTime()
                )
            );
        }

        if (cecl.calculatedLoss?.viewForecastChartingData?.economicIndexHistoricalPlusForecast) {
            setEconomicIndexHistoricals(
                cecl.calculatedLoss?.viewForecastChartingData?.economicIndexHistoricalPlusForecast.filter(
                    (c) =>
                        new Date(c.item1).getTime() >= historicalDate.getTime() &&
                        new Date(c.item1).getTime() <= forecastDate.getTime()
                )
            );
        }
    }, [props]);

    React.useEffect(() => {
        setChartProps((prevState) => ({
            ...prevState,
            data: prepChartData(),
            forecastTimeHorizon: props.forecastTimeHorizon,
            historicalTimeHorizon: props.historicalTimeHorizon,
        }));
    }, [forecasts, economicIndexHistoricals]);

    return <Chart loading={cecl.calculatedLoss?.loading} {...chartProps} />;
};

export default HistoricalForecastedLosses;
