import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';

interface Props extends ButtonProps {
  loading: boolean;
}

const LoadingButton: React.FC<Props> = ({ loading, ...rest }) => {
  if (loading)
    return (
      <Button {...rest} disabled>
        <CircularProgress size={24} />
      </Button>
    );

  return <Button {...rest} />;
};

LoadingButton.defaultProps = {
  loading: false,
  color: 'primary',
  variant: 'contained',
};

export default LoadingButton;
