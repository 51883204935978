import { FormControl, Grid, makeStyles, MenuItem, Select, Theme, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { colors } from '../../../../theme';

export interface ChartParametersProps {
    forecastTimeHorizon: number;
    historicalTimeHorizon: number;
    onChange?: ({ forecastTimeHorizon, historicalTimeHorizon }) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(5),
            paddingLeft: theme.spacing(10),
        },
        paddingLess: {
            padding: 0,
        },
        title: {
            width: '100%',
            textAlign: 'left',
            fontSize: 35,
            paddingLeft: theme.spacing(2),
        },
        dialogDivider: {
            color: colors.black,
        },
        inputName: {
            fontWeight: 'bold',
            textAlign: 'end',
            whiteSpace: 'nowrap',
        },
        inputBar: {
            width: '100%',
        },
        paddingRight: {
            paddingRight: theme.spacing(2),
        },
    })
);

const ChartParameters: React.FC<ChartParametersProps> = (props) => {
    const classes = useStyles();
    const [state, setState] = React.useState<ChartParametersProps>(props);

    const forecastTimeHorizons: number[] = Array.from(Array(6).keys());
    const historicalTimeHorizons: number[] = Array.from(Array(26).keys());

    const handleForecastTimeHorizonChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setState((prev) => ({ ...prev, forecastTimeHorizon: event.target.value as number }));

        if (state.onChange) {
            state.onChange({
                forecastTimeHorizon: event.target.value as number,
                historicalTimeHorizon: state.historicalTimeHorizon,
            });
        }
    };

    const handleHistoricalTimeHorizonChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setState((prev) => ({ ...prev, historicalTimeHorizon: event.target.value as number }));

        if (state.onChange) {
            state.onChange({
                forecastTimeHorizon: state.forecastTimeHorizon,
                historicalTimeHorizon: event.target.value as number,
            });
        }
    };

    return (
        <Grid container item direction="row" spacing={4} className={classes.root}>
            <Grid item xs={6}>
                <Grid item container direction="row" spacing={1}>
                    <Grid item xs={6}>
                        <Typography className={classes.inputName}>Forecast Time Horizon (Years):</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl className={classes.inputBar}>
                            {forecastTimeHorizons && (
                                <Select
                                    id="forecast-time-horizon-select"
                                    value={state.forecastTimeHorizon}
                                    onChange={handleForecastTimeHorizonChange}
                                >
                                    {forecastTimeHorizons.map((val, index) => {
                                        return (
                                            <MenuItem value={val} key={index}>
                                                {val}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid item container direction="row" spacing={1}>
                    <Grid item xs={6}>
                        <Typography className={classes.inputName}>Historical Time Horizon (Years):</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.paddingRight}>
                        <FormControl className={classes.inputBar}>
                            {historicalTimeHorizons && (
                                <Select
                                    id="historical-time-horizon-select"
                                    value={state.historicalTimeHorizon}
                                    onChange={handleHistoricalTimeHorizonChange}
                                >
                                    {historicalTimeHorizons.map((val, index) => {
                                        return (
                                            <MenuItem value={val} key={index}>
                                                {val}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ChartParameters;
