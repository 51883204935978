import { createStyles, Grid, makeStyles, Theme, Typography, lighten } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { flatten } from 'lodash';
import { useSelector } from 'react-redux';
import Table, { Column, TableProps } from '../../components/table/Table';
import Button from '../../components/button/Button';
import FileDownloadButton from '../../components/button/FileDownloadButton';
import { ApplicationState } from '../../store';
import { CeclApiState, ForecastInput, LossForecastResult, LossesDataPoint } from '../../store/ceclapi/types';
import { colors } from '../../theme';
import { formatCurrencyNumber, formatDate, formatNumber } from '../../utils/helpers';
import Modal from '../../components/modal/Modal';
import ProjectedMonthlyLossesTable from './ProjectedMonthlyLossesTable';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        blueBar: {
            fontWeight: 'bold',
            color: colors.white,
            backgroundColor: colors.blue,
            textAlign: 'start',
        },
        inputBar: {
            width: '100%',
        },
        inputName: {
            fontWeight: 'bold',
            textAlign: 'left',
            fontSize: 22,
            color: colors.white,
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(1),
        },
        blueBackground: {
            backgroundColor: colors.blue,
        },
        blueBorder: {
            borderWidth: 1,
            borderColor: colors.blue,
            borderStyle: 'solid',
        },
        paddingLess: {
            padding: 0,
        },
        lightBlueBackground: {
            backgroundColor: colors.lightBlue,
            textAlign: 'right',
        },
        inputRoot: {
            marginLeft: '0px !important',
        },
        value: {
            width: '100%',
            textAlign: 'right',
            fontSize: 22,
            paddingLeft: theme.spacing(2),
            marginTop: theme.spacing(1),
            fontWeight: 'bold'
        },
        noScroll: {
            '& > *': {
                '& > *': {
                    overflowX: 'unset',
                },
            },
        },
        gridLeft: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        gridRight: {
            display: 'flex',
            justifyContent: 'flex-start',
        },
        button: {
            transform: 'scale(0.7)',
        },
        closeModalButton: {
            fontSize: '16px',
            fontWeight: 'bold',
        },
        downloadButton: {
            width: 300,
        },
        btnLink: {
            '& .btn-link.btn-anchor': {
                border: 0,
                color: colors.blue,
                fontSize: '22px',
                outline: 'none !important',
                padding: 0,
                verticalAlign: 'baseline',
            },
            '& .btn-link.btn-anchor:hover': {
                textDecoration: 'underline',
            }
        },
        downloadCsvButton: {
            backgroundColor: colors.blue,
            color: colors.white,
            fontSize: '16px',
            textTransform: 'none',
        },
    })
);

interface ExpectedCreditLossesTableProps {
    noScroll?: boolean;
}

const ExpectedCreditLossesTable: React.FC<ExpectedCreditLossesTableProps> = (props) => {
    const classes = useStyles();
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const [monthlyInputsTableProps, setMonthlyInputsTableProps] = React.useState({
        loading: true,
        dense: true,
        keyColumn: 'name',
        rows: [],
        columns: [],
        selectable: false,
        hasBorder: true,
    } as TableProps);
    const [totalBalance, setTotalBalance] = React.useState({} as number);
    const [pdfModal, setPdfModal] = React.useState({
        open: false,
        title: '',
    });
    const [pdfModalData, setPdfModalData] = React.useState({
        backcast: null as LossesDataPoint[],
        economicIndexHistory: null as LossesDataPoint[],
        forecast: null as LossForecastResult,
        historyCutoffDate: null as Date,
    });
    const [csvData, setCsvData] = React.useState([{}] as any[]);

    const bodyFontSize = "1.3000rem";
    const headerFontSize = 22;

    const defaultMonthlyInputsColumns: Column[] = [
        {
            name: 'name',
            label: '',
            numeric: false,
            bodyColor: colors.lightGray3,
            bodyFontSize: bodyFontSize,
        },
        {
            name: 'Period Ending Balance',
            label: 'Period Ending Balance',
            numeric: false,
            headerColor: colors.lightGray3,            
            centerHeader: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
            alignRight: true,
        },
        {
            name: 'Number Of Loans',
            label: 'Number Of Loans',
            numeric: true,
            numericPrecision: 0,
            headerColor: colors.lightGray3,
            centerHeader: true,
            centerBody: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
        },
        {
            name: 'Average Loan Rate (%)',
            label: 'Average Loan Rate (%)',
            numeric: true,
            decorator: '%',
            decoratorPlacement: 'end',
            headerColor: colors.lightGray3,
            centerHeader: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
        },
        {
            name: 'Weighted Average Remaining Maturity (Months)',
            label: 'Weighted Average Remaining Maturity (Months)',
            numeric: true,
            numericPrecision: 0,
            headerColor: colors.lightGray3,
            centerHeader: true,
            centerBody: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
        },
        {
            name: 'Lookback Period (Years)',
            label: 'Lookback Period (Years)',
            numeric: true,
            numericPrecision: 0,
            headerColor: colors.lightGray3,
            centerHeader: true,
            centerBody: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
        },
        /* {
            name: 'Historical Net Charge-Off Ratio',
            label: 'Historical Net Charge-Off Ratio',
            numeric: true,
            decorator: '%',
            decoratorPlacement: 'end',
            headerColor: colors.lightGray3,
            centerHeader: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
        }, */
        {
            name: 'Loan Quality Qualitative Factor Adjustment (%)',
            label: 'Loan Quality Qualitative Factor Adjustment (%)',
            numeric: true,
            decorator: '%',
            decoratorPlacement: 'end',
            headerColor: colors.lightGray3,
            centerHeader: true,
            multiplyFactor: 100,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
        },
        {
            name: 'Expected Lifetime Losses (%)',
            label: 'Expected Lifetime Losses (%)',
            numeric: true,
            decorator: '%',
            decoratorPlacement: 'end',
            headerColor: colors.lightGray3,
            centerHeader: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
        },
        {
            name: 'Estimated Current Expected Credit Losses ($)',
            label: 'Estimated Current Expected Credit Losses ($)',
            numeric: false,
            headerColor: colors.lightGray3,
            centerHeader: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
            alignRight: true,
        },
        {
            name: 'Year 1 Losses ($)',
            label: 'Year 1 Losses ($)',
            numeric: false,
            headerColor: colors.lightGray3,
            centerHeader: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
            alignRight: true,
        },
        {
            name: 'Year 2 Losses ($)',
            label: 'Year 2 Losses ($)',
            numeric: false,
            headerColor: colors.lightGray3,
            centerHeader: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize, 
            alignRight: true,            
        },
        {
            name: 'Years 3-5 Losses ($)',
            label: 'Years 3-5 Losses ($)',
            numeric: false,
            headerColor: colors.lightGray3,
            centerHeader: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
            alignRight: true,
        },
    ];

    React.useEffect(() => {
        if (cecl!.calculatedLoss?.loanTypeLossForecastResults && cecl.forecastAssumptions?.detail?.data) {
            var orderedData = cecl.forecastAssumptions.detail.data.inputs
            .sort((a, b) => {
                return a.order! - b.order!;
            });

            mapMonthlyInputsTableDataFromProps(orderedData);
            mapCsvDataFromProps(orderedData);
        }
    }, [cecl.forecastAssumptions?.detail?.data, cecl.calculatedLoss?.loanTypeLossForecastResults]);

    React.useEffect(() => {
        var newBalance: number = 0;

        monthlyInputsTableProps.rows.forEach((row) => {
            newBalance += row['Period Ending Balance'];
        });

        setTotalBalance(newBalance);
    }, [monthlyInputsTableProps.rows]);

    const PdfActions = () => (
        <Grid container direction="row" alignItems="center" spacing={0} justify="center">
            <Grid item xs={5} className={classes.gridLeft}>
                <Button 
                    className={classes.closeModalButton}
                    variety="outlined"
                    onClick={() => modalOnClose()}
                >
                    Close
                </Button>
            </Grid>
            <Grid item xs={1}>
                {' '}
            </Grid>
            <Grid item xs={5} className={classes.gridRight}>
                <FileDownloadButton
                        buttonClass={classes.downloadCsvButton}
                        downloadingLabel="Document is being prepared for download."
                        label='Download All Loan Types CSV'
                        url={`forecast/getProjectedLossesCsv`}
                        requestMethod='POST'
                        data={csvData}
                />
            </Grid>
        </Grid>
    );

    const modalOnClose = () => {
        setPdfModal({
            ...pdfModal,
            open: false,
            title: '',
        });
        setPdfModalData({
            ...pdfModal,
            backcast: null,
            economicIndexHistory: null,
            forecast: null,
            historyCutoffDate: null,
        });
    };

    function GetHistoricLevelsTable(result: LossForecastResult, loanHistory: LossesDataPoint[], ecoIndexHistory: LossesDataPoint[], loanType: string, historyCutoff: Date){
        setPdfModalData({
            ...pdfModalData,
            backcast: loanHistory,
            economicIndexHistory: ecoIndexHistory,
            forecast: result,
            historyCutoffDate: historyCutoff,
        });
        setPdfModal({
            ...pdfModal,
            open: true,
            title: loanType,
        });
    }

    //Make sure that the cutoff date for backcast history is at most 10 years from the current date.
    function getHistoryCutoffDate(date: Date, lookbackPeriod: number){
        const analysisDate = new Date(date);
        const analysisLookbackDate = new Date(analysisDate.getFullYear() - lookbackPeriod, analysisDate.getMonth(), analysisDate.getDate());

        const currDate = new Date();
        const tenYearsAgo = new Date(currDate);
        tenYearsAgo.setFullYear(currDate.getFullYear() - 10);

        return (tenYearsAgo.getTime() < analysisLookbackDate.getTime()) ? analysisLookbackDate : tenYearsAgo;
    }

    function mapCsvDataFromProps(orderedData: ForecastInput[]) {
        var newData = flatten(orderedData
            .map((input, i) => {
                const result = cecl.calculatedLoss.loanTypeLossForecastResults.find(
                    (l) => l.analysisRequestTaskNumber === i + 1
                );
                return result.pandILoanAmortizationSchedule
                    .map((schedule) => {
                        var obj: any = {};
                        obj['Date'] = formatDate(schedule.paymentDate);
                        obj['Loan Type'] = input.name;
                        obj['Lookback Period'] = result.loanTypeLossAnalysisRequest.historicalLookbackPeriodYear;
                        obj['Q Factor'] = `${formatNumber(schedule.qFactorAdjustedForecastedSpecificCULossAtAllCUsValue)}%`;
                        obj['Economic Index'] = (schedule.forecastedEconomicIndexAtPaymentDate).toFixed(4);
                        obj['All CUs Forecast'] = (schedule.forecastedAllCUsValueAtEconomicIndicator).toFixed(4);
                        obj['CU Forecast'] = (schedule.forecastedSpecificCULossAtAllCUsValue).toFixed(4);

                        return obj;
                    });
            }));
        setCsvData(newData);
    }

    function mapMonthlyInputsTableDataFromProps(orderedData: ForecastInput[]) {
        var newRows = orderedData
            .map((input, i) => {
                const result = cecl.calculatedLoss.loanTypeLossForecastResults.find(
                    (l) => l.analysisRequestTaskNumber === i + 1
                );
                const loanHistory = cecl.calculatedLoss.timeHorizonHistoryAndPredictionsData.annualizedLossPctHistoricalDataPointsGroupedByLoanType[i];
                const ecoIndexHistory = cecl.calculatedLoss.timeHorizonHistoryAndPredictionsData.economicIndexHistoricalDataPoints;
                const historyCutoffDate = getHistoryCutoffDate(cecl.calculatedLoss.analysisDate, result.loanTypeLossAnalysisRequest.historicalLookbackPeriodYear);
                
                var obj: any = {};
                obj['name'] = (<div className={classes.btnLink}><button className='btn btn-link btn-anchor' onClick={() => GetHistoricLevelsTable(result, loanHistory, ecoIndexHistory, input.name, historyCutoffDate)}>{input.name}</button></div>);//will need to add in cecl.calculatedLoss.viewForecastChartingData
                obj['Period Ending Balance'] = formatCurrencyNumber(result.loanTypeLossAnalysisRequest.endingBalance);
                obj['Number Of Loans'] = result.loanTypeLossAnalysisRequest.numberOfLoans;
                obj['Average Loan Rate (%)'] = result.loanTypeLossAnalysisRequest.averageLoanRatePercent;
                obj['Weighted Average Remaining Maturity (Months)'] =
                    result.loanTypeLossAnalysisRequest.weightedAverageRemainingMaturityMonths;
                obj['Lookback Period (Years)'] = result.loanTypeLossAnalysisRequest.historicalLookbackPeriodYear;
                //obj['Historical Net Charge-Off Ratio'] = result.historicalNetChargeOffsData.specificCreditUnion * 100;
                obj['Loan Quality Qualitative Factor Adjustment (%)'] =
                    result.loanTypeLossAnalysisRequest.loanQualityQualitativeAdjustmentFactor;
                obj['Expected Lifetime Losses (%)'] = result.expectedLifetimeLossesPercent;
                obj['Estimated Current Expected Credit Losses ($)'] =
                formatCurrencyNumber(result.estimatedCurrentExpectedCreditLossesDollarAmount);
                obj['Year 1 Losses ($)'] = formatCurrencyNumber(result.year1Losses);
                obj['Year 2 Losses ($)'] = formatCurrencyNumber(result.year2Losses);
                obj['Years 3-5 Losses ($)'] = formatCurrencyNumber(result.year2To5Losses);

                return obj;
            });

        setMonthlyInputsTableProps((prevState) => ({
            ...prevState,
            columns: defaultMonthlyInputsColumns,
            rows: newRows,
            loading: false,
        }));
    }   

    return (
        <div className={props.noScroll ? classes.noScroll : null}>
            <Table
                loading={monthlyInputsTableProps.loading}
                columns={monthlyInputsTableProps.columns}
                dense={monthlyInputsTableProps.dense}
                rows={monthlyInputsTableProps.rows}
                keyColumn={monthlyInputsTableProps.keyColumn}
                selectable={monthlyInputsTableProps.selectable}
                hasBorder={monthlyInputsTableProps.hasBorder}
            />
            {!monthlyInputsTableProps.loading && (
                <Grid container direction="row" spacing={2} className={classes.inputRoot}>
                    <Grid item xs={3} className={clsx(classes.blueBackground, classes.paddingLess)}>
                        <Typography className={classes.inputName}>Total Loans &amp; Leases</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.lightBlueBackground}>
                        <Typography className={classes.value}>
                            {formatCurrencyNumber(cecl.calculatedLoss?.totalAmountOfLoansAndLeasesEndingBalances)}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <Modal
            open={pdfModal.open}
            title="Projected Monthly Losses"
            onClose={() => modalOnClose()}
            contentbackgroundcolor={colors.white}
            loading={monthlyInputsTableProps.loading}
            actions={<PdfActions />}>
                <ProjectedMonthlyLossesTable result={pdfModalData} title={pdfModal.title} />
            </Modal>
        </div>
    );
};

export default ExpectedCreditLossesTable;
