import Button from '../../../components/button/Button';
import ForecastMonthlyInputs from './ForecastAssumptionsSections/ForecastMonthlyInputs';
import ForecastParameters from './ForecastAssumptionsSections/ForecastParameters';
import ForecastSpecialClassificationLoans from './ForecastAssumptionsSections/ForecastSpecialClassificationLoans';
import LoadingSpinner from '../../../components/loadingspinner/LoadingSpinner';
import React from 'react';
import { ApplicationState, LastAction } from '../../../store';
import { colors } from '../../../theme';
import { ConfigState } from '../../../store/config/types';
import { displayToast } from '../../../store/config/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
    createStyles,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    lighten,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';
import {
    ceclapiCalculateLossClear,
    ceclapiForecastAssumptionsDetailRequest,
    ceclapiForecastAssumptionsSaveRequest,
    ceclApiForecastConfigurationRequest,
    ceclapiFredIndexesRequest,
    ceclstateForecastAssumptionsReset,
} from '../../../store/ceclapi/actions';
import {
    CeclApiState,
    ForecastAssumptionsSaveRequest,
    ForecastInput,
    ForecastParameter,
    ForecastSpecialClassificationLoan,
    UserTypes,
    ValidationError,
} from '../../../store/ceclapi/types';
import FileDownloadButton from '../../../components/button/FileDownloadButton';
import FileUploadButton from '../../../components/button/FileUploadButton';
import { AxiosResponse } from 'axios';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        saveButton: {
            color: colors.blue,
            backgroundColor: colors.white,
            borderColor: colors.blue,
            '&:hover': {
                backgroundColor: lighten(colors.blue, 0.9),
            },
        },
        hide: {
            display: 'none',
        },
        uploadDownloadButton: {
            backgroundColor: colors.blue,
            color: colors.white,
            fontSize: '18px',
            marginLeft: '10px',
            textTransform: 'none',
        },
        cancelButton: {
            backgroundColor: 'transparent',
            border: 'none',
            color: colors.blue,
            textDecoration: 'underline',
            '&:hover': {
                backgroundColor: lighten(colors.blue, 0.9),
            },
        },
        dialogTitle: {
            width: '100%',
            fontSize: 35,
        },
        dialogBox: {
            textAlign: 'center',
        },
        dialogDismiss: {
            margin: 'auto',
        },
        errorDiv: {
            color: colors.red
        }
    })
);

export interface BuildForecastRouteParams {
    routingNumber?: string;
    assumptionsId?: string;
}

interface ValidationResult {
    passes?: boolean;
    parameterErrors?: ValidationError[];
    inputErrors?: ValidationError[];
    specialLoanErrors?: ValidationError[];
}

const BuildForecastAssumptions: React.FC = () => {
    const routeParams = useParams<BuildForecastRouteParams>();
    const classes = useStyles();
    const history = useHistory();
    const lastAction = useSelector<ApplicationState, LastAction>((state) => state.lastAction);
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const config = useSelector<ApplicationState, ConfigState>((state) => state.config);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(true);
    const [parametersWithErrors, setParametersWithErrors] = React.useState<ValidationError[]>([]);
    const [inputsWithErrors, setInputsWithErrors] = React.useState<ValidationError[]>([]);
    const [specialLoansWithErrors, setSpecialLoansWithErrors] = React.useState<ValidationError[]>([]);
    const [goToViewForecast, setGoToViewForecast] = React.useState<boolean>(false);
    const [currentAssumptionId, setCurrentAssumptionId] = React.useState(null as string);
    const [currentAssumptionName, setCurrentAssumptionName] = React.useState(null as string);
    const [isLoadAssumption, setIsLoadAssumption] = React.useState(false);
    const { closeSnackbar } = useSnackbar();
    const errorMap = {
        Required: 
            '• Required fields are missing.\n',
        ErrorType1: 
            `• A parameter cannot exceed the maximum historical data retrieval range of ${process.env.MAXIMUM_RETRIEVAL_MONTHS} months.\n`,
        ErrorType2:
            `• A parameter cannot be less than the minimum historical data retrieval range of ${process.env.MINIMUM_RETRIEVAL_YEARS} years. \n`,
        ErrorType3: 
            '• Inputs cannot have an average Loan Rate of less than zero.\n',
        ErrorType4:
            '• Inputs cannot have a Dollar Amount Of Interest Only Loans that exceeds Ending Balance.\n',
        ErrorType5: 
            '• Inputs cannot have an Ending Balance that is less than or equal to zero.\n',
        ErrorType6:
            '• Inputs cannot simultaneously have an Ending Balance greater than zero and a Weighted Average Remaining Maturity less than or equal to zero.\n',
        ErrorType7:
            '• Dollar Amount of Interest Only Loans and Specific Loan Loss Ending Balance summed together cannot exceed Ending Balance.\n',
        ErrorType8:
            "• A Special Classification Loan's Allocation cannot exceed its Specific Loan Loss Ending Balance.\n",
    };

    const [dialogProps, setDialogProps] = React.useState({
        open: false,
    });

    const [csvResponse, setCsvResponse] = React.useState<AxiosResponse>();

    const [fileUploadError, setFileUploadError] = React.useState<null|string>(null);

       // This functions as a redux action watcher.
    React.useEffect(() => {
        if (lastAction.type === '@@ceclapi/CECL_FORECASTASSUMPTIONSSAVE_SUCCESS') {
            if (!goToViewForecast) {
                setCurrentAssumptionId(cecl.forecastAssumptions?.detail?.data?.id);
                setCurrentAssumptionName(cecl?.forecastAssumptions?.detail?.data?.name);
                handleDialogOpen();
            } else {
                setGoToViewForecast(false);
                history.push(`/forecast/view/${routeParams.routingNumber}/${cecl.forecastAssumptions?.detail?.data?.id}`);
            }
        }

        if (lastAction.type === '@@ceclapi/CECL_FORECASTASSUMPTIONSSAVE_ERROR') {
            dispatch(
                displayToast({
                    message: 'An unexpected error occurred. Please try your request again',
                    variant: 'error',
                })
            );
        }

        if (lastAction.type === '@@ceclapi/CECL_FORECASTCONFIGURATION_SUCCESS') {
            if (routeParams.assumptionsId) {
                dispatch(
                    ceclapiForecastAssumptionsDetailRequest({
                        cuid: routeParams.routingNumber,
                        assumptionId: routeParams.assumptionsId,
                    })
                );
            }
        }
    }, [lastAction]);

    React.useEffect(() => {
        setIsLoading(
            cecl.forecastAssumptions?.detail?.loading == true ||
                cecl.forecastAssumptions?.configuration?.loading == true ||
                cecl.forecastAssumptions?.indexes?.loading == true
        );
    });

    React.useEffect(() => {
        var shouldReset: boolean = false;

        if (!cecl.forecastAssumptions?.configuration?.config) {
            dispatch(ceclApiForecastConfigurationRequest());
        } else {
            shouldReset = true;
        }

        if (!cecl.forecastAssumptions?.indexes?.indexList || cecl.user!.userType! == UserTypes.Admin) {
            dispatch(ceclapiFredIndexesRequest({ cuid: cecl.user!.userType! == UserTypes.Admin ? routeParams.routingNumber : cecl.user?.routingNumber }));
        } else {
            shouldReset = true;
        }

        if (shouldReset) {
            dispatch(ceclstateForecastAssumptionsReset());
        }

        if (cecl.forecastAssumptions?.configuration?.config && routeParams.assumptionsId) {
            dispatch(
                ceclapiForecastAssumptionsDetailRequest({
                    cuid: routeParams.routingNumber,
                    assumptionId: routeParams.assumptionsId,
                })
            );
        }
    }, []);

    React.useEffect(() => {
        if (!isLoadAssumption && cecl?.forecastAssumptions?.detail?.data?.name) {
            setCurrentAssumptionName(cecl?.forecastAssumptions?.detail?.data?.name);
        }
    }, [cecl?.forecastAssumptions?.detail?.data?.name]);

    const handleDialogOpen = () => {
        setDialogProps({ ...dialogProps, open: true });
    };

    const handleDialogClose = () => {
        setDialogProps({ ...dialogProps, open: false });
    };

    function buildAssumptions() {
        var myAssumptions = cecl!.myAssumptionSet!;
        myAssumptions.type = 'forecast';
        myAssumptions.status = 'Draft';
        myAssumptions.cu = cecl.user!.userType! == UserTypes.Admin ? routeParams.routingNumber : cecl.user?.routingNumber;
        myAssumptions.createdBy = cecl.user?.name;
        myAssumptions.modifiedBy = cecl.user?.name;
        myAssumptions.id = !!currentAssumptionId ? currentAssumptionId : 
            (!!routeParams.assumptionsId ? routeParams.assumptionsId : '');
        
        if(!!currentAssumptionName && currentAssumptionName !== myAssumptions.name){
            myAssumptions.id = '';
        }

        if (!!!myAssumptions.name) {
            myAssumptions.name = 'Forecast-' + myAssumptions.analysisDate! + '-' + myAssumptions.economicIndex!;
        }

        return myAssumptions;
    }

    function saveForecastAssumptions() {
        var lastSnackbarKey = config.snackbarKey;
        if (lastSnackbarKey) {
            closeSnackbar(lastSnackbarKey);
        }

        var myAssumptions = buildAssumptions();

        var request: ForecastAssumptionsSaveRequest = {
            assumptionsData: myAssumptions,
        };

        dispatch(ceclapiForecastAssumptionsSaveRequest(request));
        dispatch(
            displayToast({
                message: 'Saving assumptions.',
                variant: 'info',
            })
        );
    }

    function viewForecast() {
        var lastSnackbarKey = config.snackbarKey;
        if (lastSnackbarKey) {
            closeSnackbar(lastSnackbarKey);
        }

        var validationResult = validateAssumptions();

        if (validationResult.passes) {
             var myAssumptions = buildAssumptions();

            var request: ForecastAssumptionsSaveRequest = {
                assumptionsData: myAssumptions,
            };
            
            setGoToViewForecast(true);
            if(cecl.user!.userType! == UserTypes.Admin){
                cecl.forecastAssumptions.detail.data = request.assumptionsData;
                dispatch(ceclapiCalculateLossClear());
                setGoToViewForecast(false);
                history.push(`/forecast/view/${routeParams.routingNumber}/${cecl.forecastAssumptions?.detail?.data?.id}`);
            }
            else{
                dispatch(ceclapiForecastAssumptionsSaveRequest(request));
                dispatch(ceclapiCalculateLossClear());
                dispatch(
                    displayToast({
                        message: 'Saving assumptions.',
                        variant: 'info',
                    })
                );
            }
        } else {
            var myParamErrors = validationResult.parameterErrors;
            var myInputErrors = validationResult.inputErrors;
            var mySpecialLoanErrors = validationResult.specialLoanErrors;

            var compiledErrorList: string[] = [
                ...myParamErrors.map((p) => p.errorText),
                ...myInputErrors.map((i) => i.errorText),
                ...mySpecialLoanErrors.map((s) => s.errorText),
            ];
            var uniqueErrorList = new Set(compiledErrorList);

            if (uniqueErrorList.size == 0) {
                return;
            }

            var errorString: string = 'Please correct the following errors:\n';

            uniqueErrorList.forEach((error) => {
                errorString += errorMap[error];
            });

            dispatch(
                displayToast({
                    message: errorString,
                    variant: 'error',
                    persist: true,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                })
            );
        }
    }

    function validateAssumptions(): ValidationResult {
        var parameterErrors: ValidationError[] = [];
        var inputErrors: ValidationError[] = [];
        var specialLoanErrors: ValidationError[] = [];

        if (!!!cecl!.myAssumptionSet!.economicIndex) {
            parameterErrors.push({ fieldName: 'economicIndex', errorText: 'Required' });
        }

        var myParameters = cecl!.myAssumptionSet!.parameters;
        var myInputs = cecl!.myAssumptionSet!.inputs;
        var mySpecialLoans = cecl!.myAssumptionSet!.specialClassificationLoans;

        var myDataLag = cecl!.myAssumptionSet!.dataLag;

        validateParameters(myParameters, myInputs, mySpecialLoans, myDataLag, parameterErrors);
        validateInputs(myInputs, myParameters, mySpecialLoans, inputErrors);
        validateSpecialLoans(mySpecialLoans, myInputs, specialLoanErrors);

        setParametersWithErrors(parameterErrors);
        setInputsWithErrors(inputErrors);
        setSpecialLoansWithErrors(specialLoanErrors);

        var passes: boolean = !(parameterErrors.length > 0 || inputErrors.length > 0 || specialLoanErrors.length > 0);
        return {
            passes: passes,
            parameterErrors: parameterErrors,
            inputErrors: inputErrors,
            specialLoanErrors: specialLoanErrors,
        };
    }

    function validateParameters(
        myParameters: ForecastParameter[],
        myInputs: ForecastInput[],
        mySpecialLoans: ForecastSpecialClassificationLoan[],
        dataLag: number,
        parameterErrors: ValidationError[]
    ) {
        var parameterNames = myParameters.map((param) => param.name);
        var inputNames = myInputs.map((input) => input.name);
        var specialLoanNames = mySpecialLoans.map((loan) => loan.name);

        myParameters.forEach((param) => {
            if (
                !param.historicalLookbackPeriod &&
                (param.prepaymentSpeedOverride || param.loanQualityFactorAdjustment)
            ) {
                if (!parameterErrors.map((paramError) => paramError.fieldName).includes(param.name)) {
                    parameterErrors.push({ fieldName: param.name, errorText: 'Required' });
                }
            }

            if (dataLag + param.historicalLookbackPeriod * 12 > parseInt(process.env.MAXIMUM_RETRIEVAL_MONTHS)) {
                parameterErrors.push({ fieldName: param.name, errorText: 'ErrorType1' });
            }
            else if (param.historicalLookbackPeriod < parseInt(process.env.MINIMUM_RETRIEVAL_YEARS)) {
                parameterErrors.push({ fieldName: param.name, errorText: 'ErrorType2' });
            }
        });

        inputNames.forEach((inputName) => {
            if (!parameterNames.includes(inputName)) {
                if (!parameterErrors.map((paramError) => paramError.fieldName).includes(inputName)) {
                    parameterErrors.push({ fieldName: inputName, errorText: 'Required' });
                }
            }
        });

        specialLoanNames.forEach((loanName) => {
            if (!parameterNames.includes(loanName)) {
                if (!parameterErrors.map((paramError) => paramError.fieldName).includes(loanName)) {
                    parameterErrors.push({ fieldName: loanName, errorText: 'Required' });
                }
            }
        });
    }

    function validateInputs(
        myInputs: ForecastInput[],
        myParameters: ForecastParameter[],
        mySpecialLoans: ForecastSpecialClassificationLoan[],
        inputErrors: ValidationError[]
    ) {
        var inputNames = myInputs.map((input) => input.name);
        var specialLoanNames = mySpecialLoans.map((loan) => loan.name);

        myInputs.forEach((input) => {
            var hasZeroForRequiredFieldValue: boolean =
                !!!input.endingBalance ||
                !!!input.numberOfLoans ||               
                !!!input.weightedAverageRemainingMaturity;
            if (hasZeroForRequiredFieldValue) {
                inputErrors.push({ fieldName: input.name, errorText: 'Required' });
            }

            if (input.averageLoanRate < 0) {
                inputErrors.push({ fieldName: input.name, errorText: 'ErrorType3' });
            }

            if (input.dollarAmountOfInterestOnlyLoans > input.endingBalance) {
                inputErrors.push({ fieldName: input.name, errorText: 'ErrorType4' });
            }

            if (input.endingBalance <= 0) {
                inputErrors.push({ fieldName: input.name, errorText: 'ErrorType5' });
            }

            if (input.endingBalance > 0 && input.weightedAverageRemainingMaturity <= 0) {
                inputErrors.push({ fieldName: input.name, errorText: 'ErrorType6' });
            }

            var correspondingSpecialLoan = mySpecialLoans.find((loan) => loan.name == input.name);

            if (correspondingSpecialLoan != undefined) {
                if (
                    input.endingBalance -
                        input.dollarAmountOfInterestOnlyLoans -
                        correspondingSpecialLoan.specificLoanLossEndingBalance <
                    0
                ) {
                    inputErrors.push({ fieldName: input.name, errorText: 'ErrorType7' });
                }
            }
        });

        specialLoanNames.forEach((loanName) => {
            if (!inputNames.includes(loanName)) {
                if (!inputErrors.map((inputError) => inputError.fieldName).includes(loanName)) {
                    inputErrors.push({ fieldName: loanName, errorText: 'Required' });
                }
            }
        });
    }

    function validateSpecialLoans(
        mySpecialLoans: ForecastSpecialClassificationLoan[],
        myInputs: ForecastInput[],
        specialLoanErrors: ValidationError[]
    ) {
        mySpecialLoans.forEach((loan) => {
            if (loan.allocation > loan.specificLoanLossEndingBalance) {
                specialLoanErrors.push({ fieldName: loan.name, errorText: 'ErrorType8' });
            }

            var correspondingInput = myInputs.find((input) => input.name == loan.name);

            if (correspondingInput != undefined) {
                if (
                    correspondingInput.endingBalance -
                        correspondingInput.dollarAmountOfInterestOnlyLoans -
                        loan.specificLoanLossEndingBalance <
                    0
                ) {
                    specialLoanErrors.push({ fieldName: loan.name, errorText: 'ErrorType7' });
                }
            }
        });
    }

    function handleFileUploadResponse(response: AxiosResponse) {
        console.log(response);
        setCsvResponse(response);
    }

    function handleFileUploadError(err: any){
        console.log("handleFileUploadError")
        console.log(err);
        setFileUploadError(err);
    }

    function handleFileUploadOnClick(evt){
        setFileUploadError(null);
    }

    function listFileUploadErrors(err: string) {
        return (
            <div>
                {
                    err.split("\u2022")
                    .map(function(item, i){
                        return(<p>{item}</p>)
                    })
                }
            </div>
        )
    }

    return (
        <div>
            <Grid container direction="column" spacing={4}>
                <Grid item>
                    <div style={{ position: 'relative' }}>
                        <LoadingSpinner loading={isLoading} />
                        <ForecastParameters parametersWithErrors={parametersWithErrors} onLoadAssumption = {setIsLoadAssumption}/>
                    </div>
                </Grid>
                <Grid item>
                    <FileUploadButton
                        buttonClass={classes.uploadDownloadButton}
                        uploadingLabel="Uploading template..."
                        label='Upload CSV Template'
                        url={`forecast/forecastTemplate`}
                        acceptFileType=".csv"
                        handleOnClick={handleFileUploadOnClick}
                        handleError={handleFileUploadError}
                        handleResponse={handleFileUploadResponse}
                    />
                    <FileDownloadButton
                        buttonClass={classes.uploadDownloadButton}
                        downloadingLabel="Document is being prepared for download."
                        label='Download CSV Template'
                        url={`forecast/getForecastTemplate`}
                    />
                </Grid>
                {fileUploadError && (
                    <Grid item><div className={classes.errorDiv} style={{ position: 'relative' }}>{listFileUploadErrors(fileUploadError.toString())}</div></Grid>
                )}
                <Grid item>
                    <ForecastMonthlyInputs inputsWithErrors={inputsWithErrors} inputsFromCsv={csvResponse?.data?.inputs}/>
                </Grid>
                <Grid item>
                    <ForecastSpecialClassificationLoans specialLoansWithErrors={specialLoansWithErrors} specialClassificationLoansFromCsv={csvResponse?.data?.specialClassificationLoans} />
                </Grid>
                <Grid item>
                    <Grid item container direction="row" spacing={4} justify="center">
                        <Grid item>
                            <Button
                                className={
                                    cecl!.myAssumptionSet.status !== 'Archived' ? classes.saveButton : classes.hide
                                }
                                onClick={saveForecastAssumptions}
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={viewForecast}>View Forecast</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid item container direction="row" spacing={4} justify="center">
                        <Button className={classes.cancelButton} onClick={() => history.goBack()} variant="text">
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                className={classes.dialogBox}
                id="assumptions-save-dialog"
                onClose={handleDialogClose}
                open={dialogProps.open}
                maxWidth="md"
                aria-labelledby="save-assumptions-dialog-title"
            >
                <DialogTitle id="save-assumptions-dialog-title">
                    <Typography className={classes.dialogTitle}>Your Forecast Assumptions Have Been Saved</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Saving will only save the assumptions you filled in. You can come back at a later time and view
                        the forecast for your assumptions whenever you submit. Click the button below to dismiss this
                        message and go back to the assumption input screen.
                    </DialogContentText>
                </DialogContent>
                <Button className={classes.dialogDismiss} onClick={() => handleDialogClose()}>
                    Dismiss
                </Button>
            </Dialog>
        </div>
    );
};

export default BuildForecastAssumptions;
