import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { CeclApiState } from '../../../../store/ceclapi/types';
import { formatDateToMMddyyyy } from '../../../../utils/helpers';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
        },
        cell: {
            height: 100,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginBottom: theme.spacing(5),
        },
    })
);

const PdfTopPanel: React.FC = () => {
    const classes = useStyles();
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);

    const getEconomicIndexLabel = (index: string) => {
        let indexLabel = cecl.forecastAssumptions?.indexes?.indexList.find((i) => i.fredSeriesId === index)
            ?.fredLabel;
        return indexLabel;
    };

    React.useEffect(() => {}, []);

    return (
        <div id="pdfTopPanel">
            <Grid container>
                <Grid item xs={3} className={classes.cell}>
                    <img src={require('../../../../assets/images/cecl_logo_pdf.png')}></img>
                </Grid>
                <Grid item xs={6} className={classes.cell}>
                    <Typography className={classes.title}>Forecast Name:</Typography>
                    {cecl.forecastAssumptions?.detail?.data?.name}
                </Grid>
                <Grid item xs={3} className={classes.cell}>
                    <Typography className={classes.title}>Run Date:</Typography>
                    {formatDateToMMddyyyy(cecl.forecastAssumptions?.detail?.data?.dateModified)}
                </Grid>

                <Grid item xs={3}>
                    <Typography className={classes.title}>Analysis Date:</Typography>
                    {formatDateToMMddyyyy(cecl.forecastAssumptions?.detail?.data?.analysisDate)}
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.title}>NCUA Call Report Data Date:</Typography>
                    {formatDateToMMddyyyy(cecl.calculatedLoss?.quarterAdjustedAnalysisDate)}
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.title}>Economic Index:</Typography>
                    {getEconomicIndexLabel(cecl.forecastAssumptions?.detail?.data?.economicIndex)}
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.title}>Data Lag (Months):</Typography>
                    {cecl.forecastAssumptions?.detail?.data?.dataLag}
                </Grid>
            </Grid>
        </div>
    );
};

export default PdfTopPanel;
