import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import ClassificationLoansTable from '../../../../containers/tables/ClassificationLoansTable';
import { colors } from '../../../../theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paddingLess: {
            padding: 0,
        },
        title: {
            width: '100%',
            textAlign: 'left',
            fontSize: 35,
            paddingLeft: theme.spacing(2),
        },
        dialogDivider: {
            color: colors.black,
        },
        grid: {
            marginTop: theme.spacing(10),
        },
        height: {
            height: theme.spacing(25),
        },
        top: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
        },
    })
);

const ClassificationLoans: React.FC = () => {
    const classes = useStyles();

    React.useEffect(() => {}, []);

    return (
        <div id="classificationLoans" className={classes.top}>
            <Typography className={classes.title}>Workout / TDR / Special Classification Loans</Typography>
            <ClassificationLoansTable />
        </div>
    );
};

export default ClassificationLoans;
