import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import EconomicQualitativeFactorAdjustment from '../../../../containers/economicqualitativefactoradjustment/EconomicQualitativeFactorAdjustment';
import ExpectedCreditLossesTable from '../../../../containers/tables/ExpectedCreditLossesTable';
import { ApplicationState } from '../../../../store';
import { CeclApiState } from '../../../../store/ceclapi/types';
import { colors } from '../../../../theme';
import { formatDateToMMddyyyy } from '../../../../utils/helpers';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paddingLess: {
            padding: 0,
        },
        title: {
            width: '100%',
            textAlign: 'left',
            fontSize: 35,
            paddingLeft: theme.spacing(2),
        },
        subTitle: {
            width: '100%',
            textAlign: 'left',
            fontSize: 22,
            paddingLeft: theme.spacing(2),
            marginBottom: theme.spacing(3),
            fontWeight: 'bold',
        },
        dialogDivider: {
            color: colors.black,
        },
        bottom: {
            marginBottom: theme.spacing(5),
        },
    })
);

interface ExpectedCreditLossesProps {
    noScroll?: boolean;
}

const ExpectedCreditLosses: React.FC<ExpectedCreditLossesProps> = (props) => {
    const classes = useStyles();
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const ncuaReportDate = new Date(cecl.calculatedLoss.quarterAdjustedAnalysisDate);
    const currentDate = new Date();
    const analysisDate = new Date(cecl.calculatedLoss.analysisDate);
    const economicQualitativeAdjustmentFactor = cecl.calculatedLoss.economicQualitativeAdjustmentFactor ? cecl.calculatedLoss.economicQualitativeAdjustmentFactor * 100 : 0;

    React.useEffect(() => {}, []);

    return (
        <div id="expectedCreditLosses" className={classes.bottom}>
            <Typography className={classes.title}>Expected Credit Losses</Typography>
            <Grid container>
                <Grid item xs={4}><Typography className={classes.subTitle}>NCUA Call Report Data Date: {formatDateToMMddyyyy(ncuaReportDate)}</Typography></Grid>
                <Grid item xs={4}><Typography className={classes.subTitle}>Analysis Date: {formatDateToMMddyyyy(analysisDate)}</Typography></Grid>
                <Grid item xs={4}><Typography className={classes.subTitle}>Run Date: {formatDateToMMddyyyy(currentDate)}</Typography></Grid>
            </Grid>
            <ExpectedCreditLossesTable noScroll={props.noScroll} />
            <EconomicQualitativeFactorAdjustment
                value={economicQualitativeAdjustmentFactor}
            />
        </div>
    );
};

export default ExpectedCreditLosses;
