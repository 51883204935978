import { DialogTitle, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import Panel from '../../../../components/panel/Panel';
import HistoricalForecastedLosses from '../../../../containers/charts/viewforecast/HistoricalForecastedLosses';
import PastForecastedNetChargeOffs from '../../../../containers/charts/viewforecast/PastForecastedNetChargeOffs';
import { colors } from '../../../../theme';
import ChartParameters from './ChartParameters';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paddingLess: {
            padding: 0,
        },
        title: {
            width: '100%',
            textAlign: 'left',
            fontSize: 35,
            paddingLeft: theme.spacing(2),
        },
        dialogDivider: {
            color: colors.black,
        },
        dialogTitle: {
            width: '100%',
            fontSize: 35,
        },
        cellTitle: {
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
        },
        pdfCell: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
        },
        timeHorizonText: {
            color: colors.red,
            fontStyle: 'italic',
            fontSize: 14,
            paddingLeft: theme.spacing(10),
            paddingRight: theme.spacing(10),
            paddingBottom: theme.spacing(5),
        },
        pdfchart: {
            marginTop: '30px',
            marginBottom: '30px',
            width: '1900px',
        }
    })
);

export interface ChargeOffsChartProps {
    forecastTimeHorizon?: number;
    historicalTimeHorizon?: number;
    onChange?: ({ forecastTimeHorizon: number, historicalTimeHorizon: string }) => void;
    pdfMode?: boolean;
}

const ChargeOffsChart: React.FC<ChargeOffsChartProps> = (props) => {
    const classes = useStyles();
    const [state, setState] = React.useState<ChargeOffsChartProps>(props);

    React.useEffect(() => {
        setState(props);
    }, [props]);

    const handleParameterChange = ({ forecastTimeHorizon, historicalTimeHorizon }) => {
        setState((prev) => ({ ...prev, forecastTimeHorizon, historicalTimeHorizon }));

        if (state.onChange) {
            state.onChange({ forecastTimeHorizon, historicalTimeHorizon });
        }
    };

    return (
        <Panel>
            <Grid container direction="column" spacing={2} id="chargeOffsChart">
                <Grid item>
                    <DialogTitle id="dialog-title">
                        <Typography className={classes.dialogTitle}>
                            Historical Charge-Offs Vs. Forecasted Charge-Offs
                        </Typography>
                    </DialogTitle>
                    <Divider variant="middle" className={classes.dialogDivider} />
                </Grid>
                {state.pdfMode && (
                    <Grid item container className={classes.pdfCell}>
                        <Grid item xs={6}>
                            <Typography className={classes.cellTitle}>Forecast Time Horizon (Years):</Typography>
                            {state.forecastTimeHorizon}
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.cellTitle}>Historical Time Horizon (Years):</Typography>
                            {state.historicalTimeHorizon}
                        </Grid>
                    </Grid>
                )}
                {!state.pdfMode && (
                    <>
                        <Grid item>
                            <ChartParameters
                                forecastTimeHorizon={state.forecastTimeHorizon}
                                historicalTimeHorizon={state.historicalTimeHorizon}
                                onChange={handleParameterChange}
                            />
                        </Grid>

                        <Grid item>
                            <Typography className={classes.timeHorizonText}>
                                Whatever values you select for the “Forecast Time Horizon” and the ‘Historical Time
                                Horizon” will be permanently applied to the resulting forecast after you click “Save to
                                Forecast History”. These cannot be changed after saving the forecast to your history.
                            </Typography>
                        </Grid>
                    </>
                )}
                <Grid item className={state.pdfMode ? classes.pdfchart : null}>
                    <HistoricalForecastedLosses
                        forecastTimeHorizon={state.forecastTimeHorizon}
                        historicalTimeHorizon={state.historicalTimeHorizon}
                    />
                </Grid>
                <Grid item className={state.pdfMode ? classes.pdfchart : null}>
                    <PastForecastedNetChargeOffs
                        forecastTimeHorizon={state.forecastTimeHorizon}
                        historicalTimeHorizon={state.historicalTimeHorizon}
                    />
                </Grid>
            </Grid>
        </Panel>
    );
};

export default ChargeOffsChart;
