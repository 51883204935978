import React, { ChangeEvent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { InputAdornment, Table as ReactTable, TextField } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import { colors } from '../../theme';
import TableHeader, { FilteredColumn } from './TableHeader';
import Input from '../input/Input';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MaskedInput from '../input/masked-input';

type Order = 'asc' | 'desc';

export interface Column {
    name: string;
    label: string;
    secondaryLabel?: string;
    numeric: boolean;
    numericPrecision?: number;
    maxWidth?: number;
    component?: React.ReactNode;
    onCellClick?: (event: any) => void;
    selectedClass?: string;
    sortable?: boolean;
    filterable?: boolean;
    isDate?: boolean;
    isSelect?: boolean;
    editable?: boolean;
    required?: boolean;
    decorator?: string;
    decoratorPlacement?: string;
    multiplyFactor?: number;
    alignRight?: boolean;
    bodyColor?: string;
    headerColor?: string;
    centerHeader?: boolean;
    centerBody?: boolean;
    headerFontSize?: number;
    bodyFontSize?: string;
    bodyFontWeight?: string;
    secondHeader?: string;
    secondHeaderColor?: string;
    secondHeaderHasBorder?: boolean;
    secondHeaderHide?: boolean;
    secondHeaderColSpan?: number;
    secondHeaderFontSize?: number;
    numberMask?: boolean;
}

export const tableStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            '& > *': {
                borderBottom: 'unset',
            },
        },
        table: {},
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        header: {
            fontWeight: 'bold',
            fontSize: 18,
            '&:hover': {
                '& button': {
                    '& svg': {
                        display: 'inline-block',
                    },
                },
                '& .MuiTableSortLabel-icon': {
                    opacity: 1,
                },
            },
        },
        cell: {
            fontWeight: 'bold',
            fontSize: 18,
        },
        cellBold: {
            fontWeight: 'bold',
            fontSize: 14,
        },
        filterListIcon: {
            width: 30,
            '& svg': {
                fontSize: 14,
                display: 'none',
            },
            padding: 5,
            '&:hover': {
                '& svg': {
                    display: 'inline-block',
                },
                cursor: 'pointer',
                color: colors.black,
                backgroundColor: 'transparent',
            },
        },
        showFilter: {
            '& svg': {
                display: 'inline-block',
                color: colors.blue,
                backgroundColor: 'transparent',
            },
        },
    })
);

export interface TableProps {
    id?: string;
    columns: Column[];
    rows: any[];
    keyColumn: string;
    classes?: ReturnType<typeof tableStyles>;
    numSelected?: number;
    onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;
    onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onEdit?: (event: ChangeEvent<HTMLInputElement>, rowIndex: number, colName: string) => void;
    order?: Order;
    orderBy?: string;
    rowCount?: number;
    selectable?: boolean;
    sortable?: boolean;
    filterable?: boolean;
    defaultFilterValue?: FilteredColumn[];
    pageSize?: number;
    dense?: boolean;
    loading?: boolean;
    hasBorder?: boolean;
    hasSecondHeaderRow?: boolean;
    rowValidation?: boolean;
    collapsibleColumns?: Column[];
}

const initialProps: TableProps = {
    columns: [],
    rows: [],
    keyColumn: '',
    selectable: false,
    sortable: false,
    pageSize: 0,
    dense: false,
    loading: false,
    hasBorder: false,
    hasSecondHeaderRow: false,
    rowValidation: false,
    collapsibleColumns: [],
};

const Table: React.FC<TableProps> = (props) => {
    const [state, setState] = React.useState<TableProps>({ ...initialProps, ...props });
    const classes = tableStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<string>('calories');
    const [selected, setSelected] = React.useState<string[]>([]);
    const [expanded, setExpanded] = React.useState<string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.pageSize || props.rows.length);
    const [filteredColumns, setFilteredColumns] = React.useState<FilteredColumn[]>([]);

    const collapsible = state.collapsibleColumns && state.collapsibleColumns.length > 0;

    React.useEffect(() => {
        if (props.rows.length > 0 && props.rows[0][props.keyColumn] === undefined) {
            console.error(`Provided keyColumn '${props.keyColumn}' doesn't exist in rows.`);
        }

        setState((prevState) => ({ ...prevState, ...props }));
        setRowsPerPage(props.pageSize || props.rows.length);
        if (props.pageSize) {
            if (props.rows.length < props.pageSize! * page) {
                setPage(0);
            }
        }
    }, [props]);

    const rowFilter = (r: any) => {
        let filter = true;

        if (filteredColumns.length) {
            filteredColumns.map((c) => {
                if (r[c.name]) {
                    if (c.isSelect && c.isSelect === true) {
                        //path to select component (drop down) value
                        filter =
                            filter &&
                            (r[c.name] as any)['props']['children']['props']['value']
                                .toString()
                                .toLowerCase()
                                .includes(c.text.toLowerCase());
                    } else {

                        if (r[c.name]['props'] === undefined){
                            filter = r[c.name].toString().toLowerCase().includes(c.text.toLowerCase())
                        }else{
                            filter = filter && r[c.name]['props']['children'].toString().toLowerCase().includes(c.text.toLowerCase());
                        }                        
                    }
                } else {
                    filter = false;
                }
            });
        }

        return filter;
    };

    const descendingComparator = <T extends unknown>(a: T, b: T, orderBy: keyof T) => {
        var isDate = false;
        var isSelect = false;
        state.columns.forEach((column) => {
            if (column.name == orderBy) {
                if (column.isDate) {
                    isDate = column.isDate;
                } else if (column.isSelect) {
                    isSelect = column.isSelect;
                }
            }
        });

        if (isDate) {
            if ((b[orderBy] as unknown) as string == '' || ( Date.parse((b[orderBy] as unknown) as string) < Date.parse((a[orderBy] as unknown) as string))) {
                return -1;
            }
            if ((b[orderBy] as unknown) as string !== '' || Date.parse((b[orderBy] as unknown) as string) > Date.parse((a[orderBy] as unknown) as string)) {
                return 1;
            }
        } else if (typeof a[orderBy] === 'object' && typeof b[orderBy] === 'object') {
            var aValue = {};
            var bValue = {};

            if (isSelect){
                aValue = (a[orderBy] as any)['props']['children']['props']['value'];
                bValue = (b[orderBy] as any)['props']['children']['props']['value']
            }else{
                if (a[orderBy]['props']['children']['props'] !== undefined){
                    aValue = (a[orderBy] as any)['props']['children']['props']['children'].toString().toLowerCase();
                }else{
                    aValue = (a[orderBy] as any)['props']['children'].toString().toLowerCase();
                }

                if (b[orderBy]['props']['children']['props'] !== undefined){
                    bValue = (b[orderBy] as any)['props']['children']['props']['children'].toString().toLowerCase();
                }else{
                    bValue = (b[orderBy] as any)['props']['children'].toString().toLowerCase();
                }
            }


            if (bValue < aValue) {
                return -1;
            }
            if (bValue > aValue) {
                return 1;
            }
        } else {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
        }

        return 0;
    };

    const getComparator = <Key extends keyof any>(
        order: Order,
        orderBy: Key
    ): ((a: { [key in Key]: number | string | Date }, b: { [key in Key]: number | string | Date }) => number) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const stableSort = <T extends unknown>(array: T[], comparator: (a: T, b: T) => number) => {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = state.rows.filter(rowFilter).map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleExpand = (event: React.MouseEvent<unknown>, id: string) => {
        const expandedIndex = expanded.indexOf(id);
        let newExpanded: string[] = [];

        if (expandedIndex === -1) {
            newExpanded = newExpanded.concat(expanded, id);
        } else if (expandedIndex === 0) {
            newExpanded = newExpanded.concat(expanded.slice(1));
        } else if (expandedIndex === expanded.length - 1) {
            newExpanded = newExpanded.concat(expanded.slice(0, -1));
        } else if (expandedIndex > 0) {
            newExpanded = newExpanded.concat(expanded.slice(0, expandedIndex), expanded.slice(expandedIndex + 1));
        }

        setExpanded(newExpanded);
    };

    const handleFilterColumns = (filteredColumns: FilteredColumn[]) => {
        setFilteredColumns(filteredColumns);
    };

    const handleFilterColumnsNoAction = (filteredColumns: FilteredColumn[]) => {
        //do nothing. placeholder onFilterColumnsChange action for collapsible TableHeader.
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleCellValueChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        rowIndex: number,
        colIndex: number,
        colName: string
    ) => {
        props.onEdit!(event, rowIndex, colName);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;
    const isExpanded = (id: string): boolean => expanded.indexOf(id) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.rows.filter(rowFilter).length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <LoadingSpinner loading={props.loading} />
            <TableContainer component={collapsible ? Paper : undefined}>
                <ReactTable
                    id={props.id}
                    className={classes.table}
                    aria-labelledby="tableHeader"
                    size={state.dense ? 'small' : 'medium'}
                    aria-label={collapsible ? 'collapsible table' : 'table header'}
                >
                    <TableHeader
                        classes={classes}
                        columns={state.columns}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        onFilterColumnsChange={handleFilterColumns}
                        rowCount={state.rows.filter(rowFilter).length}
                        selectable={!!state.selectable}
                        hasBorder={state.hasBorder!}
                        hasSecondHeaderRow={state.hasSecondHeaderRow!}
                        collapsible={collapsible}
                        defaultFilterValue={state.defaultFilterValue}
                    />
                    <TableBody>
                        {stableSort(state.rows.filter(rowFilter), getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, rowIndex) => {
                                const isItemSelected = isSelected(row[state.keyColumn].toString());
                                const isItemExpanded = isExpanded(row[state.keyColumn].toString());
                                const labelId = `enhanced-table-checkbox-${rowIndex}`;

                                const numberColumn = (num: number, column: Column) => {
                                    const placement = column.decoratorPlacement ? column.decoratorPlacement : 'start';
                                    const decorator = column.decorator ? column.decorator : '';

                                    if (column.multiplyFactor && decorator === '%'){
                                        num = num * column.multiplyFactor;
                                    }

                                    let formattedNumber = Number(num).toLocaleString(navigator.language, {
                                        minimumFractionDigits: !!column.numericPrecision
                                            ? column.numericPrecision!
                                            : column.numericPrecision === 0
                                            ? 0
                                            : 2,
                                    });

                                    if (placement === 'start') {
                                        formattedNumber = `${decorator}${formattedNumber}`;
                                    }

                                    if (placement === 'end') {
                                        formattedNumber = `${formattedNumber}${decorator}`;
                                    }

                                    return formattedNumber;
                                };

                                return (
                                    <React.Fragment key={rowIndex}>
                                        <TableRow
                                            hover={state.selectable}                                          
                                            role={state.selectable ? 'checkbox' : ''}
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            selected={isItemSelected}
                                        >
                                            {collapsible && (
                                                <TableCell key={rowIndex + ' collapsible'}>
                                                    <IconButton aria-label="expand row" size="small" onClick={(event) => handleExpand(event, row[state.keyColumn].toString())}>
                                                        {isItemExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                    </IconButton>
                                                </TableCell>
                                            )}
                                            {state.selectable && (
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </TableCell>
                                            )}
                                            {state.columns &&
                                                state.columns.map((column, colIndex) => (
                                                    <TableCell
                                                        key={rowIndex + ' ' + colIndex}
                                                        align={
                                                            column.centerBody
                                                                ? 'center'
                                                                : column.alignRight
                                                                ? 'right'
                                                                : column.numeric
                                                                ? 'right'
                                                                : 'left'
                                                        }
                                                        width={column.maxWidth}
                                                        className={clsx(
                                                            classes.cell,
                                                            row.bold ? classes.cellBold : undefined,
                                                            row['error'] ? 'invalid-cell' : undefined
                                                        )}
                                                        style={{
                                                            backgroundColor: column.bodyColor
                                                                ? column.bodyColor
                                                                : 'transparent',
                                                            borderRight: props.hasBorder ? '1px solid #e0e0e0' : 'none',
                                                            fontSize: column.bodyFontSize
                                                                ? column.bodyFontSize
                                                                : 'inherit',
                                                        }}
                                                    >



                                                        {column.editable && !column.numberMask ?  (
                                                            <Input
                                                                className="table-text-field"
                                                                key={rowIndex + ' ' + colIndex}
                                                                variant="standard"
                                                                valid={
                                                                    !((row['error'] as unknown) as boolean) ||
                                                                    !column.required
                                                                }
                                                                errorText={column.required 
                                                                            ? !!row[column.name] 
                                                                                ? undefined 
                                                                                : 'Required' 
                                                                            : undefined}
                                                                type={column.numeric ? 'number' : 'text'}
                                                                value={
                                                                    column.numeric
                                                                        ? (Number(row[column.name]) === 0 ? '' : Number(row[column.name]))
                                                                        : row[column.name]
                                                                }
                                                                InputProps={{
                                                                    startAdornment:
                                                                        column.decoratorPlacement === 'start' &&
                                                                        column.decorator ? (
                                                                            <InputAdornment position="start">
                                                                                {column.decorator}
                                                                            </InputAdornment>
                                                                        ) : (
                                                                            ''
                                                                        ),
                                                                    endAdornment:
                                                                        column.decoratorPlacement === 'end' &&
                                                                        column.decorator ? (
                                                                            <InputAdornment position="end">
                                                                                {column.decorator}
                                                                            </InputAdornment>
                                                                        ) : (
                                                                            ''
                                                                        ),
                                                                    disableUnderline: true,
                                                                }}
                                                                inputProps={{
                                                                    style: {
                                                                        textAlign: column.centerBody
                                                                            ? 'center'
                                                                            : column.alignRight
                                                                            ? 'right'
                                                                            : column.numeric
                                                                            ? 'right'
                                                                            : 'left',
                                                                    },
                                                                    fontSize: column.bodyFontSize
                                                                        ? column.bodyFontSize
                                                                        : 'inherit',
                                                                }}
                                                                onChange={(e) =>
                                                                    handleCellValueChange(
                                                                        e as ChangeEvent<HTMLInputElement>,
                                                                        rowIndex,
                                                                        colIndex,
                                                                        column.name
                                                                    )
                                                                }
                                                            />
                                                        ): column.numberMask ? (
                                                            <MaskedInput
                                                            key={rowIndex + ' ' + colIndex}
                                                            myKeyName={rowIndex + ' ' + colIndex}
                                                            className="table-text-field"
                                                            variant="standard"
                                                            valid={
                                                                !((row['error'] as unknown) as boolean) ||
                                                                !column.required
                                                            }
                                                            errorText="Required"
                                                            type={column.numeric ? 'number' : 'text'}
                                                            value={
                                                                column.numeric
                                                                    ? Number(row[column.name])
                                                                    : row[column.name]
                                                            }
                                                            InputProps={{
                                                                startAdornment:
                                                                    column.decoratorPlacement === 'start' &&
                                                                    column.decorator ? (
                                                                        <InputAdornment position="start">
                                                                            {column.decorator}
                                                                        </InputAdornment>
                                                                    ) : (
                                                                        ''
                                                                    ),
                                                                endAdornment:
                                                                    column.decoratorPlacement === 'end' &&
                                                                    column.decorator ? (
                                                                        <InputAdornment position="end">
                                                                            {column.decorator}
                                                                        </InputAdornment>
                                                                    ) : (
                                                                        ''
                                                                    ),
                                                                disableUnderline: true,
                                                            }}
                                                           
                                                            inputProps={{
                                                                style: {
                                                                    textAlign: column.centerBody
                                                                        ? 'center'
                                                                        : column.alignRight
                                                                        ? 'right'
                                                                        : column.numeric
                                                                        ? 'right'
                                                                        : 'left',
                                                                },
                                                                fontSize: column.bodyFontSize
                                                                    ? column.bodyFontSize
                                                                    : 'inherit',
                                                            }}
                                                            onChange={(e) =>
                                                                handleCellValueChange(
                                                                    e as ChangeEvent<HTMLInputElement>,
                                                                    rowIndex,
                                                                    colIndex,
                                                                    column.name
                                                                )
                                                            }
                                                        />
                                                        ) : row[column.name] == null ? (
                                                            ''
                                                        ) : column.numeric ? (
                                                            numberColumn(row[column.name], column)
                                                        ) : (
                                                            row[column.name]
                                                        )}
                                                    </TableCell>
                                                ))}
                                        </TableRow>
                                        {collapsible && ( //collapsible
                                            <TableRow>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                                                    <Collapse in={isItemExpanded} timeout="auto" unmountOnExit>
                                                        <Box margin={1}>
                                                            <ReactTable size="small" aria-label="purchases">
                                                                <TableHeader
                                                                    classes={classes}
                                                                    columns={state.collapsibleColumns}
                                                                    numSelected={selected.length}
                                                                    order={order}
                                                                    orderBy={orderBy}
                                                                    onSelectAllClick={handleSelectAllClick}
                                                                    onRequestSort={handleRequestSort}
                                                                    onFilterColumnsChange={handleFilterColumnsNoAction}
                                                                    rowCount={1}
                                                                    selectable={!!state.selectable}
                                                                    hasBorder={state.hasBorder!}
                                                                    hasSecondHeaderRow={state.hasSecondHeaderRow!}
                                                                    collapsible={false}
                                                                />
                                                                <TableBody>
                                                                    <TableRow
                                                                        hover={false}
                                                                        onClick={null}
                                                                        role={''}
                                                                        aria-checked={false}
                                                                        tabIndex={-1}
                                                                        selected={false}
                                                                    >
                                                                        {state.collapsibleColumns &&
                                                                            state.collapsibleColumns.map((column, colIndex) => (
                                                                                <TableCell
                                                                                    key={rowIndex + ' ' + colIndex + ' collapsible'}
                                                                                    align={
                                                                                        column.centerBody
                                                                                            ? 'center'
                                                                                            : column.alignRight
                                                                                            ? 'right'
                                                                                            : column.numeric
                                                                                            ? 'right'
                                                                                            : 'left'
                                                                                    }
                                                                                    className={clsx(
                                                                                        classes.cell,
                                                                                        row.bold ? classes.cellBold : undefined
                                                                                    )}
                                                                                    style={{
                                                                                        backgroundColor: column.bodyColor
                                                                                            ? column.bodyColor
                                                                                            : 'transparent',
                                                                                        borderRight: props.hasBorder ? '1px solid #e0e0e0' : 'none',
                                                                                        fontSize: column.bodyFontSize ? column.bodyFontSize : 'inherit',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        column.editable 
                                
                                                                                            ?   <Input
                                                                                                    className="table-text-field"
                                                                                                    variant="standard"
                                                                                                    valid={!(row["error"] as unknown as boolean) || !column.required}
                                                                                                    errorText="Required"
                                                                                                    type={
                                                                                                        column.numeric
                                                                                                            ? "number"
                                                                                                            : "text"
                                                                                                    }
                                                                                                    value={
                                                                                                        column.numeric
                                                                                                            ? Number(row[column.name]).toFixed(!!column.numericPrecision 
                                                                                                                                                ? column.numericPrecision! 
                                                                                                                                                : column.numericPrecision === 0 
                                                                                                                                                    ? 0 
                                                                                                                                                    : 2)
                                                                                                            : row[column.name]
                                                                                                    }
                                                                                                    InputProps={{                                                                                                                       //         startAdornment:(
                                                                                                        startAdornment:(
                                                                                                            (column.decoratorPlacement === "start" && column.decorator) 
                                                                                                                ?   <InputAdornment position="start">
                                                                                                                        {column.decorator}
                                                                                                                    </InputAdornment> 
                                                                                                                :   ""
                                                                                                        ),                 
                                                                                                        endAdornment:(
                                                                                                            (column.decoratorPlacement === "end" && column.decorator) 
                                                                                                                ?   <InputAdornment position="end">
                                                                                                                        {column.decorator}
                                                                                                                    </InputAdornment> 
                                                                                                                :   ""
                                                                                                        ),
                                                                                                        disableUnderline: true
                                                                                                    }}
                                                                                                    inputProps={{
                                                                                                        style:{
                                                                                                            textAlign: column.centerBody 
                                                                                                                            ? 'center' 
                                                                                                                            : column.alignRight 
                                                                                                                                ?   'right'
                                                                                                                                :   column.numeric 
                                                                                                                                        ? 'right' 
                                                                                                                                        : 'left'
                                                                                                        },
                                                                                                        fontSize: column.bodyFontSize ? column.bodyFontSize : "inherit",
                                                                                                    }}
                                                                                                    onChange={(e) => handleCellValueChange(e as ChangeEvent<HTMLInputElement>, rowIndex, colIndex, column.name)}
                                                                                                />
                                                                                            :   row[column.name] == null ? (
                                                                                                ''
                                                                                            ) : column.numeric ? (
                                                                                                numberColumn(row[column.name], column)
                                                                                            ) : (
                                                                                                row[column.name]
                                                                                            )
                                                                                            
                                                                                    }
                                                                                </TableCell>
                                                                            ))}
                                                                    </TableRow>
                                                                </TableBody>
                                                            </ReactTable>
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: (state.dense ? 33 : 53) * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </ReactTable>
            </TableContainer>
            {rowsPerPage > 0 && rowsPerPage !== props.rows.length && (
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25]}
                    component="div"
                    count={state.rows.filter(rowFilter).length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            )}
        </div>
    );
};

export default Table;
