import { FormControl, Grid, makeStyles, MenuItem, Select, Theme, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { ceclapiFredIndexesRequest } from '../../../../store/ceclapi/actions';
import { CeclApiState } from '../../../../store/ceclapi/types';
import { colors } from '../../../../theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paddingLess: {
            padding: 0,
        },
        title: {
            width: '100%',
            textAlign: 'left',
            fontSize: 35,
            paddingLeft: theme.spacing(5),
        },
        dialogDivider: {
            color: colors.black,
        },
        inputName: {
            fontWeight: 'bold',
            textAlign: 'end',
            whiteSpace: 'nowrap',
        },
        inputBar: {
            width: '100%',
        },
        paddingRight: {
            paddingRight: theme.spacing(2),
        },
        grid: {
            marginBottom: theme.spacing(5),
        },
        pdfgrid: {
            marginBottom: theme.spacing(5),
            height: 80,
        },
    })
);

export interface LossParametersProps {
    dataLag: number;
    economicIndex: string;
    onChange?: ({ dataLag: number, economicIndex: string }) => void;
    pdfMode?: boolean;
}

const LossParameters: React.FC<LossParametersProps> = (props) => {
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const classes = useStyles();
    const [economicIndexes, setEconomicIndexes] = React.useState(null);
    const [state, setState] = React.useState<LossParametersProps>(props);
    const dispatch = useDispatch();

    const dataLags: number[] = Array.from(Array(25).keys());

    const handleDataLagChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setState((prev) => ({ ...prev, dataLag: event.target.value as number }));

        if (state.onChange) {
            state.onChange({ dataLag: event.target.value as number, economicIndex: state.economicIndex });
        }
    };

    const handleEconomicIndexChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setState((prev) => ({ ...prev, economicIndex: event.target.value as string }));

        if (state.onChange) {
            state.onChange({ dataLag: state.dataLag, economicIndex: event.target.value as string });
        }
    };

    React.useEffect(() => {
        if (!cecl.forecastAssumptions?.indexes?.indexList) {
            dispatch(ceclapiFredIndexesRequest({ cuid: cecl.user!.routingNumber }));
        }
    }, []);

    React.useEffect(() => {
        if (cecl.forecastAssumptions?.indexes?.indexList) {
            setEconomicIndexes(cecl.forecastAssumptions?.indexes?.indexList);
        }
    }, [cecl.forecastAssumptions?.indexes]);

    return (
        <Grid container item direction="row" spacing={4} className={props.pdfMode ? classes.pdfgrid : classes.grid}>
            <Grid item xs={5}>
                <Grid item container direction="row" spacing={1}>
                    <Grid item xs={5}>
                        <Typography className={classes.inputName}>Economic Index:</Typography>
                    </Grid>
                    <Grid item xs>
                        <FormControl className={classes.inputBar}>
                            {state.economicIndex && economicIndexes && (
                                <Select
                                    id="economic-index-select"
                                    value={state.economicIndex}
                                    onChange={handleEconomicIndexChange}
                                >
                                    {[].slice.call(economicIndexes).sort().map((indexObject, index) => {
                                        return (
                                            <MenuItem value={indexObject.fredSeriesId} key={index}>
                                                {indexObject.fredLabel}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={5}>
                <Grid item container direction="row" spacing={1}>
                    <Grid item xs={5}>
                        <Typography className={classes.inputName}>Data Lag (Months):</Typography>
                    </Grid>
                    <Grid item xs className={classes.paddingRight}>
                        <FormControl className={classes.inputBar}>
                            {dataLags && (
                                <Select id="data-lag-select" value={state.dataLag} onChange={handleDataLagChange}>
                                    {dataLags.map((lagValue, index) => {
                                        return (
                                            <MenuItem value={lagValue} key={index}>
                                                {lagValue}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LossParameters;
