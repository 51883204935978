import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { UserManagementActionTypes } from './types';
import {
  acceptUserRequest,
  acceptUserError,
  acceptUserSuccess,
  updateUserRequest,
  updateUserError,
  updateUserSuccess,
} from './actions';
import { callApi } from '../../utils/callApi';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

function* handleUpdateUserRequest(action: ReturnType<typeof updateUserRequest>) {
  try {
    const res = yield call(
      callApi,
      'patch',
      API_ENDPOINT + 'api',
      `/usermanagement/users/${action.payload.id}`,
      action.payload.data
    );

    let successActions: any[] = [put(updateUserSuccess(res))];
    if (action.payload.verifyUserSubmission) {
      successActions.push(put(acceptUserRequest(action.payload.verifyUserSubmission)));
    }

    yield res.error ? put(updateUserError(res.error)) : all(successActions);
  } catch (err) {
    yield err instanceof Error
      ? put(updateUserError(err.stack!))
      : put(updateUserError('An unknown error occured.'));
  }
}

function* handleAcceptUserRequest(action: ReturnType<typeof acceptUserRequest>) {
  try {
    const res = yield call(
      callApi,
      'post',
      API_ENDPOINT + 'api',
      `/usermanagement/users/${action.payload.userId}/accept`,
      {
        salesforceAccountId: action.payload.salesforceAccountId,
        rtNumber: action.payload.rtNumber,
        envelopeId: action.payload.envelopeId,
        creditUnionName: action.payload.creditUnionName,
        userId: action.payload.userId,
      }
    );

    yield res.error
      ? put(acceptUserError(res.error))
      : all([put(acceptUserSuccess(action.payload.userId))]);
  } catch (err) {
    yield err instanceof Error
      ? put(acceptUserError(err.stack!))
      : put(acceptUserError('An unknown error occured.'));
  }
}

function* watchUpdateUserRequest() {
  yield takeEvery(UserManagementActionTypes.UPDATE_USER_REQUEST, handleUpdateUserRequest);
}

function* watchAcceptUserRequest() {
  yield takeEvery(UserManagementActionTypes.ACCEPT_USER_REQUEST, handleAcceptUserRequest);
}

function* userManagementSaga() {
  yield all([
    fork(watchAcceptUserRequest),
    fork(watchUpdateUserRequest),
  ]);
}

export { userManagementSaga as UserManagementSaga };
