import React, { ChangeEvent, MouseEventHandler } from "react";
import { useSelector } from "react-redux";
import Button from "../../components/button/Button";
import Table, { Column, TableProps } from "../../components/table/Table";
import { ApplicationState } from "../../store";
import { CeclApiState, ForecastParameter } from "../../store/ceclapi/types";
import { colors } from "../../theme";

export interface ForecastParametersTableProps{
    handleDialogOpen: MouseEventHandler<HTMLButtonElement>;
    parameters: ForecastParameter[];
    handleTableEdit: (event: ChangeEvent<HTMLInputElement>, rowIndex: number, colName: string) => void;
}

const ForecastParametersTable : React.FC<ForecastParametersTableProps> = (props) => {
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const [state, setState] = React.useState<ForecastParametersTableProps>({ ...props });
    const [parameterTableProps, setParameterTableProps] = React.useState({
        loading: true,
        dense: true,
        keyColumn: "name",
        rows: [],
        columns: [],
        selectable: false,
        hasBorder: true,
        hasSecondHeaderRow: true,
    } as TableProps);

    const defaultParameterColumns : Column[] = [
        {
            name: "name", 
            label: (<Button onClick={props.handleDialogOpen}>Load Assumptions</Button>) as unknown as string, 
            numeric: false, bodyColor: colors.lightGray3
        },
        {
            name: "Historical Lookback Period (Years)", 
            label: "Historical Lookback Period (Years)", 
            secondaryLabel: "Must be greater than 1 year.",
            numeric: true, 
            numericPrecision: 0,
            editable: true, 
            headerColor: colors.lightGray3,
            centerHeader: true,
            centerBody: true,
            secondHeader: "",
            secondHeaderColor: colors.lightGray4,
            secondHeaderHasBorder: false,
            required: true,
        },
        {
            name: "Catalyst Default Prepayment Speeds", 
            label: "Catalyst Default Prepayment Speeds", 
            numeric: false, 
            alignRight: true, 
            headerColor: colors.lightGray3,
            centerHeader: true,
            bodyFontSize: "1.1667rem",
            secondHeader: "Balance Sheet Inputs",
            secondHeaderColor: colors.lightGray4,
            secondHeaderHasBorder: false,
        },
        {
            name: "Prepayment Speed Override (Optional)", 
            label: "Prepayment Speed Override (Optional)", 
            numeric: true, 
            editable: true, 
            decorator: "%", 
            decoratorPlacement: "end",
            headerColor: colors.lightGray3,
            centerHeader: true,
            secondHeader: "",
            secondHeaderColor: colors.lightGray4,
            secondHeaderHasBorder: true,
        },
        {
            name: "Loan Quality Qualitative Factor Adjustment (%)*", 
            label: "Loan Quality Qualitative Factor Adjustment (%)*", 
            numeric: true, 
            editable: true, 
            decorator: "%",
            decoratorPlacement: "end",
            headerColor: colors.lightGray3,
            centerHeader: true,
            secondHeader: "Management Adjustments",
            secondHeaderColor: colors.lightGray4,
            secondHeaderHasBorder: true,
        },
    ];

    React.useEffect(() => {
        mapParametersTableDataFromModelParameters();
    }, [props])

    React.useEffect(() => {
        setParameterTableProps({...parameterTableProps, loading: cecl.forecastAssumptions?.detail?.loading!})
    }, [cecl.forecastAssumptions?.detail?.loading])

    function mapParametersTableDataFromModelParameters(){
        if(!props.parameters){
            if(cecl!.forecastAssumptions?.configuration?.loading){
                setParameterTableProps({...parameterTableProps, loading: true})
            }
            return;
        }

        var newRows = props.parameters.map((parameter) => {
                var obj : any = {};
                obj["name"] = parameter.name;
                obj["Historical Lookback Period (Years)"] = parameter.historicalLookbackPeriod;
                obj["Catalyst Default Prepayment Speeds"] = parameter.catalystDefaultPrepaymentSpeeds!.toFixed(2) + "%";
                obj["Prepayment Speed Override (Optional)"] = parameter.prepaymentSpeedOverride;
                obj["Loan Quality Qualitative Factor Adjustment (%)*"] = parameter.loanQualityFactorAdjustment;
                obj["error"] = parameter.error;
                return obj;
            });

        setParameterTableProps({...parameterTableProps, columns: defaultParameterColumns, rows: newRows, loading: false})
    }

    return (
        <Table
            loading={parameterTableProps.loading}
            columns={parameterTableProps.columns}
            dense={parameterTableProps.dense}
            rows={parameterTableProps.rows}
            keyColumn={parameterTableProps.keyColumn}
            selectable={parameterTableProps.selectable}
            hasBorder={parameterTableProps.hasBorder}
            hasSecondHeaderRow={parameterTableProps.hasSecondHeaderRow}
            onEdit={props.handleTableEdit}
        />
    );
}

export default ForecastParametersTable;