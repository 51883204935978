import { PdfPage, PdfPageHeaderFooter } from '../store/ceclapi/types';
import html2canvas from 'html2canvas';

export const logUserIn = () => {
    window.location.assign('/api/auth/signin');
};

export const logUserOut = () => {
    //Note, if you call logUserIn right after this, you will prevent the redirect that actually logs out a user.
    //And no, don't use a timer either.
    window.location.assign('/api/auth/signout');
};

export const MAX_TIME_IDLE = 420000;
export const MAX_TIME_TO_CONTINUE = 30;

export const generatePdf = async (pages: PdfPage[]) => {
    const promises = pages.map(async (page) => {
        //add slight delay to prevent UI blocking
        await new Promise(resolve => setTimeout(resolve, 1500));

        if (!page.id || page.data) {
            return page;
        }

        const element = document.getElementById(page.id);

        if (page.width){
            element.style['width'] = page.width;
        }
        else if (page.stretch) {
            element.style['width'] = '2500px';
        }

        if (element) {
            const canvas = await html2canvas(element, {scrollY: -window.scrollY, scrollX: -window.scrollX});

            page.image = canvas.toDataURL('image/png');        
            
            return page;
        }
    });

    return await Promise.all(promises);
};

export const generatePdfHeaderFooter = async (pages: PdfPageHeaderFooter[]) => {
    const promises = pages.map(async (page) => {
        if (!page.id) {
            return page;
        }

        const element = document.getElementById(page.id);

        if (page.stretch) {
            element.style['width'] = '2000px';
        }

        if (element) {
            const canvas = await html2canvas(element, {scrollY: -window.scrollY, scrollX: -window.scrollX});

            page.image = canvas.toDataURL('image/png');
            
            return page;
        }
    });

    return await Promise.all(promises);
};

export const formatCurrencyNumber = (n: number) => {
    var nf = new Intl.NumberFormat('en', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        style: 'currency',
        currency: 'USD',
    });

    return nf.format(n);
};

export const formatNumber = (n: number) => {
    var nf = new Intl.NumberFormat('en', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });

    return nf.format(n);
};

export const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

export const formatDateToMMddyyyy = (dateString: any) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${month}/${day}/${date.getFullYear()}`;
}

export const skipWeekendDates = (dateString: any) => {
    const date = new Date(dateString);

    if (date.getDay() == 6) {
        date.setDate(date.getDate() - 1);
    }
    else if (date.getDay() == 0) {
        date.setDate(date.getDate() - 2);
    }

    return date;
}

export const getPreviousBusinessDate = (date: Date) => {
    const prevBusinessDate = new Date(date);
    prevBusinessDate.setDate(prevBusinessDate.getDate() -1);
    return prevBusinessDate;
}

export const convertToPercentage = (decimalValue: number) => {
    const percentage = (decimalValue * 100).toFixed(2);
    return `${percentage}%`;
}