import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { ConfigState } from '../../store/config/types';
import { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../theme';
import { CMSContent } from '../../containers/cmscontent/CMSContent';

const pardotFormUrl = process.env.Contact_Pardot_Form_URL;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        contactUs: {
            flexGrow: 1,
            width: '100%',
            flexDirection: 'column',
            display: 'flex',
            margin: 0,
            position: 'relative',
            backgroundColor: colors.blue,
        },
        bar: {
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'center',
            padding: theme.spacing(10),
        },
        splitBox: {
            minHeight: 900,
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            margin: theme.spacing(2),
        },
        pardotForm:{
            height: "100%",
            width: "100%",
            backgroundColor: colors.white,
        }
    })
);

const LandingContactUsPage: React.FC = () => {
    const dispatch = useDispatch();
    const config = useSelector<ApplicationState, ConfigState>((state) => state.config);
    const classes = useStyles();

    useEffect(() => {}, []);

    return (
    <div className={classes.contactUs}>
        <div className={classes.bar}>
            <div className={classes.splitBox}>
                <iframe className={classes.pardotForm} src={pardotFormUrl} title="Contact Form" frameBorder="0"></iframe>
            </div>
            <div className={classes.splitBox}>
                <div id="contactUsContent">
                    <CMSContent type="contentitem" name="contact-general"/>
                </div>
            </div>
        </div>
    </div>

    );
};

export default LandingContactUsPage;
