import { Dialog, DialogContentText, DialogTitle, Grid, InputAdornment, lighten, TextField, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Search } from '@material-ui/icons';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import Button from '../../components/button/Button';
import Panel from '../../components/panel/Panel';
import Table, { Column, TableProps } from '../../components/table/Table';
import { ApplicationState } from '../../store';
import {
    ceclapiAdminForecastRoutingListRequest,
    ceclapiDeleteAssumptionsRequest,
    ceclapiDeleteForecastRequest,
    ceclapiDraftForecastAssumptionsRequest,
} from '../../store/ceclapi/actions';
import { CeclApiState, UserTypes } from '../../store/ceclapi/types';
import { colors } from '../../theme';
import Select from 'react-select';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        panels: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flex: 1,
            margin: theme.spacing(1),
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 100,
        },
        title: {
            fontSize: 20,
            fontFamily: 'Montserrat',
            fontWeight: 'bolder',
            margin: 10,
            textAlign: 'center',
        },
        controlPanel: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        controlPanelTitle: {
            alignSelf: 'center',
        },
        controlPanelForm: {
            flex: 1,
        },
        panel: {
            margin: theme.spacing(2),
        },
        tablePanel: {
            padding: 0,
        },
        grid: {
            maxWidth: 1800,
        },
        editLink: {
            color: colors.blue,
            textDecoration: 'none',
            fontWeight: 600,
            backgroundColor: colors.white,
            padding: 5,
            lineHeight: 1,
            width: theme.spacing(10),
            fontSize: 14,
            '&:hover': {
                backgroundColor: lighten(colors.blue, 0.9),
            },
        },
        deleteLink: {
            color: colors.red,
            textDecoration: 'none',
            fontWeight: 600,
            backgroundColor: colors.white,
            padding: 5,
            lineHeight: 1,
            width: theme.spacing(10),
            fontSize: 14,
            '&:hover': {
                backgroundColor: lighten(colors.red, 0.9),
            },
        },
        emptyTablePanel: {
            boxShadow: 'none',
            textAlign: 'center',
            fontStyle: 'italic',
        },
        searchBar: {
            width: '100%',
            padding: 'none',
        },
        createButton: {
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(1),
            width: 'calc(100% - 8px)',
        },
        dialogTitle: {
            fontWeight: 'bold',
        },
        dialogBox: {
            textAlign: 'center',
        },
        dialogConfirm: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        dialogCancel: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: colors.white,
            color: colors.blue,
            textDecoration: 'underline',
            '&:hover': {
                backgroundColor: colors.white,
            },
        },
        routingSelect: {
            marginLeft: theme.spacing(1)
        }
    })
);

interface DialogProps {
    id: string;
    open: boolean;
}

const ForecastPage: React.FC = () => {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const defaultColumns: Column[] = [
        { name: 'Forecast Assumptions', label: 'Forecast Assumptions', numeric: false, sortable: true },
        { name: 'Analysis Date', label: 'Analysis Date', numeric: false, sortable: true, isDate: true },
        { name: 'Date Saved', label: 'Date Saved', numeric: false, sortable: true, isDate: true },
        { name: 'Saved By', label: 'Saved By', numeric: false, sortable: true },
        { name: 'Created By', label: 'Created By', numeric: false, sortable: true },
        { name: 'Actions', label: 'Actions', numeric: false, sortable: false },
    ];
    const [tableProps, setTableProps] = React.useState({
        loading: true,
        dense: true,
        keyColumn: 'name',
        rows: [],
        columns: [],
        pageSize: 15,
    } as TableProps);
    const [adminRoutingList, setAdminRoutingList] = React.useState({loading: true, routingNumbers: []});
    const [selectedRoutingNumber, setRoutingNumber] = React.useState(null);
    const [searchBarInput, setSearchbarInput] = React.useState('');
    const [myRows, setMyRows] = React.useState([] as any[]);
    const [dialogProps, setDialogProps] = React.useState({
        id: '',
        open: false,
    } as DialogProps);

    React.useEffect(() => {
        setTableProps({ ...tableProps, loading: true });
        cecl.forecastAssumptions.drafts = [];
        dispatch(ceclapiDraftForecastAssumptionsRequest({ cuid: (cecl.user!.routingNumber! as unknown) as string }));
        if(cecl.user!.userType! == UserTypes.Admin){
            setAdminRoutingList({ ...adminRoutingList, loading: true});
            dispatch(ceclapiAdminForecastRoutingListRequest());
        }
    }, []);

    React.useEffect(() => {
        mapAdminRoutingList();
    }, [cecl.forecastAssumptions.forecastAdminRoutingList]);

    React.useEffect(() => {
        mapTableDataFromAssumptionDrafts();
    }, [cecl.forecastAssumptions]);

    React.useEffect(() => {
        filterRowsBySearchInput();
    }, [searchBarInput]);

    const handleSearchbarInputChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSearchbarInput(event.target.value as string);
    };

    const handleDialogOpen = (id: string) => {
        setDialogProps({ ...dialogProps, id: id, open: true });
    };

    const handleDialogClose = () => {
        setDialogProps({ ...dialogProps, id: '', open: false });
    };

    const handleDeleteAssumption = () => {
        var myAssumptionId = dialogProps.id;
        dispatch(ceclapiDeleteAssumptionsRequest({ cuid: cecl.user!.routingNumber, assumptionId: myAssumptionId }));
        handleDialogClose();
    };

    const handleRoutingNumberChange = (event) => {
        setRoutingNumber(event);
        setTableProps({ ...tableProps, loading: true });
        dispatch(ceclapiDraftForecastAssumptionsRequest({ cuid: (event.value as unknown) as string }));
    };

    function getAssumptionsRoutingNumber() {
        if (cecl.user!.userType! == UserTypes.Admin && selectedRoutingNumber != null) {
            return selectedRoutingNumber.value;
        }
        return cecl.user!.routingNumber!;
    }

    function mapAdminRoutingList() {
        if (!cecl?.forecastAssumptions?.forecastAdminRoutingList?.length) {
            return;
        }
        
        var routingList = cecl?.forecastAssumptions?.forecastAdminRoutingList?.map((cu) => {
            var obj: any = {};
            obj['value'] = cu.routing;
            obj['label'] = cu.routing + ' - ' + cu.cuName;

            return obj;
        });

        setRoutingNumber(null);
        setAdminRoutingList({...adminRoutingList, routingNumbers: routingList, loading: false});
    }

    function mapTableDataFromAssumptionDrafts() {
        if (!cecl?.forecastAssumptions?.drafts?.length) {
            if (cecl!.forecastAssumptions?.loading) {
                setTableProps({ ...tableProps, loading: true });
            } else {
                setTableProps({ ...tableProps, columns: defaultColumns, loading: false });
            }
            return;
        }
        
        var newRows = cecl?.forecastAssumptions?.drafts?.map((summary) => {
            var obj: any = {};
            obj['id'] = summary.id;
            obj['name'] = summary.name;
            obj['Forecast Assumptions'] = (
                <div>
                    <NavLink to={`/forecast/assumptions/${getAssumptionsRoutingNumber()}/${summary.id}`}>{summary.name}</NavLink>
                </div>
            );
            obj['Analysis Date'] = new Date(summary.analysisDate).toLocaleDateString();
            obj['Date Saved'] = summary.dateModified
                ? new Date(summary.dateModified).toLocaleString()
                : new Date(summary.dateCreated).toLocaleString();
            obj['Saved By'] = summary.modifiedBy ?? summary.createdBy;
            obj['Created By'] = summary.createdBy;
            obj['Actions'] = (
                <div>
                    <Button
                        variant="text"
                        className={classes.editLink}
                        onClick={() => history.push(`/forecast/assumptions/${getAssumptionsRoutingNumber()}/${summary.id}`)}
                    >
                        Edit
                    </Button>
                    &nbsp; &nbsp; &nbsp;
                    {cecl.user.userType != UserTypes.Admin && (
                        <Button variant="text" className={classes.deleteLink} onClick={() => handleDialogOpen(summary.id)}>
                            Delete
                        </Button>
                    )}
                </div>
            );
            return obj;
        });

        setMyRows(newRows);
        setSearchbarInput('');
        setTableProps({ ...tableProps, columns: defaultColumns, rows: newRows, loading: false, pageSize: 15 });
    }

    function filterRowsBySearchInput() {
        if (!myRows.length) {
            return;
        }
        if (!searchBarInput.length) {
            setTableProps({ ...tableProps, rows: myRows });
        } else {
            var newTableRows = myRows.filter(
                (row) =>
                    (row['name'] as string).toUpperCase().includes(searchBarInput.toUpperCase()) ||
                    (row['Saved By'] as string).toUpperCase().includes(searchBarInput.toUpperCase())
            );
            setTableProps({ ...tableProps, rows: newTableRows });
        }
    }

    return (
        <div className={classes.grid}>
            <Grid container direction="column" spacing={2}>
                <Grid item container spacing={4}>
                    <Grid item xs={3}>
                        <Button className={classes.createButton} onClick={() => history.push(`/forecast/assumptions/${getAssumptionsRoutingNumber()}`)}>
                            Create New Forecast
                        </Button>
                    </Grid>
                    <Grid item xs={9}>
                        <Panel>
                            <TextField
                                id="assumptions-searchbar"
                                className={classes.searchBar}
                                variant="standard"
                                type="search"
                                label="Search Forecast Assumptions"
                                value={searchBarInput}
                                onChange={handleSearchbarInputChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true,
                                }}
                                InputLabelProps={{
                                    margin: 'dense',
                                }}
                            />
                        </Panel>
                    </Grid>
                </Grid>
                {cecl.user.userType == UserTypes.Admin && (
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <Select
                                className={classes.routingSelect}
                                placeholder="Routing Number"
                                value={selectedRoutingNumber}
                                isClearable={false}
                                onChange={handleRoutingNumberChange}
                                options={adminRoutingList.routingNumbers}
                                isLoading={adminRoutingList.loading}
                            />
                        </FormControl>
                    </Grid>
                )}
                <Grid item>
                    <Panel className={classes.tablePanel}>
                        <Table
                            loading={tableProps.loading}
                            columns={tableProps.columns}
                            dense={tableProps.dense}
                            rows={tableProps.rows}
                            keyColumn={tableProps.keyColumn}
                            pageSize={tableProps.pageSize}
                            sortable={tableProps.sortable}
                        ></Table>
                        {!tableProps.loading && !cecl?.forecastAssumptions?.drafts?.length && (
                            <Panel className={classes.emptyTablePanel}>
                                Forecast assumptions you create and save will appear here
                            </Panel>
                        )}
                    </Panel>
                </Grid>
            </Grid>
            <Dialog
                className={classes.dialogBox}
                onClose={() => handleDialogClose()}
                open={dialogProps.open}
                aria-labelledby="delete-dialog-title"
            >
                <DialogTitle className={classes.dialogTitle} id="delete-dialog-title">
                    Delete Forecast Assumptions?
                </DialogTitle>
                <DialogContentText id="delete-dialog-description">
                    Deleting these assumptions will remove all inputs you have entered in the form currently and you
                    will not be able to undo this. Press "Confirm" below to initialize the deletion or click the
                    "Cancel" link to dismiss.
                </DialogContentText>
                <Button className={classes.dialogConfirm} onClick={handleDeleteAssumption}>
                    Confirm
                </Button>
                <Button className={classes.dialogCancel} onClick={() => handleDialogClose()}>
                    Cancel
                </Button>
            </Dialog>
        </div>
    );
};

export default ForecastPage;
