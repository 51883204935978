import { createStyles, IconButton, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.parcel';
import LeftArrowIcon from '@material-ui/icons/ArrowBack';
import RightArrowIcon from '@material-ui/icons/ArrowForward';
import Panel from '../../components/panel/Panel';
import LoadingSpinner from '../../components/loadingspinner/LoadingSpinner';

interface PdfProps {
    loading?: boolean;
    data?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pdfPage: {
            '& canvas': {
                margin: '0 auto',
            },
        },
        default: {
            height: '90vh',
        },
        buttonPanel: {
            position: 'absolute',
            margin: 'auto',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '95%',
        },
        pages: {
            textAlign: 'center',
            position: 'absolute',
            bottom: theme.spacing(2),
            left: '40%',
        },
    })
);

const Pdf: React.FC<PdfProps> = (props) => {
    const classes = useStyles();
    const [base64Pdf, setbase64Pdf] = React.useState('');
    const [numPages, setNumPages] = React.useState(0);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [showButtons, setShowButtons] = React.useState(false);
    const [pdfScale, setPdfScale] = React.useState(1.0);
    const [currentPage, setCurrentPage] = React.useState(null as any);
    const [dimensions, setDimensions] = React.useState({ height: window.innerHeight, width: window.innerWidth });

    React.useEffect(() => {
        if (props?.data) {
            setbase64Pdf('data:application/pdf;base64,' + props?.data);
            setPageNumber(1);
        }
    }, [props?.data]);

    React.useEffect(() => {
        if(currentPage){
            const parentDiv = document.querySelector('#pdfDocument');
            let pageScale = parentDiv.clientHeight / currentPage.originalHeight * 0.95;
            
            if (pdfScale !== pageScale) {
                setPdfScale(pageScale);
            }
        }
    }, [dimensions]);

    React.useEffect(() => {
        function handleResize() {
          setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
          });
        }
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize);
    });

    //@ts-ignore
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    function onPageLoadSuccess(page) {
        const parentDiv = document.querySelector('#pdfDocument');
        let pageScale = parentDiv.clientHeight / page.originalHeight * 0.95;
        
        setCurrentPage(page);
        if (pdfScale !== pageScale) {
            setPdfScale(pageScale);
        }
    }

    return (
        <div id="pdfDocument" className={classes.default}>
            <LoadingSpinner loading={props?.loading || false} />
            {!props.loading && (
                <>
                    <Panel onMouseOver={() => setShowButtons(true)} onMouseLeave={() => setShowButtons(false)}>
                        <Document file={base64Pdf} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageNumber} scale={pdfScale} className={classes.pdfPage} onLoadSuccess={onPageLoadSuccess}></Page>
                        </Document>
                        {showButtons && (
                            <div className={classes.buttonPanel}>
                                <IconButton disabled={pageNumber < 2} onClick={() => setPageNumber(pageNumber - 1)}>
                                    <LeftArrowIcon />
                                </IconButton>

                                <IconButton
                                    disabled={pageNumber >= numPages}
                                    onClick={() => setPageNumber(pageNumber + 1)}
                                >
                                    <RightArrowIcon />
                                </IconButton>
                            </div>
                        )}
                        {showButtons && (
                            <div className={classes.pages}>
                                Page {pageNumber} of {numPages}
                            </div>
                        )}
                    </Panel>
                </>
            )}
        </div>
    );
};

export default Pdf;
