import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const colors = {
    white: '#fff',
    lightBlue: '#eaf1f8',
    lightGray: '#f4f5f8',
    lightGray2: '#edeced',
    lightGray3: '#f4f4f4',
    lightGray4: '#fbfbfb',
    darkGray: '#1c1c1c',   
    gray: '#dadbdd',
    blue: '#2a5cc1',
    fadedBlue: 'rgb(234, 241, 248, 0.48)',
    darkBlue: '#1b3d6d',
    red: '#d51616',
    black: '#1c1c1c',
};

export const font =
    '"Montserrat", "Noto Sans", BlinkMacSystemFont, "avenir next", avenir, "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial, sans-serif';

const defaultTheme = createMuiTheme();

const GetTheme = (): Theme => {
    const theme: Theme = createMuiTheme({
        palette: {
            primary: {
                main: colors.white,
                light: colors.lightGray,
            },
            secondary: {
                main: colors.blue,
            },
            background: {
                default: colors.lightBlue,
            },
        },
        typography: {
            fontFamily: font,
            h1: {
                fontSize: 35,
            },
            h2: {
                fontSize: 24,
            },
            body1: {
                fontSize: 18,
            },
            body2: {
                fontSize: 16,
            },
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    html: {
                        margin: 0,
                        padding: 0,
                        height: '100%',
                    },
                    body: {
                        margin: 0,
                        padding: 0,
                        height: '100%',
                    },
                    '#main': {
                        position: 'relative',
                        height: '100%',
                        minHeight: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    },
                },
            },
            MuiTabs: {
                indicator: {
                    backgroundColor: colors.red,
                },
            },
            MuiTab: {
                textColorPrimary: {
                    color: colors.white,
                    '&$selected': {
                        color: colors.blue,
                    },
                },
            },
            MuiPaper: {
                root: {
                    padding: defaultTheme.spacing(2),
                    margin: defaultTheme.spacing(2),
                    flexGrow: 1,
                },
            },
            MuiAppBar: {
                root: {
                    padding: defaultTheme.spacing(0),
                    margin: defaultTheme.spacing(0),
                    flexGrow: 0,
                },
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1600,
            },
        },
    });

    return responsiveFontSizes(theme);
};

export default GetTheme;
