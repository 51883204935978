import { createStyles, Grid, InputAdornment, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import Table, { Column, TableProps } from '../../components/table/Table';
import { ApplicationState } from '../../store';
import { CeclApiState, ForecastInput } from '../../store/ceclapi/types';
import { colors } from '../../theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        blueBar: {
            fontWeight: 'bold',
            color: colors.white,
            backgroundColor: colors.blue,
            textAlign: 'start',
        },
        inputBar: {
            width: '100%',
        },
        inputName: {
            fontWeight: 'bold',
            textAlign: 'end',
            color: colors.white,
        },
        blueBackground: {
            backgroundColor: colors.blue,
        },
        blueBorder: {
            borderWidth: 1,
            borderColor: colors.blue,
            borderStyle: 'solid',
        },
        paddingLess: {
            padding: 0,
        },
        grayBorder: {
            border: `1px solid ${colors.lightGray}`,
        },
    })
);

export interface ForecastMonthlyInputsTableProps {
    inputs: ForecastInput[];
    handleTableEdit: (event: ChangeEvent<HTMLInputElement>, rowIndex: number, colName: string) => void;
}

const ForecastMonthlyInputsTable: React.FC<ForecastMonthlyInputsTableProps> = (props) => {
    const classes = useStyles();
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const [monthlyInputsTableProps, setMonthlyInputsTableProps] = React.useState({
        loading: true,
        dense: true,
        keyColumn: 'name',
        rows: [],
        columns: [],
        selectable: false,
        hasBorder: true,
    } as TableProps);
    const [totalBalance, setTotalBalance] = React.useState<string>('');
    const [state, setState] = React.useState<ForecastMonthlyInputsTableProps>({ ...props });

    const defaultMonthlyInputsColumns: Column[] = [
        {
            name: 'name',
            label: '',
            numeric: false,
            bodyColor: colors.lightGray3,
        },
        {
            name: 'Ending Balance',
            label: 'Ending Balance',
            numeric: false,
            alignRight: true,
            editable: true,
            required: true,
            decorator: '$',
            numberMask: true,
            decoratorPlacement: 'start',
            headerColor: colors.lightGray3,
            centerHeader: true,
        },
        {
            name: 'Number Of Loans',
            label: 'Number Of Loans',
            numeric: false,
            numericPrecision: 0,
            alignRight: true,
            numberMask: true,           
            editable: true,
            required: true,
            headerColor: colors.lightGray3,
            centerHeader: true,            
        },
        {
            name: 'Average Loan Rate (%)',
            label: 'Average Loan Rate (%)',
            numeric: true,
            editable: true,
            required: true,
            decorator: '%',
            decoratorPlacement: 'end',
            headerColor: colors.lightGray3,
            centerHeader: true,
        },
        {
            name: 'Weighted Average Remaining Maturity (Months)',
            label: 'Weighted Average Remaining Maturity (Months)',
            numeric: true,
            numericPrecision: 0,
            editable: true,
            required: true,
            headerColor: colors.lightGray3,
            centerHeader: true,
            centerBody: true,
        },
        {
            name: 'Dollar Amount Of Interest Only Loans',
            label: 'Dollar Amount Of Interest Only Loans',
            alignRight: true,
            numeric: false,
            editable: true,
            decorator: '$',
            numberMask: true,
            decoratorPlacement: 'start',
            headerColor: colors.lightGray3,
            centerHeader: true,
        },
    ];

    React.useEffect(() => {
        mapMonthlyInputsTableDataFromProps();
    }, [props]);

    React.useEffect(() => {
        var newBalance: number = 0;

        monthlyInputsTableProps.rows.forEach((row) => {
            newBalance += row['Ending Balance'];
        });

        var nf = new Intl.NumberFormat('en', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });

        setTotalBalance(nf.format(newBalance));
    }, [monthlyInputsTableProps.rows]);

    function mapMonthlyInputsTableDataFromProps() {
        if (!props.inputs) {
            if (cecl!.forecastAssumptions?.configuration?.loading) {
                setMonthlyInputsTableProps((prevState) => ({ ...prevState, loading: true }));
            }
            return;
        }

        var newRows = props.inputs
            .sort((a, b) => {
                return a.order! - b.order!;
            })
            .map((input) => {
                var obj: any = {};
                obj['name'] = input.name;
                obj['Ending Balance'] = input.endingBalance;
                obj['Number Of Loans'] = input.numberOfLoans;
                obj['Average Loan Rate (%)'] = input.averageLoanRate;
                obj['Weighted Average Remaining Maturity (Months)'] = input.weightedAverageRemainingMaturity;
                obj['Dollar Amount Of Interest Only Loans'] = input.dollarAmountOfInterestOnlyLoans;
                obj['error'] = input.error;
                return obj;
            });

        setMonthlyInputsTableProps((prevState) => ({
            ...prevState,
            columns: defaultMonthlyInputsColumns,
            rows: newRows,
            loading: false,
        }));
    }

    return (
        <div>
            <Grid container direction="column" spacing={1}>
                <Grid item>
                    <Table
                        loading={monthlyInputsTableProps.loading}
                        columns={monthlyInputsTableProps.columns}
                        dense={monthlyInputsTableProps.dense}
                        rows={monthlyInputsTableProps.rows}
                        keyColumn={monthlyInputsTableProps.keyColumn}
                        selectable={monthlyInputsTableProps.selectable}
                        hasBorder={monthlyInputsTableProps.hasBorder}
                        onEdit={props.handleTableEdit}
                    />
                </Grid>
                <Grid item xs={5}>
                    <Grid container item xs={12} direction="row" spacing={2}>
                        <Grid item xs={7} className={clsx(classes.blueBackground, classes.paddingLess)}>
                            <Typography className={classes.inputName}>Total Balance: </Typography>
                        </Grid>
                        <Grid item xs={5} className={classes.grayBorder}>
                            <TextField
                                id="total-balance"
                                className={classes.inputBar}
                                variant="standard"
                                // type="number"
                                value={totalBalance}
                                disabled={true}
                                InputProps={{
                                    disableUnderline: true,
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                inputProps={{
                                    style: {
                                        textAlign: 'right',
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default ForecastMonthlyInputsTable;
