import * as React from 'react';
import { InputProps as StandardInputProps, TextField } from '@material-ui/core';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}        
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      
      thousandSeparator      
     
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,  
  onChange: PropTypes.func.isRequired,
};

interface DebouncedInputProps {
  required?: boolean;
  id?: string;
  label?: string;
  touched?: boolean;
  valid?: boolean;
  value?: any;
  errorText?: string;
  regEx?: RegExp;
  readonly?: boolean;
  type?: any;
  className?: any;
  variant?: 'outlined' | 'standard' | 'filled';
  onClick?: (event: any) => void;
  onChange?: (event: any) => void;
  onSubmit?: (event: any) => void;
  onValidate?: (valid: boolean) => void;
  InputProps?: any;
  inputProps?: any;
}

const initialProps: DebouncedInputProps = {
  required: false,
  touched: false,
  valid: true,
  readonly: false,
  variant: 'outlined',
};

const MaskedInput: React.FC<DebouncedInputProps> = (props) => {
  const [state, setState] = React.useState<DebouncedInputProps>({ ...initialProps, ...props });
    const [inputValue, setInputValue] = React.useState(state.value); 
    const [tempInputValue, setTempInputValue] = React.useState(null);
    
    const debouncedFunctionRef = React.useRef();
    //@ts-ignore
    debouncedFunctionRef.current = (...args) => change(...args);

    const debouncedChange = React.useCallback(
        _.debounce(
            //@ts-ignore
            (...args) => debouncedFunctionRef.current(...args),
            250
        ),
        []
    );
 
    React.useEffect(() => {
      setState((prevState) => ({...prevState, ...props}));
  }, [props])

  React.useEffect(() => {
    setInputValue(props.value);
}, [props.value]);

  const change = (e: string) => {
    if (state.onChange) {
        state.onChange(e);
    }

    setState((prevState) => ({
        ...prevState,
        touched: true,
        valid: validate(e),
        value: e,
    }));
};

const validate = (value: string): boolean => {
  let valid = true;

  if (state.touched) {
      if (state.required && value.length < 1) {
          valid = false;
      }

      if (valid && state.regEx) {
          valid = state.regEx.test(value);
      }
  }

  if (state.onValidate) {
      setTimeout(() => {
          //@ts-ignore
          state.onValidate(valid);
      }, 0);
  }

  return valid;
};       

    return (
        <TextField
            required={state.required}
            id={state.id}
            label={state.label}
            error={!state.valid}            
            type={state.type}
            color="secondary"
            variant={state.variant}
            className={state.className}
            helperText={state.valid ? ' ' : state.errorText}
            value={tempInputValue === null ? inputValue || '' : tempInputValue}         
            onFocus={() => setState((prevState) => ({ ...prevState, touched: true }))}
                         
            InputProps={{
                readOnly: state.readonly,
                ...props.InputProps,
                inputComponent: NumberFormatCustom,
            }}
            inputProps={{
                ...props.inputProps
                
            }} 
            onChange={(e) => {
              debouncedChange(e.target.value);
              setInputValue(e.target.value);
          }}           
            
        />
        
    );

}
export default MaskedInput;