import React from 'react';
import { useSelector } from 'react-redux';
import Chart, { ChartProps, historyGraphColorMap } from '../../../components/chart/Chart';
import { ApplicationState } from '../../../store';
import { CeclApiState, ChartData, Chart as ChartType } from '../../../store/ceclapi/types';

const initialChartProps: ChartProps = {
    options: {
        title: {
            text: 'Ending Balances',
            fontSize: 20,
            fontFamily: 'Montserrat',
            fontWeight: 'bolder',
            margin: 50,
        },
        dataSetNamesForYAxis1: ['CU Balances'],
    },
    data: [],
    id: 'endingBalances',
};

const EndingBalances: React.FC<ChartProps> = (props) => {
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const [chartProps, setChartProps] = React.useState<ChartProps>({ ...initialChartProps, ...props });

    const prepChartData = (chart: ChartType): ChartData[] => {
        if (chart.data) {
            const chartData = chart.data.map((d) => {
                d.type = 'column';
                d.color = historyGraphColorMap[d.name]?.color;
                d.dataPoints = d.dataPoints
                    ?.sort((a, b) => {
                        var dateA: Date = new Date(a.label);
                        var dateB: Date = new Date(b.label);

                        return dateA.getTime() - dateB.getTime();
                    })
                    .map((dp) => {
                        dp.label = dp.label;
                        dp.x = undefined;
                        dp.y = +dp.y;

                        return dp;
                    });

                return d;
            });

            return chartData;
        }

        return [];
    };

    React.useEffect(() => {
        const charts = cecl.historicalData?.charts?.filter((d) =>
            chartProps.options.dataSetNamesForYAxis1.includes(d.graphName)
        );

        if (charts && charts.length === 1 && charts[0].data) {
            var jointData: ChartData[] = [];

            jointData = [...prepChartData(charts[0])];
            setChartProps((prevState) => ({
                ...prevState,
                data: jointData,
            }));
        } else {
            setChartProps(initialChartProps);
        }
    }, [cecl.historicalData]);

    React.useEffect(() => {
        setChartProps((prevState) => ({
            ...prevState,
            ...props,
        }));
    }, [props]);

    return (
        <>
            <Chart loading={cecl.historicalData?.loading} {...chartProps} />
        </>
    );
};

export default EndingBalances;
