import React, { ReactNode } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Transition from './Transition';

interface ModalProps extends DialogProps {
    open: boolean;
    title?: string;
    description?: string;
    actions?: ReactNode;
    titleactions?: ReactNode;
    contentbackgroundcolor?: string;
    loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogPaper: {
            padding: 0,
            minHeight: '0vh',
            maxHeight: '100vh',
            maxWidth: '35vw',
            [theme.breakpoints.down(2000)]: {
                maxWidth: '40vw'
            },
            [theme.breakpoints.down(1750)]: {
                maxWidth: '50vw'
            },
            [theme.breakpoints.down(1500)]: {
                maxWidth: '65vw'
            },
            [theme.breakpoints.down(1250)]: {
                maxWidth: '80vw'
            },
            [theme.breakpoints.down(1000)]: {
                maxWidth: '90vw'
            },
        },
        titleActions: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
        },
    })
);

const initialProps: ModalProps = {
    open: false,
    title: '',
    loading: false,
};

const Modal: React.FC<ModalProps> = (props) => {
    const [state, setState] = React.useState<ModalProps>({ ...initialProps, ...props });
    const { loading, ...dialogProps } = state;
    const classes = useStyles();

    React.useEffect(() => {
        setState((prevState) => ({ ...prevState, ...props }));
    }, [props]);

    return (
        <Dialog {...dialogProps} TransitionComponent={Transition} keepMounted classes={{ paper: classes.dialogPaper }}>
            {!state.loading && state.title && <DialogTitle>{state.title}</DialogTitle>}
            {!state.loading && state.titleactions && <div className={classes.titleActions}>{state.titleactions}</div>}
            <DialogContent style={{ backgroundColor: state.contentbackgroundcolor }}>
                {state.description && <DialogContentText>{state.description}</DialogContentText>}
                {state.children}
            </DialogContent>
            {!state.loading && state.actions && <DialogActions>{state.actions}</DialogActions>}
        </Dialog>
    );
};

export default Modal;
