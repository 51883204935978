/* eslint-disable react-hooks/exhaustive-deps */
import * as jsonpatch from 'fast-json-patch';
import React, { ChangeEvent, useContext, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { updateUserRequest } from '../../store/user-management/actions';
import { UserInfo } from '../../store/user-management/types';
import { get } from '../../utils/callApi';
import { useParams, useLocation } from 'react-router-dom';
import { CeclApiState, UserTypes } from '../../store/ceclapi/types';

const initialState = {
  initialUser: undefined,
  user: undefined,
  userRoles: undefined,
  mounted: false,
  userPatch: (initialUser, currentUser) => jsonpatch.compare(initialUser, currentUser),
  submitting: false,
};

type State = {
  initialUser?: UserInfo;
  user?: UserInfo;
  userRoles?: any[];
  mounted: boolean;
  userPatch: (initialUser: UserInfo, currentUser: UserInfo) => jsonpatch.Operation[];
  submitting: boolean;
};

export const actions = {
  INPUT_CHANGE: 'INPUT_CHANGE',
  TOGGLE_PERMISSION: 'TOGGLE_PERMISSION',
  SET_USER: 'SET_USER',
  SET_USER_ROLES: 'SET_USER_ROLES',
  MOUNT: 'MOUNT',
  TOGGLE_SUBMITTING: 'TOGGLE_SUBMITTING',
  UNMOUNT: 'UNMOUNT',
};

type Action = {
  type: typeof actions[keyof typeof actions];
  payload?: any;
};

export const toBool: (pred: any) => boolean = pred => {
  if (typeof pred === 'string') {
    if (pred === 'False') {
      return false;
    }
    if (pred === 'True') {
      return true;
    }
  }

  if (typeof pred === 'boolean') {
    return pred;
  }
  if (pred === null || pred === undefined) {
    return false;
  }
  return false;
};

const reducer: (state: State, action: Action) => State = (state, action) => {
  switch (action.type) {
    /*
    ? Set initial User
    ** This is the default redux state for "auth.user"
    */
    case actions.SET_USER: {
      return { ...state, initialUser: action.payload, user: action.payload };
    }

    case actions.SET_USER_ROLES: {
      return { ...state, userRoles: action.payload };
    }

    case actions.TOGGLE_PERMISSION: {
      let payload: { field: string };
      payload = action.payload;

      // need to set disableReports and disableUpload to null if it is false instead of actually false
      // in order to be consistent with what comes back from the API
      let value: any = !toBool(state.user[payload.field]);
      if (payload.field === 'disableReports' || payload.field === 'disableUpload') {
        if (value === false) {
          if (state.initialUser[payload.field] === null) {
            value = null;
          } else {
            value = 'False';
          }
        } else {
          value = 'True';
        }
      }
      return {
        ...state,
        user: {
          ...state.user,
          [payload.field]: value,
        },
      };
    }

    /*
    ? Handle Input Change
    ** Changes user info on input change
    */
    case actions.INPUT_CHANGE: {
      let payload: { name: string; value: string };
      payload = action.payload;
      if (payload.name === 'smsNumber') {
      }
      return {
        ...state,
        user: { ...state.user, [payload.name]: payload.value },
      };
    }

    /*
    ? Sets mounted to true
    ** Set this to true once you have all your data you need for component to render
    */
    case actions.MOUNT: {
      return { ...state, mounted: true };
    }

    case actions.UNMOUNT: {
      return { ...state, mounted: false };
    }

    case actions.TOGGLE_SUBMITTING: {
      return { ...state, submitting: !state.submitting };
    }
    default:
      return state;
  }
};

export interface IUseParams {
  id: string;
}

const useUserProfileReducer = () => {
  const { id: userId } = useParams<IUseParams>();
  const { pathname } = useLocation();
  const dis = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const cecl = useSelector<ApplicationState, CeclApiState>(state => state.cecl);

  const setUser = user => {
    dispatch({ type: actions.SET_USER, payload: user });
  };

  const setUserRoles = roles => {
    dispatch({ type: actions.SET_USER_ROLES, payload: roles });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.currentTarget;

    dispatch({
      type: actions.INPUT_CHANGE,
      payload: { name, value },
    });
  };

  const toggleSubmitting = () => dispatch({ type: actions.TOGGLE_SUBMITTING });

  const togglePermission = (field: string) =>
    dispatch({ type: actions.TOGGLE_PERMISSION, payload: { field } });

  const updateUser = async () =>
    dis(updateUserRequest(state.user.objectId, state.userPatch(state.initialUser, state.user)));

  const handleSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void = event => {
    event.preventDefault();
    toggleSubmitting();
    updateUser();
    // Promise.all([updateUser()]).then(() =>
    //   window.location.assign(pathname)
    // );
  };

  const edited = () => {
    if (state?.initialUser)
      return (
        state.userPatch(state.initialUser, state.user).length !== 0
      );
    return false;
  };

  const mount = () => dispatch({ type: actions.MOUNT });

  useEffect(() => {
    if (cecl.user && !cecl.user.loading && !state.submitting) {
      if (cecl.user.userType == UserTypes.Admin && userId !== undefined && state.user === undefined) {
        get(`api/usermanagement/users/${userId}`)
          .then(data => {
            setUser(data);
          })
          .catch(error => console.log('There was an error fetching the user', error));
        get(`api/usermanagement/users/${userId}/roles`)
          .then(data => setUserRoles(data))
          .catch(error => console.log('There was an error fetching the user roles', error));
      } else if (state.user === undefined) {
        get(`api/usermanagement/users/${cecl.user.userObjectId}`)
          .then(data => {
            setUser(data);
          })
          .catch(error => console.log('There was an error fetching the user', error));
        setUserRoles(cecl.user.roles);
      }
    }

    if (!cecl?.user?.loading && state?.user && state?.userRoles) {
      mount();
    }
}, [cecl.user.userObjectId, state.user, state.userRoles, state.mounted, state.submitting, dis]);

  return {
    ...state,
    handleInputChange,
    handleSubmit,
    togglePermission,
    dispatch,
    edited: edited(),
  };
};

export default useUserProfileReducer;
