import React from 'react';
import { useSelector } from 'react-redux';
import Chart, { ChartProps } from '../../../components/chart/Chart';
import { ApplicationState } from '../../../store';
import { CeclApiState, ChartData, Chart as ChartType } from '../../../store/ceclapi/types';

const initialChartProps: ChartProps = {
    options: {
        title: {
            text: 'Net Worth and ROA',
            fontSize: 20,
            fontFamily: 'Montserrat',
            fontWeight: 'bolder',
            margin: 50,
        },
        dataSetNamesForYAxis1: ['CU CU'],
        dataSetNamesForYAxis2: ['CU CU'],
        axisY: {
            valueFormatString: "#%"            
        },
        axisY2: {
            valueFormatString: "#%"            
        },
    },
    data: [],
    id: 'networthandroa',
};

const accounts0: string[] = ['Net Worth/Total Asset'];
const accounts1: string[] = ['Return On Average Assets'];

const NetWorthAndROA: React.FC<ChartProps> = (props) => {
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const [chartProps, setChartProps] = React.useState<ChartProps>({ ...initialChartProps, ...props });

    const prepChartData = (chart: ChartType, yIndex: number): ChartData[] => {
        if (chart.data) {

            var myChartData: ChartData[] = JSON.parse(JSON.stringify(chart.data));

            const mappedData = myChartData.filter((d) => {
                if(yIndex === 1){
                    return accounts1.includes(d.name);
                }
                else{
                    return accounts0.includes(d.name);
                }
            }).map((d) => {
                d.axisYType = yIndex === 1 ? 'secondary' : 'primary';
                d.type = yIndex === 1 ? 'line' : 'column';
                if(d.type == 'line'){
                    d.markerType = "circle";
                    d.lineDashType = "dot";
                    d.lineThickness = 5;
                    d.color = "black";
                }
                d.dataPoints = d.dataPoints?.sort((a, b) => {
                    var dateA: Date = new Date(a.label);
                    var dateB: Date = new Date(b.label);

                    return dateA.getTime() - dateB.getTime();
                }).map((dp) => {
                    dp.label = dp.label;
                    dp.x = undefined;
                    dp.y = +dp.y;

                    return dp;
                });

                return d;
            });

            return mappedData;
        }

        return [];
    };

    React.useEffect(() => {
        const chartsYAxis1 = cecl.dashboardData?.charts?.filter(
            (d) => chartProps.options.dataSetNamesForYAxis1.includes(d.graphName)
        );

        const chartsYAxis2 = cecl.dashboardData?.charts?.filter(
            (d) => chartProps.options.dataSetNamesForYAxis2?.includes(d.graphName)
        );

        var shouldSetToInitial: boolean = true;
        var jointData: ChartData[] = [];

        if (chartsYAxis1 && chartsYAxis1.length > 0) {

            shouldSetToInitial = false;

            for(var i = 0; i < chartsYAxis1.length; i++){
                jointData = [...jointData, ...prepChartData(chartsYAxis1[i],0)];
            }
        }

        if (chartsYAxis2 && chartsYAxis2.length > 0) {

            shouldSetToInitial = false;

            for(var i = 0; i < chartsYAxis2.length; i++){
                jointData = [...jointData, ...prepChartData(chartsYAxis2[i],1)];
            }
        } 

        if(shouldSetToInitial){
            setChartProps(initialChartProps);
        }
        else{
            setChartProps((prevState) => ({
                ...prevState,
                data: jointData,
            }));
        }
        
    }, [cecl.dashboardData]);

    return (
        <>
            <Chart loading={cecl.dashboardData?.loading} {...chartProps} />
        </>
    );
};

export default NetWorthAndROA;
