import { createStyles, makeStyles, Paper, PaperProps, Theme } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';

export interface PanelProps extends PaperProps {
    children?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        panel: {
            margin: theme.spacing(1),
            position: 'relative',
        },
    })
);

const initialProps: PanelProps = {};

const Panel: React.FC<PanelProps> = (props) => {
    const [state, setState] = React.useState<PanelProps>({ ...initialProps, ...props });
    const { children, ...paperProps } = props;
    const classes = useStyles();

    React.useEffect(() => {
        setState((prevState) => ({ ...prevState, ...props }));
    }, [props]);

    return (
        <Paper {...paperProps} className={clsx(classes.panel, state.className)}>
            {state.children}
        </Paper>
    );
};

export default Panel;
