import { combineReducers, Dispatch, Action, AnyAction } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { ConfigState } from './config/types';
import { ConfigReducer } from './config/reducer';
import { ConfigSaga } from './config/sagas';
import { CeclApiState } from './ceclapi/types';
import { CeclApiReducer } from './ceclapi/reducer';
import { CeclApiSaga } from './ceclapi/sagas';
import { UserManagementReducer } from './user-management/reducer';
import { UserManagementSaga } from './user-management/sagas';
import { UserManagementState } from './user-management/types';

export interface ApplicationState {
    router: RouterState;
    config: ConfigState;
    cecl: CeclApiState;
    lastAction: LastAction;
    userManagement: UserManagementState
}
export interface LastAction {
    type: string;
    payload: any;
    meta: any;
    error: any;
}
export interface ConnectedReduxProps<A extends Action = AnyAction> {
    dispatch: Dispatch<A>;
}

export const lastAction = (state = null, action: any) => {
    return action;
};

export const CreateRootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        config: ConfigReducer,
        cecl: CeclApiReducer,
        lastAction,
        userManagement: UserManagementReducer,
    });

export function* RootSaga() {
    yield all([fork(ConfigSaga), fork(CeclApiSaga),fork(UserManagementSaga)]);
}
