import * as React from 'react';
import { createStyles, makeStyles, Theme, Container } from '@material-ui/core';
import { colors } from '../../theme';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';
const URL = '/usefuldocuments/content/';
const VideoName = 'Getting Started With CECL';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        scheduleDemo: {
            width: '100%',
            margin: "0",
            position: 'relative',
            backgroundColor: colors.blue,
        },
        bar: {
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'center',
            padding: theme.spacing(10),
        },
        splitBox: {
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            margin: theme.spacing(2),
        },
        pardotForm:{
            height: "100%",
            width: "100%",
            backgroundColor: colors.white,
        }
    })
);

const CECLViewDemoPage: React.FC = () => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.bar}>
                <div className={classes.splitBox}>
                <Container>
                    <video controls autoPlay width="100%">
                        <source src={API_ENDPOINT + 'api' + URL + "getVideo/" + VideoName} type={'video/mp4'} />
                    </video>
                </Container>
                </div>
            </div>
        </div>
    );
};

export default CECLViewDemoPage;