import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { ConfigState } from '../../store/config/types';
import { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../theme';
import { CMSContent } from '../../containers/cmscontent/CMSContent';
import Button from '../../components/button/Button';
import clsx from 'clsx';
import { displayToast } from '../../store/config/actions';
import { CeclApiState, UserTypes } from '../../store/ceclapi/types';
import { logUserIn } from '../../utils/helpers';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        home: {
            flexGrow: 1,
            width: '100%',
            flexDirection: 'column',
            display: 'flex',
            margin: 0,
            position: 'relative',
        },
        bar: {
            display: 'flex',
            position: 'relative',
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
        },
        logo: {
            width: '50%',
            margin: '1%'

        },
        center: {
            margin: '0 auto',
            width: '60%',
        },
        centerText: {
            textAlign: 'center',
        },
        blueBar: {
            backgroundColor: colors.blue,
            color: colors.white,
            fontWeight: 600,
        },
        whiteBar: {
            backgroundColor: colors.white,
            color: colors.blue,
            fontWeight: 600,
        },
        videoBar: {
            flexDirection: 'row',
            marginTop: 25,
            display: 'flex',
            justifyContent: 'center',
            padding: theme.spacing(4),
        },
        splitBox: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
        },
        button: {
            position: 'absolute',
            bottom: theme.spacing(2),
            margin: '0 auto',
            textAlign: 'center',
        },
        buttonTop: {                       
            marginTop: '5%',
            textAlign: 'center',          
        },
        signupButton: {
            display: 'flex',
            justifyContent: 'center',
        },
        marketingText: {
            fontSize: 'large'
        }
    })
);

const LandingHomePage: React.FC = () => {
    const dispatch = useDispatch();
    const config = useSelector<ApplicationState, ConfigState>((state) => state.config);
    const classes = useStyles();
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl)


    useEffect(() => {}, []);

    const goToHomePage = () =>{
        window.location.assign(cecl.user?.userType === UserTypes.Admin ? '/usermanagement' : '/dashboard');        
    }

    return (
        <div className={classes.home}>
            <div className={classes.videoBar}>
                <div className={classes.splitBox}>

                    <img className ={classes.logo} src={require('../../assets/images/cecl_logo.svg')}></img>
                    <br/>
               <p className={classes.marketingText}> A robust, self-directed online platform to help credit unions comply with FASB's new accounting standard for estimating loan losses.</p>
                    {cecl.user?.isAuthenticated ? (
                        <Button className={classes.buttonTop} onClick={goToHomePage}>
                            Start Using the CECLution
                        </Button>
                    ) : (
                        <Button className={classes.buttonTop} onClick={() => {
                            logUserIn();
                        }}>
                            Start Using the CECLution
                        </Button>
                    )}
                </div>
                <div className={classes.splitBox}>
                    <CMSContent type="video" name="home-graphic" />
                </div>
            </div>
            <div className={clsx(classes.bar, classes.blueBar)}>
                <div className={classes.center}>
                    <CMSContent type="contentitem" name="home-welcome" />
                </div>
            </div>
            <div className={classes.bar}>
                <div className={classes.center}>
                    <CMSContent type="contentitem" name="home-about" />
                </div>
            </div>
            <div className={clsx(classes.bar, classes.blueBar)}>
                <div className={classes.center}>
                    <CMSContent type="contentitem" name="home-why" />
                </div>
            </div>
            <div className={clsx(classes.bar, classes.whiteBar)}>
                <div className={classes.center}>
                    <h2 className={classes.centerText}>Ready to Start?</h2>
                    <div className={classes.signupButton}>
                         {cecl.user?.isAuthenticated ? (
                        <Button className={classes.button} onClick={goToHomePage}>
                            Sign Up
                        </Button>
                    ) : (
                        <Button className={classes.button} onClick={() => {
                            logUserIn();
                        }}>
                            Sign Up
                        </Button>
                    )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingHomePage;
