import React from 'react';
import { Helmet } from 'react-helmet';
import * as seoConfig from './SEOConfig';

export interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  keywords?: string[];
  titleTemplate?: string;
}

const SEO: React.FC<SEOProps> = ({
  title = 'Welcome',
  description,
  image = '../assets/images/cecl_logo.svg',
  url = '/',
  keywords = [],
  titleTemplate = `CECLution | `,
}) => {
  return (
    <Helmet title={title} titleTemplate={`${titleTemplate} %s`}>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta name="keywords" content={seoConfig.seoDefaultKeyWords.concat(keywords).toString()} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
    </Helmet>
  );
};

export default SEO;