import { Box } from '@material-ui/core';
import * as React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import DashboardPage from './pages/dashboard';
import UserProfilePage from './pages/profile/UserProfilePage';
import ForecastPage from './pages/forecast';
import HistoricalDataPage from './pages/historicaldata';
import ForecastHistoryPage from './pages/forecasthistory';
import AdminUserManagementPage from './pages/admin/UserManagement';
import LandingPage from './pages/landing';
import Logout from './pages/logout';
import LandingRequestDemoPage from './pages/landing/RequestDemo';
import { useSelector } from 'react-redux';
import { ApplicationState } from './store';
import { CeclApiState, UserTypes } from './store/ceclapi/types';
import BuildForecastAssumptions from './pages/forecast/assumptions/BuildForecastAssumptions';
import Auth from './pages/auth';
import TestPage from './pages/test';
import ViewForecastPage from './pages/forecast/view/ViewForecast';
import * as seoConfig from './components/seo/SEOConfig';
import SEO from './components/seo/seo';
import UsefulDocumentsPage from './pages/usefuldocuments';
import CECLViewDemoPage from './pages/viewDemo';

export const routes = [
    { id: 'test', path: '/test', name: 'Test', Component: TestPage, order: -1 },
    { id: 'logout', path: '/logout', name: 'Logout', Component: Logout, order: -1 },
    { id: 'auth', path: '/auth', name: 'Auth', Component: Auth, order: -1 },
    { id: 'landing', path: '/landing', name: 'Landing', Component: LandingPage, order: -1 },
    {
        id: 'home',
        path: '/home',
        name: 'Home',
        Component: LandingPage,
        order: -1,
        seo: { keywords: seoConfig.seoHomePageKeyWords },
    },
    {
        id: 'home',
        path: '/home/:tab',
        name: 'Home',
        Component: LandingPage,
        order: -1,
        seo: { keywords: seoConfig.seoDashboardPageKeyWords },
    },
    {
        id: 'dashboard',
        path: '/dashboard',
        name: 'Dashboard',
        Component: DashboardPage,
        order: 0,
        auth: UserTypes.Authenticated | UserTypes.Subscribed,
        seo: { title: 'Dashboard', keywords: seoConfig.seoDashboardPageKeyWords },
    },    
    {
        id: 'forecast',
        path: '/forecast',
        name: 'Build Forecast',
        Component: ForecastPage,
        order: 1,
        auth: UserTypes.Subscribed | UserTypes.Admin,
        seo: { title: 'Forecast', keywords: seoConfig.seoForecastPageKeyWords }
    },
    {
        id: 'historicaldata',
        path: '/historicaldata',
        name: 'Historical Data',
        Component: HistoricalDataPage,
        order: 3,
        auth: UserTypes.Subscribed,
        seo: { title: 'Historical', keywords: seoConfig.seoHistoricalDataKeyWords },
    },
    {
        id: 'forecasthistory',
        path: '/forecasthistory',
        name: 'Forecast History',
        Component: ForecastHistoryPage,
        order: 4,
        auth: UserTypes.Subscribed,
        seo: { title: 'History', keywords: seoConfig.seoHistoryKeyWords },
    },
    {
        id: 'usefuldocuments',
        path: '/usefuldocuments',
        name: 'Useful Documents',
        Component: UsefulDocumentsPage,
        order: 5,
        auth: UserTypes.Subscribed | UserTypes.Admin,
        seo: { title: 'Useful Documents' }
    },
    {
        id: 'viewDemo',
        path: '/viewDemo',
        name: 'View a Demo',
        Component: CECLViewDemoPage,
        order: 6,
        auth: UserTypes.Subscribed | UserTypes.Admin,
        seo: { title: 'View a Demo', keywords: seoConfig.seoDemoKeyWords },
    },
    {
        id: 'scheduledemo',
        path: '/scheduledemo',
        name: 'Schedule a Demo',
        Component: LandingRequestDemoPage,
        order: 6,
        auth: UserTypes.Subscribed | UserTypes.Authenticated,
        seo: { title: 'Demo', keywords: seoConfig.seoDemoKeyWords },
    },
    {
        id: 'usermanagement',
        path: '/usermanagement',
        name: 'User Management',
        Component: AdminUserManagementPage,
        order: 7,
        auth: UserTypes.Admin,
        seo: { title: 'Users' },
    },
    {
        id: 'profile',
        path: '/profile',
        name: 'Profile',
        Component: UserProfilePage,
        order: 8,
        auth: UserTypes.Authenticated | UserTypes.Subscribed | UserTypes.Admin,
        seo: { title: 'Profile', keywords: seoConfig.seoUserProfilePageKeyWords }
    },
    {
        id: 'landing',
        path: '/',
        name: 'Landing',
        Component: LandingPage,
        order: -1,
        seo: { keywords: seoConfig.seoHomePageKeyWords },
    },
    {
        id: 'forecastassumptions',
        path: '/forecast/assumptions/:routingNumber',
        name: 'Forecast Assumptions',
        Component: BuildForecastAssumptions,
        order: -1,
        auth: UserTypes.Subscribed | UserTypes.Admin,
        seo: { title: 'Assumptions', keywords: seoConfig.seoAssumptionsKeyWords },
    },
    {
        id: 'forecastassumptionswithid',
        path: '/forecast/assumptions/:routingNumber/:assumptionsId',
        name: 'Forecast Assumptions',
        Component: BuildForecastAssumptions,
        order: -1,
        auth: UserTypes.Subscribed | UserTypes.Admin,
        seo: { title: 'Assumptions', keywords: seoConfig.seoAssumptionsKeyWords },
    },
    {
        id: 'viewforecastwithid',
        path: '/forecast/view/:routingNumber/:id',
        name: 'View Forecast',
        Component: ViewForecastPage,
        order: -1,
        auth: UserTypes.Subscribed | UserTypes.Admin,
        seo: { title: 'Forecast', keywords: seoConfig.seoForecastPageKeyWords },
    },
    {
        id: 'viewforecastpdf',
        path: '/forecast/view/:routingNumber/:id/:pdf',
        name: 'View Forecast',
        Component: ViewForecastPage,
        order: -1,
        auth: UserTypes.Subscribed,
    },
    {
        id: 'adminedituserprofile',
        path: '/profile/:id',
        name: 'Profile',
        Component: UserProfilePage,
        order: -1,
        auth: UserTypes.Admin,
        seo: { title: 'Profile', keywords: seoConfig.seoUserProfilePageKeyWords },
    },
];

const AuthRoute = ({ component, allow, id, seo, ...rest }: any) => {
    const routeComponent = (props: any) =>
        allow ? (
            <Box>
                <SEO {...seo} />
                {React.createElement(component, props)}
            </Box>
        ) : (
            <Redirect to={{ pathname: '/auth', state: { url: location.pathname, page: 'routes', id: id } }} />
        );
    return <Route {...rest} render={routeComponent} />;
};

const Routes = withRouter(({ location }) => {
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);

    return (
        <Switch location={location}>
            {routes.map(({ path, Component, auth, id, seo }, i) => {
                const userType: UserTypes = cecl.user?.userType ?? UserTypes.Anonymous;

                return auth ? (
                    <AuthRoute
                        key={i}
                        exact
                        path={path}
                        component={Component}
                        allow={!!(auth & userType)}
                        id={id}
                        seo={seo}
                    />
                ) : (
                    <Route
                        key={i}
                        exact
                        path={path}
                        render={(routeProps) => {
                            if (routeProps.match.params.tab && seo) {
                                seo.keywords = seoConfig.findByTab(routeProps.match.params.tab);
                            }
                            return (
                                <Box>
                                    <SEO {...seo} />
                                    {/* @ts-ignore */}
                                    <Component {...routeProps} />
                                </Box>
                            );
                        }}
                    />
                );
            })}
            <Redirect to="/" />
        </Switch>
    );
});

export default Routes;
