import { Reducer } from 'redux';
import {
    CeclApiActionTypes,
    CeclApiState,
    CeclStateActionTypes,
    ForecastAssumptionsData,
    ForecastInput,
    ForecastParameter,
    ForecastSpecialClassificationLoan,
    AdminUserManagementUserUserRole,
} from './types';

export const initialState: CeclApiState = {
    data: undefined,
    loading: false,
    request: undefined,
    user: undefined,
    historicalData: { loading: false, charts: [] },
    forecastAssumptions: {
        loading: false,
        drafts: [],
        archived: [],
        configuration: undefined,
        detail: { loading: false },
        indexes: undefined,
        forecastAdminRoutingList: []
    },
    myAssumptionSet: {
        parameters: [],
        inputs: [],
        specialClassificationLoans: [],
        forecastTimeHorizon: 5,
        historicalTimeHorizon: 25,
    },
    calculatedLoss: {
        loading: false,
    },
    forecastHistory: { loading: false, summaries: [] },
    pdf: { loading: false, data: undefined },
    userList: { loading: false, users: [] },
};

export const CeclApiReducer: Reducer<CeclApiState> = (state = initialState, action) => {
    switch (action.type) {
        case CeclApiActionTypes.CECLAPI_SUBMITCOMMENTS_REQUEST:
            return { ...state, loading: true };
        case CeclApiActionTypes.CECLAPI_SUBMITCOMMENTS_SUCCESS:
            return { ...state, loading: false, data: action.payload };
        case CeclApiActionTypes.CECLAPI_SUBMITCOMMENTS_ERROR:
            return { ...state, loading: false };
        case CeclApiActionTypes.CECLAPI_AUTH_REQUEST:
            return { ...state, user: { loading: true } };
        case CeclApiActionTypes.CECLAPI_AUTH_SUCCESS:
            return { ...state, user: { ...action.payload, loading: false } };
        case CeclApiActionTypes.CECLAPI_AUTH_LOGOUT:
        case CeclApiActionTypes.CECLAPI_AUTH_DENIED:
        case CeclApiActionTypes.CECLAPI_AUTH_ERROR:
            return { ...state, user: { loading: false } };
        case CeclApiActionTypes.CECLAPI_HISTORICALDATA_REQUEST:
            return { ...state, historicalData: { loading: true } };
        case CeclApiActionTypes.CECLAPI_HISTORICALDATA_SUCCESS:
            return { ...state, historicalData: { loading: false, charts: action.payload } };
        case CeclApiActionTypes.CECLAPI_HISTORICALDATA_ERROR:
            return { ...state, historicalData: { loading: false } };
        case CeclApiActionTypes.CECLAPI_CREATEPDF_REQUEST:
            return { ...state, pdf: { loading: true } };
        case CeclApiActionTypes.CECLAPI_CREATEPDF_CLEAR:
            return { ...state, pdf: { loading: false, data: undefined } };
        case CeclApiActionTypes.CECLAPI_CREATEPDF_SUCCESS:
            return { ...state, pdf: { loading: false, data: action.payload } };
        case CeclApiActionTypes.CECLAPI_CREATEPDF_ERROR:
            return { ...state, pdf: { loading: false } };
        case CeclApiActionTypes.CECLAPI_DOCUMENT_REQUEST:
            return { ...state, pdf: { loading: true } };
        case CeclApiActionTypes.CECLAPI_DOCUMENT_SUCCESS:
            return { ...state, pdf: { loading: false, data: action.payload } };
        case CeclApiActionTypes.CECLAPI_DOCUMENT_ERROR:
            return { ...state, pdf: { loading: false } };

        case CeclApiActionTypes.CECLAPI_FORECASTTOGGLEFINAL_REQUEST:
            return { ...state, forecastHistory: { ...state.forecastHistory, loading: true } };
        case CeclApiActionTypes.CECLAPI_FORECASTTOGGLEFINAL_SUCCESS:
            return {
                ...state,
                forecastHistory: {
                    summaries: state.forecastHistory.summaries.reduce((s, c) => {
                        if (c.id === action.payload.id) {
                            c.isFinal = action.payload.isFinal;
                        }

                        s.push(c);

                        return s;
                    }, []),
                    loading: false,
                },
            };
        case CeclApiActionTypes.CECLAPI_FORECASTTOGGLEFINAL_ERROR:
            return { ...state, forecastHistory: { ...state.forecastHistory, loading: false } };

        case CeclApiActionTypes.CECLAPI_DRAFTFORECASTASSUMPTIONS_REQUEST:
            return { ...state, forecastAssumptions: { ...state.forecastAssumptions, loading: true } };
        case CeclApiActionTypes.CECLAPI_DRAFTFORECASTASSUMPTIONS_SUCCESS:
            return {
                ...state,
                forecastAssumptions: { ...state.forecastAssumptions, loading: false, drafts: action.payload },
            };
        case CeclApiActionTypes.CECLAPI_DRAFTFORECASTASSUMPTIONS_ERROR:
            return { ...state, forecastAssumptions: { ...state.forecastAssumptions, loading: false } };

        case CeclApiActionTypes.CECLAPI_AdminForecastRoutingList_SUCCESS:
            return{
                ...state,
                forecastAssumptions: { ...state.forecastAssumptions, loading: false, forecastAdminRoutingList: action.payload }
            }
        case CeclApiActionTypes.CECLAPI_AdminForecastRoutingList_ERROR:
            return { ...state, forecastAssumptions: { ...state.forecastAssumptions, loading: false } };

        case CeclApiActionTypes.CECLAPI_ARCHIVEDFORECASTASSUMPTIONS_REQUEST:
            return { ...state, forecastAssumptions: { ...state.forecastAssumptions, loading: true } };
        case CeclApiActionTypes.CECLAPI_ARCHIVEDFORECASTASSUMPTIONS_SUCCESS:
            return {
                ...state,
                forecastAssumptions: { ...state.forecastAssumptions, loading: false, archived: action.payload },
            };
        case CeclApiActionTypes.CECLAPI_ARCHIVEDFORECASTASSUMPTIONS_ERROR:
            return { ...state, forecastAssumptions: { ...state.forecastAssumptions, loading: false } };
        case CeclApiActionTypes.CECLAPI_DELETEASSUMPTIONS_REQUEST:
            return { ...state, forecastAssumptions: { ...state.forecastAssumptions, loading: false } };
        case CeclApiActionTypes.CECLAPI_DELETEASSUMPTIONS_SUCCESS:
            var myAssumptions = state.forecastAssumptions!.drafts;
            var index = myAssumptions!.findIndex((assumption) => assumption.id === action.payload.assumptionId);
            if (index > -1) {
                myAssumptions!.splice(index, 1);
            }
            return {
                ...state,
                forecastAssumptions: { ...state.forecastAssumptions, loading: false, drafts: myAssumptions },
            };
        case CeclApiActionTypes.CECLAPI_DELETEASSUMPTIONS_ERROR:
            return { ...state, forecastAssumptions: { ...state.forecastAssumptions, loading: false } };
        case CeclApiActionTypes.CECLAPI_FORECASTCONFIGURATION_REQUEST:
            return {
                ...state,
                forecastAssumptions: { ...state.forecastAssumptions, configuration: { loading: true } },
            };
        case CeclApiActionTypes.CECLAPI_FORECASTCONFIGURATION_SUCCESS:
            return {
                ...state,
                forecastAssumptions: {
                    ...state.forecastAssumptions,
                    configuration: { loading: false, config: action.payload },
                },
            };
        case CeclApiActionTypes.CECLAPI_FORECASTCONFIGURATION_ERROR:
            return {
                ...state,
                forecastAssumptions: { ...state.forecastAssumptions, configuration: { loading: false } },
            };
        case CeclApiActionTypes.CECLAPI_MESSAGESCONFIGURATION_REQUEST:
            return {
                ...state,
                messageConfig: { ...state?.messageConfig, loading: true },
            };
        case CeclApiActionTypes.CECLAPI_MESSAGESCONFIGURATION_SUCCESS:
            return {
                ...state,
                messageConfig: {
                    ...state.messageConfig,
                    messages: action.payload,
                    loading: false,
                },
            };
        case CeclApiActionTypes.CECLAPI_MESSAGESCONFIGURATION_ERROR:
            return {
                ...state,
                forecastAssumptions: { ...state.messageConfig.messages, loading: false },
            };

        case CeclApiActionTypes.CECLAPI_FORECASTASSUMPTIONSSAVE_SUCCESS:
            return {
                ...state,
                forecastAssumptions: { ...state.forecastAssumptions, detail: { loading: false, data: action.payload } },
                myAssumptionSet: { ...action.payload, id: '' },
            };

        case CeclApiActionTypes.CECLAPI_FREDINDEXES_REQUEST:
            return { ...state, forecastAssumptions: { ...state.forecastAssumptions, indexes: { loading: true } } };
        case CeclApiActionTypes.CECLAPI_FREDINDEXES_SUCCESS:
            return {
                ...state,
                forecastAssumptions: {
                    ...state.forecastAssumptions,
                    indexes: { loading: false, indexList: action.payload },
                },
            };
        case CeclApiActionTypes.CECLAPI_FREDINDEXES_ERROR:
            return { ...state, forecastAssumptions: { ...state.forecastAssumptions, indexes: { loading: false } } };
        case CeclApiActionTypes.CECLAPI_FORECASTASSUMPTIONSDETAIL_REQUEST:
            return { ...state, forecastAssumptions: { ...state.forecastAssumptions, detail: { loading: true } } };
        case CeclApiActionTypes.CECLAPI_FORECASTASSUMPTIONSDETAIL_SUCCESS:
            return {
                ...state,
                forecastAssumptions: { ...state.forecastAssumptions, detail: { loading: false, data: action.payload } },
                myAssumptionSet: { ...action.payload, id: '' },
            };
        case CeclApiActionTypes.CECLAPI_FORECASTASSUMPTIONSDETAIL_ERROR:
            return { ...state, forecastAssumptions: { ...state.forecastAssumptions, detail: { loading: false } } };
        case CeclStateActionTypes.CECLSTATE_FORECASTASSUMPTIONS_RESET:
            return {
                ...state,
                forecastAssumptions: { ...state.forecastAssumptions, detail: undefined },
                myAssumptionSet: initialState.myAssumptionSet!,
            };
        case CeclStateActionTypes.CECLSTATE_FORECASTOTHERMODELPARAMETERS_UPDATE:
            var modelParams: ForecastAssumptionsData = action.payload;
            return {
                ...state,
                myAssumptionSet: {
                    ...state.myAssumptionSet,
                    dataLag: modelParams.dataLag,
                    economicIndex: modelParams.economicIndex,
                    name: modelParams.name,
                    analysisDate: modelParams.analysisDate,
                    economicQualitativeFactorAdjustmentPercentage:
                        modelParams.economicQualitativeFactorAdjustmentPercentage
                        ? modelParams.economicQualitativeFactorAdjustmentPercentage
                        : 0,
                },
            };
        case CeclStateActionTypes.CECLSTATE_FORECASTSINGLEMODELPARAMETER_UPDATE:
            var myParameter: ForecastParameter = action.payload;
            var qualifies: boolean = true;
            if (
                !!!myParameter.historicalLookbackPeriod &&
                !!!myParameter.loanQualityFactorAdjustment &&
                !!!myParameter.prepaymentSpeedOverride
            ) {
                qualifies = false;
            }

            var parametersArray = Array.isArray(state.myAssumptionSet?.parameters)
                ? [...state.myAssumptionSet!.parameters!]
                : [];
            var myIndex = parametersArray.findIndex((param) => param.name == myParameter.name);
            if (myIndex >= 0) {
                if (qualifies) {
                    parametersArray[myIndex] = myParameter;
                } else {
                    parametersArray.splice(myIndex, 1);
                }
            } else {
                if (qualifies) {
                    parametersArray.push(myParameter);
                }
            }

            parametersArray.sort((a, b) => a.order! - b.order!);
            return { ...state, myAssumptionSet: { ...state.myAssumptionSet, parameters: parametersArray } };
        case CeclStateActionTypes.CECLSTATE_FORECASTSINGLEMONTHLYINPUT_UPDATE:
            var myInput: ForecastInput = action.payload;
            var qualifies: boolean = true;
            if (
                !!!myInput.endingBalance &&
                !!!myInput.numberOfLoans &&
                !!!myInput.averageLoanRate &&
                !!!myInput.weightedAverageRemainingMaturity &&
                !!!myInput.dollarAmountOfInterestOnlyLoans
            ) {
                qualifies = false;
            }

            var inputsArray = Array.isArray(state.myAssumptionSet?.inputs) ? [...state.myAssumptionSet!.inputs!] : [];
            var myIndex = inputsArray.findIndex((input) => input.name == myInput.name);
            if (myIndex >= 0) {
                if (qualifies) {
                    inputsArray[myIndex] = myInput;
                } else {
                    inputsArray.splice(myIndex, 1);
                }
            } else {
                if (qualifies) {
                    inputsArray.push(myInput);
                }
            }

            inputsArray.sort((a, b) => a.order! - b.order!);
            return { ...state, myAssumptionSet: { ...state.myAssumptionSet, inputs: inputsArray } };
        case CeclStateActionTypes.CECLSTATE_FORECASTSINGLESPECIALLOAN_UPDATE:
            var myLoan: ForecastSpecialClassificationLoan = action.payload;
            var qualifies: boolean = true;
            if (!!!myLoan.specificLoanLossEndingBalance && !!!myLoan.allocation) {
                qualifies = false;
            }

            var loansArray = Array.isArray(state.myAssumptionSet?.specialClassificationLoans)
                ? [...state.myAssumptionSet!.specialClassificationLoans!]
                : [];
            var myIndex = loansArray.findIndex((loan) => loan.name == myLoan.name);
            if (myIndex >= 0) {
                if (qualifies) {
                    loansArray[myIndex] = myLoan;
                } else {
                    loansArray.splice(myIndex, 1);
                }
            } else {
                if (qualifies) {
                    loansArray.push(myLoan);
                }
            }

            loansArray.sort((a, b) => a.order! - b.order!);
            return { ...state, myAssumptionSet: { ...state.myAssumptionSet, specialClassificationLoans: loansArray } };
        case CeclApiActionTypes.CECLAPI_FORECASTHISTORY_REQUEST:
            return { ...state, forecastHistory: { ...state.forecastHistory, loading: true } };
        case CeclApiActionTypes.CECLAPI_FORECASTHISTORY_SUCCESS:
            return {
                ...state,
                forecastHistory: { ...state.forecastHistory, loading: false, summaries: action.payload },
            };
        case CeclApiActionTypes.CECLAPI_FORECASTHISTORY_ERROR:
            return { ...state, forecastHistory: { ...state.forecastHistory, loading: false } };
        case CeclApiActionTypes.CECLAPI_DELETE_FORECAST_REQUEST:
            return { ...state, forecastHistory: { ...state.forecastHistory, loading: false } };
        case CeclApiActionTypes.CECLAPI_DELETE_FORECAST_SUCCESS:
            var myForecasts = state.forecastHistory!.summaries;
            index = myForecasts!.findIndex((forecast) => forecast.id === action.payload.assumptionId);
            if (index > -1) {
                myForecasts!.splice(index, 1);
            }
            return { ...state, forecastHistory: { ...state.forecastHistory, loading: false, summaries: myForecasts } };
        case CeclApiActionTypes.CECLAPI_DELETE_FORECAST_ERROR:
            return { ...state, forecastHistory: { ...state.forecastHistory, loading: false } };

        case CeclApiActionTypes.CECLAPI_CALCULATELOSS_REQUEST:
            return { ...state, calculatedLoss: { loading: true } };
        case CeclApiActionTypes.CECLAPI_CALCULATELOSS_SUCCESS:
            return { ...state, calculatedLoss: { ...action.payload, loading: false } };
        case CeclApiActionTypes.CECLAPI_CALCULATELOSS_ERROR:
            return { ...state, calculatedLoss: { loading: false } };
        case CeclApiActionTypes.CECLAPI_CALCULATELOSS_CLEAR:
            return { ...state, calculatedLoss: { loading: false, cuid: undefined } };

        case CeclApiActionTypes.CECLAPI_NCUAHISTORICALDATA_REQUEST:
            return { ...state, historicalData: { loading: true } };
        case CeclApiActionTypes.CECLAPI_NCUAHISTORICALDATA_SUCCESS:
            return { ...state, historicalData: { loading: false, charts: action.payload.charts } };
        case CeclApiActionTypes.CECLAPI_NCUAHISTORICALDATA_ERROR:
            return { ...state, historicalData: { loading: false } };
        case CeclApiActionTypes.CECLAPI_ADMIN_USERMANAGEMENT_REQUEST:
            return { ...state, userList: { ...state.userList, loading: true } };
        case CeclApiActionTypes.CECLAPI_ADMIN_USERMANAGEMENT_SUCCESS:
            return { ...state, userList: { ...state.userList, loading: false, users: action.payload } };
        case CeclApiActionTypes.CECLAPI_ADMIN_USERMANAGEMENT_ERROR:
            return { ...state, userList: { ...state.userList, loading: false } };
        case CeclApiActionTypes.CECLAPI_ADMIN_USERMANAGEMENT_SUBSCRIPTION_UPDATE:
            return { ...state, userList: { ...state.userList, loading: true } };
        case CeclApiActionTypes.CECLAPI_ADMIN_USERMANAGEMENT_SUBSCRIPTION_SUCCESS:
            const verifiedRoleName = 'CECLVerified';
            var myUsers = state.userList!.users;
            var userId = myUsers!.findIndex((u) => u.id === action.payload.userId);
            if (userId > -1) {
                var user = myUsers[userId];
                var ceclVerifiedRoles = user.userRoles?.filter((r) => r.role == verifiedRoleName);
                var currentDate = new Date().toLocaleDateString();

                if (!ceclVerifiedRoles) {
                    //no CECLVerified user roles found
                    //Create updated CECLVerified user role object
                    let verifiedUserRole = {} as AdminUserManagementUserUserRole;
                    verifiedUserRole.role = verifiedRoleName;
                    verifiedUserRole.removed = action.payload.subscribed == 'Yes' ? false : true;
                    verifiedUserRole.updated = currentDate;

                    if (!user.userRoles) {
                        //userRoles array not found on user object. Add user role array with CECLVerified role
                        let userRoleArray = [];
                        userRoleArray.push(verifiedUserRole);

                        user.userRoles = userRoleArray;
                    } //userRoles array exists. Add new CECLVerified role to array
                    else {
                        user.userRoles.push(verifiedUserRole);
                    }
                } //user object has CECLVerified user role
                else {
                    //Update CECLVerified role in userRoles
                    for (var i = 0; i < ceclVerifiedRoles.length; i++) {
                        ceclVerifiedRoles[i].removed = !(action.payload.subscribed === 'Yes');
                        ceclVerifiedRoles[i].updated = currentDate;
                    }
                }
            }
            return { ...state, userList: { ...state.userList, loading: false, users: myUsers } };
        case CeclApiActionTypes.CECLAPI_ADMIN_USERMANAGEMENT_SUBSCRIPTION_ERROR:
            return { ...state, userList: { ...state.userList, loading: false } };
        case CeclApiActionTypes.CECLAPI_NCUADASHBOARDDATA_REQUEST:
            return { ...state, dashboardData: { loading: true } };
        case CeclApiActionTypes.CECLAPI_NCUADASHBOARDDATA_SUCCESS:
            return {
                ...state,
                dashboardData: {
                    loading: false,
                    info: action.payload.dashboardInfo,
                    loans: action.payload.dashboardDictionary,
                    charts: action.payload.dashboardGraphs,
                },
            };
        case CeclApiActionTypes.CECLAPI_NCUADASHBOARDDATA_ERROR:
            return { ...state, dashboardData: { loading: false } };
        case CeclApiActionTypes.CECLAPI_USEFULDOCUMENTS_REQUEST:
            return { ...state, usefulDocumentsData: { loading: true } };
        case CeclApiActionTypes.CECLAPI_USEFULDOCUMENTS_SUCCESS:
            return { ...state, usefulDocumentsData: {
                loading: false,
                documents: action.payload,
                },
            };
        case CeclApiActionTypes.CECLAPI_USEFULDOCUMENTS_ERROR:
            return { ...state, usefulDocumentsData: { loading: false } };
        default:
            return state;
    }
};
