import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ceclapiAuthLogout, ceclapiAuthRequest } from '../../store/ceclapi/actions';
import { useHistory, useLocation } from 'react-router-dom';
import { ApplicationState, LastAction } from '../../store';
import { displayToast, saveCookiesRequest } from '../../store/config/actions';
import { ConfigState } from '../../store/config/types';
import { routes } from '../../Routes';
import { CeclApiState, UserTypes } from '../../store/ceclapi/types';

const Auth: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation<any>();
    const config = useSelector<ApplicationState, ConfigState>((state) => state.config);
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const lastAction = useSelector<ApplicationState, LastAction>((state) => state.lastAction);

    const getQueryVariable = (variable: string) => {
        const query = window.location.search.substring(1);
        const vars = query.split('&');

        for (var i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');

            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
    };

    React.useEffect(() => {
        dispatch(ceclapiAuthRequest());
    }, []);

    React.useEffect(() => {
        if (lastAction.type === '@@ceclapi/CECLAPI_AUTH_SUCCESS') {
            const userType: UserTypes = cecl.user?.userType ?? UserTypes.Anonymous;
            let route = null;
            let url: string = '/';

            // Check permissions
            if (location.state?.id) {
                url = location.state?.url;

                route = routes.filter((r) => r.id === location.state?.id)[0];
            }

            if (location.search) {
                const redirect = getQueryVariable('r');

                if (redirect) {
                    url = decodeURI(redirect);
                    route = routes.find((r) => url.startsWith(r.path));
                    if (url === '/') {
                        url = userType === UserTypes.Admin ? '/usermanagement' : '/dashboard';
                    }
                }
            }

            if (route?.auth) {
                if (!(route.auth & userType)) {
                    history.push({
                        pathname: '/dashboard',
                        state: { url: location.pathname, page: 'auth' },
                    });

                    dispatch(
                        displayToast({
                            message: `You don't have access to path ${url}.`,
                            variant: 'warning',
                        })
                    );

                    return;
                }
            }

            if (!location?.state?.id && !route?.auth && location?.state?.url) {
                url = location?.state?.url;
            }

            history.push({
                pathname: url ? url : '/dashboard',
                state: { url: location.pathname, page: 'auth' },
            });

            if (!config.isLoggedIn) {
                dispatch(displayToast({ message: 'Login successful!', variant: 'success' }));
            }

            dispatch(saveCookiesRequest({ ...config, isLoggedIn: true }));
        }

        if (lastAction.type === '@@ceclapi/CECLAPI_AUTH_DENIED') {
            dispatch(saveCookiesRequest({ ...config, isLoggedIn: false }));

            history.push('/');
        }

        if (lastAction.type === '@@ceclapi/CECLAPI_AUTH_ERROR') {
            dispatch(ceclapiAuthLogout());
            dispatch(saveCookiesRequest({ ...config, isLoggedIn: false }));

            history.push('/logout');
        }
    }, [lastAction]);

    return <div>Authenticating...</div>;
};

export default Auth;
