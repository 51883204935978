import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { ConfigActionTypes, ConfigState } from './types';
import { cookiesSuccess, saveCookiesRequest, saveCookiesSuccess } from './actions';

function* handleCookies() {
    const cookie = getCookie('config');
    const config: ConfigState = cookie !== undefined ? JSON.parse(atob(cookie)) : null;

    yield config ? put(cookiesSuccess(config)) : null;
}

function* handleSaveCookies(action: ReturnType<typeof saveCookiesRequest>) {
    const config = { ...action.payload, menu: false, log: false };

    setCookie('config', btoa(JSON.stringify(config)));

    yield put(saveCookiesSuccess());
}


function* watchCookiesRequest() {
    yield takeEvery(ConfigActionTypes.COOKIES_REQUEST, handleCookies);
}

function* watchSaveCookiesRequest() {
    yield takeEvery(ConfigActionTypes.SAVE_COOKIES_REQUEST, handleSaveCookies);
}

function* configSaga() {
    yield all([
        fork(watchCookiesRequest), 
        fork(watchSaveCookiesRequest),
    ]);
}

export function setCookie(name: string, val: string) {
    const date = new Date();
    const value = val;

    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
    document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; secure; path=/';
}

export function getCookie(name: string) {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');

    if (parts.length == 2) {
        return parts.pop()?.split(';').shift();
    }
}

export { configSaga as ConfigSaga };
