import { action } from 'typesafe-actions';
import {
    PdfRequest,
    AssumptionSummary,
    CeclApiActionTypes,
    Chart,
    ForecastAssumptionsRequest,
    HistoricalDataRequest,
    User,
    ConfigurationSet,
    Messages,
    DeleteAssumptionsRequest,
    ForecastAssumptionsDetailRequest,
    CeclStateActionTypes,
    ForecastAssumptionsData,
    ForecastInput,
    ForecastSpecialClassificationLoan,
    ForecastAssumptionsSaveRequest,
    ForecastParameter,
    FredIndex,
    FredIndexesRequest,
    ForecastHistoryRequest,
    DeleteForecastRequest,
    CalculatedLoss,
    NcuaHistoricalDataRequest,
    NcuaHistoricalDataResponse,
    AdminUserManagementRequest,
    AdminUserManagementSubscriptionUpdate,
    AdminUserManagementSubscriptionUpdateResponse,
    DashboardDataResponse,
    DocumentRequest,
    UsefulDocumentsDataResponse,
} from './types';

export const ceclapiSubmitCommentsRequest = (request: any) =>
    action(CeclApiActionTypes.CECLAPI_SUBMITCOMMENTS_REQUEST, request);
export const ceclapiSubmitCommentsSuccess = (data: any) =>
    action(CeclApiActionTypes.CECLAPI_SUBMITCOMMENTS_SUCCESS, data);
export const ceclapiSubmitCommentsError = (message: string) =>
    action(CeclApiActionTypes.CECLAPI_SUBMITCOMMENTS_ERROR, message);

export const ceclapiAuthRequest = () => action(CeclApiActionTypes.CECLAPI_AUTH_REQUEST);
export const ceclapiAuthSuccess = (data: User) => action(CeclApiActionTypes.CECLAPI_AUTH_SUCCESS, data);
export const ceclapiAuthError = (message: string) => action(CeclApiActionTypes.CECLAPI_AUTH_ERROR, message);
export const ceclapiAuthDenied = () => action(CeclApiActionTypes.CECLAPI_AUTH_DENIED);
export const ceclapiAuthLogout = () => action(CeclApiActionTypes.CECLAPI_AUTH_LOGOUT);

export const ceclapiCreatePdfRequest = (request: PdfRequest) =>
    action(CeclApiActionTypes.CECLAPI_CREATEPDF_REQUEST, request);
export const ceclapiCreatePdfClear = () => action(CeclApiActionTypes.CECLAPI_CREATEPDF_CLEAR);
export const ceclapiCreatePdfCompileData = (request: PdfRequest) =>
    action(CeclApiActionTypes.CECLAPI_CREATEPDF_COMPILEDATA, request);
export const ceclapiCreatePdfSuccess = (data: any) => action(CeclApiActionTypes.CECLAPI_CREATEPDF_SUCCESS, data);
export const ceclapiCreatePdfError = (message: string) => action(CeclApiActionTypes.CECLAPI_CREATEPDF_ERROR, message);

export const ceclapiHistoricalDataRequest = (request: HistoricalDataRequest) =>
    action(CeclApiActionTypes.CECLAPI_HISTORICALDATA_REQUEST, request);
export const ceclapiHistoricalDataSuccess = (charts: Chart[]) =>
    action(CeclApiActionTypes.CECLAPI_HISTORICALDATA_SUCCESS, charts);
export const ceclapiHistoricalDataError = (message: string) =>
    action(CeclApiActionTypes.CECLAPI_HISTORICALDATA_ERROR, message);

export const ceclapiForecastToggleFinalRequest = (forecastId: string) =>
    action(CeclApiActionTypes.CECLAPI_FORECASTTOGGLEFINAL_REQUEST, forecastId);
export const ceclapiForecastToggleFinalSuccess = (data: any) =>
    action(CeclApiActionTypes.CECLAPI_FORECASTTOGGLEFINAL_SUCCESS, data);
export const ceclapiForecastToggleFinalError = (message: string) =>
    action(CeclApiActionTypes.CECLAPI_FORECASTTOGGLEFINAL_ERROR, message);

export const ceclapiDocumentRequest = (request: DocumentRequest) =>
    action(CeclApiActionTypes.CECLAPI_DOCUMENT_REQUEST, request);
export const ceclapiDocumentSuccess = (data: any) => action(CeclApiActionTypes.CECLAPI_DOCUMENT_SUCCESS, data);
export const ceclapiDocumentError = (message: string) => action(CeclApiActionTypes.CECLAPI_DOCUMENT_ERROR, message);

export const ceclapiDraftForecastAssumptionsRequest = (request: ForecastAssumptionsRequest) =>
    action(CeclApiActionTypes.CECLAPI_DRAFTFORECASTASSUMPTIONS_REQUEST, request);
export const ceclapiDraftForecastAssumptionsSuccess = (summaries: AssumptionSummary[]) =>
    action(CeclApiActionTypes.CECLAPI_DRAFTFORECASTASSUMPTIONS_SUCCESS, summaries);
export const ceclapiDraftForecastAssumptionsError = (message: string) =>
    action(CeclApiActionTypes.CECLAPI_DRAFTFORECASTASSUMPTIONS_ERROR, message);

export const ceclapiAdminForecastRoutingListRequest = () =>
    action(CeclApiActionTypes.CECLAPI_AdminForecastRoutingList_REQUEST);
export const ceclapiAdminForecastRoutingListRequestSuccess = (resp: []) =>
    action(CeclApiActionTypes.CECLAPI_AdminForecastRoutingList_SUCCESS, resp);
export const ceclapiAdminForecastRoutingListRequestError = (message: string) =>
    action(CeclApiActionTypes.CECLAPI_AdminForecastRoutingList_ERROR, message);

export const ceclapiArchivedForecastAssumptionsRequest = (request: ForecastAssumptionsRequest) =>
    action(CeclApiActionTypes.CECLAPI_ARCHIVEDFORECASTASSUMPTIONS_REQUEST, request);
export const ceclapiArchivedForecastAssumptionsSuccess = (summaries: AssumptionSummary[]) =>
    action(CeclApiActionTypes.CECLAPI_ARCHIVEDFORECASTASSUMPTIONS_SUCCESS, summaries);
export const ceclapiArchivedForecastAssumptionsError = (message: string) =>
    action(CeclApiActionTypes.CECLAPI_ARCHIVEDFORECASTASSUMPTIONS_ERROR, message);

export const ceclapiDeleteAssumptionsRequest = (request: DeleteAssumptionsRequest) =>
    action(CeclApiActionTypes.CECLAPI_DELETEASSUMPTIONS_REQUEST, request);
export const ceclapiDeleteAssumptionsSuccess = (successResult: any) =>
    action(CeclApiActionTypes.CECLAPI_DELETEASSUMPTIONS_SUCCESS, successResult);
export const ceclapiDeleteAssumptionsError = (errorResult: any) =>
    action(CeclApiActionTypes.CECLAPI_DELETEASSUMPTIONS_ERROR, errorResult);

export const ceclApiForecastConfigurationRequest = () =>
    action(CeclApiActionTypes.CECLAPI_FORECASTCONFIGURATION_REQUEST);
export const ceclApiForecastConfigurationSuccess = (configuration: ConfigurationSet) =>
    action(CeclApiActionTypes.CECLAPI_FORECASTCONFIGURATION_SUCCESS, configuration);
export const ceclApiForecastConfigurationError = (message: string) =>
    action(CeclApiActionTypes.CECLAPI_FORECASTCONFIGURATION_ERROR, message);

//message configuration
export const ceclApiMessagesConfigurationRequest = () =>
    action(CeclApiActionTypes.CECLAPI_MESSAGESCONFIGURATION_REQUEST);
export const ceclApiMessagesConfigurationSuccess = (messages: Messages[]) =>
    action(CeclApiActionTypes.CECLAPI_MESSAGESCONFIGURATION_SUCCESS, messages);
export const ceclApiMessagesConfigurationError = (message: string) =>
    action(CeclApiActionTypes.CECLAPI_MESSAGESCONFIGURATION_ERROR, message);

export const ceclapiFredIndexesRequest = (request: FredIndexesRequest) =>
    action(CeclApiActionTypes.CECLAPI_FREDINDEXES_REQUEST, request);
export const ceclapiFredIndexesSuccess = (indexes: FredIndex[]) =>
    action(CeclApiActionTypes.CECLAPI_FREDINDEXES_SUCCESS, indexes);
export const ceclapiFredIndexesError = (message: string) =>
    action(CeclApiActionTypes.CECLAPI_FREDINDEXES_ERROR, message);

export const ceclapiForecastAssumptionsDetailRequest = (request: ForecastAssumptionsDetailRequest) =>
    action(CeclApiActionTypes.CECLAPI_FORECASTASSUMPTIONSDETAIL_REQUEST, request);
export const ceclapiForecastAssumptionsDetailSuccess = (detail: ForecastAssumptionsData) =>
    action(CeclApiActionTypes.CECLAPI_FORECASTASSUMPTIONSDETAIL_SUCCESS, detail);
export const ceclapiForecastAssumptionsDetailError = (message: string) =>
    action(CeclApiActionTypes.CECLAPI_FORECASTASSUMPTIONSDETAIL_ERROR, message);

export const ceclstateForecastAssumptionsReset = () => action(CeclStateActionTypes.CECLSTATE_FORECASTASSUMPTIONS_RESET);

export const ceclstateForecastOtherModelParametersUpdate = (modelParams: ForecastAssumptionsData) =>
    action(CeclStateActionTypes.CECLSTATE_FORECASTOTHERMODELPARAMETERS_UPDATE, modelParams);
export const ceclstateForecastSingleModelParameterUpdate = (param: ForecastParameter) =>
    action(CeclStateActionTypes.CECLSTATE_FORECASTSINGLEMODELPARAMETER_UPDATE, param);

export const ceclstateForecastSingleMonthlyInputUpdate = (input: ForecastInput) =>
    action(CeclStateActionTypes.CECLSTATE_FORECASTSINGLEMONTHLYINPUT_UPDATE, input);
export const ceclstateForecastSingleSpecialLoanUpdate = (specialLoan: ForecastSpecialClassificationLoan) =>
    action(CeclStateActionTypes.CECLSTATE_FORECASTSINGLESPECIALLOAN_UPDATE, specialLoan);

export const ceclapiForecastAssumptionsSaveRequest = (request: ForecastAssumptionsSaveRequest) =>
    action(CeclApiActionTypes.CECLAPI_FORECASTASSUMPTIONSSAVE_REQUEST, request);
export const ceclapiForecastAssumptionsSaveSuccess = (message: string) =>
    action(CeclApiActionTypes.CECLAPI_FORECASTASSUMPTIONSSAVE_SUCCESS, message);
export const ceclapiForecastAssumptionsSaveError = (message: string) =>
    action(CeclApiActionTypes.CECLAPI_FORECASTASSUMPTIONSSAVE_ERROR, message);

export const ceclapiForecastHistoryRequest = (request: ForecastHistoryRequest) =>
    action(CeclApiActionTypes.CECLAPI_FORECASTHISTORY_REQUEST, request);
export const ceclapiForecastHistorySuccess = (summaries: AssumptionSummary[]) =>
    action(CeclApiActionTypes.CECLAPI_FORECASTHISTORY_SUCCESS, summaries);
export const ceclapiForecastHistoryError = (message: string) =>
    action(CeclApiActionTypes.CECLAPI_FORECASTHISTORY_ERROR, message);

export const ceclapiDeleteForecastRequest = (request: DeleteForecastRequest) =>
    action(CeclApiActionTypes.CECLAPI_DELETE_FORECAST_REQUEST, request);
export const ceclapiDeleteForecastSuccess = (successResult: any) =>
    action(CeclApiActionTypes.CECLAPI_DELETE_FORECAST_SUCCESS, successResult);
export const ceclapiDeleteForecastError = (errorResult: any) =>
    action(CeclApiActionTypes.CECLAPI_DELETE_FORECAST_ERROR, errorResult);

export const ceclapiCalculateLossRequest = (forecast: ForecastAssumptionsData) =>
    action(CeclApiActionTypes.CECLAPI_CALCULATELOSS_REQUEST, forecast);
export const ceclapiCalculateLossSuccess = (response: CalculatedLoss) =>
    action(CeclApiActionTypes.CECLAPI_CALCULATELOSS_SUCCESS, response);
export const ceclapiCalculateLossError = (errorResult: any) =>
    action(CeclApiActionTypes.CECLAPI_CALCULATELOSS_ERROR, errorResult);
export const ceclapiCalculateLossClear = () => action(CeclApiActionTypes.CECLAPI_CALCULATELOSS_CLEAR);

export const ceclapiNcuaHistoricalDataRequest = (request: NcuaHistoricalDataRequest) =>
    action(CeclApiActionTypes.CECLAPI_NCUAHISTORICALDATA_REQUEST, request);
export const ceclapiNcuaHistoricalDataSuccess = (aggregations: NcuaHistoricalDataResponse) =>
    action(CeclApiActionTypes.CECLAPI_NCUAHISTORICALDATA_SUCCESS, aggregations);
export const ceclapiNcuaHistoricalDataError = (message: string) =>
    action(CeclApiActionTypes.CECLAPI_NCUAHISTORICALDATA_ERROR, message);

export const ceclapiNcuaDashboardDataRequest = () => action(CeclApiActionTypes.CECLAPI_NCUADASHBOARDDATA_REQUEST);
export const ceclapiNcuaDashboardDataSuccess = (dashboardInfo: DashboardDataResponse) =>
    action(CeclApiActionTypes.CECLAPI_NCUADASHBOARDDATA_SUCCESS, dashboardInfo);
export const ceclapiNcuaDashboardDataError = (message: string) =>
    action(CeclApiActionTypes.CECLAPI_NCUADASHBOARDDATA_ERROR, message);

export const ceclapiAdminUserManagementRequest = (request: AdminUserManagementRequest) =>
    action(CeclApiActionTypes.CECLAPI_ADMIN_USERMANAGEMENT_REQUEST, request);
export const ceclapiAdminUserManagementSuccess = (res: any) =>
    action(CeclApiActionTypes.CECLAPI_ADMIN_USERMANAGEMENT_SUCCESS, res);
export const ceclapiAdminUserManagementError = (message: string) =>
    action(CeclApiActionTypes.CECLAPI_ADMIN_USERMANAGEMENT_ERROR, message);

export const ceclapiAdminUserManagementSubscriptionUpdate = (request: AdminUserManagementSubscriptionUpdate) =>
    action(CeclApiActionTypes.CECLAPI_ADMIN_USERMANAGEMENT_SUBSCRIPTION_UPDATE, request);
export const ceclapiAdminUserManagementSubscriptionSuccess = (res: AdminUserManagementSubscriptionUpdateResponse) =>
    action(CeclApiActionTypes.CECLAPI_ADMIN_USERMANAGEMENT_SUBSCRIPTION_SUCCESS, res);
export const ceclapiAdminUserManagementSubscriptionError = (message: string) =>
    action(CeclApiActionTypes.CECLAPI_ADMIN_USERMANAGEMENT_SUBSCRIPTION_ERROR, message);

export const ceclapiUsefulDocumentsRequest = () =>
    action(CeclApiActionTypes.CECLAPI_USEFULDOCUMENTS_REQUEST);
export const ceclapiUsefulDocumentsSuccess = (res: UsefulDocumentsDataResponse) =>
    action(CeclApiActionTypes.CECLAPI_USEFULDOCUMENTS_SUCCESS, res);
export const ceclapiUsefulDocumentsError = (message: string) =>
    action(CeclApiActionTypes.CECLAPI_USEFULDOCUMENTS_ERROR, message);
