import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ceclapiAuthLogout } from '../../store/ceclapi/actions';
import { useHistory } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { CeclApiState } from '../../store/ceclapi/types';
import { ConfigState } from '../../store/config/types';
import { saveCookiesRequest } from '../../store/config/actions';
import { logUserOut } from '../../utils/helpers';

const Logout: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const config = useSelector<ApplicationState, ConfigState>((state) => state.config);

    React.useEffect(() => {
        dispatch(ceclapiAuthLogout());
        dispatch(saveCookiesRequest({ ...config, isLoggedIn: false }));
        logUserOut();
    }, []);

    return <div>Logout</div>;
};

export default Logout;
