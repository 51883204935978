import { createStyles, FormControl, Grid, Hidden, makeStyles, MenuItem, Select, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import Table, { Column } from '../../components/table/Table';
import Panel from '../../components/panel/Panel';
import { ceclapiNcuaDashboardDataRequest } from '../../store/ceclapi/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { CeclApiState, DashboardInfo } from '../../store/ceclapi/types';
import LoadingSpinner from '../../components/loadingspinner/LoadingSpinner';
import QrtlyDepositGrowth from '../../containers/charts/dashboard/QrtlyDepositGrowth';
import NetChargeOffsAvgLoans from '../../containers/charts/dashboard/NetChargeOffsAvgLoans';
import CuLoanComposition from '../../containers/charts/dashboard/CuLoanComposition';
import CuDepositComposition from '../../containers/charts/dashboard/CuDepositComposition';
import PeerLoanComposition from '../../containers/charts/dashboard/PeerLoanComposition';
import PeerDepositComposition from '../../containers/charts/dashboard/PeerDepositComposition';
import NetWorthAndROA from '../../containers/charts/dashboard/NetWorthAndROA';
import QrtlyGrowthAssetsAndEquity from '../../containers/charts/dashboard/QrtlyGrowthAssetsAndEquity';
import QrtlyLoanGrowth from '../../containers/charts/dashboard/QrtlyLoanGrowth';
import DelinquentLoansTotalLoans from '../../containers/charts/dashboard/DelinquentLoansTotalLoans';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        panels: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flex: 1,
        },
        columns: {
            display: 'flex',
            flex: 1,
        },
        tablePanel: {
            margin: theme.spacing(1),
            padding: 0,
        },
        grid:{
            maxWidth:1800,
        },
        bold:{
            fontWeight: 'bold',
        }
    })
);

const DashboardPage: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const [isLoading, setIsLoading] = React.useState(false);
    const [myDashboardInfo, setMyDashboardInfo] = React.useState(undefined as DashboardInfo);
    const [loanInfo, setLoanInfo] = React.useState(undefined as Map<string, number>);
    const [keyRatioRows, setKeyRatioRows] = React.useState([] as any);
    const [financialSummaryRows, setFinancialSummaryRows] = React.useState([] as any);
    const [loanBalanceRows, setLoanBalanceRows] = React.useState([] as any); 
    const [graphNameOne, setGraphNameOne] = React.useState(localStorage.getItem('dashboardGraphOne') || 'Net Worth & ROA');
    const [graphNameTwo, setGraphNameTwo] = React.useState(localStorage.getItem('dashboardGraphTwo') || 'Quarterly Growth - Assets and Equity');
    const [graphNameThree, setGraphNameThree] = React.useState(localStorage.getItem('dashboardGraphThree') || 'Loan Composition - CU');

    React.useEffect(() => {
        if(!cecl.dashboardData?.info){
            dispatch(ceclapiNcuaDashboardDataRequest());
        }
        else{
            setMyDashboardInfo(cecl.dashboardData?.info);
        }
    }, [])

    React.useEffect(() => {
        if(cecl.dashboardData){
            setIsLoading(cecl.dashboardData!.loading);
        }
    }, [cecl.dashboardData])

    React.useEffect(() => {
        if(cecl.dashboardData?.info){
            setMyDashboardInfo(cecl.dashboardData?.info);
        }
    }, [cecl.dashboardData?.info])

    React.useEffect(() => {
        if(cecl.dashboardData?.loans){
            setLoanInfo(cecl.dashboardData?.loans);
        }
    }, [cecl.dashboardData?.loans])

    React.useEffect(() => {
        mapDashboardInfo();
    }, [myDashboardInfo])

    React.useEffect(() => {
        localStorage.setItem('dashboardGraphOne', graphNameOne);
    }, [graphNameOne])

    React.useEffect(() => {
        localStorage.setItem('dashboardGraphTwo', graphNameTwo);
    }, [graphNameTwo])

    React.useEffect(() => {
        localStorage.setItem('dashboardGraphThree', graphNameThree);
    }, [graphNameThree])

    function mapDashboardInfo(){
        if(!myDashboardInfo){
            return;
        }

        var myKeyRatios = [
            { name: 'EARNINGS', value: null, bold: true },
            { name: 'Asset Yield', value: (myDashboardInfo.assetYields * 100).toFixed(2) + '%' },
            { name: 'Cost of Funds', value: (myDashboardInfo.costOfFunds * 100).toFixed(2) + '%' },
            { name: 'Net Operating Expense', value: (myDashboardInfo.netOperatingExpense * 100).toFixed(2) + '%' },
            { name: 'Provision Expense', value: (myDashboardInfo.provisionExpense * 100).toFixed(2) + '%' },
            { name: 'LIQUIDITY', value: null, bold: true },
            { name: 'Loans to Shares', value: (myDashboardInfo.loansToShares * 100).toFixed(2) + '%' },
            { name: 'Loans to Assets', value: (myDashboardInfo.loansToAssets * 100).toFixed(2) + '%' },
            { name: 'Cash & S-T Inv. To Assets', value: (myDashboardInfo.cashAndSTInvToAssets * 100).toFixed(2) + '%'},
            { name: 'LOAN QUALITY', value: null, bold: true },
            { name: 'Delinquent Loans to Net Loans', value: (myDashboardInfo.delinquentLoansToNetLoans * 100).toFixed(2) + '%' },
            { name: '2-6 Mths Delinquent', value: '$' +  myDashboardInfo.twoToSixMonthsDelinquent.toLocaleString() },
            { name: '6-12 Mths Delinquent', value:  '$' + myDashboardInfo.sixToTwelveMonthsDelinquent.toLocaleString() },
            { name: '>12 Mths Delinquent', value:  '$' + myDashboardInfo.greaterThanTwelveMonthsDelinquent.toLocaleString() },
        ];

        var myFinancialSummaries = [
            { name: 'Total Assets', value: myDashboardInfo.totalAssets },
            { name: 'Cash', value: myDashboardInfo.cash },
            { name: 'Total Investments', value: myDashboardInfo.totalInvestments },
            { name: 'Net Loans', value: myDashboardInfo.netLoans },
            { name: 'Total Deposits', value: myDashboardInfo.totalDeposits, bold: true },
            { name: 'Total Borrowings', value: myDashboardInfo.totalBorrowings },
            { name: 'Total Equity', value: myDashboardInfo.totalEquity },
            { name: 'YTD Net Income', value: myDashboardInfo.ytdNetIncome, bold: true },
        ];

        var myLoanBalances = Object.entries(loanInfo).map(function([key, value]) {
            return { name: key, value: value };
        });

        setKeyRatioRows(myKeyRatios);
        setFinancialSummaryRows(myFinancialSummaries);
        setLoanBalanceRows(myLoanBalances);
    }

    const keyRatioColumns: Column[] = [
        { name: 'name', numeric: false, label: 'Key Ratios' },
        { name: 'value', numeric: false, label: '' },
    ];

    const financialSummaryColumns: Column[] = [
        { name: 'name', numeric: false, label: 'FINANCIAL SUMMARY' },
        { name: 'value', numeric: true, label: '', decorator: '$', decoratorPlacement: 'start'},
    ];

    const loanBalanceColumns: Column[] = [
        { name: 'name', numeric: false, label: 'Loan Balances' },
        { name: 'value', numeric: true, label: '', decorator: '$', decoratorPlacement: 'start'},
    ]

    const chartSelections: object = 
        {
            'Net Worth & ROA': <NetWorthAndROA/>,
            'Quarterly Growth - Assets and Equity': <QrtlyGrowthAssetsAndEquity/>,
            'Loan Composition - CU' : <CuLoanComposition/>,
            'Loan Composition - Peer': <PeerLoanComposition/>,
            'Deposit Composition - CU': <CuDepositComposition/>,
            'Deposit Composition - Peer': <PeerDepositComposition/>,
            'Qrtly Deposit Growth': <QrtlyDepositGrowth/>,
            'Qrtly Loan Growth': <QrtlyLoanGrowth/>,
            'Net Charge Offs/Avg Loans': <NetChargeOffsAvgLoans/>,
            'Delinquent Loans/Total Loans': <DelinquentLoansTotalLoans/>,
        };

    const handleGraphNameChange = (event: React.ChangeEvent<{ value: unknown }>, graphNum: number) => {
        switch(graphNum){
            case 1:
                setGraphNameOne(event.target.value as string);
                break;
            case 2:
                setGraphNameTwo(event.target.value as string);
                break;
            case 3:
                setGraphNameThree(event.target.value as string);
                break;
            default:
                break;
        }
    };

    return (
        <div className={classes.grid}>
            <LoadingSpinner loading={isLoading} />
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} xl={6}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item >
                            <Panel>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item container direction="column">
                                        <Grid item>
                                            <Typography className={classes.bold}>{cecl.user?.creditUnionName}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography><span className={classes.bold}>Peer Group:</span> {myDashboardInfo?.ceclPeerGroupDescription ? myDashboardInfo.ceclPeerGroupDescription : ""}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography><span className={classes.bold}>Charter #:</span> {myDashboardInfo?.charterNumber ? myDashboardInfo.charterNumber : ""}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography><span className={classes.bold}>Call Report Date:</span> {myDashboardInfo?.cycleDate ? new Date(myDashboardInfo.cycleDate).toLocaleDateString() : ""}</Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Panel>
                        </Grid>
                        <Grid item >
                            <Panel className={classes.tablePanel}>
                                <Table
                                    columns={financialSummaryColumns}
                                    dense={true}
                                    rows={financialSummaryRows}
                                    keyColumn="name"
                                />
                            </Panel>
                        </Grid>
                        <Grid item >
                            <Panel className={classes.tablePanel}>
                                <Table
                                    columns={loanBalanceColumns}
                                    dense={true}
                                    rows={loanBalanceRows}
                                    keyColumn="name"
                                />
                            </Panel>
                        </Grid>
                        <Grid item >
                            <Panel>
                                <FormControl>
                                    <Select
                                        labelId="graphtwo-select-label"
                                        id="graphtwo-select"
                                        value={graphNameTwo}
                                        onChange={(e) => handleGraphNameChange(e, 2)}
                                    >
                                        {Object.keys(chartSelections).map((keyName, i) => {
                                            return (
                                                <MenuItem key={i} value={keyName}>
                                                    {keyName}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                                <br />
                                <br />
                                {chartSelections[graphNameTwo]}
                            </Panel>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} xl={6}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item >
                            <Panel className={classes.tablePanel}>
                                <Table columns={keyRatioColumns} rows={keyRatioRows} dense={true} keyColumn="name" />
                            </Panel>
                        </Grid>
                        <Grid item >
                            <Panel>
                                <FormControl>
                                    <Select
                                        labelId="graphone-select-label"
                                        id="graphone-select"
                                        value={graphNameOne}
                                        onChange={(e) => handleGraphNameChange(e, 1)}
                                    >
                                        {Object.keys(chartSelections).map((keyName, i) => {
                                            return (
                                                <MenuItem key={i} value={keyName}>
                                                    {keyName}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                                <br />
                                <br />
                                {chartSelections[graphNameOne]}
                            </Panel>
                        </Grid>
                        <Grid item >
                            <Panel>
                                <FormControl>
                                    <Select
                                        labelId="graphthree-select-label"
                                        id="graphthree-select"
                                        value={graphNameThree}
                                        onChange={(e) => handleGraphNameChange(e, 3)}
                                    >
                                        {Object.keys(chartSelections).map((keyName, i) => {
                                            return (
                                                <MenuItem key={i} value={keyName}>
                                                    {keyName}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                                <br />
                                <br />
                                {chartSelections[graphNameThree]}
                            </Panel>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default DashboardPage;
