﻿import { Grid, InputAdornment, lighten, TextField } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Search } from '@material-ui/icons';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Panel from '../../components/panel/Panel';
import Table, { Column, TableProps } from '../../components/table/Table';
import { ApplicationState } from '../../store';
import { ceclapiAdminUserManagementRequest, ceclapiAdminUserManagementSubscriptionUpdate } from '../../store/ceclapi/actions';
import { CeclApiState } from '../../store/ceclapi/types';
import LoadingSpinner from '../../components/loadingspinner/LoadingSpinner';
import Button from '../../components/button/Button';
import { useHistory } from 'react-router-dom';
import { colors } from '../../theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        panels: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flex: 1,
            margin: theme.spacing(1),
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 100,
        },
        title: {
            fontSize: 20,
            fontFamily: 'Montserrat',
            fontWeight: 'bolder',
            margin: 10,
            textAlign: 'center'
        },
        controlPanel: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        controlPanelTitle: {
            alignSelf: 'center',
        },
        controlPanelForm: {
            flex: 1,
        },
        panel: {
            margin: theme.spacing(2),
        },
        tablePanel: {
            padding: 0,
            '& .MuiPaper-root':{
                margin: '0',
            },
        },
        grid: {
            maxWidth: 1800,
        },
        emptyTablePanel: {
            boxShadow: 'none',
            textAlign: 'center',
            fontStyle: 'italic',
        },
        searchBar: {
            width: '100%',
            padding: 'none',
        },
        editLink: {
            color: colors.blue,
            textDecoration: 'none',
            fontWeight: 600,
            backgroundColor: colors.white,
            padding: 5,
            lineHeight: 1,
            width: theme.spacing(10),
            fontSize: 14,
            '&:hover': {
                backgroundColor: lighten(colors.blue, 0.9),
            },
        },
        root: {
            '& > *': {
                borderBottom: 'unset',
            },
        },               
    })
);

const AdminUserManagementPage: React.FC = () => {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const defaultColumns: Column[] = [
        { name: "RoutingNumber", label: "RT #", numeric: false, sortable: true, filterable: true, maxWidth: 150 },
        { name: "CreditUnionName", label: "Credit Union", numeric: false, sortable: true, filterable: true, maxWidth: 300 },
        { name: "LastName", label: "Last Name", numeric: false, sortable: true, filterable: true, maxWidth: 200 },
        { name: "FirstName", label: "First Name", numeric: false, sortable: true, filterable: true, maxWidth: 200 },
        { name: "SubscriptionChangeDate", label: "Subscription Change Date", numeric: false, isDate: true, sortable: true, filterable: true },
        { name: "Subscribed", label: "Subscribed", numeric: false, sortable: true, filterable: true, centerHeader: true, centerBody: true, isSelect: true },
        { name: "Origination", label: "Origination", numeric: false, sortable: true, filterable: true, centerHeader: true, centerBody: true },
        { name: "Actions", label: "Actions", numeric: false, sortable: false, filterable: false, centerHeader: true, centerBody: true },
    ];
    const defaultCollapsibleColumns: Column[] = [ //Collapsible
        { name: "BlankColumn", label: "", numeric: false, sortable: false, filterable: false },
        { name: "Email", label: "Email", numeric: false, sortable: false, filterable: false },
        { name: "Phone", label: "Phone", numeric: false, sortable: false, filterable: false },
        { name: "JobTitle", label: "Job Title", numeric: false, sortable: false, filterable: false },
        { name: "AccountCreationDate", label: "Account Creation Date", numeric: false, isDate: true, sortable: false, filterable: false },
    ];
    const [tableProps, setTableProps] = React.useState({
        loading: true,
        dense: true,
        keyColumn: "UserId",
        rows: [],
        columns: [],
        pageSize: 0,
        collapsible: true,
        collapsibleRows:[],
        collapsibleColumns: [],
        defaultFilterValue: [{ name: 'Origination', text: 'CECL'}]
    } as TableProps);

    
    const [searchBarInput, setSearchbarInput] = React.useState('');
    const [myRows, setMyRows] = React.useState([] as any[]);
    const [myCollapsibleRows, setMyCollapsibleRows] = React.useState([] as any[]); //Collapsible

    React.useEffect(() => {
        if (!cecl.userList?.users || !cecl.userList?.users?.length) {
            setTableProps({ ...tableProps, loading: true });
            dispatch(ceclapiAdminUserManagementRequest(null));
        }
    }, []);

    React.useEffect(() => {
        mapTableDataFromUserManagementUsers();
    }, [cecl.userList])

    React.useEffect(() => {
        filterRowsBySearchInput(null);
    }, [searchBarInput])

    const handleSearchbarInputChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSearchbarInput(event.target.value as string);
    }

    const handleSubscribedChange = (userId: string, val: string) => {
        dispatch(ceclapiAdminUserManagementSubscriptionUpdate({ id: userId, subscribed: val }));
    }

    function mapTableDataFromUserManagementUsers() {
        if (!cecl?.userList?.users?.length) {
            if (cecl!.userList?.loading) {
                setTableProps({ ...tableProps, loading: true })
                return;
            }
            else {
                setTableProps({ ...tableProps, columns: defaultColumns, collapsibleColumns: defaultCollapsibleColumns, loading: false })
            }
            return;
        }

        //non-admin users sorted by account create date descending
        var sortedUsers = cecl?.userList?.users?.filter(u => !(u.userRoles && u.userRoles.length > 0 && u.roles.some(r => r === 'CECLAdmin'))).sort((a, b) => new Date(a.createdDate) > new Date(b.createdDate) ? -1 : 1);

        var newRows = sortedUsers?.map((user) => { //sort by createdDate desc
            const verifiedRoleName = "CECLVerified";
            var ceclVerifiedRoles = user.userRoles?.filter(r => r.role == verifiedRoleName).sort((a,b) => (new Date(a.updated) > new Date(b.updated)) ? 1 : ((new Date(b.updated) > new Date(a.updated)) ? -1 : 0));

            var ceclVerified = "No";
            var ceclVerifiedDate = "";
            
            if (ceclVerifiedRoles && ceclVerifiedRoles.length > 0){
                var latestCeclVerifiedRole = ceclVerifiedRoles[ceclVerifiedRoles.length - 1];
                if(!latestCeclVerifiedRole.removed){
                    ceclVerified = "Yes";
                }
                ceclVerifiedDate = new Date(latestCeclVerifiedRole.updated).toLocaleDateString();
            }

            var obj: any = {};
            obj["UserId"] = user.id;
            obj["RoutingNumber"] = user.rtNumber;
            obj["CreditUnionName"] = user.creditUnionName;
            obj["LastName"] = user.surname;
            obj["FirstName"] =  user.givenName;
            obj["SubscriptionChangeDate"] = ceclVerifiedDate;
            obj["CeclVerified"] = ceclVerified;
            obj["Subscribed"] =                 
                <div>
                    <select value={obj["CeclVerified"]} onChange={(e) => {handleSubscribedChange(user.id, e.target.value)}}> 
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
            obj["Origination"] = user.originatingApplication;  
            obj["Actions"] = 
                <div>
                    <Button
                        variant="text"
                        className={classes.editLink}
                        onClick={() => history.push(`/profile/${user.id}`)}
                    >
                        Edit
                    </Button>
                </div>
            
            //Collapsible row
            obj["BlankColumn"] = '';
            obj["Email"] = user.emailAddress;
            obj["Phone"] = user.phoneNumber;
            obj["JobTitle"] = user.jobTitle;
            obj["AccountCreationDate"] = new Date(user.createdDate).toLocaleDateString();

            return obj;        
        });

        setMyRows(newRows);

        if(searchBarInput === '') {
            setTableProps({ ...tableProps, columns: defaultColumns, rows: newRows, collapsibleColumns: defaultCollapsibleColumns, loading: false, pageSize: 15 });
        }
        else 
        {
            filterRowsBySearchInput(newRows);
        }
    }

    function filterRowsBySearchInput(updatedRows) {
        var rows = !updatedRows ? myRows : updatedRows; //introduced updatedRows param due to myRows update race condition
        if (!rows.length) {
            return;
        }
        if (!searchBarInput.length) {
            setTableProps({ ...tableProps, rows: rows });
        }
        else {
            var newTableRows = rows.filter(row =>
                (row["RoutingNumber"] as string)?.toUpperCase().includes(searchBarInput.toUpperCase())
                || (row["CreditUnionName"] as string)?.toUpperCase().includes(searchBarInput.toUpperCase())
                || (row["LastName"] as string)?.toUpperCase().includes(searchBarInput.toUpperCase())
                || (row["FirstName"] as string)?.toUpperCase().includes(searchBarInput.toUpperCase()));

            setTableProps({ ...tableProps, columns: defaultColumns, rows: newTableRows, collapsibleColumns: defaultCollapsibleColumns, loading: false });
        }
    }

    return (
        <div className={classes.grid}>
            <Grid container direction="column" spacing={2}>
                <Grid item container spacing={4}>
                    <Grid item xs={12}>
                        <Panel>
                            <TextField
                                id="usermanagement-searchbar"
                                className={classes.searchBar}
                                variant="standard"
                                type="search"
                                label="Search User Management Data"
                                value={searchBarInput}
                                onChange={handleSearchbarInputChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true,
                                }}
                                InputLabelProps={{
                                    margin: "dense"
                                }}
                            />
                        </Panel>
                    </Grid>
                </Grid>
                <Grid item>
                    <Panel className={classes.tablePanel}>
                        <LoadingSpinner loading={cecl!.userList?.loading} />
                        <Table
                            loading={tableProps.loading}
                            columns={tableProps.columns}
                            dense={tableProps.dense}
                            rows={tableProps.rows}
                            keyColumn={tableProps.keyColumn}
                            pageSize={tableProps.pageSize}
                            sortable={tableProps.sortable}
                            collapsibleColumns={tableProps.collapsibleColumns}
                            defaultFilterValue={tableProps.defaultFilterValue}
                        >
                        </Table> 
                        {!tableProps.loading && !cecl?.userList?.users?.length && (
                            <Panel className={classes.emptyTablePanel}>User Management data will appear here</Panel>
                        )}
                    </Panel>
                </Grid>
            </Grid>
        </div>
    );
};

export default AdminUserManagementPage;
