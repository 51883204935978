import { SnackbarKey } from 'notistack';
import { action } from 'typesafe-actions';
import { ConfigActionTypes, ConfigState, ToastMessage } from './types';

export const toggleBottomPanel = () => action(ConfigActionTypes.TOGGLE_BOTTOMPANEL);
export const toggleSideMenu = () => action(ConfigActionTypes.TOGGLE_SIDEMENU);
export const toggleModal = () => action(ConfigActionTypes.TOGGLE_MODAL);
export const cookiesRequest = () => action(ConfigActionTypes.COOKIES_REQUEST);
export const cookiesSuccess = (config: ConfigState) => action(ConfigActionTypes.COOKIES_SUCCESS, config);
export const saveCookiesRequest = (config: ConfigState) => action(ConfigActionTypes.SAVE_COOKIES_REQUEST, config);
export const saveCookiesSuccess = () => action(ConfigActionTypes.SAVE_COOKIES_SUCCESS);
export const displayToast = (toast: ToastMessage) => action(ConfigActionTypes.DISPLAY_TOAST, toast);
export const saveSnackbarKey = (snackbarKey: SnackbarKey) => action(ConfigActionTypes.SAVE_SNACKBAR_KEY_REQUEST, snackbarKey);
