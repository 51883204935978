import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { CeclApiState } from '../../../store/ceclapi/types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            fontSize: '1.8em',
        },
        cell: {
            height: 100,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginBottom: theme.spacing(5),
        },
        cellAlignRight: {
            height: 100,
            textAlign: 'right',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginBottom: theme.spacing(5),
        },
    })
);

const PdfHeaderPanel: React.FC = () => {
    const classes = useStyles();
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);

    const formatDate = (dateString: string | Date) => {

        const date = new Date(dateString);
        const year = date.getFullYear();

        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        let militaryHours = date.getHours();
        const ampm = militaryHours > 11 ? "PM" : "AM";

        let hour = militaryHours > 12 ? (militaryHours - 12).toString() : '0' + militaryHours;

        let minute = date.getMinutes().toString();
        minute = minute.length > 1 ? minute : '0' + minute;

        day = day.length > 1 ? day : '0' + day;

        return `${month}/${day}/${year} ${hour}:${minute} ${ampm}`;
    };

    React.useEffect(() => {}, []);

    return (
        <div id="pdfHeaderPanel">
            <Grid container>
                <Grid item xs={3} className={classes.cell}>
                    <img src={require('../../../assets/images/cecl_logo_pdf.png')}></img>
                </Grid>
                <Grid item xs={6} className={classes.cell}>
                    <Typography className={classes.title}>{cecl.user?.creditUnionName + ' Historical Data'}</Typography>
                </Grid>
                <Grid item xs={3} className={classes.cellAlignRight}>
                    <Typography className={classes.title}>
                        {'Date Created: ' + formatDate(new Date())}
                    </Typography>
                </Grid>
            </Grid>
            <br/>
            <br/>
        </div>
    );
};

export default PdfHeaderPanel;