import { UserPermissionsForm } from './UserPermissionsForm';
import React, { Fragment } from 'react';
import { createStyles, makeStyles, Theme,} from '@material-ui/core';
import styled from 'styled-components';
import { colors } from '../.,/../../../theme';
import { UserTypes } from '../../../store/ceclapi/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notificationTable: {
      color: colors.white,
      '& thead tr th': {
        fontSize: '18px !important',
        color: colors.white,
        textTransform: 'uppercase',
        borderBottom: 'none',
        fontWeight: 'bold',
      },
      '& tbody tr': {
        '& td': {
          fontSize: '18px !important',
          color: colors.white,
          paddingLeft: 25,
          borderBottom: 'none',
          '& h6': {
            fontSize: '1.0em',
          },
          '& span': {
            fontSize: '0.75em',
          },
          '& > *': {
            paddingLeft: '0px !important',
          },
          '&:first-child': {
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
          },
          '&:last-child': {
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
          },
        },
        '& td:first-child': {
          width: '100%',
        },
      },
      '& tbody tr:nth-child(odd)': {
        backgroundColor: '#3c82d6',
        borderRadius: 5,
      },
    },
    verifyUserButton: {
      textAlign: 'center',
      marginTop: 20,
    },
    forgotPasswordLink: {
      color: colors.white,
      textDecoration: 'underline',
      textAlign: 'center',
      fontSize: '1.15em',
    },
  })
);

export const NotificationsOrAdminPermissions = props => {
  const {
    user,
    currentUser,
    togglePermission,
    isUserVerified,
  } = props;
  const classes = useStyles(props);

  const currentUserIsAdmin = currentUser.userType == UserTypes.Admin;
  const currentUsersProfile = user && currentUser && user.objectId == currentUser.userObjectId;

  if (currentUserIsAdmin && !currentUsersProfile) {
    return (
      <Fragment>
        <PermissionsWrapper>
          <UserPermissionsForm user={user} togglePermission={togglePermission} />
        </PermissionsWrapper>
      </Fragment>
    );
  }
  else {
    return null;
  }
};

const TableWrapper = styled.div`
  background-color: #185daf;
  border-radius: 10px;
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.16);
  padding: 40px;
  width: 100%;
  max-width: 800px;
  margin: 50px auto;

  .header {
    margin-bottom: 20px;
    h4 {
      font-size: 2.75em;
      font-weight: bold;
    }
    h6 {
      font-style: italic;
    }
  }
`;

const PermissionsWrapper = styled.div`
  margin: 50px auto;
  width: 100%;
`;
