import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../../utils/callApi';
import { generatePdf, generatePdfHeaderFooter } from '../../utils/helpers';
import {
    ceclapiAuthDenied,
    ceclapiAuthError,
    ceclapiAuthSuccess,
    ceclapiDeleteAssumptionsError,
    ceclapiDeleteAssumptionsSuccess,
    ceclapiForecastAssumptionsDetailError,
    ceclapiForecastAssumptionsDetailSuccess,
    ceclapiCreatePdfCompileData,
    ceclapiCreatePdfError,
    ceclapiCreatePdfSuccess,
    ceclapiDraftForecastAssumptionsError,
    ceclapiDraftForecastAssumptionsSuccess,
    ceclApiForecastConfigurationError,
    ceclApiForecastConfigurationSuccess,
    ceclApiMessagesConfigurationError,
    ceclApiMessagesConfigurationSuccess,
    ceclapiForecastHistorySuccess,
    ceclapiForecastHistoryError,
    ceclapiHistoricalDataError,
    ceclapiHistoricalDataSuccess,
    ceclapiSubmitCommentsError,
    ceclapiSubmitCommentsSuccess,
    ceclapiForecastAssumptionsSaveSuccess,
    ceclapiForecastAssumptionsSaveError,
    ceclapiArchivedForecastAssumptionsSuccess,
    ceclapiArchivedForecastAssumptionsError,
    ceclapiDeleteForecastSuccess,
    ceclapiDeleteForecastError,
    ceclapiFredIndexesSuccess,
    ceclapiFredIndexesError,
    ceclapiCalculateLossSuccess,
    ceclapiCalculateLossError,
    ceclapiNcuaHistoricalDataSuccess,
    ceclapiNcuaHistoricalDataError,
    ceclapiNcuaDashboardDataSuccess,
    ceclapiNcuaDashboardDataError,
    ceclapiAdminUserManagementSuccess,
    ceclapiAdminUserManagementError,
    ceclapiAdminUserManagementSubscriptionSuccess,
    ceclapiAdminUserManagementSubscriptionError,
    ceclapiDocumentError,
    ceclapiDocumentSuccess,
    ceclapiForecastToggleFinalSuccess,
    ceclapiForecastToggleFinalError,
    ceclapiAdminForecastRoutingListRequestSuccess,
    ceclapiAdminForecastRoutingListRequestError,
    ceclapiUsefulDocumentsSuccess,
    ceclapiUsefulDocumentsError,
} from './actions';
import {
    AssumptionSummary,
    CeclApiActionTypes,
    CeclApiCreatePdfRequestAction,
    CeclApiDeleteAssumptionsRequestAction,
    CeclApiForecastAssumptionsDetailRequestAction,
    CeclApiDraftForecastAssumptionsRequestAction,
    CeclApiAdminForecastRoutingListRequestAction,
    CeclApiForecastAssumptionsSaveRequestAction,
    CeclApiForecastConfigurationRequestAction,
    CeclApiMessagesConfigurationRequestAction,
    CeclApiForecastHistoryRequestAction,
    CeclApiHistoricalDataRequestAction,
    CeclApiSubmitCommentsRequestAction,
    ConfigurationSet,
    Messages,
    DeleteAssumptionsResult,
    UserTypes,
    CeclApiArchivedForecastAssumptionsRequestAction,
    CeclApiDeleteForecastRequestAction,
    CeclApiFredIndexesRequestAction,
    FredIndex,
    CeclApiCalculateLossRequestAction,
    CeclApiNcuaHistoricalDataRequestAction,
    NcuaHistoricalDataResponse,
    CeclApiNcuaDashboardDataRequestAction,
    DashboardDataResponse,
    CeclApiAdminUserManagementRequestAction,
    AdminUserManagementUserData,
    CeclApiAdminUserManagementSubscriptionUpdateAction,
    AdminUserManagementSubscriptionUpdateResponse,
    CeclApiDocumentRequestAction,
    CeclApiForecastToggleFinalRequestAction,
    CeclApiUsefulDocumentsRequestAction,
    UsefulDocumentsDataResponse,
} from './types';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

function* handleCeclApiSubmitComments(action: CeclApiSubmitCommentsRequestAction) {
    try {
        let res = yield call(callApi, 'post', API_ENDPOINT + 'api', '/notification/comments', action.payload);
        let nextActions: any[] = [put(ceclapiSubmitCommentsSuccess(res))];

        yield res.error ? put(ceclapiSubmitCommentsError(res.error)) : all(nextActions);
    } catch (err) {
        yield err instanceof Error
            ? put(ceclapiSubmitCommentsError(err.stack!))
            : put(ceclapiSubmitCommentsError('An unknown error occurred.'));
    }
}

function* handleCeclApiAuth() {
    try {
        let res = yield call(callApi, 'get', API_ENDPOINT + 'api', '/auth');

        if (res.userType) {
            res.userType = UserTypes[res.userType];
        }

        let nextActions: any[] = res?.isAuthenticated ? [put(ceclapiAuthSuccess(res))] : [put(ceclapiAuthDenied())];

        yield res.error ? put(ceclapiAuthError(res.error)) : all(nextActions);
    } catch (err) {
        yield err instanceof Error
            ? put(ceclapiAuthError(err.stack!))
            : put(ceclapiAuthError('An unknown error occurred.'));
    }
}

function* handleCeclApiCalculateLossRequest(action: CeclApiCalculateLossRequestAction) {
    try {
        let res = yield call(callApi, 'post', API_ENDPOINT + 'api', '/forecast/calculateloss', action.payload);
        let nextActions: any[] = [put(ceclapiCalculateLossSuccess(res))];

        if (res.status > 299) {
            if (res.data) {
                const error = JSON.parse(res.data);
                res.error = `[${error.source}] ${error.message}`;
            } else {
                res.error = 'Unknown error';
            }
        }

        yield res.error ? put(ceclapiCalculateLossError(res.error)) : all(nextActions);
    } catch (err) {
        yield err instanceof Error
            ? put(ceclapiCalculateLossError(err.stack!))
            : put(ceclapiCalculateLossError('An unknown error occurred.'));
    }
}

function* handleCeclApiHistoricalDataRequest(action: CeclApiHistoricalDataRequestAction) {
    try {
        let res = yield call(
            callApi,
            'get',
            API_ENDPOINT + 'api',
            `/historical/${action.payload.cuid}/${action.payload.timeHorizon}`
        );

        let nextActions: any[] = [put(ceclapiHistoricalDataSuccess(res))];

        yield res.error ? put(ceclapiHistoricalDataError(res.error)) : all(nextActions);
    } catch (err) {
        yield err instanceof Error
            ? put(ceclapiHistoricalDataError(err.stack!))
            : put(ceclapiHistoricalDataError('An unknown error occurred.'));
    }
}

function* handleCeclApiCreatePdfRequest(action: CeclApiCreatePdfRequestAction) {
    yield put(ceclapiCreatePdfCompileData(action.payload));
}

function* handleCeclApiDocumentRequest(action: CeclApiDocumentRequestAction) {
    try {
        const request = action.payload;
        let url = request.base64Response ? '/documentcatalog/getFileBase64' : '/documentcatalog/getFile';
        url += `/${request.forecastId}`;

        let res = yield call(
            callApi,
            'get',
            API_ENDPOINT + 'api',
            url,
            null,
            request.base64Response ? null : request.fileName
        );

        if (res.status > 299 && res.data) {
            res.error = res.data;
        }

        let nextActions: any[] = [put(ceclapiDocumentSuccess(request.base64Response ? res : ''))];

        yield res.error ? put(ceclapiDocumentError(res.error)) : all(nextActions);
    } catch (err) {
        yield err instanceof Error
            ? put(ceclapiDocumentError(err.stack!))
            : put(ceclapiDocumentError('An unknown error occurred.'));
    }
}

function* handleCeclApiCreatePdfCompileData(action: CeclApiCreatePdfRequestAction) {
    try {
        const request = action.payload;
        request.pages = yield call(generatePdf, request.pages || []);
        request.pagesHeaderFooter = yield call(generatePdfHeaderFooter, request.pagesHeaderFooter || []);

        const formData = new FormData();
        const requestString = JSON.stringify(request);
        formData.append('file', new File([new Blob([requestString])], 'pdf.json'));

        let res = yield call(
            callApi,
            'post',
            API_ENDPOINT + 'api',
            '/pdf/generate',
            formData,
            request.base64Response ? null : request.fileName,
            true
        );
        let nextActions: any[] = [put(ceclapiCreatePdfSuccess(request.base64Response ? res : ''))];
        
        yield (typeof res?.status !== 'undefined' && res.status != 200)  ? put(ceclapiCreatePdfError(res.error)) : all(nextActions);
    } catch (err) {
        console.log("error component")
        yield err instanceof Error
            ? put(ceclapiCreatePdfError(err.stack!))
            : put(ceclapiCreatePdfError('An unknown error occurred.'));
    }
}

function* handleCeclApiDraftForecastAssumptionsRequest(action: CeclApiDraftForecastAssumptionsRequestAction) {
    try {
        let res = yield call(callApi, 'get', API_ENDPOINT + 'api', `/forecast/${action.payload.cuid}/draft`);
        var resJson: AssumptionSummary[] = res;
        let nextActions: any[] = [put(ceclapiDraftForecastAssumptionsSuccess(resJson))];

        yield res.error ? put(ceclapiDraftForecastAssumptionsError(res.error)) : all(nextActions);
    } catch (err) {
        yield err instanceof Error
            ? put(ceclapiDraftForecastAssumptionsError(err.stack!))
            : put(ceclapiDraftForecastAssumptionsError('An unknown error occurred.'));
    }
}

function* handleCeclApiAdminForecastRoutingListRequest(action: CeclApiAdminForecastRoutingListRequestAction) {
    try {
        let res = yield call(callApi, 'get', API_ENDPOINT + 'api', `/forecastadmin/routingList`);
        var resJson: [] = res;
        let nextActions: any[] = [put(ceclapiAdminForecastRoutingListRequestSuccess(resJson))];

        yield res.error ? put(ceclapiAdminForecastRoutingListRequestError(res.error)) : all(nextActions);
    } catch (err) {
        yield err instanceof Error
            ? put(ceclapiAdminForecastRoutingListRequestError(err.stack!))
            : put(ceclapiAdminForecastRoutingListRequestError('An unknown error occurred.'));
    }
}

function* handleCeclApiArchivedForecastAssumptionsRequest(action: CeclApiArchivedForecastAssumptionsRequestAction) {
    try {
        let res = yield call(callApi, 'get', API_ENDPOINT + 'api', `/forecast/${action.payload.cuid}/archived`);
        var resJson: AssumptionSummary[] = res;
        let nextActions: any[] = [put(ceclapiArchivedForecastAssumptionsSuccess(resJson))];

        yield res.error ? put(ceclapiArchivedForecastAssumptionsError(res.error)) : all(nextActions);
    } catch (err) {
        yield err instanceof Error
            ? put(ceclapiArchivedForecastAssumptionsError(err.stack!))
            : put(ceclapiArchivedForecastAssumptionsError('An unknown error occurred.'));
    }
}

function* handleCeclApiForecastHistoryRequest(action: CeclApiForecastHistoryRequestAction) {
    try {
        let res = yield call(callApi, 'get', API_ENDPOINT + 'api', `/forecast/${action.payload.cuid}/history`);

        var resJson: AssumptionSummary[] = res;
        let nextActions: any[] = [put(ceclapiForecastHistorySuccess(resJson))];

        yield res.error ? put(ceclapiForecastHistoryError(res.error)) : all(nextActions);
    } catch (err) {
        yield err instanceof Error
            ? put(ceclapiForecastHistoryError(err.stack!))
            : put(ceclapiForecastHistoryError('An unknown error occurred.'));
    }
}

function* handleCeclApiDeleteForecastRequest(action: CeclApiDeleteForecastRequestAction) {
    try {
        let res = yield call(
            callApi,
            'delete',
            API_ENDPOINT + 'api',
            `/forecast/${action.payload.cuid}/${action.payload.id}/history`
        );

        var resJson: DeleteAssumptionsResult = res;
        let nextActions: any[] = [put(ceclapiDeleteForecastSuccess(resJson))];

        yield res.error ? put(ceclapiDeleteForecastError(res.error)) : all(nextActions);
    } catch (err) {
        yield err instanceof Error
            ? put(ceclapiDeleteForecastError(err.stack!))
            : put(ceclapiDeleteForecastError('An unknown error occurred.'));
    }
}

function* handleCeclApiDeleteAssumptionsRequest(action: CeclApiDeleteAssumptionsRequestAction) {
    try {
        let res = yield call(
            callApi,
            'delete',
            API_ENDPOINT + 'api',
            `/forecast/${action.payload.cuid}/${action.payload.assumptionId}/history`
        );

        var resJson: DeleteAssumptionsResult = res;
        let nextActions: any[] = [put(ceclapiDeleteAssumptionsSuccess(resJson))];

        yield res.error ? put(ceclapiDeleteAssumptionsError(res.error)) : all(nextActions);
    } catch (err) {
        yield err instanceof Error
            ? put(ceclapiDeleteAssumptionsError(err.stack!))
            : put(ceclapiDeleteAssumptionsError('An unknown error occurred.'));
    }
}

function* handleCeclApiForecastConfigurationRequest(action: CeclApiForecastConfigurationRequestAction) {
    try {
        let res = yield call(callApi, 'get', API_ENDPOINT + 'api', `/forecast/configuration`);

        var resJson: ConfigurationSet = res;
        let nextActions: any[] = [put(ceclApiForecastConfigurationSuccess(resJson))];

        yield res.error ? put(ceclApiForecastConfigurationError(res.error)) : all(nextActions);
    } catch (err) {
        yield err instanceof Error
            ? put(ceclApiForecastConfigurationError(err.stack!))
            : put(ceclApiForecastConfigurationError('An unknown error occurred.'));
    }
}

function* handleCeclApiMessagesConfigurationRequest(action: CeclApiMessagesConfigurationRequestAction) {
    try {
        let res = yield call(callApi, 'get', API_ENDPOINT + 'api', `/configuration/messages`);

        var resJson: Messages[] = res;
        let nextActions: any[] = [put(ceclApiMessagesConfigurationSuccess(resJson))];

        yield res.error ? put(ceclApiForecastConfigurationError(res.error)) : all(nextActions);
    } catch (err) {
        yield err instanceof Error
            ? put(ceclApiMessagesConfigurationError(err.stack!))
            : put(ceclApiMessagesConfigurationError('An unknown error occurred.'));
    }
}

function* handleCeclApiFredIndexesRequest(action: CeclApiFredIndexesRequestAction) {
    try {
        let res = yield call(callApi, 'get', API_ENDPOINT + 'api', `/fred/indexes/${action.payload.cuid}`);

        var resJson: FredIndex[] = res;
        let nextActions: any[] = [put(ceclapiFredIndexesSuccess(resJson))];

        yield res.error ? put(ceclapiFredIndexesError(res.error)) : all(nextActions);
    } catch (err) {
        yield err instanceof Error
            ? put(ceclapiFredIndexesError(err.stack!))
            : put(ceclapiFredIndexesError('An unknown error occurred.'));
    }
}

function* handleCeclApiForecastAssumptionsDetailRequest(action: CeclApiForecastAssumptionsDetailRequestAction) {
    try {
        let res = yield call(
            callApi,
            'get',
            API_ENDPOINT + 'api',
            `/forecast/${action.payload.cuid}/${action.payload.assumptionId}`
        );

        var resJson: AdminUserManagementSubscriptionUpdateResponse = res;
        let nextActions: any[] = [put(ceclapiForecastAssumptionsDetailSuccess(resJson))];

        yield res.error ? put(ceclapiForecastAssumptionsDetailError(res.error)) : all(nextActions);
    } catch (err) {
        yield err instanceof Error
            ? put(ceclapiForecastAssumptionsDetailError(err.stack!))
            : put(ceclapiForecastAssumptionsDetailError('An unknown error occurred.'));
    }
}

function* handleCeclApiForecastToggleFinalRequest(action: CeclApiForecastToggleFinalRequestAction) {
    try {
        let res = yield call(callApi, 'get', API_ENDPOINT + 'api', `/forecast/${action.payload}/toggleFinal`);

        let nextActions: any[] = [put(ceclapiForecastToggleFinalSuccess(res))];

        if (res.status > 299 && res.data) {
            res.error = res.data;
        }

        yield res.error ? put(ceclapiForecastToggleFinalError(res.error)) : all(nextActions);
    } catch (err) {
        yield err instanceof Error
            ? put(ceclapiForecastToggleFinalError(err.stack!))
            : put(ceclapiForecastToggleFinalError('An unknown error occurred.'));
    }
}

function* handleCeclApiForecastAssumptionsSaveRequest(action: CeclApiForecastAssumptionsSaveRequestAction) {
    try {
        let method = action.payload.assumptionsData!.id?.length ? 'put' : 'post';
        let res = yield call(callApi, method, API_ENDPOINT + 'api', `/forecast`, action.payload.assumptionsData);

        let nextActions: any[] = [put(ceclapiForecastAssumptionsSaveSuccess(res))];

        if (res.status > 299 && res.data) {
            res.error = JSON.parse(res.data)
                ?.map((e) => e.errorMessage)
                .join('\r\n');
        }

        yield res.error ? put(ceclapiForecastAssumptionsSaveError(res.error)) : all(nextActions);
    } catch (err) {
        yield err instanceof Error
            ? put(ceclapiForecastAssumptionsSaveError(err.stack!))
            : put(ceclapiForecastAssumptionsSaveError('An unknown error occurred.'));
    }
}

function* handleCeclApiNcuaHistoricalDataRequest(action: CeclApiNcuaHistoricalDataRequestAction) {
    try {
        let res = yield call(
            callApi,
            'get',
            API_ENDPOINT + 'api',
            `/ncua/historicaldata/${action.payload.cuid}/${action.payload.lookback}`
        );

        let nextActions: any[] = [put(ceclapiNcuaHistoricalDataSuccess(res as NcuaHistoricalDataResponse))];

        yield res.error ? put(ceclapiNcuaHistoricalDataError(res.error)) : all(nextActions);
    } catch (err) {
        yield err instanceof Error
            ? put(ceclapiNcuaHistoricalDataError(err.stack!))
            : put(ceclapiNcuaHistoricalDataError('An unknown error occurred.'));
    }
}

function* handleCeclApiAdminUserManagementRequest(action: CeclApiAdminUserManagementRequestAction) {
    try {
        let res = yield call(callApi, 'get', API_ENDPOINT + 'api', `/usermanagement/views/admin/users?$top=50000`);

        let nextActions: any[] = [put(ceclapiAdminUserManagementSuccess(res as AdminUserManagementUserData[]))];

        yield res.error ? put(ceclapiAdminUserManagementError(res.error)) : all(nextActions);
    } catch (err) {
        yield err instanceof Error
            ? put(ceclapiAdminUserManagementError(err.stack!))
            : put(ceclapiAdminUserManagementError('An unknown error occurred.'));
    }
}

function* handleCeclApiAdminUserManagementSubscriptionUpdateRequest(
    action: CeclApiAdminUserManagementSubscriptionUpdateAction
) {
    try {
        let res = yield call(
            callApi,
            'post',
            API_ENDPOINT + 'api',
            `/usermanagement/admin/users/${action.payload.id}/subscription/${action.payload.subscribed}`
        );

        let resJson: AdminUserManagementSubscriptionUpdateResponse = res;
        let nextActions: any[] = [put(ceclapiAdminUserManagementSubscriptionSuccess(resJson))];

        yield res.error ? put(ceclapiAdminUserManagementSubscriptionError(res.error)) : all(nextActions);
    } catch (err) {
        yield err instanceof Error
            ? put(ceclapiAdminUserManagementSubscriptionError(err.stack!))
            : put(ceclapiAdminUserManagementSubscriptionError('An unknown error occurred.'));
    }
}

function* handleCeclApiNcuaDashboardDataRequest(action: CeclApiNcuaDashboardDataRequestAction) {
    try {
        let res = yield call(callApi, 'get', API_ENDPOINT + 'api', `/ncua/dashboard`);

        let nextActions: any[] = [put(ceclapiNcuaDashboardDataSuccess(res as DashboardDataResponse))];

        yield res.error ? put(ceclapiNcuaDashboardDataError(res.error)) : all(nextActions);
    } catch (err) {
        yield err instanceof Error
            ? put(ceclapiNcuaDashboardDataError(err.stack!))
            : put(ceclapiNcuaDashboardDataError('An unknown error occurred.'));
    }
}

function* handleCeclApiUsefulDocumentsRequest(action: CeclApiUsefulDocumentsRequestAction) {
    try {
        let res = yield call(callApi, 'get', API_ENDPOINT + 'api', `/usefulDocuments/getUsefulDocuments`);

        let resJson: UsefulDocumentsDataResponse = res;
        let  nextActions: any[] = [put(ceclapiUsefulDocumentsSuccess(resJson))];

        yield res.error ? put(ceclapiUsefulDocumentsError(res.error)) : all(nextActions);
    } catch (err) {
        yield err instanceof Error
            ? put(ceclapiUsefulDocumentsError(err.stack!))
            : put(ceclapiUsefulDocumentsError('An unknown error occurred.'));
    }
}

function* watchCeclApiSubmitCommentsRequest() {
    yield takeEvery(CeclApiActionTypes.CECLAPI_SUBMITCOMMENTS_REQUEST, handleCeclApiSubmitComments);
}

function* watchCeclApiAuthRequest() {
    yield takeEvery(CeclApiActionTypes.CECLAPI_AUTH_REQUEST, handleCeclApiAuth);
}

function* watchCeclApiHistoricalDataRequest() {
    yield takeEvery(CeclApiActionTypes.CECLAPI_HISTORICALDATA_REQUEST, handleCeclApiHistoricalDataRequest);
}

function* watchCeclApiCreatePdfRequest() {
    yield takeEvery(CeclApiActionTypes.CECLAPI_CREATEPDF_REQUEST, handleCeclApiCreatePdfRequest);
}

function* watchCeclApiCreateDocumentRequest() {
    yield takeEvery(CeclApiActionTypes.CECLAPI_DOCUMENT_REQUEST, handleCeclApiDocumentRequest);
}

function* watchCeclApiCalculateLossRequest() {
    yield takeEvery(CeclApiActionTypes.CECLAPI_CALCULATELOSS_REQUEST, handleCeclApiCalculateLossRequest);
}

function* watchCeclApiCreatePdfCompileData() {
    yield takeEvery(CeclApiActionTypes.CECLAPI_CREATEPDF_COMPILEDATA, handleCeclApiCreatePdfCompileData);
}

function* watchCeclApiDraftForecastAssumptionsRequest() {
    yield takeEvery(
        CeclApiActionTypes.CECLAPI_DRAFTFORECASTASSUMPTIONS_REQUEST,
        handleCeclApiDraftForecastAssumptionsRequest
    );
}

function* watchCeclApiAdminForecastRoutingListRequest() {
    yield takeEvery(
        CeclApiActionTypes.CECLAPI_AdminForecastRoutingList_REQUEST,
        handleCeclApiAdminForecastRoutingListRequest
    );
}

function* watchCeclApiArchivedForecastAssumptionsRequest() {
    yield takeEvery(
        CeclApiActionTypes.CECLAPI_ARCHIVEDFORECASTASSUMPTIONS_REQUEST,
        handleCeclApiArchivedForecastAssumptionsRequest
    );
}

function* watchCeclApiForecastHistoryRequest() {
    yield takeEvery(CeclApiActionTypes.CECLAPI_FORECASTHISTORY_REQUEST, handleCeclApiForecastHistoryRequest);
}

function* watchCeclApiForecastToggleFinalRequest() {
    yield takeEvery(CeclApiActionTypes.CECLAPI_FORECASTTOGGLEFINAL_REQUEST, handleCeclApiForecastToggleFinalRequest);
}

function* watchCeclApiDeleteForecastRequest() {
    yield takeEvery(CeclApiActionTypes.CECLAPI_DELETE_FORECAST_REQUEST, handleCeclApiDeleteForecastRequest);
}

function* watchCeclApiDeleteAssumptionsRequest() {
    yield takeEvery(CeclApiActionTypes.CECLAPI_DELETEASSUMPTIONS_REQUEST, handleCeclApiDeleteAssumptionsRequest);
}

function* watchCeclApiForecastConfigurationRequest() {
    yield takeEvery(
        CeclApiActionTypes.CECLAPI_FORECASTCONFIGURATION_REQUEST,
        handleCeclApiForecastConfigurationRequest
    );
}

function* watchCeclApiMessagesConfigurationRequest() {
    yield takeEvery(
        CeclApiActionTypes.CECLAPI_MESSAGESCONFIGURATION_REQUEST,
        handleCeclApiMessagesConfigurationRequest
    );
}

function* watchCeclApiFredIndexesRequest() {
    yield takeEvery(CeclApiActionTypes.CECLAPI_FREDINDEXES_REQUEST, handleCeclApiFredIndexesRequest);
}

function* watchCeclApiForecastAssumptionsDetailRequest() {
    yield takeEvery(
        CeclApiActionTypes.CECLAPI_FORECASTASSUMPTIONSDETAIL_REQUEST,
        handleCeclApiForecastAssumptionsDetailRequest
    );
}

function* watchCeclApiForecastAssumptionsSaveRequest() {
    yield takeEvery(
        CeclApiActionTypes.CECLAPI_FORECASTASSUMPTIONSSAVE_REQUEST,
        handleCeclApiForecastAssumptionsSaveRequest
    );
}

function* watchCeclApiNcuaHistoricalData() {
    yield takeEvery(CeclApiActionTypes.CECLAPI_NCUAHISTORICALDATA_REQUEST, handleCeclApiNcuaHistoricalDataRequest);
}

function* watchCeclApiDashboardData() {
    yield takeEvery(CeclApiActionTypes.CECLAPI_NCUADASHBOARDDATA_REQUEST, handleCeclApiNcuaDashboardDataRequest);
}

function* watchCeclApiAdminUserManagementRequest() {
    yield takeEvery(CeclApiActionTypes.CECLAPI_ADMIN_USERMANAGEMENT_REQUEST, handleCeclApiAdminUserManagementRequest);
}

function* watchCeclApiAdminUserManagementSubscriptionUpdateRequest() {
    yield takeEvery(
        CeclApiActionTypes.CECLAPI_ADMIN_USERMANAGEMENT_SUBSCRIPTION_UPDATE,
        handleCeclApiAdminUserManagementSubscriptionUpdateRequest
    );
}

function* watchCeclApiUsefulDocumentsRequest() {
    yield takeEvery(
        CeclApiActionTypes.CECLAPI_USEFULDOCUMENTS_REQUEST, 
        handleCeclApiUsefulDocumentsRequest
    );
}

function* ceclApiSaga() {
    yield all([
        fork(watchCeclApiSubmitCommentsRequest),
        fork(watchCeclApiAuthRequest),
        fork(watchCeclApiHistoricalDataRequest),
        fork(watchCeclApiCreatePdfRequest),
        fork(watchCeclApiCreateDocumentRequest),
        fork(watchCeclApiCalculateLossRequest),
        fork(watchCeclApiCreatePdfCompileData),
        fork(watchCeclApiForecastHistoryRequest),
        fork(watchCeclApiForecastToggleFinalRequest),
        fork(watchCeclApiDeleteForecastRequest),
        fork(watchCeclApiDraftForecastAssumptionsRequest),
        fork(watchCeclApiArchivedForecastAssumptionsRequest),
        fork(watchCeclApiDeleteAssumptionsRequest),
        fork(watchCeclApiForecastConfigurationRequest),
        fork(watchCeclApiMessagesConfigurationRequest),
        fork(watchCeclApiFredIndexesRequest),
        fork(watchCeclApiForecastAssumptionsDetailRequest),
        fork(watchCeclApiForecastAssumptionsSaveRequest),
        fork(watchCeclApiNcuaHistoricalData),
        fork(watchCeclApiDashboardData),
        fork(watchCeclApiAdminUserManagementRequest),
        fork(watchCeclApiAdminUserManagementSubscriptionUpdateRequest),
        fork(watchCeclApiAdminForecastRoutingListRequest),
        fork(watchCeclApiUsefulDocumentsRequest)
    ]);
}

export { ceclApiSaga as CeclApiSaga };
