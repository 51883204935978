import Axios, { AxiosPromise } from 'axios';
import FileSaver from 'file-saver';
import { stringify } from 'querystring';

export const apiUrl = process.env.REACT_APP_API_ENDPOINT !== undefined ? process.env.REACT_APP_API_ENDPOINT : '';

const headers = {
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

Axios.interceptors.request.use((request) => {
    const token = localStorage.getItem('token');

    if (token) {
        request.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    }

    return request;
});

export const getLocal = async (path: string) => await Axios.get(path);
export const get = async (path: string) => await callApi('get', apiUrl, path).catch((e) => new Error());
export const post = async (path: string, data?: any) => await callApi('post', apiUrl, path, data);
export const postDownload = async (path: string, data?: any, fileName?: string) =>
    await callApi('post', apiUrl, path, data, fileName);

export const AxiosInstance = Axios.create({
    baseURL: '/api',
    headers,
    paramsSerializer: args => stringify(args),
});

export const callApi = async (
    method: string,
    url: string,
    path: string,
    data?: any,
    fileName: string | null = null,
    upload: boolean = false
) => {
    let res: AxiosPromise<any> | null = null;
    const config = upload ? { headers: {} } : { headers };

    if (fileName !== null) {
        config['responseType'] = 'blob';
    }

    switch (method.toLowerCase()) {
        case 'get':
            res = Axios.get(url + path, config);
            break;
        case 'post':
            res = Axios.post(url + path, upload ? data : JSON.stringify(data), config);
            break;
        case 'patch':
            res = Axios.patch(url + path, JSON.stringify(data), config);
            break;
        case 'put':
            res = Axios.put(url + path, JSON.stringify(data), config);
            break;
        case 'delete':
            res = Axios.delete(url + path);
            break;
    }

    try {
        const result = await res;

        if (fileName !== null && result !== null) {
            FileSaver.saveAs(new Blob([result.data]), fileName);
        }

        return result?.data;
    } catch (error) {
        return error.response;
    }
};
