import { Tab } from "@material-ui/icons";

export const seoDefaultKeyWords = [
  'CECLution',
  'CECLutions',
  'Catalyst CECLution',
  'Catalyst CECLutions',
  'CSS CECLution',
  'CSS CECLutions',
  'CECL',
  'Current Expected Credit Losses',
  'Current Expected Credit Loss',
  'CECL WARM Method',
  'CECL WARM Model',
  'Weighted Average Remaining Maturity Method',
  'Weighted Average Remaining Maturity Model',
  'NCUA CECL',
  'Allowance for Loan and Lease Losses',
  'ALLL',
  'Catalyst CECL',
  'Catalyst ALLL',
  'Catalyst Current Expected Credit Losses',
  'Catalyst Current Expected Credit Loss',
  'Catalyst Allowance for Loan and Lease Losses',
  'Catalyst WARM Model',
  'Catalyst WARM Method',
  'CSS CECL',
  'CSS ALLL',
  'CSS Current Expected Credit Losses',
  'CSS Current Expected Credit Loss',
  'CSS Allowance for Loan and Lease Losses',
  'CSS WARM Model',
  'CSS WARM Method'
  
];
export const seoHomePageKeyWords = seoDefaultKeyWords;
export const seoDashboardPageKeyWords = seoDefaultKeyWords;
export const seoUserProfilePageKeyWords = seoDefaultKeyWords;
export const seoForecastPageKeyWords = seoDefaultKeyWords;
export const seoHistoricalDataKeyWords = seoDefaultKeyWords;
export const seoHistoryKeyWords = seoDefaultKeyWords;
export const seoDemoKeyWords = seoDefaultKeyWords;
export const seoAssumptionsKeyWords = seoDefaultKeyWords;

export const modeloverviewandregulations = seoDefaultKeyWords;
export const contactUs = seoDefaultKeyWords;
export const privacyPolicy = seoDefaultKeyWords;
export const termsOfUse = seoDefaultKeyWords;

export const findByTab = (tab) => {
  switch (tab) {
    case 'model-overview-and-regulations':
        return modeloverviewandregulations;
    case 'request-demo':
        return seoDemoKeyWords;
    case 'contact-us':
        return contactUs;
    case 'privacy-policy':
        return privacyPolicy;
    case 'termsofuse':
        return termsOfUse;
    default:
        return seoDefaultKeyWords;
}
}

