import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Main from './Main';
import { createBrowserHistory } from 'history';
import configureStore from './store/configureStore';

const initialState: any = {};
const history = createBrowserHistory();
const store = configureStore(history, initialState);

ReactDOM.render(<Main store={store} history={history} />, document.getElementById('main'));
