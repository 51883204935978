﻿import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import ActionBar from '../../components/actionbar/ActionBar';
import LoadingSpinner from '../../components/loadingspinner/LoadingSpinner';
import LoadingButton from '../../components/loadingbutton/LoadingButton';
import { ApplicationState } from '../../store';
import { fluidPadding } from '../../utils/styles';
import { NotificationsOrAdminPermissions } from './components/NotificationsOrAdminPermissions';
import { UserInfoInputFields } from './components/UserInfoInputFields';
import useUserProfileReducer, { actions } from './useUserProfileReducer';
import { CeclApiState, UserTypes } from '../../store/ceclapi/types';
import { UserManagementState } from '../../store/user-management/types';

const UserProfilePage: React.FC<RouteComponentProps<{ id?: string }>> = props => {
  const {
      mounted,
      user,
      userRoles,
      dispatch,
      togglePermission,
      handleInputChange,
      handleSubmit,
      edited,
  } = useUserProfileReducer();
  const cecl = useSelector<ApplicationState, CeclApiState>(state => state.cecl);
  const userManagement = useSelector<ApplicationState, UserManagementState>(
    state => state.userManagement
  );
  const currentUserIsAdmin = cecl.user?.userType == UserTypes.Admin;

  useEffect(() => {
    if (mounted && userManagement.updated) {
      dispatch({ type: actions.TOGGLE_SUBMITTING });
      dispatch({ type: actions.UNMOUNT });
      dispatch({ type: actions.SET_USER, payload: undefined });
      window.scrollTo(0, 0);
    }
  }, [userManagement.updated]);
  
  if (!mounted) {
    return (
      <PageWrapper>
        <Wrapper style={{ height: '100vh' }}>
            <LoadingSpinner loading={true} />
        </Wrapper>
      </PageWrapper>
    );
  }

  const isUserVerified = userRoles.includes('CECLVerified');
  const isLPXVerified = userRoles.includes('Verified');

  return (
    <PageWrapper>
      {currentUserIsAdmin && (
        <UserStatusWrapper>
          <Typography variant="h4">
            [{user.accountEnabled ? 'active' : 'deactivated'}
            {user.accountEnabled && <span>: {isUserVerified ? 'subscribed' : 'unverified'}</span>}]
          </Typography>
        </UserStatusWrapper>
      )}
      <Wrapper>
        <ContentWrapper>
          <div className="instructions">
            <Typography variant="h4">
              Edit {currentUserIsAdmin ? user.givenName + "'s" : 'your'} profile
            </Typography>

            <Typography variant="subtitle1">
              To make changes, place your cursor in a box to select it then start typing.
            </Typography>
          </div>

          <Form>
            <UserInfoInputFields
              user={user}
              userIsLPX={isLPXVerified}
              currentUserIsAdmin={currentUserIsAdmin}
              handleInputChange={handleInputChange}
            />
            <NotificationsOrAdminPermissions
              user={user}
              currentUser={cecl.user}
              togglePermission={togglePermission}
              isUserVerified={isUserVerified}
              {...props}
            />
          </Form>
        </ContentWrapper>
      </Wrapper>
      <ActionBar show={edited}>
        <LoadingButton
          color="primary"
          type="submit"
          variant="contained"
          loading={cecl?.user?.loading}
          onClick={handleSubmit}
        >
          SUBMIT CHANGES
        </LoadingButton>
      </ActionBar>
    </PageWrapper>
  );
};

//TODO: replace color codes (ex.'#397cc6') with (example): ${({ theme }) => theme.colors.windowsBlue};

const PageWrapper = styled.div`
  position: relative;
  background-color: #397cc6;
`;

const UserStatusWrapper = styled.div`
  background-color: #397cc6;
  h4 {
    padding: 1em 1em 0 1em;
    color: #fff;
    opacity: 0.5;
    text-align: right;
  }
`;

const Form = styled.form`
  display: grid;
  grid-template-rows: repeat(6, auto);
  grid-template-columns: 100%;
  gap: 2em;
  width: 100%;
  input {
    background-color: #fff;
    border: none;
  }

  .MuiFormControl-root {
    border: 1px solid white;
    border-radius: 4px !important;
  }

  .MuiFormLabel-root.Mui-disabled {
    color: white;
  }

  .MuiInputBase-root.Mui-disabled {
    opacity: 0.3;
    color: white;
    input {
      background-color: #397cc6;
      color: #fff;
    }
  }
`;

const Wrapper = styled.div`
  ${fluidPadding}
  padding-top: 3em !important;
  padding-bottom: 3em !important;
  height: 100%;
  background-color: #397cc6;
  color: #fff;
  display: flex;
  & > div:nth-child(2) {
    flex: 1 1 auto;
  }

  .instructions {
    font-weight: bold;
    margin-bottom: 30px;
    h4 {
      font-size: 2.75em;
      font-weight: bold;
    }
    h6 {
      font-style: italic;
    }
  }

  .info {
    text-align: center;
    ${fluidPadding}
  }

  span {
    color: white;
    fill: white;
  }

  .update {
    text-align: center;
  }
`;

const ContentWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  max-width: 800px;
  margin: 0px auto;
`;

export default withRouter(UserProfilePage);