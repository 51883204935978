import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Panel from '../../../../components/panel/Panel';
import { ApplicationState } from '../../../../store';
import { CeclApiState, UserTypes } from '../../../../store/ceclapi/types';
import { colors } from '../../../../theme';
import Button from '../../../../components/button/Button';

export interface TopPanelProps {
    onSave?: () => void;
    routingNumber?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
        },
        buttons: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    })
);

const TopPanel: React.FC<TopPanelProps> = (props) => {
    const classes = useStyles();
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const history = useHistory();

    React.useEffect(() => {}, []);

    const saveToHistory = () => {
        if (props.onSave) {
            props.onSave();
        }
    };

    return (
        <Grid container>
            <Grid item xs={6}>
                <Panel>
                    <Typography className={classes.title}>
                        Forecast Name: {cecl.forecastAssumptions?.detail?.data?.name}
                    </Typography>
                </Panel>
            </Grid>
            <Grid item xs={3} className={classes.buttons}>
                <Button
                    variety="outlined"
                    onClick={() => history.push(`/forecast/assumptions/${props.routingNumber}/${cecl.forecastAssumptions?.detail?.data?.id}`)}
                >
                    Edit Assumptions
                </Button>
            </Grid>
            <Grid item xs={3} className={classes.buttons}>
                <Button onClick={saveToHistory} disabled={cecl.user.userType == UserTypes.Admin}>Save to Forecast History</Button>
            </Grid>
        </Grid>
    );
};

export default TopPanel;
