import {
    createStyles,
    Dialog,
    DialogTitle,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Theme,
    Typography,
} from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import Button from '../../../../components/button/Button';
import Input from '../../../../components/input/Input';
import Panel from '../../../../components/panel/Panel';
import Table, { Column, TableProps } from '../../../../components/table/Table';
import ForecastParametersTable from '../../../../containers/tables/ForecastParametersTable';
import { ApplicationState } from '../../../../store';
import {
    ceclapiDraftForecastAssumptionsRequest,
    ceclapiForecastAssumptionsDetailRequest,
    ceclstateForecastAssumptionsReset,
    ceclstateForecastOtherModelParametersUpdate,
    ceclstateForecastSingleModelParameterUpdate,
} from '../../../../store/ceclapi/actions';
import { CeclApiState, ForecastAssumptionsData, FredIndex, ValidationError, UserTypes } from '../../../../store/ceclapi/types';
import { colors } from '../../../../theme';
import { BuildForecastRouteParams } from '../BuildForecastAssumptions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputBar: {
            width: '100%',
        },
        inputName: {
            fontWeight: 'bold',
            textAlign: 'end',
            fontSize: 22,
        },
        note: {
            color: 'red',
            paddingLeft: '10%',
            paddingTop: '4px',
            fontSize: 14,
        },
        title: {
            fontWeight: 'bold',
            fontSize: 22,
        },
        dialogBox: {
            textAlign: 'center',
        },
        emptyTablePanel: {
            boxShadow: 'none',
            textAlign: 'center',
            fontStyle: 'italic',
        },
        dialogCancel: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: colors.white,
            color: colors.blue,
            textDecoration: 'underline',
            '&:hover': {
                backgroundColor: colors.white,
            },
        },
        dialogDivider: {
            color: colors.black,
        },
        dialogTitle: {
            width: '100%',
            textAlign: 'left',
            fontSize: 35,
        },
        asteriskText: {
            fontStyle: 'italic',
        },
        grayBackground1: {
            backgroundColor: colors.lightGray3,
        },
        grayBackground2: {
            backgroundColor: colors.lightGray4,
        },
        grayBorder: {
            borderWidth: 1,
            borderColor: colors.lightGray3,
            borderStyle: 'solid',
        },
        noUnderline: {
            textDecoration: 'none',
        },
        paddingLess: {
            padding: 0,
        },
        paddingRight: {
            paddingRight: theme.spacing(2),
        },
        disclaimers: {
            margin: theme.spacing(2),
        },
    })
);

const dataLag: number[] = Array.from(Array(25).keys());

interface DialogProps {
    open: boolean;
}

export interface ForecastParametersProps {
    parametersWithErrors?: ValidationError[];
    onLoadAssumption: (loadAssumption: boolean) => void;
}

const ForecastParameters: React.FC<ForecastParametersProps> = (props) => {
    const routeParams = useParams<BuildForecastRouteParams>();
    const classes = useStyles();
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const dispatch = useDispatch();

    const defaultAssumptionsColumns: Column[] = [
        {
            name: 'Forecast Assumptions',
            label: 'Forecast Name',
            numeric: false,
            sortable: true,
        },
        {
            name: 'Analysis Date',
            label: 'Analysis Date',
            numeric: false,
            sortable: true,
            isDate: true,
            centerHeader: true,
            centerBody: true,
        },
        {
            name: 'Date Saved',
            label: 'Date Saved',
            numeric: false,
            sortable: true,
            isDate: true,
            centerHeader: true,
            centerBody: true,
        },
        {
            name: 'Saved By',
            label: 'Saved By',
            numeric: false,
            sortable: true,
        },
        {
            name: 'Actions',
            label: 'Load Assumptions',
            numeric: false,
            sortable: false,
            centerHeader: true,
            centerBody: true,
        },
    ];
    const [modelParameters, setModelParameters] = React.useState<ForecastAssumptionsData>({
        dataLag: 0,
        economicIndex: '',
        name: '',
        analysisDate:
            new Date().getFullYear() +
            '-' +
            (new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1) +
            '-' +
            (new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()),
        economicQualitativeFactorAdjustmentPercentage: 0,
        parameters: [],
    } as ForecastAssumptionsData);
    const [assumptionsTableProps, setAssumptionsTableProps] = React.useState({
        loading: true,
        dense: true,
        keyColumn: 'name',
        rows: [],
        columns: [],
        pageSize: 0,
        selectable: false,
    } as TableProps);
    const [dialogProps, setDialogProps] = React.useState({
        id: '',
        open: false,
    } as DialogProps);
    const [isLoadAssumptions, setIsLoadAssumptions] = React.useState(false);
    const [minDate, setMinDate] = React.useState('0001-01-01' as string);
    const [maxDate, setMaxDate] = React.useState('9999-12-31' as string);
    const [economicIndexes, setEconomicIndexes] = React.useState([] as FredIndex[]);
    const economicQualitativeFactorAdjustmentPercentageDisplay = modelParameters.economicQualitativeFactorAdjustmentPercentage
                                                                    && modelParameters.economicQualitativeFactorAdjustmentPercentage !== 0
                                                                    ? modelParameters.economicQualitativeFactorAdjustmentPercentage
                                                                    : '';

    React.useEffect(() => {
        var currentDate = new Date();
        var newState: ForecastAssumptionsData = {
            dataLag: 0,
            economicIndex: '',
            name: '',
            analysisDate:
                currentDate.getFullYear() +
                '-' +
                (currentDate.getMonth() + 1 < 10 ? '0' + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1) +
                '-' +
                (currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate()),
            economicQualitativeFactorAdjustmentPercentage: 0,
            parameters: [],
        };

        if (cecl.forecastAssumptions?.configuration?.config?.parameters) {
            newState.parameters = [...cecl.forecastAssumptions!.configuration!.config!.parameters!];
        }

        setModelParameters({ ...newState });
        getMinMaxDates();
    }, []);

    React.useEffect(() => {
        if (cecl.forecastAssumptions?.configuration?.config?.parameters) {
            setModelParameters((prevState) => ({
                ...prevState,
                parameters: JSON.parse(JSON.stringify(cecl.forecastAssumptions!.configuration!.config!.parameters!)),
            }));
        }
    }, [cecl.forecastAssumptions?.configuration]);

    React.useEffect(() => {
        if (cecl.forecastAssumptions?.indexes?.indexList) {
            setEconomicIndexes(cecl.forecastAssumptions!.indexes!.indexList);
        }
    }, [cecl.forecastAssumptions?.indexes]);

    React.useEffect(() => {
        if (!modelParameters.parameters?.length || !cecl.forecastAssumptions?.detail?.data) {
            return;
        }

        var myParams = JSON.parse(JSON.stringify(cecl.forecastAssumptions!.configuration!.config!.parameters!));
        var newParameters = [...cecl.forecastAssumptions!.detail!.data.parameters!];

        for (var i = 0; i < newParameters!.length; i++) {
            for (var j = 0; j < myParams!.length; j++) {
                if (newParameters![i].name == myParams![j].name) {
                    myParams![j] = newParameters![i];
                }
            }
        }

        const date = cecl.forecastAssumptions!.detail!.data!.analysisDate.substr(0, 10);
        const economicQualitativeFactorAdjustmentPercentage = cecl.forecastAssumptions!.detail!.data!.economicQualitativeFactorAdjustmentPercentage 
                                ? cecl.forecastAssumptions!.detail!.data!.economicQualitativeFactorAdjustmentPercentage 
                                : 0;       
        
        setModelParameters({
            ...modelParameters,
            parameters: myParams,
            analysisDate: date,
            economicIndex: cecl.forecastAssumptions!.detail!.data!.economicIndex,
            dataLag: cecl.forecastAssumptions!.detail!.data!.dataLag,
            economicQualitativeFactorAdjustmentPercentage: economicQualitativeFactorAdjustmentPercentage,
            id: routeParams.assumptionsId ? routeParams.assumptionsId! : '',
            name: isLoadAssumptions ? 
                (
                    modelParameters.name
                    ? modelParameters.name
                    : ''
                ) :
                (
                    cecl.forecastAssumptions!.detail!.data!.name
                    ? cecl.forecastAssumptions!.detail!.data!.name
                    : modelParameters.name
                    ? modelParameters.name
                    : ''
                ),
        });
        setIsLoadAssumptions(false);
    }, [cecl.forecastAssumptions?.detail]);

    React.useEffect(() => {
        mapAssumptionsTableDataFromAssumptionArchives();
    }, [cecl.forecastAssumptions?.drafts]);

    React.useEffect(() => {
        dispatch(ceclstateForecastOtherModelParametersUpdate(modelParameters));
    }, [modelParameters]);

    React.useEffect(() => {
        var myParameters = [...modelParameters.parameters];

        if (myParameters.length == 0) {
            return;
        }

        myParameters.forEach((param) => {
            param.error = false;
        });

        props.parametersWithErrors
            ?.map((paramError) => paramError.fieldName)
            .forEach((paramName) => {
                var paramIndex = myParameters.findIndex((param) => param.name == paramName);
                if (paramIndex >= 0) {
                    myParameters[paramIndex].error = true;
                }
            });

        setModelParameters((prevState) => ({ ...prevState, parameters: myParameters }));
    }, [props.parametersWithErrors]);

    React.useEffect(() => {
        props.onLoadAssumption(isLoadAssumptions);
    }, [isLoadAssumptions])

    function getMinMaxDates() {
        var currentDate = new Date();
        var maxDate: string =
            currentDate.getFullYear() +
            '-' +
            (currentDate.getMonth() + 1 < 10 ? '0' + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1) +
            '-' +
            (currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate());
        var minDate: string =
            currentDate.getFullYear() -
            25 +
            '-' +
            (currentDate.getMonth() + 1 < 10 ? '0' + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1) +
            '-' +
            (currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate());
        setMaxDate(maxDate);
        setMinDate(minDate);
    }

    const handleForecastNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        var change = (event as unknown) as string;
        setModelParameters((prevState) => ({ ...prevState, name: change }));
    };

    const handleAnalysisDateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setModelParameters((prev) => ({ ...prev, analysisDate: event.target.value as string }));
    };

    const handleEconomicIndexChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setModelParameters((prev) => ({ ...prev, economicIndex: event.target.value as string }));
    };

    const handleDataLagChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setModelParameters((prev) => ({ ...prev, dataLag: event.target.value as number }));
    };

    const handleQualitativeFactorChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setModelParameters((prev) => ({
            ...prev,
            economicQualitativeFactorAdjustmentPercentage: event.target.value as number,
        }));
    };

    const handleDialogOpen = () => {
        dispatch(ceclapiDraftForecastAssumptionsRequest({ cuid: (routeParams.routingNumber as unknown) as string }));
        if (cecl.forecastAssumptions?.loading) {
            setAssumptionsTableProps((prevState) => ({ ...prevState, loading: true }));
        }
        setDialogProps({ ...dialogProps, open: true });
    };

    const handleDialogClose = () => {
        setDialogProps((prev) => ({ ...prev, open: false }));
    };

    const handleLoadAssumptions = (id: string) => {
        handleDialogClose();
        setIsLoadAssumptions(true);
        dispatch(ceclstateForecastAssumptionsReset());
        dispatch(ceclapiForecastAssumptionsDetailRequest({ cuid: routeParams.routingNumber, assumptionId: id }));
    };

    function handleTableEdit(event: any, rowIndex: number, colName: string) {
        var parameterIndex = modelParameters.parameters!.findIndex((parameter) => parameter.order == rowIndex + 1);
        if (parameterIndex < 0) {
            return;
        }

        if (event === null || event === '') {
            event = '0';
        }
        var value = parseFloat((event as unknown) as string);
        var newParams = [...modelParameters.parameters!];
        switch (colName) {
            case 'Historical Lookback Period (Years)':
                newParams[parameterIndex].historicalLookbackPeriod = value;
                break;
            case 'Prepayment Speed Override (Optional)':
                newParams[parameterIndex].prepaymentSpeedOverride = value;
                break;
            case 'Loan Quality Qualitative Factor Adjustment (%)*':
                newParams[parameterIndex].loanQualityFactorAdjustment = value;
                break;
            default:
                break;
        }
        var parameter = newParams[parameterIndex];
        dispatch(ceclstateForecastSingleModelParameterUpdate(parameter));
        setModelParameters((prevState) => ({ ...prevState, parameters: newParams }));
    }

    function mapAssumptionsTableDataFromAssumptionArchives() {
        if (!cecl?.forecastAssumptions?.drafts?.length) {
            setAssumptionsTableProps((prev) => ({ ...prev, loading: cecl!.forecastAssumptions?.loading }));

            return;
        }

        var newRows = cecl?.forecastAssumptions?.drafts?.map((summary) => {
            var obj: any = {};
            obj['id'] = summary.id;
            obj['name'] = summary.name;
            obj['Forecast Assumptions'] = (
                <div>
                    <Button variety="text" onClick={() => handleLoadAssumptions(summary.id)}>{summary.name}</Button>
                </div>
            );
            obj['Analysis Date'] = new Date(summary.analysisDate).toLocaleDateString();
            obj['Date Saved'] = summary.dateModified
                ? new Date(summary.dateModified).toLocaleString()
                : new Date(summary.dateCreated).toLocaleString();
            obj['Saved By'] = summary.modifiedBy ?? summary.createdBy;
            obj['Actions'] = (
                <div>
                    <Button onClick={() => handleLoadAssumptions(summary.id)}>Load</Button>
                </div>
            );
            return obj;
        });

        setAssumptionsTableProps((prev) => ({
            ...prev,
            columns: defaultAssumptionsColumns,
            rows: newRows,
            loading: false,
            pageSize: 10,
        }));
    }

    return (
        <div>
            <Grid container direction="column" spacing={2}>
                <Grid item xs={6}>
                    <Panel>
                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={3}>
                                <Typography className={classes.inputName}>Forecast Name:</Typography>
                            </Grid>
                            <Grid item xs>
                                <Input
                                    id="forecast-name"
                                    className={classes.inputBar}
                                    variant="standard"
                                    type="text"
                                    value={modelParameters.name}
                                    onChange={handleForecastNameChange}
                                />
                            </Grid>
                        </Grid>
                    </Panel>
                </Grid>
                <Grid item xs={12}>
                    <Panel className={classes.paddingLess}>
                        <Grid container direction="column" spacing={2}>
                            <Grid container item direction="row" spacing={4}>
                                <Grid item xs={4}>
                                    <Grid item container direction="row" spacing={1}>
                                        <Grid item xs={5}>
                                            <Typography className={classes.inputName}>Analysis Date:</Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <TextField
                                                id="analysis-date"
                                                className={classes.inputBar}
                                                variant="standard"
                                                type="date"
                                                value={modelParameters.analysisDate}
                                                onChange={handleAnalysisDateChange}
                                                inputProps={{
                                                    min: minDate,
                                                    max: maxDate,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item container direction="row" spacing={1}>
                                        <Grid item xs={5}>
                                            <Typography className={classes.inputName}>Economic Index:</Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <FormControl className={classes.inputBar}>
                                                {economicIndexes && (
                                                    <Select
                                                        id="economic-index-select"
                                                        value={modelParameters.economicIndex}
                                                        onChange={handleEconomicIndexChange}
                                                        error={props.parametersWithErrors
                                                            .map((paramError) => paramError.fieldName)
                                                            .includes('economicIndex')}
                                                    >
                                                        {[].slice.call(economicIndexes).sort().map((indexObject, index) => {
                                                            return (
                                                                <MenuItem value={indexObject.fredSeriesId} key={index}>
                                                                    {indexObject.fredLabel}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                )}
                                                {props.parametersWithErrors
                                                    .map((paramError) => paramError.fieldName)
                                                    .includes('economicIndex') && (
                                                    <FormHelperText error>Required</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item container direction="row" spacing={1}>
                                        <Grid item xs={5}>
                                            <Typography className={classes.inputName}>Data Lag (Months):</Typography>
                                        </Grid>
                                        <Grid item xs className={classes.paddingRight}>
                                            <FormControl className={classes.inputBar}>
                                                <Select
                                                    id="data-lag-select"
                                                    value={modelParameters.dataLag}
                                                    onChange={handleDataLagChange}
                                                >
                                                    {dataLag.map((lagValue, index) => {
                                                        return (
                                                            <MenuItem value={lagValue} key={index}>
                                                                {lagValue}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <ForecastParametersTable
                                    handleDialogOpen={handleDialogOpen}
                                    parameters={modelParameters.parameters!}
                                    handleTableEdit={handleTableEdit}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container item xs={12} direction="row" spacing={2}>
                                    <Grid item xs={10} className={classes.grayBackground1}>
                                        <Typography className={classes.inputName}>
                                            Economic Qualitative Factor Adjustment (%)** (Optional):
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} className={classes.grayBorder}>
                                        <TextField
                                            id="qualitative-factor"
                                            className={classes.inputBar}
                                            variant="standard"
                                            type="number"
                                            value={economicQualitativeFactorAdjustmentPercentageDisplay}
                                            onChange={handleQualitativeFactorChange}
                                            InputProps={{
                                                disableUnderline: true,
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            }}
                                            inputProps={{
                                                style: {
                                                    textAlign: 'right',
                                                    fontSize: "1.3000rem",
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.disclaimers}>
                                <Typography className={classes.asteriskText}>
                                    * Loan Quality Qualitative Factor Adjustments reflect the amount of change in
                                    expected losses due to improvement or deterioration in loan quality.
                                </Typography>
                                <Typography className={classes.asteriskText}>
                                    ** Economic Qualitative Factor Adjustments reflect the amount of change in expected
                                    losses due to improvement or deterioration in the economy (national or local).
                                </Typography>
                            </Grid>
                        </Grid>
                    </Panel>
                </Grid>
            </Grid>
            <Dialog
                id="assumptions-table-dialog"
                className={classes.dialogBox}
                onClose={handleDialogClose}
                open={dialogProps.open}
                maxWidth="xl"
                aria-labelledby="load-assumptions-dialog-title"
            >
                <DialogTitle id="load-assumptions-dialog-title">
                    <Typography className={classes.dialogTitle}>Load Assumptions</Typography>
                </DialogTitle>
                <Divider variant="middle" className={classes.dialogDivider} />
                <br />
                <br />
                <Table
                    loading={assumptionsTableProps.loading}
                    columns={assumptionsTableProps.columns}
                    dense={assumptionsTableProps.dense}
                    rows={assumptionsTableProps.rows}
                    keyColumn={assumptionsTableProps.keyColumn}
                    pageSize={assumptionsTableProps.pageSize}
                    sortable={assumptionsTableProps.sortable}
                    selectable={assumptionsTableProps.selectable}
                ></Table>
                {!assumptionsTableProps.loading && !cecl?.forecastAssumptions?.drafts?.length && (
                    <Panel className={classes.emptyTablePanel}>
                        Forecast assumptions you create and save will appear here.
                    </Panel>
                )}
                <Button className={classes.dialogCancel} onClick={() => handleDialogClose()}>
                    Cancel
                </Button>
            </Dialog>
        </div>
    );
};

export default ForecastParameters;
