import { Button, Modal as MuiModal, Typography } from '@material-ui/core';
import { DateTimeUtils } from '@microsoft/applicationinsights-common';
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ApplicationState } from '../../store';
import { logUserOut, MAX_TIME_IDLE, MAX_TIME_TO_CONTINUE } from '../../utils/helpers';
import { CeclApiState} from '../../store/ceclapi/types';

const SessionTimeout: React.FC = () => {
  const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
  const loggedIn = cecl?.user?.isAuthenticated;
  const [showModal, setShowModal] = useState(false);
  const [isTimedOut, setIsTimedOut] = useState(false);
  const [timeLeft, setTimeLeft] = useState(MAX_TIME_TO_CONTINUE);

  let idleTimer;
  let intervalTimer: any = 0;
  let intervalStartTime = DateTimeUtils.Now();

  useEffect(() => {  
    if (loggedIn && showModal && !isTimedOut) {
      intervalStartTime = DateTimeUtils.Now();
      if (!intervalTimer) {
        intervalTimer = setInterval(() => {
          setTimeLeft(() =>
            Math.ceil(
              (MAX_TIME_TO_CONTINUE * 1000 - (DateTimeUtils.Now() - intervalStartTime)) / 1000
            )
          );
        }, 1000);
        let intervalT = intervalTimer;
       
        return () => {
           
            clearInterval(intervalT);
            intervalTimer = 0;
        };
      }
    }
  }, [isTimedOut, loggedIn, showModal]);

  const onAction = () => {  
    setIsTimedOut(false);
  };

  const onActive = () => {   
    setIsTimedOut(false);
  };

  const onIdle = () => {   

    if (loggedIn) {
      intervalStartTime = DateTimeUtils.Now();

      idleTimer.reset();
      setShowModal(true);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setIsTimedOut(false);
    setTimeLeft(MAX_TIME_TO_CONTINUE);
  };

  const handleLogout = useCallback(() => {
    setIsTimedOut(true);
    logUserOut();
  }, []);

  useEffect(() => {
    if (timeLeft <= 0) handleLogout();
  }, [handleLogout, timeLeft]);

  //if (process.env.NODE_ENV === 'development') return null;

  return (
    <>
      <IdleTimer
        ref={ref => {
          idleTimer = ref;
        }}
        element={document}
        onActive={onActive}
        onIdle={onIdle}
        onAction={onAction}
        debounce={250}
        timeout={MAX_TIME_IDLE}
      />

      <MuiModal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={showModal}
        onClose={handleClose}
      >
        <Modal>
          <div className="content">
           
            <Typography variant="h3" id="simple-modal-title">
              You have been idle
            </Typography>
            <br />
            <Typography variant="h5" id="simple-modal-description">
              You will be logged out in
              <br />
              <strong>{timeLeft}</strong>
              <br />
              seconds
            </Typography>
            <div className="actions">
              <Button onClick={handleClose} variant="contained" color="secondary">
                Continue
              </Button>
              <Button color="primary" onClick={handleLogout}>
                Logout
              </Button>
            </div>
          </div>
        </Modal>
      </MuiModal>
    </>
  );
};

const Modal = styled.div`
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  text-align: center;
  backdrop-filter: blur(10px);
  h3 {
  }
  img {
    margin-bottom: 2em;
    margin: 0 auto;
    max-width: 200px;
  }

  strong {
    
  }

  .content {
    padding: 2em;
    border-radius: 1em;
    background: white;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2em;
  }

  .actions {
    max-width: 300px;
    margin: 3em auto 0 auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
`;

export default SessionTimeout;

export const SessionTimeoutProvider: React.FC = ({ children }) => {

  return (
    <Fragment>
      {children}
      <SessionTimeout />
    </Fragment>
  );
};
