import { Grid, InputAdornment, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { colors } from '../../theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(3),
        },
        inputName: {
            fontWeight: 'bold',
            textAlign: 'end',
            fontSize: 22,
        },
        inputBar: {
            width: '100%',
        },
        grayBorder: {
            borderWidth: 1,
            borderColor: colors.lightGray3,
            borderStyle: 'solid',
        },
        grayBackground1: {
            backgroundColor: colors.lightGray3,
        },
    })
);

export interface EconomicQualitativeFactorAdjustmentProps {
    value: number;
    onChange?: (value: number) => void;
}

const EconomicQualitativeFactorAdjustment: React.FC<EconomicQualitativeFactorAdjustmentProps> = (props) => {
    const classes = useStyles();
    const propsValue = (props.value && props.value !== 0) ? props.value : '';

    const handleQualitativeFactorChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (props.onChange) {
            props.onChange(event.target.value as number);
        }
    };

    React.useEffect(() => {}, []);

    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={6} className={classes.grayBackground1}>
                <Typography className={classes.inputName}>
                    Economic Qualitative Factor Adjustment (%)** (Optional):
                </Typography>
            </Grid>
            <Grid item className={classes.grayBorder}>
                <TextField
                    id="qualitative-factor"
                    className={classes.inputBar}
                    variant="standard"
                    type="number"
                    value={propsValue}
                    onChange={handleQualitativeFactorChange}
                    InputProps={{
                        disableUnderline: true,
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    inputProps={{
                        style: {
                            textAlign: 'right',
                            fontSize: "1.3000rem",
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default EconomicQualitativeFactorAdjustment;
