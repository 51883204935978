import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Routes, { routes } from '../Routes';
import { ApplicationState, LastAction } from '../store';
import { ConfigState } from '../store/config/types';
import { colors } from '../theme';
import { RouterState } from 'connected-react-router';
import SideMenu from '../containers/sidemenu/SideMenu';
import NavBar from '../components/navbar/NavBar';
import Button from '../components/button/Button';
import AppFooter from '../containers/footer/AppFooter';
import { useSnackbar } from 'notistack';
import { CeclApiState, UserTypes } from '../store/ceclapi/types';
import UserInfoDropdown from '../containers/userinfodropdown/UserInfoDropdown';
import { cookiesRequest, saveSnackbarKey } from '../store/config/actions';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pages: {
            display: 'flex',
            height: 'calc(100% - 64px)',
        },
        titleBar: {
            backgroundColor: colors.white,
            padding: theme.spacing(3),
            display: 'flex',
        },
        fixed: {
            position: 'fixed',
        },
        title: {
            flexGrow: 1,
        },
        titleRight: {
            flexGrow: 1,
            textAlign: 'right',
        },
        button: {
            margin:  theme.spacing(3)
        },
        main: {
            flexGrow: 1,
            display: 'flex',
        },
        content: {
            backgroundColor: colors.lightGray,
            padding: theme.spacing(3),
            paddingTop: theme.spacing(11),
            overflowX: 'hidden',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
    })
);

const Pages: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const config = useSelector<ApplicationState, ConfigState>((state) => state.config);
    const router = useSelector<ApplicationState, RouterState>((state) => state.router);
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const lastAction = useSelector<ApplicationState, LastAction>((state) => state.lastAction);
    const currentPage = routes.find((r) => router.location.pathname.startsWith(r.path));
    const { enqueueSnackbar } = useSnackbar();

    // This functions as a redux action watcher.
    React.useEffect(() => {
        if (lastAction.type === '@@config/DISPLAY_TOAST') {
            const { message, variant, persist, anchorOrigin, action } = lastAction.payload;
            var snackbarKey = enqueueSnackbar(message, { variant, persist, style: {
                whiteSpace: 'pre-line'
            }, anchorOrigin: anchorOrigin, action});
            dispatch(saveSnackbarKey(snackbarKey));
        }

        if (lastAction.type === '@@config/COOKIES_SUCCESS' && location.pathname !== '/auth' && config.isLoggedIn) {
            history.push({ pathname: '/auth', state: { url: location.pathname, page: 'root' } });
        }
    }, [lastAction]);

    React.useEffect(() => {
        if (!config.isLoggedIn) {
            dispatch(cookiesRequest());
        }
    }, []);

    let navBarTitle = currentPage?.name;
    if (currentPage?.name && currentPage?.name.toUpperCase() === "DASHBOARD" && cecl.user?.givenName){
        navBarTitle = "Welcome, " + cecl.user.givenName + "!";
    } 

    return (
        <>
            {currentPage?.order === -1 ? (
                <Routes />
            ) : (
                <>
                    <NavBar className={classes.fixed} title={navBarTitle}>

                    {  cecl.user?.userType !== UserTypes.Subscribed &&  (
                            <Button className={classes.button} onClick={() => history.push('/scheduledemo/')}>Subscribe</Button>
                        ) }

                        {currentPage?.name === 'Dashboard' && cecl.user?.userType === UserTypes.Subscribed ? (
                            <Button onClick={() => history.push(`/forecast/assumptions/${cecl.user!.routingNumber!}`)}>Create New Forecast</Button>
                        ) : (
                            <Typography>{cecl.user?.creditUnionName}</Typography>
                        )}                                              

                        <UserInfoDropdown />
                    </NavBar>
                    <div className={classes.pages}>
                        {/* TODO: fix CSS for persistent hidden side menu */}
                        <SideMenu open={config.sideMenu} title="[CECL Logo]" loggedIn={cecl.user?.isAuthenticated} />
                        <div className={classes.content}>
                            <Routes />
                        </div>
                    </div>
                    <AppFooter />
                </>
            )}
        </>
    );
};

export default Pages;
