import { makeStyles, Theme, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(5),
            fontStyle: 'italic',
        },
        title:{
            fontSize: '1.8em',
        }
    })
);

const PdfFooterDiv: React.FC = () => {
    const classes = useStyles();

    React.useEffect(() => {}, []);

    return (
        <div id="pdfFooterDiv" className={classes.root}>
            <Typography className={classes.title}>*The information provided is from NCUA 5300 Call Report Data. While we believe the information to be accurate and to fairly represent the credit union, we are not liable for errors, omissions, or use of this data beyond its intended purpose.</Typography>
        </div>
    );
};

export default PdfFooterDiv;