import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import Table, { Column, TableProps } from '../../components/table/Table';
import { ApplicationState } from '../../store';
import { CeclApiState } from '../../store/ceclapi/types';
import { colors } from '../../theme';
import { formatCurrencyNumber } from '../../utils/helpers';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        blueBar: {
            fontWeight: 'bold',
            color: colors.white,
            backgroundColor: colors.blue,
            textAlign: 'start',
        },
        inputBar: {
            width: '100%',
        },
        inputName: {
            fontWeight: 'bold',
            textAlign: 'start',
            color: colors.white,
        },
        blueBackground: {
            backgroundColor: colors.blue,
        },
        blueBorder: {
            borderWidth: 1,
            borderColor: colors.blue,
            borderStyle: 'solid',
        },
        paddingLess: {
            padding: 0,
        },
        lightBlueBackground: {
            backgroundColor: colors.lightBlue,
        },
        noScroll: {
            '& > *': {
                '& > *': {
                    overflowX: 'unset',
                },
            },
        },
    })
);

interface ModelAssumptionsTableProps {
    noScroll?: boolean;
}

const ModelAssumptionsTable: React.FC<ModelAssumptionsTableProps> = (props) => {
    const classes = useStyles();
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const [monthlyInputsTableProps, setMonthlyInputsTableProps] = React.useState({
        loading: true,
        dense: true,
        keyColumn: 'name',
        rows: [],
        columns: [],
        selectable: false,
        hasBorder: true,
    } as TableProps);
    const creditUnionName = cecl.user?.creditUnionName;

    const bodyFontSize = "1.3000rem";
    const headerFontSize = 22;
    const secondaryHeaderFontSize = 22;

    const defaultMonthlyInputsColumns: Column[] = [
        {
            name: 'name',
            label: '',
            numeric: false,
            bodyColor: colors.lightGray3,
            bodyFontSize: bodyFontSize,
        },
        {
            name: 'Ending Balance',
            label: 'Ending Balance',
            numeric: false,
            headerColor: colors.lightGray3,
            centerHeader: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
            alignRight: true,
        },
        {
            name: 'Number Of Loans',
            label: 'Number Of Loans',
            numeric: true,
            numericPrecision: 0,
            headerColor: colors.lightGray3,
            centerHeader: true,
            centerBody: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
        },
        {
            name: 'Average Loan Rate (%)',
            label: 'Average Loan Rate (%)',
            numeric: true,
            decorator: '%',
            decoratorPlacement: 'end',
            headerColor: colors.lightGray3,
            centerHeader: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
        },
        {
            name: 'Weighted Average Remaining Maturity (Months)',
            label: 'Weighted Average Remaining Maturity (Months)',
            numeric: true,
            numericPrecision: 0,
            headerColor: colors.lightGray3,
            centerHeader: true,
            centerBody: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
        },
        {
            name: 'Dollar Amount Of Interest Only Loans',
            label: 'Dollar Amount Of Interest Only Loans',
            numeric: false,
            headerColor: colors.lightGray3,
            centerHeader: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
            alignRight: true,
        },
        {
            name: 'Historical Lookback Period (Years)',
            label: 'Historical Lookback Period (Years)',
            numeric: true,
            headerColor: colors.lightGray3,
            centerHeader: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
        },
        {
            name: 'Prepayment Speeds',
            label: 'Prepayment Speeds',
            numeric: true,
            decorator: '%',
            decoratorPlacement: 'end',
            headerColor: colors.lightGray3,
            centerHeader: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
        },
        {
            name: 'Specific CU',
            label: creditUnionName,
            numeric: true,
            decorator: '%',
            decoratorPlacement: 'end',
            headerColor: colors.lightGray3,
            bodyColor: colors.lightBlue,
            secondHeader: 'Historical Net Charge-Offs',
            secondHeaderColSpan: 3,
            secondHeaderColor: colors.fadedBlue,
            secondHeaderHasBorder: true,
            centerHeader: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
            secondHeaderFontSize: secondaryHeaderFontSize,
        },
        {
            name: 'All Credit Unions',
            label: 'All Credit Unions',
            numeric: true,
            decorator: '%',
            decoratorPlacement: 'end',
            headerColor: colors.lightGray3,
            bodyColor: colors.lightBlue,
            centerHeader: true,
            secondHeaderHide: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
        },
        {
            name: 'State Level Peer Group',
            label: 'State Level Peer Group',
            numeric: true,
            decorator: '%',
            decoratorPlacement: 'end',
            headerColor: colors.lightGray3,
            bodyColor: colors.lightBlue,
            centerHeader: true,
            secondHeaderHide: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
        },
        {
            name: 'Loan Quality Qualitative Factor Adjustment (%)',
            label: 'Loan Quality Qualitative Factor Adjustment (%)',
            numeric: true,
            decorator: '%',
            decoratorPlacement: 'end',
            headerColor: colors.lightGray3,
            secondHeader: 'Management Adjustments (Q Factors)',
            secondHeaderColor: colors.fadedBlue,
            secondHeaderHasBorder: true,
            centerHeader: true,
            bodyFontSize: bodyFontSize,
            headerFontSize: headerFontSize,
            secondHeaderFontSize: secondaryHeaderFontSize,
        },
    ];

    React.useEffect(() => {
        if (cecl!.calculatedLoss?.loanTypeLossForecastResults && cecl.forecastAssumptions?.detail?.data) {
            mapMonthlyInputsTableDataFromProps();
        }
    }, [cecl.forecastAssumptions?.detail?.data, cecl.calculatedLoss?.loanTypeLossForecastResults]);

    const mapMonthlyInputsTableDataFromProps = () => {
        var newRows = cecl.forecastAssumptions.detail.data.inputs
            .sort((a, b) => {
                return a.order! - b.order!;
            })
            .map((input, i) => {
                const result = cecl.calculatedLoss.loanTypeLossForecastResults.find(
                    (l) => l.analysisRequestTaskNumber === i + 1
                );

                var obj: any = {};
                obj['name'] = input.name;
                obj['Ending Balance'] = formatCurrencyNumber(result.loanTypeLossAnalysisRequest.endingBalance);
                obj['Number Of Loans'] = result.loanTypeLossAnalysisRequest.numberOfLoans;
                obj['Average Loan Rate (%)'] = result.loanTypeLossAnalysisRequest.averageLoanRatePercent;
                obj['Weighted Average Remaining Maturity (Months)'] =
                    result.loanTypeLossAnalysisRequest.weightedAverageRemainingMaturityMonths;
                obj['Dollar Amount Of Interest Only Loans'] =
                    formatCurrencyNumber(result.loanTypeLossAnalysisRequest.dollarAmountOfInterestOnlyLoans);
                obj['Historical Lookback Period (Years)'] =
                    result.loanTypeLossAnalysisRequest.historicalLookbackPeriodYear;
                obj['Prepayment Speeds'] =
                    result.loanTypeLossAnalysisRequest.prepaymentSpeedOverride > 0
                        ? result.loanTypeLossAnalysisRequest.prepaymentSpeedOverride
                        : result.loanTypeLossAnalysisRequest.catalystDefaultPrepaymentSpeeds;
                obj['Specific CU'] = result.historicalNetChargeOffsData.specificCreditUnion * 100;
                obj['All Credit Unions'] = result.historicalNetChargeOffsData.allCreditUnions * 100;
                obj['State Level Peer Group'] = result.historicalNetChargeOffsData.peerGroupByStateAndAssetSize * 100;
                obj['Loan Quality Qualitative Factor Adjustment (%)'] = result.loanTypeLossAnalysisRequest.loanQualityQualitativeAdjustmentFactor * 100;

                return obj;
            });

        setMonthlyInputsTableProps((prevState) => ({
            ...prevState,
            columns: defaultMonthlyInputsColumns,
            rows: newRows,
            loading: false,
        }));
    };

    return (
        <div className={props.noScroll ? classes.noScroll : null}>
            <Table
                loading={monthlyInputsTableProps.loading}
                columns={monthlyInputsTableProps.columns}
                dense={monthlyInputsTableProps.dense}
                rows={monthlyInputsTableProps.rows}
                keyColumn={monthlyInputsTableProps.keyColumn}
                selectable={monthlyInputsTableProps.selectable}
                hasBorder={monthlyInputsTableProps.hasBorder}
                hasSecondHeaderRow={true}
            />
        </div>
    );
};

export default ModelAssumptionsTable;
