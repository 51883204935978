import { SnackbarOrigin } from '@material-ui/core';
import { SnackbarKey, VariantType, SnackbarAction } from 'notistack';

export interface ConfigState {
    bottomPanel: boolean;
    sideMenu: boolean;
    modal: boolean;
    isLoggedIn: boolean;
    snackbarKey?: SnackbarKey;
}

export enum ConfigActionTypes {
    TOGGLE_BOTTOMPANEL = '@@config/TOGGLE_BOTTOMPANEL',
    TOGGLE_SIDEMENU = '@@config/TOGGLE_SIDEMENU',
    TOGGLE_MODAL = '@@config/TOGGLE_MODAL',
    DISPLAY_TOAST = '@@config/DISPLAY_TOAST',
    COOKIES_REQUEST = '@@config/COOKIES_REQUEST',
    COOKIES_SUCCESS = '@@config/COOKIES_SUCCESS',
    SAVE_COOKIES_REQUEST = '@@config/SAVE_COOKIES_REQUEST',
    SAVE_COOKIES_SUCCESS = '@@config/SAVE_COOKIES_SUCCESS',
    SAVE_SNACKBAR_KEY_REQUEST = '@@config/SAVE_SNACKBAR_KEY',
}

export interface ToastMessage {
    message: string;
    variant: VariantType;
    persist?: boolean;
    anchorOrigin?: SnackbarOrigin;
    action?: SnackbarAction;
}
