import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { CeclApiState } from '../../../../store/ceclapi/types';
import { colors } from '../../../../theme';
import { formatNumber } from '../../../../utils/helpers';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gap: {
            backgroundColor: colors.white,
        },
        paddingLess: {
            padding: 0,
        },
        header: {
            width: '100%',
            textAlign: 'center',
            fontSize: 22,
            color: colors.white,
            paddingLeft: theme.spacing(2),
            fontWeight: 'bold',
        },
        headerItem: {
            backgroundColor: colors.fadedBlue,
        },
        title: {
            width: '100%',
            textAlign: 'left',
            fontSize: 22,
            color: colors.white,
            paddingLeft: theme.spacing(2),
            fontWeight: 'bold',
        },
        value: {
            width: '100%',
            textAlign: 'center',
            fontSize: 22,
            color: colors.white,
            paddingLeft: theme.spacing(2),
            fontWeight: 'bold',
        },
        dialogDivider: {
            color: colors.black,
        },
        grid: {
            backgroundColor: colors.blue,
            marginBottom: theme.spacing(5),
        },
    })
);

const Allowances: React.FC = () => {
    const classes = useStyles();
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const percentOfTotalLoans = cecl.calculatedLoss?.loansAndLeasesLossesForCECLPercentOfTotalLoans
                                    ? cecl.calculatedLoss?.loansAndLeasesLossesForCECLPercentOfTotalLoans * 100
                                    : 0; 

    React.useEffect(() => {}, []);

    return (
        <Grid container spacing={2} className={classes.grid} id="allowances">
            <Grid item xs={6} className={classes.gap}>
                &nbsp;
            </Grid>
            <Grid item xs={3} className={classes.headerItem}>
                <Typography className={classes.header}>Dollar Amount:</Typography>
            </Grid>
            <Grid item xs={3} className={classes.headerItem}>
                <Typography className={classes.header}>Percent of Total Loans:</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography className={classes.title}>Allowances for Loan &amp; Lease Losses for CECL:</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography className={classes.value}>
                    $ {formatNumber(cecl.calculatedLoss?.loansAndLeasesLossesForCECLTotalDollarAmount)}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography className={classes.value}>
                    {formatNumber(percentOfTotalLoans)} %:
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Allowances;
