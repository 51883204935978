import React from 'react';
import { AppBarProps, Toolbar, IconButton, makeStyles, Theme, createStyles, Link } from '@material-ui/core';
import { CMSContent } from '../../containers/cmscontent/CMSContent';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(3)
        }
    })
);

export const Privacy = props => {
    const classes = useStyles();
    return <div className={classes.container}> <CMSContent type={'contentitem'} name={'privacy-policy'} /></div>;
  };