import { Action } from 'redux';

export interface CeclApiState {
    readonly request: any;
    readonly data: any;
    readonly loading: boolean;
    readonly user?: User;
    readonly historicalData?: HistoricalData;
    readonly pdf?: Pdf;
    readonly forecastHistory?: ForecastHistory;
    readonly forecastAssumptions?: ForecastAssumptions;
    readonly myAssumptionSet?: ForecastAssumptionsData;
    readonly calculatedLoss?: CalculatedLoss;
    readonly dashboardData?: DashboardData;
    readonly userList?: AdminUserManagementUsers;
    readonly messageConfig?: MessageConfig;
    readonly usefulDocumentsData?: UsefulDocumentsData;
}
export interface MessageConfig {
    messages: Message[],
    loading: boolean
}

export interface Message {
    route: string;
    messageText: string;
    expirationDate: Date;
    action: MessageAction;
}

export enum MessageAction {
    None = 'None',
    PermantelyDismissable = 'PermantelyDismissable'
}

export enum CeclApiActionTypes {
    CECLAPI_SUBMITCOMMENTS_REQUEST = '@@ceclapi/CECLAPI_SUBMITCOMMENTS_REQUEST',
    CECLAPI_SUBMITCOMMENTS_SUCCESS = '@@ceclapi/CECLAPI_SUBMITCOMMENTS_SUCCESS',
    CECLAPI_SUBMITCOMMENTS_ERROR = '@@ceclapi/CECLAPI_SUBMITCOMMENTS_ERROR',
    CECLAPI_AUTH_REQUEST = '@@ceclapi/CECLAPI_AUTH_REQUEST',
    CECLAPI_AUTH_SUCCESS = '@@ceclapi/CECLAPI_AUTH_SUCCESS',
    CECLAPI_AUTH_DENIED = '@@ceclapi/CECLAPI_AUTH_DENIED',
    CECLAPI_AUTH_LOGOUT = '@@ceclapi/CECLAPI_AUTH_LOGOUT',
    CECLAPI_AUTH_ERROR = '@@ceclapi/CECLAPI_AUTH_ERROR',
    CECLAPI_HISTORICALDATA_REQUEST = '@@ceclapi/CECLAPI_HISTORICALDATA_REQUEST',
    CECLAPI_HISTORICALDATA_SUCCESS = '@@ceclapi/CECLAPI_HISTORICALDATA_SUCCESS',
    CECLAPI_HISTORICALDATA_ERROR = '@@ceclapi/CECLAPI_HISTORICALDATA_ERROR',
    CECLAPI_CREATEPDF_REQUEST = '@@ceclapi/CECLAPI_CREATEPDF_REQUEST',
    CECLAPI_CREATEPDF_CLEAR = '@@ceclapi/CECLAPI_CREATEPDF_CLEAR',
    CECLAPI_CREATEPDF_COMPILEDATA = '@@ceclapi/CECLAPI_CREATEPDF_COMPILEDATA',
    CECLAPI_CREATEPDF_SUCCESS = '@@ceclapi/CECLAPI_CREATEPDF_SUCCESS',
    CECLAPI_CREATEPDF_ERROR = '@@ceclapi/CECLAPI_CREATEPDF_ERROR',
    CECLAPI_DRAFTFORECASTASSUMPTIONS_REQUEST = '@@ceclapi/CECL_DRAFTFORECASTASSUMPTIONS_REQUEST',
    CECLAPI_DRAFTFORECASTASSUMPTIONS_SUCCESS = '@@ceclapi/CECL_DRAFTFORECASTASSUMPTIONS_SUCCESS',
    CECLAPI_DRAFTFORECASTASSUMPTIONS_ERROR = '@@ceclapi/CECL_DRAFTFORECASTASSUMPTIONS_ERROR',
    CECLAPI_AdminForecastRoutingList_REQUEST = '@@ceclapi/CECLAPI_AdminForecastRoutingList_REQUEST',
    CECLAPI_AdminForecastRoutingList_SUCCESS = '@@ceclapi/CECLAPI_AdminForecastRoutingList_SUCCESS',
    CECLAPI_AdminForecastRoutingList_ERROR = '@@ceclapi/CECLAPI_AdminForecastRoutingList_ERROR',
    CECLAPI_ARCHIVEDFORECASTASSUMPTIONS_REQUEST = '@@ceclapi/CECL_ARCHIVEDFORECASTASSUMPTIONS_REQUEST',
    CECLAPI_ARCHIVEDFORECASTASSUMPTIONS_SUCCESS = '@@ceclapi/CECL_ARCHIVEDFORECASTASSUMPTIONS_SUCCESS',
    CECLAPI_ARCHIVEDFORECASTASSUMPTIONS_ERROR = '@@ceclapi/CECL_ARCHIVEDFORECASTASSUMPTIONS_ERROR',
    CECLAPI_DELETEASSUMPTIONS_REQUEST = '@@ceclapi/CECL_DELETEASSUMPTIONS_REQUEST',
    CECLAPI_DELETEASSUMPTIONS_SUCCESS = '@@ceclapi/CECL_DELETEASSUMPTIONS_SUCCESS',
    CECLAPI_DELETEASSUMPTIONS_ERROR = '@@ceclapi/CECL_DELETEASSUMPTIONS_ERROR',
    CECLAPI_FORECASTCONFIGURATION_REQUEST = '@@ceclapi/CECL_FORECASTCONFIGURATION_REQUEST',
    CECLAPI_FORECASTCONFIGURATION_SUCCESS = '@@ceclapi/CECL_FORECASTCONFIGURATION_SUCCESS',
    CECLAPI_FORECASTCONFIGURATION_ERROR = '@@ceclapi/CECL_FORECASTCONFIGURATION_ERROR',
    CECLAPI_MESSAGESCONFIGURATION_REQUEST = '@@ceclapi/CECL_MESSAGESCONFIGURATION_REQUEST',
    CECLAPI_MESSAGESCONFIGURATION_SUCCESS = '@@ceclapi/CECL_MESSAGESCONFIGURATION_SUCCESS',
    CECLAPI_MESSAGESCONFIGURATION_ERROR = '@@ceclapi/CECL_MESSAGESCONFIGURATION_ERROR',
    CECLAPI_FREDINDEXES_REQUEST = '@@ceclapi/CECL_FREDINDEXES_REQUEST',
    CECLAPI_FREDINDEXES_SUCCESS = '@@ceclapi/CECL_FREDINDEXES_SUCCESS',
    CECLAPI_FREDINDEXES_ERROR = '@@ceclapi/CECL_FREDINDEXES_ERROR',
    CECLAPI_FORECASTASSUMPTIONSDETAIL_REQUEST = '@@ceclapi/CECL_FORECASTASSUMPTIONSDETAIL_REQUEST',
    CECLAPI_FORECASTASSUMPTIONSDETAIL_SUCCESS = '@@ceclapi/CECL_FORECASTASSUMPTIONSDETAIL_SUCCESS',
    CECLAPI_FORECASTASSUMPTIONSDETAIL_ERROR = '@@ceclapi/CECL_FORECASTASSUMPTIONSDETAIL_ERROR',
    CECLAPI_FORECASTASSUMPTIONSSAVE_REQUEST = '@@ceclapi/CECL_FORECASTASSUMPTIONSSAVE_REQUEST',
    CECLAPI_FORECASTASSUMPTIONSSAVE_SUCCESS = '@@ceclapi/CECL_FORECASTASSUMPTIONSSAVE_SUCCESS',
    CECLAPI_FORECASTASSUMPTIONSSAVE_ERROR = '@@ceclapi/CECL_FORECASTASSUMPTIONSSAVE_ERROR',
    CECLAPI_FORECASTASSUMPTIONSEDIT_REQUEST = '@@ceclapi/CECL_FORECASTASSUMPTIONSEDIT_REQUEST',
    CECLAPI_FORECASTASSUMPTIONSEDIT_SUCCESS = '@@ceclapi/CECL_FORECASTASSUMPTIONSEDIT_SUCCESS',
    CECLAPI_FORECASTASSUMPTIONSEDIT_ERROR = '@@ceclapi/CECL_FORECASTASSUMPTIONSEDIT_ERROR',
    CECLAPI_FORECASTHISTORY_REQUEST = '@@ceclapi/CECLAPI_FORECASTHISTORY_REQUEST',
    CECLAPI_FORECASTHISTORY_SUCCESS = '@@ceclapi/CECLAPI_FORECASTHISTORY_SUCCESS',
    CECLAPI_FORECASTHISTORY_ERROR = '@@ceclapi/CECLAPI_FORECASTHISTORY_ERROR',

    CECLAPI_FORECASTTOGGLEFINAL_REQUEST = '@@ceclapi/CECLAPI_FORECASTTOGGLEFINAL_REQUEST',
    CECLAPI_FORECASTTOGGLEFINAL_SUCCESS = '@@ceclapi/CECLAPI_FORECASTTOGGLEFINAL_SUCCESS',
    CECLAPI_FORECASTTOGGLEFINAL_ERROR = '@@ceclapi/CECLAPI_FORECASTTOGGLEFINAL_ERROR',

    CECLAPI_DELETE_FORECAST_REQUEST = '@@ceclapi/CECLAPI_DELETE_FORECAST_REQUEST',
    CECLAPI_DELETE_FORECAST_SUCCESS = '@@ceclapi/CECLAPI_DELETE_FORECAST_SUCCESS',
    CECLAPI_DELETE_FORECAST_ERROR = '@@ceclapi/CECLAPI_DELETE_FORECAST_ERROR',
    CECLAPI_CALCULATELOSS_REQUEST = '@@ceclapi/CECLAPI_CALCULATELOSS_REQUEST',
    CECLAPI_CALCULATELOSS_SUCCESS = '@@ceclapi/CECLAPI_CALCULATELOSS_SUCCESS',
    CECLAPI_CALCULATELOSS_CLEAR = '@@ceclapi/CECLAPI_CALCULATELOSS_CLEAR',
    CECLAPI_CALCULATELOSS_ERROR = '@@ceclapi/CECLAPI_CALCULATELOSS_ERROR',
    CECLAPI_NCUAHISTORICALDATA_REQUEST = '@@ceclapi/CECLAPI_NCUACATEGORIES_REQUEST',
    CECLAPI_NCUAHISTORICALDATA_SUCCESS = '@@ceclapi/CECLAPI_NCUACATEGORIES_SUCCESS',
    CECLAPI_NCUAHISTORICALDATA_ERROR = '@@ceclapi/CECLAPI_NCUACATEGORIES_ERROR',
    CECLAPI_NCUADASHBOARDDATA_REQUEST = '@@ceclapi/CECLAPI_NCUADASHBOARDDATA_REQUEST',
    CECLAPI_NCUADASHBOARDDATA_SUCCESS = '@@ceclapi/CECLAPI_NCUADASHBOARDDATA_SUCCESS',
    CECLAPI_NCUADASHBOARDDATA_ERROR = '@@ceclapi/CECLAPI_NCUADASHBOARDDATA_ERROR',

    CECLAPI_DOCUMENT_REQUEST = '@@ceclapi/CECLAPI_DOCUMENT_REQUEST',
    CECLAPI_DOCUMENT_SUCCESS = '@@ceclapi/CECLAPI_DOCUMENT_SUCCESS',
    CECLAPI_DOCUMENT_ERROR = '@@ceclapi/CECLAPI_DOCUMENT_ERROR',

    CECLAPI_ADMIN_USERMANAGEMENT_REQUEST = '@@ceclapi/CECL_ADMIN_USERMANAGEMENT_REQUEST',
    CECLAPI_ADMIN_USERMANAGEMENT_SUCCESS = '@@ceclapi/CECL_ADMIN_USERMANAGEMENT_SUCCESS',
    CECLAPI_ADMIN_USERMANAGEMENT_ERROR = '@@ceclapi/CECL_ADMIN_USERMANAGEMENT_ERROR',
    CECLAPI_ADMIN_USERMANAGEMENT_SUBSCRIPTION_UPDATE = '@@ceclapi/CECL_ADMIN_USERMANAGEMENT_SUBSCRIPTION_UPDATE',
    CECLAPI_ADMIN_USERMANAGEMENT_SUBSCRIPTION_SUCCESS = '@@ceclapi/CECL_ADMIN_USERMANAGEMENT_SUBSCRIPTION_SUCCESS',
    CECLAPI_ADMIN_USERMANAGEMENT_SUBSCRIPTION_ERROR = '@@ceclapi/CECL_ADMIN_USERMANAGEMENT_SUBSCRIPTION_ERROR',

    CECLAPI_USEFULDOCUMENTS_REQUEST = '@@ceclapi/CECLAPI_USEFULDOCUMENTS_REQUEST',
    CECLAPI_USEFULDOCUMENTS_SUCCESS = '@@ceclapi/CECLAPI_USEFULDOCUMENTS_SUCCESS',
    CECLAPI_USEFULDOCUMENTS_ERROR = '@@ceclapi/CECLAPI_USEFULDOCUMENTS_ERROR',
}

export enum CeclStateActionTypes {
    CECLSTATE_FORECASTASSUMPTIONS_RESET = '@@ceclstate/CECL_FORECASTASSUMPTIONS_RESET',
    CECLSTATE_FORECASTOTHERMODELPARAMETERS_UPDATE = '@@ceclstate/CECL_FORECASTOTHERMODELPARAMETERS_UPDATE',
    CECLSTATE_FORECASTSINGLEMODELPARAMETER_UPDATE = '@@ceclstate/CECL_FORECASTSINGLEMODELPARAMETER_UPDATE',
    CECLSTATE_FORECASTSINGLEMONTHLYINPUT_UPDATE = '@@ceclstate/CECL_FORECASTSINGLEMONTHLYINPUT_UPDATE',
    CECLSTATE_FORECASTSINGLESPECIALLOAN_UPDATE = '@@ceclstate/CECL_FORECASTSINGLESPECIALLOANS_UPDATE',
}

export interface ContactFormValues {
    FirstName: string;
    LastName: string;
    Company: string;
    FromEmailAddress: string;
    PhoneNumber: string;
    Message: string;
}

export interface User {
    loading: boolean;
    isAuthenticated: boolean;
    userType: UserTypes;
    roles?: null[] | null;
    claims?: null[] | null;
    userObjectId?: null;
    name?: null;
    givenName?: null;
    surName?: null;
    authenticationType?: null;
    authenticationTime?: null;
    routingNumber?: string;
    email?: null;
    creditUnionAccountId?: null;
    creditUnionState?: null;
    creditUnionName?: null;
    disableUpload: boolean;
    disableReports: boolean;
    phoneNumber?: null;
    jobTitle?: null;
}

export enum UserTypes {
    Anonymous = 0,
    Authenticated = 1,
    Subscribed = 2,
    Admin = 8,
}

export class CeclApiSubmitCommentsRequestAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_SUBMITCOMMENTS_REQUEST;

    constructor(public payload: any) {}
}

export class CeclApiForecastToggleFinalRequestAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_FORECASTTOGGLEFINAL_REQUEST;

    constructor(public payload: string) {}
}

export class CeclApiAuthRequestAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_AUTH_REQUEST;

    constructor(public payload: any) {}
}

export class CeclApiCreatePdfRequestAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_CREATEPDF_REQUEST;

    constructor(public payload: PdfRequest) {}
}

export class CeclApiHistoricalDataRequestAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_HISTORICALDATA_REQUEST;

    constructor(public payload: HistoricalDataRequest) {}
}

export class CeclApiCalculateLossRequestAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_CALCULATELOSS_REQUEST;

    constructor(public payload: ForecastAssumptionsData) {}
}

export class CeclApiForecastHistoryRequestAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_FORECASTHISTORY_REQUEST;

    constructor(public payload: ForecastHistoryRequest) {}
}

export class CeclApiDeleteForecastRequestAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_DELETE_FORECAST_REQUEST;

    constructor(public payload: DeleteForecastRequest) {}
}

export class CeclApiDocumentRequestAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_DOCUMENT_REQUEST;

    constructor(public payload: DocumentRequest) {}
}

export interface DocumentRequest {
    forecastId: string;
    base64Response: boolean;
    fileName?: string;
}

export interface Pdf {
    loading?: boolean;
    data?: string;
}

export interface HistoricalData {
    loading?: boolean;
    charts?: Chart[];
    allChartNames?: string[];
}

export interface HistoricalDataRequest {
    cuid?: string;
    timeHorizon?: string;
}

export interface ForecastHistory {
    loading?: boolean;
    summaries?: AssumptionSummary[];
}

export interface ForecastHistoryRequest {
    cuid?: string;
}

export interface CalculateLossRequest {
    cuId?: string;
    forecastId?: string;
    economicIndex?: string;
    dataLag?: number;
}

export interface PdfRequest {
    fileName?: string;
    pages?: PdfPage[];
    base64Response?: boolean;
    fileId?: string;
    pagesHeaderFooter?: PdfPageHeaderFooter[];
}

export interface PdfPage {
    id?: string;
    ordinal?: number;
    image?: string;
    data?: any;
    stretch?: boolean;
    newPage?: boolean;
    width?: string;
}

export interface PdfPageHeaderFooter {
    id?: string;
    image?: string;
    stretch?: boolean;
    isHeader?: boolean;
}

export interface ChartOptions {
    animationEnabled?: boolean;
    backgroundColor?: string;
    title?: ChartTitle;
    legend?: ChartLabel;
    legendDisplay?: 'none' | 'bottom' | 'left' | 'right';
    legendTitle?: string;
    axisX?: ChartLabel;
    axisY?: ChartLabel;
    axisY2?: ChartLabel;
    colorSet?: string;
    dataPointMaxWidth?: number;
    dataSetNamesForYAxis1?: string[];
    dataSetNamesForYAxis2?: string[];
}

export interface ChartYAxis {
    title?: string;
}

export interface Chart {
    graphName?: string;
    data?: ChartData[];
}
export interface ChartData {
    type?: string;
    markerType?: string;
    lineDashType?: string;
    lineThickness?: number;
    visible?: boolean;
    name?: string;
    color?: string;
    dataPoints?: DataPoint[];
    axisYIndex?: number;
    axisYType?: string;
}

export interface DataPoint {
    x: any;
    y: any;
    color?: string;
    label?: string;
}

export interface ChartTitle {
    text?: string;
    fontSize?: number;
    fontFamily?: string;
    fontWeight?: string;
    margin?: number;
}

export interface ChartLabel {
    labelFontFamily?: string;
    labelFontSize?: number;
    labelFontColor?: string;
    labelFontWeight?: string;
    labelFontStyle?: string;
    valueFormatString?: string;
    tickLength?: number;
    lineColor?: string;
    gridColor?: string;
}

export class CeclApiDraftForecastAssumptionsRequestAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_DRAFTFORECASTASSUMPTIONS_REQUEST;

    constructor(public payload: ForecastAssumptionsRequest) {}
}

export class CeclApiArchivedForecastAssumptionsRequestAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_ARCHIVEDFORECASTASSUMPTIONS_REQUEST;

    constructor(public payload: ForecastAssumptionsRequest) {}
}

export interface ForecastAssumptionsRequest {
    cuid?: string;
}

export interface ForecastAssumptions {
    loading?: boolean;
    configuration?: ForecastConfiguration;
    drafts?: AssumptionSummary[];
    archived?: AssumptionSummary[];
    detail?: ForecastAssumptionsDetail;
    indexes?: FredIndexConfiguration;
    forecastAdminRoutingList?: RoutingNumbers[];
}

export interface FredIndexConfiguration {
    loading?: boolean;
    indexList?: FredIndex[];
}

export interface FredIndex {
    tableName?: string;
    fredSeriesId?: string;
    stateCode?: string;
    fredLabel?: string;
}

export interface FredIndexesRequest {
    cuid?: string;
}

export class CeclApiFredIndexesRequestAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_FREDINDEXES_REQUEST;

    constructor(public payload: FredIndexesRequest) {}
}

export interface RoutingNumbers {
    routing: string;
    cuName: string;
}

export interface AssumptionSummary {
    id: string;
    name: string;
    analysisDate: Date;
    isFinal: boolean;
    dateModified: Date;
    modifiedBy: string;
    dateCreated: Date;
    createdBy: string;
}
export class CeclApiAdminForecastRoutingListRequestAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_AdminForecastRoutingList_REQUEST;

    constructor(public payload: Empty) {}
}
export interface Empty{}

export class CeclApiDeleteAssumptionsRequestAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_DELETEASSUMPTIONS_REQUEST;

    constructor(public payload: DeleteAssumptionsRequest) {}
}

export interface DeleteAssumptionsRequest {
    cuid?: string;
    assumptionId?: string;
}

export interface DeleteAssumptionsResult {
    success?: boolean;
    assumptionId?: string;
}

export class CeclApiForecastConfigurationRequestAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_FORECASTCONFIGURATION_REQUEST;

    constructor() {}
}

export class CeclApiMessagesConfigurationRequestAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_MESSAGESCONFIGURATION_REQUEST;

    constructor() {}
}

export interface ForecastConfiguration {
    loading?: boolean;
    config?: ConfigurationSet;
}

export interface ConfigurationSet {
    parameters?: ForecastParameter[];
    inputs?: ForecastInput[];
    specialClassificationLoans?: ForecastSpecialClassificationLoan[];
}

export interface ForecastParameter {
    order?: number;
    name?: string;
    historicalLookbackPeriod?: number;
    catalystDefaultPrepaymentSpeeds?: number;
    prepaymentSpeedOverride?: number;
    loanQualityFactorAdjustment?: number; // Divide by 100000
    error?: boolean;
}

export interface ForecastInput {
    name?: string;
    order?: number;
    endingBalance?: number;
    numberOfLoans?: number;
    averageLoanRate?: number;
    weightedAverageRemainingMaturity?: number;
    dollarAmountOfInterestOnlyLoans?: number;
    error?: boolean;
}

export interface ForecastSpecialClassificationLoan {
    name?: string;
    order?: number;
    allocation?: number;
    specificLoanLossEndingBalance?: number;
    error?: boolean;
}

export class CeclApiForecastAssumptionsDetailRequestAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_FORECASTASSUMPTIONSDETAIL_REQUEST;

    constructor(public payload: ForecastAssumptionsDetailRequest) {}
}

export interface ForecastAssumptionsDetailRequest {
    cuid?: string;
    assumptionId?: string;
}

export interface ForecastAssumptionsDetail {
    data?: ForecastAssumptionsData;
    loading?: boolean;
}

export interface ForecastAssumptionsData {
    id?: string;
    type?: string;
    name?: string;
    analysisDate?: string;
    economicIndex?: string;
    economicQualitativeFactorAdjustmentPercentage?: number; // Divide by 100
    cu?: string;
    status?: string;
    isFinal?: boolean;
    dateCreated?: Date;
    createdBy?: string;
    modifiedBy?: string;
    dateModified?: Date;
    dataLag?: number;
    lookBack?: number;
    parameters?: ForecastParameter[];
    inputs?: ForecastInput[];
    specialClassificationLoans?: ForecastSpecialClassificationLoan[];
    forecastTimeHorizon?: number;
    historicalTimeHorizon?: number;
}

export interface ForecastAssumptionsParameters {
    name?: string;
    analysisDate?: string;
    economicIndex?: string;
    economicQualitativeFactorAdjustmentPercentage?: number;
    dataLag?: number;
    parameters?: ForecastParameter[];
}

export class CeclApiForecastAssumptionsSaveRequestAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_FORECASTASSUMPTIONSSAVE_REQUEST;

    constructor(public payload: ForecastAssumptionsSaveRequest) {}
}

export interface ForecastAssumptionsSaveRequest {
    assumptionsData?: ForecastAssumptionsData;
}

export interface DeleteForecastRequest {
    cuid?: string;
    id?: string;
}

export interface LoanTypeLossAnalysisRequest {
    loanType: number;
    historicalLookbackPeriodYear: number;
    catalystDefaultPrepaymentSpeeds: number;
    prepaymentSpeedOverride: number;
    loanQualityQualitativeAdjustmentFactor: number;
    endingBalance: number;
    numberOfLoans: number;
    averageLoanRatePercent: number;
    weightedAverageRemainingMaturityMonths: number;
    dollarAmountOfInterestOnlyLoans: number;
    specificLoanLossEndingBalance: number;
    allocation: number;
}

export interface EconomicIndexLinearCoefficients {
    item1: number;
    item2: number;
}

export interface LossRegressionResults {
    economicIndexLinearCoefficients: EconomicIndexLinearCoefficients;
    cUsAgainstEconomicIndexPolynomialCoefficients: number[];
    cuAgainstCUsPolynomialCoefficients: number[];
    assetSizeAgainstCUPolynomialCoefficients: number[];
    usStateAndAssetSizeAgainstCUPolynomialCoefficients: number[];
}

export interface AmortizationSchedule {
    paymentNumber: number;
    paymentDate: Date;
    beginningBalance: number;
    pandIPayment: number;
    interestPayment: number;
    principalPayment: number;
    prepaymentSMM: number;
    projectedLossRatio: number;
    monthlyLosses: number;
    prepayment: number;
    endingBalance: number;
    forecastedEconomicIndexAtPaymentDate: number;
    qFactorAdjustedForecastedEconomicIndexAtPaymentDate: number;
    forecastedAllCUsValueAtEconomicIndicator: number;
    forecastedSpecificCULossAtAllCUsValue: number;
    qFactorAdjustedForecastedSpecificCULossAtAllCUsValue: number;
}

export interface LossForecastResult {
    cuId: string;
    unityFactorizedEconomicQualitativeAdjustmentFactor: number;
    analysisRequestTaskNumber: number;
    dispatchTimeUTC: Date;
    completionTimeUTC: Date;
    loanTypeLossAnalysisRequest: LoanTypeLossAnalysisRequest;
    economicIndex: string;
    dataRetrievalTimeHorizonEndDate: Date;
    dataRetrievalTimeHorizonStartDate: Date;
    analysisDate: Date;
    lossRegressionResults: LossRegressionResults;
    backcastingPandILoanAmortizationSchedule: AmortizationSchedule[];
    pandILoanAmortizationSchedule: AmortizationSchedule[];
    interestOnlyLoansAmortizationSchedule: AmortizationSchedule[];
    expectedLifetimeLossesPercent: number;
    estimatedCurrentExpectedCreditLossesDollarAmount: number;
    year1Losses: number;
    year2Losses: number;
    year2To5Losses: number;
    historicalNetChargeOffsData: HistoricalNetChargeOffsData;
}

export interface CalculatedLoss {
    loading?: boolean;
    cuId?: string;
    analysisDate?: Date;
    quarterAdjustedAnalysisDate?: Date;
    polynomialRegressionDataRetrievalTimeHorizonEndDate?: Date;
    economicIndexRegressionDataRetrievalTimeHorizonEndDate?: Date;
    dataLagMonths?: number;
    economicIndex?: string;
    economicQualitativeAdjustmentFactor?: number;
    unityFactorizedEconomicQualitativeAdjustmentFactor?: number;
    loanTypeLossForecastResults?: LossForecastResult[];
    loansAndLeasesLossesForCECLTotalDollarAmount?: number;
    totalAmountOfLoansAndLeasesEndingBalances?: number;
    loansAndLeasesLossesForCECLPercentOfTotalLoans?: number;
    timeHorizonHistoryAndPredictionsData?: TimeHorizonHistoryAndPredictionsData;
    viewForecastChartingData?: ChartingData;
}

export interface TimeHorizonHistoryAndPredictionsData {
    allLoanTypesEndingBalanceWeightedForecastDataPoints: LossesDataPoint[];
    allLoanTypesEndingBalanceWeightedBackcastDataPoints: LossesDataPoint[];
    allLoanTypesEndingBalanceWeightedHistoricalDataPoints: LossesDataPoint[];
    economicIndexHistoricalDataPoints: LossesDataPoint[];
    endingBalanceWeightedHistoricalDataPointsGroupedByLoanType: Array<LossesDataPoint[]>;
    annualizedLossPctHistoricalDataPointsGroupedByLoanType: Array<LossesDataPoint[]>;
}

export interface ChartingData {
    historicalPlusForecast: LossesDataPoint[];
    backcastPlusForecast: LossesDataPoint[];
    historicalOnly: LossesDataPoint[];
    economicIndexHistoricalPlusForecast: LossesDataPoint[];
}

export interface HistoricalNetChargeOffsData {
    specificCreditUnion: number;
    allCreditUnions: number;
    peerGroupByStateAndAssetSize: number;
}

export interface LossesDataPoint {
    item1: Date;
    item2: any;
    item3: any;
    item4: any;
    item5: any;
    item6: any;
}

export interface DeleteForecastRequest {
    cuid?: string;
    id?: string;
}

export class CeclApiNcuaHistoricalDataRequestAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_NCUAHISTORICALDATA_REQUEST;

    constructor(public payload: NcuaHistoricalDataRequest) {}
}

export interface NcuaHistoricalDataRequest {
    cuid?: string;
    lookback?: string;
}

export interface NcuaHistoricalDataResponse {
    charts?: Chart[];
    allChartNames: string[];
}

export interface NcuaDashboardInfoRequest {
    cuid?: string;
}

export interface DashboardData {
    info?: DashboardInfo;
    loans?: Map<string, number>;
    loading?: boolean;
}

export interface DashboardInfo {
    charterNumber?: number;
    cycleDate?: Date;
    ceclPeerGroupDescription?: string;
    totalAssets?: number;
    cash?: number;
    totalInvestments?: number;
    netLoans?: number;
    totalDeposits?: number;
    totalBorrowings?: number;
    totalEquity?: number;
    ytdNetIncome?: number;
    unsecuredLoans?: number;
    paydayAltLoans?: number;
    nonFedGuaranteedStudentLoans?: number;
    newVehicleLoans?: number;
    usedVehicleLoans?: number;
    firstMtgReLoans?: number;
    otherReLoans?: number;
    allOtherLoans?: number;
    assetYields?: number;
    costOfFunds?: number;
    netOperatingExpense?: number;
    provisionExpense?: number;
    loansToShares?: number;
    loansToAssets?: number;
    cashAndSTInvToAssets?: number;
    delinquentLoansToNetLoans?: number;
    twoToSixMonthsDelinquent?: number;
    sixToTwelveMonthsDelinquent?: number;
    greaterThanTwelveMonthsDelinquent?: number;
}

export class CeclApiNcuaDashboardDataRequestAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_NCUADASHBOARDDATA_REQUEST;

    constructor(public payload: NcuaDashboardDataRequest) {}
}

export interface NcuaDashboardDataRequest {
    cuid?: string;
}

export interface DashboardData {
    info?: DashboardInfo;
    loans?: Map<string, number>;
    loading?: boolean;
    charts?: Chart[];
}

export interface DashboardDataResponse {
    info?: DashboardInfo;
    loans?: Map<string, number>;
    charts?: Chart[];
}

export class CeclApiAdminUserManagementRequestAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_ADMIN_USERMANAGEMENT_REQUEST;

    constructor(public payload: AdminUserManagementRequest) {}
}

export interface AdminUserManagementRequest {}

export class AdminUserManagementUserUserRole {
    role?: string;
    removed?: boolean;
    updated?: string;
}

export interface AdminUserManagementUserData {
    id?: string;
    rtNumber?: string;
    creditUnionName?: string;
    surname?: string;
    givenName?: string;
    originatingApplication?: string;
    emailAddress?: string;
    phoneNumber?: string;
    jobTitle?: string;
    createdDate?: string;
    userRoles?: AdminUserManagementUserUserRole[];
    roles?: string[];
}

export interface AdminUserManagementUsers {
    loading?: boolean;
    users?: AdminUserManagementUserData[];
}

export interface AdminUserManagementSubscriptionUpdate {
    id: string;
    subscribed: string;
}

export interface AdminUserManagementSubscriptionUpdateResponse {
    id: string;
    subscribed: string;
}

export class CeclApiAdminUserManagementSubscriptionUpdateAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_ADMIN_USERMANAGEMENT_SUBSCRIPTION_UPDATE;

    constructor(public payload: AdminUserManagementSubscriptionUpdate) {}
}

export interface ValidationError {
    fieldName?: string;
    errorText?: string;
}

export class CeclApiUsefulDocumentsRequestAction implements Action {
    readonly type = CeclApiActionTypes.CECLAPI_USEFULDOCUMENTS_REQUEST;

    constructor() {}
}

export interface UsefulDocumentsData {
    documents?: UsefulDocuments[];
    loading?: boolean;
}

export interface UsefulDocuments {
    uniqueId: string;
    title: string;
    path: string;
}

export interface UsefulDocumentsDataResponse {
    documents?: UsefulDocuments[];
}