import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { ConfigState } from '../../store/config/types';
import { useEffect } from 'react';
import { colors } from './../../theme';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CMSContent } from './../../containers/cmscontent/CMSContent';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: '100%',
            flexDirection: 'column',
            display: 'flex',
            margin: 0,
            position: 'relative',
        },
        title: {
            // fontSize: 14,
        },
        blueBar: {
            backgroundColor: colors.blue,
            color: colors.white,
            fontWeight: 600,
        },
        center: {
            margin: '0 auto',
            width: '60%',
        },
        bar: {
            display: 'flex',
            position: 'relative',
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
        },
    })
);

const LandingModelOverviewPage: React.FC = () => {
    const dispatch = useDispatch();
    const config = useSelector<ApplicationState, ConfigState>((state) => state.config);
    const classes = useStyles();

    useEffect(() => {}, []);

    return (
        <div className={classes.root}>

            <div className={classes.bar}>
                <div className={classes.center}>
                    <CMSContent type="contentitem" name="model-bulletins" props={classes.center}/>
                </div>
            </div>

            <div className={clsx(classes.blueBar, classes.bar)}>
                <div className={classes.center}>
                    <CMSContent type="contentitem" name="model-warm" props={classes.center}/>
                </div>
            </div>

            <div className={classes.bar}>
                <div className={classes.center}>
                    <CMSContent type="contentitem" name="model-methods" props={classes.center}/>
                </div>
            </div>
        </div>
    );
};

export default LandingModelOverviewPage;
