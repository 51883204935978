import React from 'react';
import { AppBarProps, Toolbar, IconButton, makeStyles, Theme, createStyles, Link } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { colors } from '../../theme';
import { CMSContent } from '../../containers/cmscontent/CMSContent';
import { classicNameResolver } from 'typescript';
import { ThemeConsumer } from 'styled-components';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(3)
        }
    })
);



export const TermsOfUsePage = props => {
    const classes = useStyles();
    return <div className={classes.container}> <CMSContent type={'contentitem'} name={'terms-of-use'} /></div>;
  };