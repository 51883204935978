import { createStyles, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Panel from '../../../../components/panel/Panel';
import ForecastSpecialClassificationLoansTable from '../../../../containers/tables/ForecastSpecialClassificationLoansTable';
import { ApplicationState } from '../../../../store';
import { ceclstateForecastSingleSpecialLoanUpdate } from '../../../../store/ceclapi/actions';
import { CeclApiState, ForecastSpecialClassificationLoan, ValidationError } from '../../../../store/ceclapi/types';
import { colors } from '../../../../theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paddingLess: {
            padding: 0,
        },
        title: {
            width: '100%',
            textAlign: 'left',
            fontSize: 35,
            paddingLeft: theme.spacing(2),
        },
        dialogDivider: {
            color: colors.black,
        },
        disclaimers: {
            margin: theme.spacing(2),
        },
        asteriskText: {
            fontStyle: 'italic',
        },
        secondHeader: {
            fontWeight: 'bold',
            backgroundColor: colors.lightGray4,
            textAlign: 'center',
        },
    })
);

export interface ForecastSpecialClassificationLoanProps{
    specialLoansWithErrors?: ValidationError[];
    specialClassificationLoansFromCsv?: ForecastSpecialClassificationLoan[];
}

const ForecastSpecialClassificationLoans: React.FC<ForecastSpecialClassificationLoanProps> = (props) => {
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [specialClassificationLoans, setSpecialClassificationLoans] = React.useState(
        [] as ForecastSpecialClassificationLoan[]
    );

    const specialClassificationLoansFromCsv = props.specialClassificationLoansFromCsv;

    React.useEffect(()=> {
        if(specialClassificationLoansFromCsv){
            setSpecialClassificationLoans(specialClassificationLoansFromCsv);
        }
    }, [specialClassificationLoansFromCsv])

    React.useEffect(() => {
        if (cecl.forecastAssumptions?.configuration?.config?.specialClassificationLoans) {
            setSpecialClassificationLoans([
                ...cecl.forecastAssumptions!.configuration!.config!.specialClassificationLoans!,
            ]);
        }
    }, []);

    React.useEffect(() => {
        if (cecl.forecastAssumptions?.configuration?.config?.specialClassificationLoans) {
            setSpecialClassificationLoans(
                JSON.parse(JSON.stringify(cecl.forecastAssumptions!.configuration!.config!.specialClassificationLoans!))
            );
        }
    }, [cecl.forecastAssumptions?.configuration]);

    React.useEffect(() => {
        if (!specialClassificationLoans.length || !cecl.forecastAssumptions?.detail?.data) {
            return;
        }
        var currentLoans = JSON.parse(JSON.stringify(cecl.forecastAssumptions!.configuration.config.specialClassificationLoans));
        var newLoans = [...cecl.forecastAssumptions!.detail!.data.specialClassificationLoans!];

        for (var i = 0; i < newLoans!.length; i++) {
            for (var j = 0; j < currentLoans!.length; j++) {
                if (newLoans![i].name == currentLoans![j].name) {
                    currentLoans![j] = newLoans![i];
                }
            }
        }

        setSpecialClassificationLoans(currentLoans);
    }, [cecl.forecastAssumptions?.detail]);

    React.useEffect(() => {
        var mySpecialLoans = [...specialClassificationLoans];

        if(mySpecialLoans.length == 0){
            return;
        }
     
        mySpecialLoans.forEach(specialLoans => {
            specialLoans.error = false
        });

        props.specialLoansWithErrors?.map(specialLoanError => specialLoanError.fieldName).forEach(specialLoanName => {
            var specialLoanIndex = mySpecialLoans.findIndex(specialLoan => specialLoan.name == specialLoanName);
            if(specialLoanIndex >= 0){
                mySpecialLoans[specialLoanIndex].error = true;
            } 
        });

        setSpecialClassificationLoans(mySpecialLoans);
    }, [props.specialLoansWithErrors])

    function handleTableEdit(event: ChangeEvent<HTMLInputElement>, rowIndex: number, colName: string) {
        var loanIndex = specialClassificationLoans.findIndex((loan) => loan.order == rowIndex + 1);
        if (loanIndex < 0) {
            return;
        }

        if (event === null || event === ''){
            event = '0';
        }

        var value = parseFloat((event as unknown) as string);
        var newLoans = [...specialClassificationLoans];
        switch (colName) {
            case 'Specific Loan Loss Ending Balances':
                newLoans[loanIndex].specificLoanLossEndingBalance = value;
                break;
            case 'Allocation':
                newLoans[loanIndex].allocation = value;
                break;
            default:
                break;
        }
        var loan = newLoans[loanIndex];
        dispatch(ceclstateForecastSingleSpecialLoanUpdate(loan));
        setSpecialClassificationLoans(newLoans);
    }

    return (
        <div>
            <Panel className={classes.paddingLess}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography className={classes.title}>
                            Special Classification Loans / Carve Outs (Optional)
                        </Typography>
                        <Divider variant="middle" className={classes.dialogDivider} />
                    </Grid>
                    <Grid item>
                        <Grid item container direction="row" justify="flex-end">
                            <Grid item xs={5}></Grid>
                            <Grid item xs={7}>
                                <Typography className={classes.secondHeader}>
                                    Special Classification Loans***
                                </Typography>
                            </Grid>
                        </Grid>
                        <ForecastSpecialClassificationLoansTable
                            specialClassificationLoans={specialClassificationLoans}
                            handleTableEdit={handleTableEdit}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.disclaimers}>
                        <Typography className={classes.asteriskText}>
                            *** Balances entered represent specific loan carve outs that the credit union desires to
                            allocate specific losses for. The amounts classified here will be excluded from the loss
                            forecast.
                        </Typography>
                    </Grid>
                </Grid>
            </Panel>
        </div>
    );
};

export default ForecastSpecialClassificationLoans;
