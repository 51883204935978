import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  IconButton,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowUp } from '@material-ui/icons';
import FileDownloadButton from '../../components/button/FileDownloadButton';
import { orderBy } from 'lodash';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/modal/Modal';
import Pdf from '../../containers/pdf/Pdf';
import { ApplicationState } from '../../store';
import { CeclApiState, UsefulDocuments } from '../../store/ceclapi/types';
import { ceclapiUsefulDocumentsRequest }
from '../../store/ceclapi/actions';
import { colors } from '../../utils/theme';
import { v4 as uuid } from 'uuid';
import FileViewButton from '../../components/button/FileViewButton';

const groupBy: (arr: object[], keyGetter: Function) => Map<string, object[]> = (arr, keyGetter) => {
  const map = new Map();
  arr?.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

interface UDocument {
  uniqueId: string;
  title: string;
  path: string;
}

const UsefulDocumentsPage: React.FC = props => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  const [expanded, setExpanded] = React.useState<number | boolean>(false);
  const [pdfModal, setPdfModal] = React.useState(false);
  const [selectedSummary, setSelectedSummary] = React.useState({ pdfData: null as string, pdfTitle: null as string, uniqueId: null as string, loading: false });
  const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);

  useEffect(() => {
    if (!cecl.usefulDocumentsData?.documents) {
      dispatch(ceclapiUsefulDocumentsRequest());
      setExpanded(0);
    }
  }, []);

  if (!cecl.usefulDocumentsData?.documents) {
    return <CircularProgress size={24} className={classes.buttonProgress} />;
  }

  function filterAndSortDocuments(documents: UsefulDocuments[]) {
    let filteredDocs = documents.filter(d => d.title !== null && d.title !== "");
    return filteredDocs.sort((a, b) => a.title.localeCompare(b.title));
  }

  const grouped = groupBy(filterAndSortDocuments(cecl.usefulDocumentsData.documents), document => {
    let docArr = document.path.split('/');
    return docArr[docArr.length - 2];
  });

  const createSections = () => {
    let items = Array.from(grouped.keys()).filter(s => s);
    let order = 0;
    const map = new Map();

    const newArr = items.sort().map(str => {
      const index = map.get(str);
      if (!index) {
        map.set(str, order);
        order++;
        return { label: str, order };
      } 
      
      return { label: str, index };
    });

    return orderBy(newArr, o => o.order, 'asc');
  };

  const sections = createSections();

  const handleChange = (i: number) => (event, expanded: boolean) => {
    setExpanded(expanded ? i : false);
    setPage(0);
  };

  const handleClick = (e, i: number) => {
    setExpanded(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const labelDisplayedRows = ({ from, to, count }) => `${from}-${to} of ${count}`;

  function viewPDF() {
    setPdfModal(true);
    setSelectedSummary({...selectedSummary, loading: true})
  };

  const pdfOnClose = () => {
    setPdfModal(false);
    setSelectedSummary({...selectedSummary, pdfData: null, pdfTitle: null, loading: false});
  };

  function isPdfFile(fileName) {
    var extension = fileName.substring(fileName.lastIndexOf('.') + 1);
    return (extension.toLowerCase() === 'pdf');
  };

  const PdfTitleActions = () => (
    <FileDownloadButton
      modalHandler={pdfOnClose} 
      url={`usefulDocuments/getFile/${selectedSummary.uniqueId}`}
      isIcon={true}
    />
);

  return (
    <div>
      <Fragment>
        <div>
          {sections.map(({ label }, i) => {
            const count = grouped.get(label).length;

            return (
              <ExpansionPanel
                square
                expanded={expanded === i}
                onChange={handleChange(i)}
                key={uuid()}
              >
                <ExpansionPanelSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  className={classes.expansionPanel}
                >
                  <Typography style={{ fontSize: 18, color: '#444' }}>{label}</Typography>
                  <Button>{expanded === i ? 'HIDE' : 'VIEW'} FILES</Button>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                  <Table>
                    <TableBody>
                      {grouped
                        .get(label)
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((f: UDocument, i) => (
                          <TableRow key={uuid()}>
                            <TableCell>
                              <Typography variant="h6" component="p">
                                {f.title}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              { isPdfFile(f.path) && (
                                <FileViewButton 
                                  clickHandler={setSelectedSummary}
                                  modalHandler={viewPDF}
                                  modalTitle={f.title}
                                  pdfId={f.uniqueId}
                                  url={`usefulDocuments/getFileBase64/${f.uniqueId}`}
                                />
                              )}
                              <FileDownloadButton
                                downloadingLabel="Document is being prepared for download."
                                url={`usefulDocuments/getFile/${f.uniqueId}`}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <div className={classes.tablePagination}>
                    <IconButton
                      className={classes.firstOrLast}
                      onClick={e => handleChangePage(e, 0)}
                      disabled={page === 0 || count < rowsPerPage}
                      color="inherit"
                      aria-label="previous page"
                    >
                      <KeyboardArrowLeft />
                      <KeyboardArrowLeft />
                    </IconButton>
                    <IconButton
                      onClick={e => handleChangePage(e, page - 1)}
                      disabled={page === 0}
                      color="inherit"
                      aria-label="previous page"
                    >
                      <KeyboardArrowLeft />
                    </IconButton>
                    <Typography variant="body2">
                      {labelDisplayedRows({
                        from: grouped.get(label).length === 0 ? 0 : page * rowsPerPage + 1,
                        to: Math.min(grouped.get(label).length, (page + 1) * rowsPerPage),
                        count: grouped.get(label).length,
                      })}
                    </Typography>
                    <IconButton
                      onClick={e => handleChangePage(e, page + 1)}
                      disabled={page >= Math.ceil(grouped.get(label).length / rowsPerPage) - 1}
                      color="inherit"
                      aria-label="next page"
                    >
                      <KeyboardArrowRight />
                    </IconButton>
                    <IconButton
                      className={classes.firstOrLast}
                      onClick={e => handleChangePage(e, Math.ceil(count / rowsPerPage) - 1)}
                      disabled={page === Math.ceil(count / rowsPerPage) - 1 || count < rowsPerPage}
                      color="inherit"
                      aria-label="previous page"
                    >
                      <KeyboardArrowRight />
                      <KeyboardArrowRight />
                    </IconButton>
                  </div>
                </ExpansionPanelDetails>
                <div className={classes.footer}>
                  <IconButton onClick={e => handleClick(e, i)} color="inherit">
                    <KeyboardArrowUp />
                  </IconButton>
                </div>
              </ExpansionPanel>
            );
          })}
        </div>
      </Fragment>
      <Modal
          open={pdfModal}
          onClose={() => pdfOnClose()}
          contentbackgroundcolor={colors.paleGrey}
          title={selectedSummary.pdfTitle}
          loading={selectedSummary.loading}
          titleactions={<PdfTitleActions />}
      >
          <Pdf data={selectedSummary.pdfData} loading={selectedSummary.loading} />
      </Modal>
    </div>
  );
};

export default UsefulDocumentsPage;

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    marginTop: 40,
    marginBottom: 40,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      height: 'unset',
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 45,
    '&$expanded': {
      height: 'unset',
      minHeight: 45,
    },
  },
  content: {
    color: colors.navyBlue,
    '& p': {
      fontSize: 18,
      fontWeight: 600,
      marginTop: 10,
    },
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expansionPanel: {
      backgroundColor: colors.white,
      display: 'flex',
      '& button': {
        marginLeft: 'auto',
        fontSize: 18,
        color: colors.navyBlue,
        '&:hover': {
          backgroundColor: colors.navyBlue,
          color: colors.white,
        },
      },
    },
    buttonProgress: {
      color: colors.lipstick,
      position: 'absolute',
      top: '50%',
      left: '54%',
      marginTop: -12,
      marginLeft: -12,
    },
    tableHeader: {
      backgroundColor: colors.navyBlue,
      '& th': {
        fontSize: 16,
        color: colors.white,
      },
    },
    expansionPanelDetails: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '95%',
      [theme.breakpoints.only('xs')]: {
        width: '100%',
        '& td': {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
      margin: '0 auto',
    },
    tablePagination: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 15,
    },
    footer: {
      borderTop: `1px solid rgba(0, 0, 0, 0.125)`,
      borderBottom: `1px solid rgba(0, 0, 0, 0.125)`,
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      '& svg': {
        opacity: 0.3,
      },
    },
    firstOrLast: {
      padding: 0,
      '& svg': {
        '&:last-child': {
          marginLeft: -15,
        },
      },
    },
  })
);