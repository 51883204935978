import React, { ChangeEvent } from "react";
import { useSelector } from "react-redux";
import Table, { Column, TableProps } from "../../components/table/Table";
import { ApplicationState } from "../../store";
import { CeclApiState, ForecastSpecialClassificationLoan } from "../../store/ceclapi/types";
import { colors } from "../../theme";

export interface ForecastSpecialClassificationLoansTableProps{
    specialClassificationLoans: ForecastSpecialClassificationLoan[];
    handleTableEdit: (event: ChangeEvent<HTMLInputElement>, rowIndex: number, colName: string) => void;
}

const ForecastSpecialClassificationLoansTable: React.FC<ForecastSpecialClassificationLoansTableProps> = (props) =>{
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);

    const [specialClassificationLoansTableProps, setSpecialClassificationLoansTableProps] = React.useState({
        loading: true,
        dense: true,
        keyColumn: "name",
        rows: [],
        columns: [],
        selectable: false,
        hasBorder: true,
    } as TableProps)
    const [state, setState] = React.useState<ForecastSpecialClassificationLoansTableProps>({ ...props });

    const defaultSpecialClassificationLoansColumns : Column[] = [
        {
            name: "name", 
            label: "", 
            numeric: false, 
            bodyColor: colors.lightGray3
        },
        {
            name: "Specific Loan Loss Ending Balances", 
            label: "Specific Loan Loss Ending Balances", 
            numeric: false, 
            alignRight: true,
            editable: true, 
            decorator: "$",
            decoratorPlacement: "start",
            numberMask: true,
            headerColor: colors.lightGray3,
            centerHeader: true,
        },
        {
            name: "Allocation", 
            label: "Allocation", 
            numeric: false,
            alignRight: true,          
            editable: true, 
            decorator: "$",
            decoratorPlacement: "start",
            numberMask: true,
            headerColor: colors.lightGray3,
            centerHeader: true,
        },
    ];

    React.useEffect(() => {
        mapSpecialClassificationLoansTableDataFromProps();
    }, [props])

    function mapSpecialClassificationLoansTableDataFromProps(){
        if(!props.specialClassificationLoans){
            if(cecl!.forecastAssumptions?.configuration?.loading){
                setSpecialClassificationLoansTableProps((prevState) => ({...prevState, loading: true}))
            }
            return;
        }
        
        var newRows = props.specialClassificationLoans
        .sort((a, b) => {
            return a.order! - b.order!;
        })
        .map((loan) => {
                var obj : any = {};
                obj["name"] = loan.name;
                obj["Specific Loan Loss Ending Balances"] = loan.specificLoanLossEndingBalance;
                obj["Allocation"] = loan.allocation;  
                obj["error"] = loan.error;
                return obj;
        });

        setSpecialClassificationLoansTableProps((prevState) => ({...prevState, columns: defaultSpecialClassificationLoansColumns, rows: newRows, loading: false}))
    }
    
    return (
        <div>
            <Table
                loading={specialClassificationLoansTableProps.loading}
                columns={specialClassificationLoansTableProps.columns}
                dense={specialClassificationLoansTableProps.dense}
                rows={specialClassificationLoansTableProps.rows}
                keyColumn={specialClassificationLoansTableProps.keyColumn}
                selectable={specialClassificationLoansTableProps.selectable}
                hasBorder={specialClassificationLoansTableProps.hasBorder}
                onEdit={props.handleTableEdit}
            />
        </div>
    );
}

export default ForecastSpecialClassificationLoansTable;