import { Button, ButtonProps, CircularProgress, IconButton, makeStyles, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import SaveIcon from '@material-ui/icons/Save';
import { AxiosError, Method } from 'axios';
import React, { FC, useState } from 'react';
import { AxiosInstance } from '../../utils/callApi';

interface FileDownloadButtonProps extends ButtonProps {
  url: string;
  label?: string;
  downloadingLabel?: string;
  fileName?: string;
  isIcon?: boolean;
  buttonClass?: string;
  requestMethod?: Method;
  data?: any;
  modalHandler?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button:{}
    })
);

const FileDownloadButton: FC<FileDownloadButtonProps> = ({
  url,
  label,
  downloadingLabel,
  startIcon,
  isIcon,
  buttonClass,
  requestMethod,
  data,
  modalHandler,
  ...props
}) => {
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState<AxiosError<{ message: string }> & any>();
  const classes = useStyles();

  const handleDownload = e => {
    const run = async () => {
      setDownloading(true);

      await AxiosInstance({
        method: requestMethod,
        url,
        responseType: 'blob',
        data: data,
        timeout: null,
      })
        .then(response => {
          const blob = new Blob([response.data], { type: response.data.type });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          const contentDisposition = response.headers['content-disposition'];
          let fileName = 'unknown';

          if (props.fileName) {
            fileName = props.fileName;
          } else {
            if (contentDisposition) {
              const fileNameMatch = contentDisposition.match(/filename=(.+);/);
              if (fileNameMatch?.length === 2) {
                fileName = fileNameMatch[1];
                fileName = fileName.replace(/[/\\?%*:|"<>]/g, '');
              }
            }
          }
          
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch(err => setError(err))
        .finally(() => {
          setDownloading(false)
          if (isIcon) {
            modalHandler();
          }
        });
    };

    run();
  };

  if (error) return <div>{error?.message}</div>;

  return isIcon ? (
      <IconButton
        disabled={downloading}
        onClick={handleDownload}
      >
        <SaveIcon />
      </IconButton>
  ) : (
      <Button 
        className={buttonClass ? buttonClass : classes.button}
        disabled={downloading}
        onClick={handleDownload}
        startIcon={downloading ? startIcon : null}
        {...props}
      >
        {downloading ? downloadingLabel : label}
      </Button>
  );
};

FileDownloadButton.defaultProps = {
  startIcon: <CircularProgress size={10} color="inherit" />,
  label: 'download',
  downloadingLabel: 'downloading',
  isIcon: false,
  requestMethod: 'GET',
  data: null,
  modalHandler: null,
};

export default FileDownloadButton;
