import { Reducer } from 'redux';
import { ConfigState, ConfigActionTypes } from './types';

export const initialState: ConfigState = {
    bottomPanel: false,
    sideMenu: true,
    modal: false,
    isLoggedIn: false,
};

export const ConfigReducer: Reducer<ConfigState> = (state = initialState, action) => {
    switch (action.type) {
        case ConfigActionTypes.TOGGLE_MODAL:
            return { ...state, modal: !state.modal };
        case ConfigActionTypes.TOGGLE_SIDEMENU:
            return { ...state, sideMenu: !state.sideMenu };
        case ConfigActionTypes.TOGGLE_BOTTOMPANEL:
            return { ...state, bottomPanel: !state.bottomPanel };
        case ConfigActionTypes.COOKIES_SUCCESS:
            return { ...state, ...action.payload };
        case ConfigActionTypes.SAVE_COOKIES_SUCCESS:
            return { ...state, ...action.payload };
        case ConfigActionTypes.SAVE_SNACKBAR_KEY_REQUEST:
            return { ...state, snackbarKey: action.payload};
        default:
            return state;
    }
};
