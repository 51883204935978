import { Checkbox, Dialog, DialogContentText, DialogTitle, Grid, IconButton, lighten } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../components/button/Button';
import Modal from '../../components/modal/Modal';
import Panel from '../../components/panel/Panel';
import Table, { Column, TableProps } from '../../components/table/Table';
import Pdf from '../../containers/pdf/Pdf';
import { ApplicationState, LastAction } from '../../store';
import {
    ceclapiForecastHistoryRequest,
    ceclapiDeleteForecastRequest,
    ceclapiDocumentRequest,
    ceclapiCreatePdfClear,
    ceclapiForecastToggleFinalRequest,
} from '../../store/ceclapi/actions';
import { AssumptionSummary, CeclApiState } from '../../store/ceclapi/types';
import { colors } from '../../theme';
import SaveIcon from '@material-ui/icons/Save';
import { displayToast } from '../../store/config/actions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        panels: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flex: 1,
            margin: theme.spacing(1),
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 100,
        },
        title: {
            fontSize: 20,
            fontFamily: 'Montserrat',
            fontWeight: 'bolder',
            margin: 10,
            textAlign: 'center',
        },
        controlPanel: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        controlPanelTitle: {
            alignSelf: 'center',
        },
        controlPanelForm: {
            flex: 1,
        },
        panel: {
            margin: theme.spacing(2),
        },
        tablePanel: {
            padding: 0,
        },
        grid: {
            maxWidth: 1800,
        },
        viewPDFLink: {
            color: colors.blue,
            textDecoration: 'none',
            fontWeight: 600,
            backgroundColor: colors.white,
            padding: 5,
            lineHeight: 1,
            width: theme.spacing(10),
            fontSize: 14,
            '&:hover': {
                backgroundColor: lighten(colors.blue, 0.9),
            },
            whiteSpace: 'nowrap',
        },
        deleteLink: {
            color: colors.red,
            textDecoration: 'none',
            fontWeight: 600,
            backgroundColor: colors.white,
            padding: 5,
            lineHeight: 1,
            width: theme.spacing(10),
            fontSize: 14,
            '&:hover': {
                backgroundColor: lighten(colors.red, 0.9),
            },
        },
        emptyTablePanel: {
            boxShadow: 'none',
            textAlign: 'center',
            fontStyle: 'italic',
        },
        dialogTitle: {
            fontWeight: 'bold',
        },
        dialogBox: {
            textAlign: 'center',
        },
        dialogConfirm: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        dialogCancel: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: colors.white,
            color: colors.blue,
            textDecoration: 'underline',
            '&:hover': {
                backgroundColor: colors.white,
            },
        },
        gridLeft: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        gridRight: {
            display: 'flex',
            justifyContent: 'flex-start',
        },
        button: {
            transform: 'scale(0.7)',
        },
        forecastHistoryButton: {
            color: colors.blue,
            backgroundColor: colors.white,
            borderColor: colors.blue,
            '&:hover': {
                backgroundColor: lighten(colors.blue, 0.9),
            },
        },
        createForecast: {
            width: 300,
        },
        forecastLink: {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    })
);

interface DialogProps {
    id: string;
    open: boolean;
}

const ForecastHistoryPage: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const lastAction = useSelector<ApplicationState, LastAction>((state) => state.lastAction);
    const history = useHistory();
    const [pdfModal, setPdfModal] = React.useState(false);
    const [selectedSummary, setSelectedSummary] = React.useState(null);

    const defaultColumns: Column[] = [
        { name: 'Forecast Name', label: 'Forecast Name', numeric: false, sortable: true, filterable: true },
        {
            name: 'Analysis Date',
            label: 'Analysis Date',
            numeric: false,
            sortable: true,
            filterable: true,
            isDate: true,
        },
        { name: 'Date Created', label: 'Date Created', numeric: false, sortable: true, filterable: true, isDate: true },
        { name: 'Created By', label: 'Created By', numeric: false, sortable: true, filterable: true },
        { name: 'Final', label: 'Final', numeric: false, sortable: false, filterable: false, centerHeader: true },
        { name: 'Actions', label: 'Actions', numeric: false, sortable: false, centerHeader: true, centerBody: true },
    ];
    const [tableProps, setTableProps] = React.useState({
        loading: true,
        dense: true,
        keyColumn: 'name',
        rows: [],
        columns: [],
        pageSize: 15,
    } as TableProps);
    const [dialogProps, setDialogProps] = React.useState({
        id: '',
        open: false,
    } as DialogProps);

    React.useEffect(() => {
        setTableProps({ ...tableProps, loading: true });
        dispatch(ceclapiForecastHistoryRequest({ cuid: cecl.user!.routingNumber }));
    }, []);

    // This functions as a redux action watcher.
    React.useEffect(() => {
        if (lastAction.type === '@@ceclapi/CECLAPI_DOCUMENT_ERROR') {
            pdfOnClose();

            dispatch(
                displayToast({
                    message: 'An unexpected error occurred. Please try your request again.',
                    variant: 'error',
                })
            );
        }

        if (lastAction.type === '@@ceclapi/CECLAPI_FORECASTTOGGLEFINAL_SUCCESS') {
            dispatch(
                displayToast({
                    message: `Forecast "${lastAction.payload.name}" is now ${
                        lastAction.payload.isFinal ? 'finalized' : 'un-finalized'
                    }.`,
                    variant: 'success',
                })
            );
        }

        if (lastAction.type === '@@ceclapi/CECLAPI_FORECASTTOGGLEFINAL_ERROR') {
            dispatch(
                displayToast({
                    message: 'An unexpected error occurred. Please try your request again',
                    variant: 'error',
                })
            );
        }
    }, [lastAction]);

    React.useEffect(() => {
        mapTableDataFromHistorySummaries();
    }, [cecl.forecastHistory]);

    const handleDialogOpen = (id: string) => {
        setDialogProps({ ...dialogProps, id: id, open: true });
    };

    const handleDialogClose = () => {
        setDialogProps({ ...dialogProps, id: '', open: false });
    };

    const handleDeleteForecast = () => {
        var forecastId = dialogProps.id;
        dispatch(ceclapiDeleteForecastRequest({ cuid: cecl.user!.routingNumber, id: forecastId }));
        handleDialogClose();
    };

    const viewPDF = (summary: AssumptionSummary) => {
        dispatch(ceclapiCreatePdfClear());
        dispatch(ceclapiDocumentRequest({ forecastId: summary.id, base64Response: true }));
        setSelectedSummary(summary);
        setPdfModal(true);
    };

    const mapTableDataFromHistorySummaries = () => {
        if (!cecl?.forecastHistory?.summaries?.length) {
            if (cecl?.forecastHistory?.loading) {
                setTableProps({ ...tableProps, loading: true });
            } else {
                setTableProps({ ...tableProps, columns: defaultColumns, loading: false });
            }
            return;
        }

        var newRows = cecl?.forecastHistory?.summaries?.map((summary) => {
            var obj: any = {};
            obj['id'] = summary.id;
            obj['name'] = summary.name;
            obj['Forecast Name'] = (
                <div className={classes.forecastLink} onClick={() => viewPDF(summary)}>
                    {summary.name}
                </div>
            );
            obj['Analysis Date'] = new Date(summary.analysisDate).toLocaleDateString();
            obj['Date Created'] = new Date(summary.dateCreated).toLocaleString();
            obj['Created By'] = summary.createdBy;
            obj['Final'] = (
                <div>
                    <Checkbox
                        checked={summary.isFinal}
                        onClick={() => dispatch(ceclapiForecastToggleFinalRequest(summary.id))}
                    />
                </div>
            );
            obj['Actions'] = (
                <div>
                    <Button variant="text" className={classes.viewPDFLink} onClick={() => viewPDF(summary)}>
                        View PDF
                    </Button>
                    &nbsp; &nbsp; &nbsp;
                    <Button variant="text" className={classes.deleteLink} onClick={() => handleDialogOpen(summary.id)}>
                        Delete
                    </Button>
                </div>
            );
            return obj;
        });

        setTableProps({ ...tableProps, columns: defaultColumns, rows: newRows, loading: false, pageSize: 15 });
    };

    const PdfActions = () => (
        <Grid container direction="row" alignItems="center" spacing={0} justify="center">
            <Grid item xs={2}>
                {' '}
            </Grid>
            <Grid item xs={2} className={classes.gridLeft}>
                <Checkbox
                    checked={selectedSummary?.isFinal}
                    onClick={() => dispatch(ceclapiForecastToggleFinalRequest(selectedSummary?.id))}
                    color="secondary"
                />
            </Grid>
            <Grid item xs={6}>
                Mark as Final Reported Model
            </Grid>
            <Grid item xs={2}>
                {' '}
            </Grid>
            <Grid item xs={6} className={classes.gridLeft}>
                <Button className={clsx(classes.button, classes.forecastHistoryButton)} onClick={() => pdfOnClose()}>
                    Go to Forecast History
                </Button>
            </Grid>
            <Grid item xs={6} className={classes.gridRight}>
                <Button
                    className={clsx(classes.button, classes.createForecast)}
                    onClick={() => history.push(`forecast/assumptions/${cecl.user!.routingNumber}`)}
                >
                    Create Another Forecast
                </Button>
            </Grid>
        </Grid>
    );

    const pdfOnClose = () => {
        setPdfModal(false);
        setSelectedSummary(null);
    };

    const PdfTitleActions = () => (
        <IconButton
            onClick={() => {
                dispatch(ceclapiCreatePdfClear());
                dispatch(
                    ceclapiDocumentRequest({
                        forecastId: selectedSummary.id,
                        base64Response: false,
                        fileName: `forecast_${new Date().toISOString()}.pdf`,
                    })
                );

                pdfOnClose();
            }}
        >
            <SaveIcon />
        </IconButton>
    );

    return (
        <div className={classes.grid}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Panel className={classes.tablePanel}>
                        <Table
                            loading={cecl.forecastHistory?.loading || tableProps.loading}
                            columns={tableProps.columns}
                            dense={tableProps.dense}
                            rows={tableProps.rows}
                            keyColumn={tableProps.keyColumn}
                            pageSize={tableProps.pageSize}
                            sortable={tableProps.sortable}
                        ></Table>
                        {!tableProps.loading && !cecl?.forecastHistory?.summaries?.length && (
                            <Panel className={classes.emptyTablePanel}>Forecast history will appear here</Panel>
                        )}
                    </Panel>
                </Grid>
            </Grid>
            <Dialog
                className={classes.dialogBox}
                onClose={() => handleDialogClose()}
                open={dialogProps.open}
                aria-labelledby="delete-dialog-title"
            >
                <DialogTitle className={classes.dialogTitle} id="delete-dialog-title">
                    Delete Forecast?
                </DialogTitle>
                <DialogContentText id="delete-dialog-description">
                    Deleting this forecast will remove it from your credit union's history and you will not be able to
                    retrieve it again. If you are sure you want to proceed with deleting this forecast, click "Confirm"
                    below. Otherwise, click "Cancel" to go back to your forecast history screen without deleting the
                    forecast.{' '}
                </DialogContentText>
                <Button className={classes.dialogConfirm} onClick={handleDeleteForecast}>
                    Confirm
                </Button>
                <Button className={classes.dialogCancel} onClick={() => handleDialogClose()}>
                    Cancel
                </Button>
            </Dialog>
            <Modal
                open={pdfModal}
                onClose={() => pdfOnClose()}
                contentbackgroundcolor={colors.lightGray}
                title="Forecast Data PDF"
                loading={cecl?.pdf?.loading}
                titleactions={<PdfTitleActions />}
                actions={<PdfActions />}
            >
                <Pdf data={cecl?.pdf?.data} loading={cecl?.pdf?.loading} />
            </Modal>
        </div>
    );
};

export default ForecastHistoryPage;
