import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import Table, { Column } from '../../components/table/Table';
import { ApplicationState } from '../../store';
import { CeclApiState } from '../../store/ceclapi/types';
import { colors } from '../../theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: 24,
            fontFamily: 'Montserrat',
            fontWeight: 'bolder',
            margin: 10,
            textAlign: 'center',
            marginBottom: theme.spacing(5)
        },
        noScroll: {
            '& > *': {
                '& > *': {
                    overflowX: 'unset',
                },
            },
        },
    })
);

interface HistoricalDataTableProps {
    selectedTable: string;
    noScroll?: boolean;
}

interface TableProps {
    columns: Column[];
    dense: boolean;
    rows: any;
    keyColumn: string;
    loading: boolean;
    availableTables: string[];
}

const HistoricalDataTable: React.FC<HistoricalDataTableProps> = (props) => {
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const classes = useStyles();

    const [tableProps, setTableProps] = React.useState({
        loading: true,
        dense: true,
        keyColumn: 'name',
        availableTables: [],
        rows: [],
        columns: [],
    } as TableProps);

    React.useEffect(() => {
        if (props.selectedTable) {
            mapTableDataFromGraphData(props.selectedTable);
        }
    }, [props.selectedTable]);

    const mapTableDataFromGraphData = (tableName: string) => {
        if (!cecl?.historicalData?.charts?.length || !tableName) {
            return;
        }

        const myChart = cecl?.historicalData?.charts?.filter((chart) => chart.graphName === tableName)[0];

        let myColumns: Column[] = myChart
            ?.data![0].dataPoints?.sort((a, b) => a.label.localeCompare(b.label))
            .map((dataPoint) => {
                const obj: Column = 
                { 
                    name: '', 
                    numeric: false, label: '', 
                    numericPrecision: 0,
                    headerFontSize: 22,
                    bodyFontSize: '1.3000rem',
                    bodyFontWeight: 'bold',
                };

                if (dataPoint.label) {
                    obj.name = dataPoint.label;
                    obj.numeric = true;
                    obj.label = dataPoint.label;
                } else {
                    obj.name = dataPoint.x;
                    obj.numeric = true;
                    obj.label = dataPoint.x;
                }

                if (
                    myChart.graphName == 'All CUs Annualized Loss Pct' ||
                    myChart.graphName == 'Asset Size Annualized Loss Pct' ||
                    myChart.graphName == 'CU Annualized Loss Pct' ||
                    myChart.graphName == 'US State & Asset Size Annualized Loss Pct' ||
                    myChart.graphName == 'CU Rates'
                ) {
                    obj.multiplyFactor = 100;
                    obj.decorator = '%';
                    obj.decoratorPlacement = 'end';
                    obj.numericPrecision = 2;
                } else if (
                    myChart.graphName == 'All CUs Balances' ||
                    myChart.graphName == 'All CUs Net Charge Offs' ||
                    myChart.graphName == 'Asset Size Balances' ||
                    myChart.graphName == 'Asset Size Net Charge Offs' ||
                    myChart.graphName == 'CU Balances' ||
                    myChart.graphName == 'CU Charge Offs' ||
                    myChart.graphName == 'CU Net Charge Offs' ||
                    myChart.graphName == 'US State Balances' ||
                    myChart.graphName == 'US State Net Charge Offs' ||
                    myChart.graphName == 'US State & Asset Size Balances' ||
                    myChart.graphName == 'US State & Asset Size Net Charge Offs'
                ) {
                    obj.decorator = '$';
                    obj.decoratorPlacement = 'start';
                    obj.numericPrecision = 0;
                } else {
                    obj.numericPrecision = 2;
                }

                obj.headerColor = colors.blue;
                return obj;
            })!;

        if (!myColumns) {
            return;
        }

        const firstColumn: Column = { name: tableName, numeric: false, label: tableName, bodyColor: colors.blue, bodyFontSize: '1.3000rem', bodyFontWeight: 'bold' };

        myColumns = myColumns.sort((a, b) => {
            const splitA = a.name.toString().split(' ');
            const splitB = b.name.toString().split(' ');

            return parseInt(splitA[splitA.length - 1]) - parseInt(splitB[splitB.length - 1]);
        });
        myColumns = [firstColumn, ...myColumns];

        const myRows = myChart?.data?.map((account) => {
            const obj: any = {
                name: account.name,
            };

            obj[tableName] = account.name;
            obj['type'] = account.type;

            account?.dataPoints?.forEach((dataPoint) => {
                if (dataPoint.label) {
                    obj[dataPoint.label] = dataPoint.y;
                } else {
                    obj[dataPoint.x] = dataPoint.y;
                }
            });

            return obj;
        });

        setTableProps({
            ...tableProps,
            columns: myColumns,
            rows: myRows,
            loading: false,
        });
    };

    return (
        <div>
            <div id="historical-data-table" className={props.noScroll ? classes.noScroll : null}>
                <div id={props.selectedTable.toLowerCase().replace(/ /g, '-')}>
                    <div className={classes.title} id={props.selectedTable.toLowerCase().replace(/ /g, '-') + '-title'}>
                        {props.selectedTable}
                    </div>
                    <Table
                        id={props.selectedTable.toLowerCase().replace(/ /g, '-')  + '-table'}
                        loading={tableProps.loading}
                        columns={tableProps.columns}
                        dense={tableProps.dense}
                        rows={tableProps.rows}
                        keyColumn={tableProps.keyColumn}
                    />
                </div>
            </div>
        </div>
    );
};

export default HistoricalDataTable;
