import React from 'react';
import { IconButton, Popover, TextField } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Close, FilterList } from '@material-ui/icons';
import clsx from 'clsx';
import { colors } from '../../theme';
import { Column, tableStyles } from './Table';

type Order = 'asc' | 'desc';

export interface FilteredColumn {
    name: string;
    text: string;
    isSelect?: boolean;
}

interface TableHeaderProps {
    classes: ReturnType<typeof tableStyles>;
    columns: Column[];
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFilterColumnsChange: (filteredColumns: FilteredColumn[]) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    selectable: boolean;
    hasBorder: boolean;
    hasSecondHeaderRow: boolean;
    collapsible: boolean;
    defaultFilterValue?: FilteredColumn[];
    headerFontSize?: string;
}

const TableHeader = (props: TableHeaderProps) => {
    const {
        classes,
        columns,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        selectable,
        onFilterColumnsChange,
        onRequestSort,
        hasBorder,
        hasSecondHeaderRow,
        collapsible,
        defaultFilterValue,
        headerFontSize,
    } = props;
    
    const [filteredColumns, setFilteredColumns] = React.useState<FilteredColumn[]>(defaultFilterValue != null ? defaultFilterValue : []);

    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    React.useEffect(() => {
        onFilterColumnsChange(filteredColumns);
    }, [filteredColumns]);

    //@ts-ignore
    const FilterButton = ({ column, i }) => {
        const currentFilter = filteredColumns.filter((f) => f.name === column.name);
        const [showFilterBox, setShowFilterBox] = React.useState(false);
        const [anchorElement, setAnchorElement] = React.useState<EventTarget & HTMLButtonElement>();
        const [filterValue, setFilterValue] = React.useState(currentFilter.length ? currentFilter[0].text : '');

        const submit = (value: string | null = null) => {
            const filters = filteredColumns.filter((c) => c.name !== column.name);

            if (value) {
                var isSelect = column.isSelect ? column.isSelect : null; //is select/drop down component
                if (isSelect){ 
                    filters.push({ name: column.name, text: value, isSelect: isSelect });
                }
                else {
                    filters.push({ name: column.name, text: value });
                }                
            }

            setFilteredColumns(filters);
        };

        return (
            <>
                <IconButton
                    className={clsx(classes.filterListIcon, currentFilter.length ? classes.showFilter : null)}
                    onClick={(e) => {
                        setAnchorElement(e.currentTarget);
                        setShowFilterBox(true);
                    }}
                >
                    <FilterList />
                </IconButton>
                <Popover
                    id={column.name}
                    open={showFilterBox}
                    anchorEl={anchorElement}
                    onClose={() => setShowFilterBox(false)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <TextField
                        id={column.name + '-filter'}
                        variant="standard"
                        label={'Filter ' + column.label}
                        value={filterValue}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                submit(filterValue);
                            }
                        }}
                        onChange={(e) => {
                            setFilterValue(e.target.value);
                        }}
                        InputLabelProps={{
                            margin: 'dense',
                            style: { color: colors.black },
                        }}
                    />
                    <IconButton
                        onClick={() => {
                            submit('');
                        }}
                    >
                        <Close />
                    </IconButton>
                </Popover>
            </>
        );
    };

    return (
        <TableHead>
            {props.hasSecondHeaderRow && (
                <TableRow>
                    {columns
                        .filter((c) => !c.secondHeaderHide)
                        .map((column, colIndex) => (
                            <TableCell
                                key={colIndex}
                                align={column.centerHeader ? 'center' : column.numeric ? 'right' : 'left'}
                                className={classes.header}
                                colSpan={column.secondHeaderColSpan ?? undefined}
                                width={column.maxWidth}
                                style={{
                                    backgroundColor: column.secondHeaderColor
                                        ? column.secondHeaderColor
                                        : 'transparent',
                                    borderRight: column.secondHeaderHasBorder ? '1px solid #e0e0e0' : 'none',
                                    fontSize: column.secondHeaderFontSize ? column.secondHeaderFontSize : 'inherit',
                                }}
                            >
                                {column.secondHeader}
                            </TableCell>
                        ))}
                </TableRow>
            )}
            <TableRow>
                {selectable && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all' }}
                        />
                    </TableCell>
                )}
                {collapsible && (
                    <TableCell
                        key={-1}
                        align='center'
                        sortDirection={false}
                        className={classes.header}
                        style={{
                            backgroundColor: 'transparent',
                            borderRight: props.hasBorder ? '1px solid #e0e0e0' : 'none',
                        }}
                    >                        
                    </TableCell>
                )}
                {columns.map((column, i) => (
                    <TableCell
                        key={i}
                        align={column.centerHeader ? 'center' : column.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === column.name ? order : false}
                        className={classes.header}
                        style={{
                            backgroundColor: column.headerColor ? column.headerColor : 'transparent',
                            borderRight: props.hasBorder ? '1px solid #e0e0e0' : 'none',
                            fontSize: column.headerFontSize ? column.headerFontSize : 'inherit',
                        }}
                    >
                        {column.sortable && (
                            <TableSortLabel
                                active={orderBy === column.name}
                                direction={orderBy === column.name ? order : 'asc'}
                                onClick={createSortHandler(column.name)}
                            >
                                {column.label}
                                {orderBy === column.name ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        )}
                        {column.filterable && <FilterButton column={column} i={i} />}
                        {!column.sortable && column.label}
                        <p style={{fontSize: 12, fontStyle: "italic"}}>{column.secondaryLabel}</p>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default TableHeader;
