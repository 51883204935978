import React from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Button, Popper, ClickAwayListener, Paper, MenuList, Grow, ListItem, ListItemText } from '@material-ui/core';
import { withRouter, RouteComponentProps, NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { CeclApiState, UserTypes } from '../../store/ceclapi/types';

type Props = RouteComponentProps;

const UserInfoDropdown: React.FC<Props> = () => {
    const cecl = useSelector<ApplicationState, CeclApiState>((state) => state.cecl);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const location = useLocation<any>();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    //   const goToProfile = (event: React.MouseEvent<EventTarget, MouseEvent>) => {
    //     handleClose(event);
    //     history.push('/profile');
    //   };

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Button
                ref={anchorRef}
                onClick={handleToggle}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                style={{ zIndex: 1000 }}
            >
                <AccountCircle />
            </Button>

            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement="bottom-end"
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: 'left' }}>
                        <Paper className="menu">
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    {/* <MenuItem disabled>{user.name}</MenuItem>
                  {user.admin || <MenuItem onClick={goToProfile}>Profile</MenuItem>} */}
                                    <ListItem disabled={true}>
                                        <ListItemText id={`list-label-username`} primary={cecl.user?.name} />
                                    </ListItem>
                                    {(location.pathname === '/' || location.pathname.startsWith('/home')) && (
                                        <ListItem button component={NavLink} to={cecl.user?.userType === UserTypes.Admin ? '/usermanagement' : '/dashboard'}>
                                            <ListItemText id={`list-label-dashboard`} primary="Dashboard" />
                                        </ListItem>
                                    )}
                                    <ListItem button component={NavLink} to="/profile">
                                        <ListItemText id={`list-label-profile`} primary="Profile" />
                                    </ListItem>
                                    <ListItem button component={NavLink} to="/logout">
                                        <ListItemText id={`list-label-logout`} primary="Logout" />
                                    </ListItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export default withRouter(UserInfoDropdown);
